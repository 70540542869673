import axios from "axios"
import { useRef, useState } from "react"
import { useCookies } from "react-cookie"
import { useDispatch } from "react-redux"
import { ReactSVG } from "react-svg"
import { apiKey } from "../../../../../redux/store"
import { useParams } from "react-router-dom"
import { ADD_FILE_TO_CHAT_USER, REMOVE_ERROR_CHAT_FILE, SET_ERROR_CHAT_FILE, SET_ERROR_FOR_FILE_IN_CHAT_USER, SET_SUCCESS_FILE_IN_CHAT_USER } from "../../../../../redux/action/messages/chat/chatType"

const SendMessageFile = () => {

    const dispatch = useDispatch()

    const [progress, setProgress] = useState({pc: 0, started: false})

    const fileRef = useRef()

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    const addToMessage = (event) => {

        const file = event.target.files[0]

        if(!file) return

        if (!file?.type?.includes('docx') && !file?.type?.includes('png') &&  !file?.type?.includes('jpg') &&  !file?.type?.includes('pdf') ){
            
            dispatch({
                type: SET_ERROR_CHAT_FILE,
                payload: 'file.mime وارد نشده است'
            })

            fileRef.current.value = ''

            return

        }

        if ( (file?.size / 1000) >= 9000 ){
            
            dispatch({
                type: SET_ERROR_CHAT_FILE,
                payload: 'تا ۸ مگابایت مجاز است'
            })

            fileRef.current.value = ''

            return

        }

        const fileReader = new FileReader()

        fileReader.onloadend = async () => {
            const data = fileReader.result

            // send to the server
            addToServer(data, file)
        }

        fileReader.readAsDataURL(file)
       
    }

    const addToServer = async (data, file) => {

        // make data for send to api
        const info = {
            type: 'file',
            body: (file.name.slice(0, file.name.length - 4).slice(0, 15)) + `${file.name.length >= 23 ? '...' + file.name.slice(file.name.length - 7, file.name.length - 4) : ''}` + (file.name.slice(file.name.length - 4)),
            file: {
                caption: (file.name.slice(0, file.name.length - 4).slice(0, 15)) + `${file.name.length >= 23 ? '...' + file.name.slice(file.name.length - 7, file.name.length - 4) : ''}` + (file.name.slice(file.name.length - 4)),
                kind: file.type.split('/')[0],
                mime: file.type,
                data: data,
                size: file.size
            }

        }

        // set config of api
        const config = {
            headers: {
                'Auth-Token': cookies.authToken,
                'X-Auth-Token': cookies.authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 30000,
            onUploadProgress: (progressEvent) => handleLoading(progressEvent)
        }

        setProgress((prevProgress) => {
            return {...prevProgress, started: true}
        })

        const fileId = Math.floor(Math.random() * 1000000)
        const messageId = Math.floor(Math.random() * 1000000)

        dispatch({
            type: ADD_FILE_TO_CHAT_USER,
            payload: {

                file: {
                    id: fileId,
                    caption: (file.name.slice(0, file.name.length - 4).slice(0, 15)) + `${file.name.length >= 23 ? '...' + file.name.slice(file.name.length - 7, file.name.length - 4) : ''}` + (file.name.slice(file.name.length - 4)),
                    kind: file.type.split('/')[0],
                    mime: file.type,
                    src: data,
                    size: file.size
                },
                
                status: 'loading',
                
                id: messageId, 
                receiver_id: params.id,
                sender_id: 101010101010,
                type: 'file',
                body: (file.name.slice(0, file.name.length - 4).slice(0, 15)) + `${file.name.length >= 23 ? '...' + file.name.slice(file.name.length - 7, file.name.length - 4) : ''}` + (file.name.slice(file.name.length - 4)),
                seen: false,
                created_at: new Date(),
                date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'})

            }
        })

        setTimeout(() => {
            window.scrollTo({top: window.innerHeight + 50000, behavior: 'smooth'})
        }, 100);

        // add file to the server
        const response = await axios.post(`${apiKey}conversations/with/${params.id}`, {...info, kind: file?.type?.split('/')[0]}, config)
        .catch(error => {
                
            setProgress({pc: 0, started: false})

            fileRef.current.value = ''

            dispatch({
                type: SET_ERROR_FOR_FILE_IN_CHAT_USER,
                payload: {
                    receiver_id: params.id,
                    fileId,    
                    message: 'عدم برقراری ارتباط با سرور', 
                    date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'})
                }
            })
            
        })

        if(response && response?.data?.success){

            setProgress({pc: 0, started: false})

            fileRef.current.value = ''

            dispatch({
                type: SET_SUCCESS_FILE_IN_CHAT_USER,
                payload: {
                    receiver_id: params.id,
                    fileId,
                    messageId: response.data?.data.id,
                    date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'})
                }
            })

        }
        else if(response && response?.data?.errors?.length >= 1) {
            
            // cancle progress
            setProgress({pc: 0, started: false})

            // reset data
            fileRef.current.value = ''

            // set error for file
            dispatch({
                type: SET_ERROR_FOR_FILE_IN_CHAT_USER,
                payload: {
                    receiver_id: params.id,
                    fileId,    
                    message: response.data.errors[0]?.message,      
                    date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'})
                }
            })

        }
        else {

            // cancle progress
            setProgress({pc: 0, started: false})

            // reset data
            fileRef.current.value = ''

            // set error for file
            dispatch({
                type: SET_ERROR_FOR_FILE_IN_CHAT_USER,
                payload: {
                    receiver_id: params.id,
                    fileId,    
                    message: 'عدم برقراری ارتباط با سرور',      
                    date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'})
                }
            })

        }
        
    }

    const handleLoading = (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        
        if (percent < 100) {
            setProgress({started: progress.started, pc: percent})
        }
        else
            setProgress({started: false, pc: 0})

    }

    return (
        <>
            <input ref={fileRef} onInput={addToMessage} type="file" id="send-file" className="hidden" />
            
            <label htmlFor="send-file" className="absolute cursor-pointer text-gray-500 bottom-[0.8px] 3xm:w-10 w-8 h-12 flex items-center justify-center 3xm:left-12 left-10" type="button">
                
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/tag.svg`} className="3xm:w-6 3xm:h-6 w-[18px] h-[18px] rotate-[225deg]" />

            </label>

        </>
    )

}

export default SendMessageFile