import { useEffect } from "react"

const CaseAddFileContextItem = ({title, index, context, onSetContext}) => {

    useEffect(() => {

        if( !context && index === 0 )
            updateField(title)


    }, [])

    const updateField = () => {
        onSetContext(title)
    }

    return (
        <div>
            <input onChange={updateField} id={title} type="radio" name="title-context-suggest" className="hidden peer" value={title} checked={(context === title || (!context && index === 0)) ? true : false} />
            <label htmlFor={title} className="bg-white text-gray-700 shadow-custom-lg outline outline-2 outline-purple-200/40 peer-checked:outline-primary/20 px-3.5 py-1.5 rounded-2xl peer-checked:bg-primary peer-checked:text-white cursor-pointer duration-300"> {title} </label>
        </div>
    )

}

export default CaseAddFileContextItem