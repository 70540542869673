import { ADD_CASE_TO_CASES, SET_DELETE_ERROR_CASE, SET_DELETE_LOADING_CASE, RESET_FILEDS_OF_FILTER, SET_CASES_CASES, SET_FAILURE_LIST_CASES, SET_FILEDS_OF_FILTER, SET_FILTER_CASES, SET_FILTER_ERROR_CASES, SET_FILTER_FOR_CASES, SET_FILTER_SECTION_LOADING_CASES, SET_LIST_CASES, SET_LOADING_LIST_CASES, SHOW_FILTER_SECTION_CASES, DELETE_ITEM_OF_CASES, ADD_CASE_TO_SINGLE_CASE, SET_ERROR_SINGLE_CASE, SET_LOADING_SINGLE_CASE, SET_ACTIVE_TAB_SINGLE_CASE, SET_LAST_OPENED_SINGLE_CASE, SET_SHOW_DESCRIPTION_COLLAPSE_SINGLE_CASE, UPDATE_NOTE_IN_SINGLE_CASE, DELETE_ITEM_NOTE_SINGLE_CASE, ADD_ITEM_TO_NOTE_IN_SINGLE_CASE, SET_SORTED_INTO_SINGLE_CASE, DELETE_EVENT_FROM_SINGLE_CASE, UPDATE_EVENT_ITEM_IN_SINGLE_CASE, UPDATE_USERS_FROM_CASES, SET_SINGLES_CASES, UPDATE_CASE_FROM_CASES, ADD_EVENT_TO_SINGLE_CASE, SET_LOADING_ARCHIVE_LIKE_CASE, SET_ERROR_ARCHIVE_LIKE_CASE, SET_SUCCSESS_ARCHIVE_LIKE_CASE, UPDATE_ARCHIVE_DATA_FROM_CASE, UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE, SET_EXIST_FILTER_IS_FAVORITE, SET_EXIST_FILTER_IS_ARCHIVE, REMOVE_FROM_ARCHIVE_CASE_IN_FILTER, UPDATE_FAVORITE_DATA_FROM_CASE, UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE, REMOVE_FROM_FAVORITE_CASE_IN_FILTER, RESTE_ALL_CASES_DATA_APP, SET_FILE_FAILURE, SET_FILE_SUCCESSFULLY, SET_FILE_LOADING, ADD_FILE_TO_FILES_IN_CASE, DELETE_FILE_FROM_FILES_IN_CASE, DELETE_ITEMS_NOTE_SINGLE_CASE, DELETE_FINANCIAL_FROM_CASE, ADD_FINANCIAL_TO_CASE, EDIT_FINANCIAL_TO_CASE } from '../../action/cases/casesType'

const initalState = {
    // all
    allCases: [],
    cases: [],
    loading: false,
    error: null,
    loaded: false,

    // search
    filtered: {
        searchedValue: '',
        list: [],
    },

    // filter
    showFilterSection: false,   
    fieldsFilter: {
        archive_code: {
            value: '',
            applied: false
        },
        client: {
            value: '',
            applied: false
        },
        code: {
            value: '',
            applied: false,
        },
        category: {
            value: '',
            applied: false
        },
        judicial: {
            value: '',
            applied: false
        },
        step: {
            value: '',
            applied: false
        },
        state: {
            value: '',
            applied: false
        },
        is_archived: {
            value: false,
            applied: false
        },
        is_favorite: {
            value: false,
            applied: false
        }
    },
    filterSectionLoading: false,
    filterIsArchived: false,
    filterIsFavorite: false,

    deleteLoading: false,
    deleteError: null,

    singlesCase: {},
    singleCaseLoading: false,
    singleCaseError: null,
    singleCaseActiveTab: 0,
    singleCaseLastOpened: null,
    showDescriptionCollapse: {
        show: true,
        loading: true
    },
    
    archiveLikeLoading: false,
    errorArchiveLike: null,
    successArchiveLike: null,


    fileLoading: false,
    fileMessage: null
}

const casesReducer = (state = initalState, action) => {

    switch(action.type){
        
        case SET_LOADING_LIST_CASES :
            return {
                ...state,
                loading: action.payload,
            }

        case SET_FAILURE_LIST_CASES :
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case SET_CASES_CASES :
            return {
                ...state,
                loading: false,
                error: null,
                cases: action.payload.list,
                loaded: true,
                pagination: action.payload.pagination,
                allCases: action.payload.list
            }
            

            
        case SET_FILTER_CASES :
            return {
                ...state,
                filtered: {
                    searchedValue: action.payload.value,
                    list: action.payload.list
                },
                loading: false,
            }



        case SHOW_FILTER_SECTION_CASES :
            return {
                ...state,
                showFilterSection: action.payload
            }

        case SET_FILEDS_OF_FILTER :
            return {
                ...state,
                fieldsFilter: action.payload
            }

        case RESET_FILEDS_OF_FILTER :
            return {
                ...state,
                fieldsFilter: {
                    archive_code: {
                        value: '',
                        applied: false
                    },
                    client: {
                        value: '',
                        applied: false
                    },
                    code: {
                        value: '',
                        applied: false,
                    },
                    category: {
                        value: '',
                        applied: false
                    },
                    judicial: {
                        value: '',
                        applied: false
                    },
                    step: {
                        value: '',
                        applied: false
                    },
                    state: {
                        value: '',
                        applied: false
                    },
                    is_archived: {
                        value: false,
                        applied: false
                    },
                    is_favorite: {
                        value: false,
                        applied: false
                    }
                },
            }

        case SET_FILTER_SECTION_LOADING_CASES :
            return {
                ...state,
                filterSectionLoading: action.payload,
            }

        case SET_FILTER_FOR_CASES :
            return {
                ...state,
                cases: action.payload,
                loading: false,
                filterSectionLoading: false,
            }

        
        case ADD_CASE_TO_CASES :
            return {
                ...state,
                allCases: [
                    action.payload,
                    ...state.allCases
                ],
                cases: [
                    action.payload,
                    ...state.cases
                ]
            }

        
        case SET_DELETE_ERROR_CASE :
            return {
                ...state,
                deleteError: action.payload,
                deleteLoading: false
            }

        case SET_DELETE_LOADING_CASE :
            return {
                ...state,
                deleteError: action.payload,
                deleteLoading: false
            }

        case DELETE_ITEM_OF_CASES :
            return {
                ...state,
                allCases: state.allCases.filter(item => item.id != action.payload),
                cases: state.cases.filter(item => item.id != action.payload),
                filtered: {
                    ...state.filtered,
                    list: state.filtered.list.filter(item => item.id != action.payload),
                }
            }


        case ADD_CASE_TO_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: action.payload.data
                },
                singleCaseLoading: false,
                singleCaseError: null
            }

        case SET_LOADING_SINGLE_CASE :
            return {
                ...state,
                singleCaseLoading: action.payload
            }

        case SET_ERROR_SINGLE_CASE :
            return {
                ...state,
                singleCaseLoading: false,
                singleCaseError: action.payload
            }

        case SET_ACTIVE_TAB_SINGLE_CASE :
            return {
                ...state,
                singleCaseActiveTab: action.payload
            }    

        case SET_LAST_OPENED_SINGLE_CASE :
            return {
                ...state,
                singleCaseLastOpened: action.payload
            }    

        case SET_SHOW_DESCRIPTION_COLLAPSE_SINGLE_CASE :
            return {
                ...state,
                showDescriptionCollapse: action.payload
            } 

        case UPDATE_NOTE_IN_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        notes: [
                            action.payload.data,
                            ...state.singlesCase[action.payload.id].notes.filter(item => item.id != action.payload.data.id)
                        ]
                    }
                }
            }

        case DELETE_ITEM_NOTE_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        notes: state.singlesCase[action.payload.id].notes.filter(item => item.id != action.payload.data_id)
                    }
                }
            }

        case DELETE_ITEMS_NOTE_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        notes: state.singlesCase[action.payload.id].notes.filter(item => !action.payload.data_id.includes(item.id))
                    }
                }
            }

        case ADD_ITEM_TO_NOTE_IN_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        notes: [
                            action.payload.note,
                            ...state.singlesCase[action.payload.id].notes
                        ]
                    }
                }
            }

        case SET_SORTED_INTO_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        sorted: action.payload.status
                    }
                }
            }

        case DELETE_EVENT_FROM_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        events: state.singlesCase[action.payload.id].events.filter(item => item.id != action.payload.eventId)
                    }
                }
            }

        case UPDATE_EVENT_ITEM_IN_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        events: state.singlesCase[action.payload.id].events.map(item => {
                            if(item.id != action.payload.data.id)
                                return action.payload.data
                            else
                                return item
                        })
                    }
                }
            }

        case UPDATE_USERS_FROM_CASES :
            return {
                ...state,
                allCases: state.allCases.map(item => {
                    if(item?.clients[0]?.id === Number(action.payload.id)){
                        return {
                            ...item,
                            clients: item.clients.map(client => {
                                if(client.id === Number(action.payload.id)){
                                    return {
                                        ...client,
                                        name: action.payload.data.name,
                                         mobile: action.payload.data.mobile,
                                    }
                                }

                                return client
                            })
                            
                        }
                    }
                    
                    return item
                }),
                cases: state.cases.map(item => {
                    if(item?.clients[0]?.id === Number(action.payload.id)){
                        return {
                            ...item,
                            clients: item.clients.map(client => {
                                if(client.id === Number(action.payload.id)){
                                    return {
                                        ...client,
                                        name: action.payload.data.name,
                                         mobile: action.payload.data.mobile,
                                    }
                                }

                                return client
                            })
                            
                        }
                    }
                    
                    return item
                }),
                filtered: {
                    ...state.filtered,
                    list: state.filtered.list.map(item => {
                        if(item?.clients[0]?.id === Number(action.payload.id)){
                            return {
                                ...item,
                                clients: item.clients.map(client => {
                                    if(client.id === Number(action.payload.id)){
                                        return {
                                            ...client,
                                            name: action.payload.data.name,
                                             mobile: action.payload.data.mobile,
                                        }
                                    }
    
                                    return client
                                })
                                
                            }
                        }
                        
                        return item
                    })
                },
            }

        case SET_SINGLES_CASES :
            return {
                ...state,
                singlesCase: {
                    ...action.payload
                }
            }

        case UPDATE_CASE_FROM_CASES :
            return {
                ...state,
                allCases: state.allCases.map(item => {
                    if( Number(item.id) === Number(action.payload.id) ){
                        return action.payload
                    }

                    return item
                }),
                cases: state.cases.map(item => {
                    if( Number(item.id) === Number(action.payload.id) ){
                        return action.payload
                    }

                    return item
                }),
                filtered: {
                    searchedValue: state.filtered.searchedValue,
                    list: state?.filtered?.list?.map(item => {
                        if( Number(item.id) === Number(action.payload.id) ){
                            return action.payload
                        }
    
                        return item
                    })
                },
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        ...action.payload
                    }
                }
            }

        case ADD_EVENT_TO_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.caseId]: {
                        ...state.singlesCase[action.payload.caseId],
                        events: [
                            action.payload.data,
                            ...state.singlesCase[action.payload.caseId].events
                        ]
                    }
                }
            }


        case SET_LOADING_ARCHIVE_LIKE_CASE :
            return {
                ...state,
                archiveLikeLoading: action.payload,
                errorArchiveLike: null,
                successArchiveLike: null
            }

        case SET_ERROR_ARCHIVE_LIKE_CASE :
            return {
                ...state,
                archiveLikeLoading: false,
                errorArchiveLike: action.payload,
                successArchiveLike: null
            }

        case SET_SUCCSESS_ARCHIVE_LIKE_CASE :
            return {
                ...state,
                archiveLikeLoading: false,
                errorArchiveLike: null,
                successArchiveLike: action.payload
            }

        case UPDATE_ARCHIVE_DATA_FROM_CASE :
            return {
                ...state,
                allCases: state.allCases.map(item => {
                    if( Number(item.id) === Number(action.payload.id) ){
                        return {
                            ...item,
                            is_archived: action.payload.status
                        }
                    }

                    return item
                }),
                cases: state.cases.map(item => {
                    if( Number(item.id) === Number(action.payload.id) ){
                        return {
                            ...item,
                            is_archived: action.payload.status
                        }
                    }

                    return item
                }),
                filtered: {
                    searchedValue: state.filtered.searchedValue,
                    list: state?.filtered?.list?.map(item => {
                        if( Number(item.id) === Number(action.payload.id) ){
                            return {
                                ...item,
                                is_archived: action.payload.status
                            }
                        }
    
                        return item
                    })
                }
            }

        case UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        is_archived: action.payload.status
                    }
                }
            }

        case SET_EXIST_FILTER_IS_ARCHIVE :
            return {
                ...state,
                filterIsArchived: action.payload,
            }

        case SET_EXIST_FILTER_IS_FAVORITE :
            return {
                ...state,
                filterIsFavorite: action.payload,
            }

        case REMOVE_FROM_ARCHIVE_CASE_IN_FILTER :
            return {
                ...state,
                cases: state.cases.filter(item => Number(item.id) !== Number(action.payload)),
                filtered: {
                    searchedValue: state.filtered.searchedValue,
                    list: state.filtered.list.filter(item => Number(item.id) !== Number(action.payload))
                }
            }

        case UPDATE_FAVORITE_DATA_FROM_CASE :
            return {
                ...state,
                allCases: state.allCases.map(item => {
                    if( Number(item.id) === Number(action.payload.id) ){
                        return {
                            ...item,
                            is_favorite: action.payload.status
                        }
                    }

                    return item
                }),
                cases: state.cases.map(item => {
                    if( Number(item.id) === Number(action.payload.id) ){
                        return {
                            ...item,
                            is_favorite: action.payload.status
                        }
                    }

                    return item
                }),
                filtered: {
                    searchedValue: state.filtered.searchedValue,
                    list: state?.filtered?.list?.map(item => {
                        if( Number(item.id) === Number(action.payload.id) ){
                            return {
                                ...item,
                                is_favorite: action.payload.status
                            }
                        }
    
                        return item
                    })
                }
            }

        case UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        is_favorite: action.payload.status
                    }
                }
            }

        case REMOVE_FROM_FAVORITE_CASE_IN_FILTER :
            return {
                ...state,
                cases: state.cases.filter(item => Number(item.id) !== Number(action.payload)),
                filtered: {
                    searchedValue: state.filtered.searchedValue,
                    list: state.filtered.list.filter(item => Number(item.id) !== Number(action.payload))
                }
            }



        case SET_FILE_LOADING:
            return {
                ...state,
                fileLoading: action.payload
            }

        case SET_FILE_SUCCESSFULLY:
            return {
                ...state,
                fileMessage: {
                    type: 'success',
                    message: action.payload
                }
            }

        case SET_FILE_FAILURE:
            return {
                ...state,
                fileLoading: false,
                fileMessage: {
                    type: 'error',
                    message: action.payload
                }
            }

        case ADD_FILE_TO_FILES_IN_CASE : 
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.id]: {
                        ...state.singlesCase[action.payload.id],
                        files: [
                            ...state.singlesCase[action.payload.id].files,
                            action.payload.data
                        ]
                    }
                }
            }

        case DELETE_FILE_FROM_FILES_IN_CASE : 
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.caseId]: {
                        ...state.singlesCase[action.payload.caseId],
                        files: state.singlesCase[action.payload.caseId].files.filter(item => item.id != action.payload.fileId)
                    }
                }
            }

        
        case DELETE_FINANCIAL_FROM_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.lawId]: {
                        ...state.singlesCase[action.payload.lawId],
                        financials: state.singlesCase[action.payload.lawId].financials.filter(item => item.id != action.payload.id),
                        financials_total_income: action.payload.type === 'income' ? ( Number(state.singlesCase[action.payload.lawId].financials_total_income) - Number(action.payload.amount) ) : state.singlesCase[action.payload.lawId].financials_total_income,
                        financials_total_expense: action.payload.type === 'expense' ? ( Number(state.singlesCase[action.payload.lawId].financials_total_expense) - Number(action.payload.amount) ) : state.singlesCase[action.payload.lawId].financials_total_expense
                    }
                }
            }

        case ADD_FINANCIAL_TO_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.law_suit_id]: {
                        ...state.singlesCase[action.payload.law_suit_id],
                        financials: [
                            action.payload,
                            ...state.singlesCase[action.payload.law_suit_id].financials
                        ],
                        financials_total_income: action.payload.type === 'income' ? ( Number(state.singlesCase[action.payload.law_suit_id].financials_total_income) + Number(action.payload.amount) ) : state.singlesCase[action.payload.law_suit_id].financials_total_income,
                        financials_total_expense: action.payload.type === 'expense' ? ( Number(state.singlesCase[action.payload.law_suit_id].financials_total_expense) + Number(action.payload.amount) ) : state.singlesCase[action.payload.law_suit_id].financials_total_expense
                    }
                }
            }

            // amountChange: info.amountChange,
            // incomeChange: info.incomeChange,
            // expenseChange: info.expenseChange

        case EDIT_FINANCIAL_TO_CASE :
            return {
                ...state,
                singlesCase: {
                    ...state.singlesCase,
                    [action.payload.info.law_suit_id]: {
                        ...state.singlesCase[action.payload.info.law_suit_id],
                        financials: state.singlesCase[action.payload.info.law_suit_id].financials.map(item => {
                            if(item.id == action.payload.info.id)
                                return action.payload.info
                            else
                                return item
                        }),
                        financials_total_income: action.payload.info.type === 'income' && action.payload.info.amountChange !== 0 ? ( Number(state.singlesCase[action.payload.info.law_suit_id].financials_total_income) + Number(action.payload.amountChange) ) : action.payload.incomeChange !== 0 ? ( Number(state.singlesCase[action.payload.info.law_suit_id].financials_total_income) + Number(action.payload.incomeChange) ) : state.singlesCase[action.payload.info.law_suit_id].financials_total_income,
                        financials_total_expense: action.payload.info.type === 'expense' && action.payload.info.amountChange !== 0 ? ( Number(state.singlesCase[action.payload.info.law_suit_id].financials_total_expense) + Number(action.payload.amountChange) ) : action.payload.expenseChange !== 0 ? ( Number(state.singlesCase[action.payload.info.law_suit_id].financials_total_expense) + Number(action.payload.expenseChange) ) : state.singlesCase[action.payload.info.law_suit_id].financials_total_expense
                    }
                }
            }


        case RESTE_ALL_CASES_DATA_APP :
            return {
                ...state,
                ...initalState
            }

        default : 
            return state

    }

}

export default casesReducer