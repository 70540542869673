import { useEffect } from "react"
import Input from "../../../../../UI/Input"
import { useDispatch, useSelector } from "react-redux"
import { SET_FILTER_CONTACTS, SET_LOADING_CONTACTS, UNSET_LOADING_CONTACTS } from "../../../../../../redux/action/contacts/typeContacts"
import { SET_CONTACTS_SEARCH } from "../../../../../../redux/action/search/searchType"
import { searchInList } from "../../../../../../utils/search"

const ContactsSearch = (props) => {

    const dispatch = useDispatch()
    const filtered = useSelector(state => state.contacts.filtered)
    const contacts = useSelector(state => state.addCase.uniqContacts)
    const contactsEdit = useSelector(state => state.editCase.uniqContacts)
    const searchValue = useSelector(state => state.search.contactsSearch)
    
    useEffect(() => {

        if(searchValue){
            doSearch()
        }

    }, [])

    useEffect(() => {
    
        // dont send request to server when not in search state
        if(searchValue === '' ||  filtered.searchedValue === searchValue) return
        if(contacts.length <= 0 && contactsEdit <= 0) return

        // fake loading - for watching better user.
        dispatch({
            type: SET_LOADING_CONTACTS
        })

        const timeout = setTimeout(async () => {

            doSearch()

        }, 600)

        return () => {
            clearTimeout(timeout)
        }

    }, [searchValue])

    const updateSearch = (event) => {
        
        if(event.target.value === ''){
            clearSearchValue()
        }

        else {
            
            dispatch({
                type: SET_CONTACTS_SEARCH,
                payload: event.target.value
            })

        }

    }

    const clearSearchValue = () => {
        
        dispatch({
            type: SET_CONTACTS_SEARCH,
            payload: ''
        })
        
        dispatch({
            type: SET_FILTER_CONTACTS,
            payload: {
                value: '',
                list: []
            }
        })

        // set fake loading
        dispatch({
            type: SET_LOADING_CONTACTS
        })

        setTimeout(() => {
            
            dispatch({
                type: UNSET_LOADING_CONTACTS
            })

        }, 250)

    }

    const doSearch = () => {

        if(!props.edit){
            
            const filtered = searchInList(contacts, searchValue, ['name', 'mobile'])

            dispatch({
                type: SET_FILTER_CONTACTS,
                payload: {
                    value: searchValue,
                    list: filtered
                }
            })   

        }
        else if(props.edit){

            const filtered = searchInList(contactsEdit, searchValue, ['name', 'mobile'])
            
            dispatch({
                type: SET_FILTER_CONTACTS,
                payload: {
                    value: searchValue,
                    list: filtered
                }
            })   

        }
        
    }

    return (
        <div className="mb-4">
            
            <Input 
                id='search-city-field'
                placeholder={'جستجوی مخاطب'}
                className={'shadow-custom-lg border-gray-300/60 focus:shadow-primary/5'}
                value={searchValue}
                onChange={updateSearch}
            />

        </div>
    )

}

export default ContactsSearch