import { useDispatch, useSelector } from "react-redux"
import AddCaseForm from "../../../components/Cases/Add"
import Header from "../../../components/Header"
import Button from "../../../components/UI/Button"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { addCaseToApi } from "../../../redux/action/cases/casesAction"
import { useCookies } from "react-cookie"
import LoadingDot from "../../../components/UI/LoadingDot"
import ToastCustom from "../../../components/UI/ToastCustom"
import { ReactSVG } from "react-svg"
import moment from "jalali-moment"
import { ADD_ITEM_TO_LAW_SUITS_HOME } from "../../../redux/action/home/homeType"
import { ADD_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS, ADD_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS } from "../../../redux/action/contacts/typeContacts"

const AddCase = () => {

    const dispatch = useDispatch()
    const forms = useSelector(state => state.addCase.forms)
    const formsNotValid = useSelector(state => state.addCase.formsNotValid)
    const clients = useSelector(state => state.addCase.clients)
    const contacts = useSelector(state => state.addCase.contacts)
    const error = useSelector(state => state.addCase.error)
    const loading = useSelector(state => state.addCase.loading)
    const successMessage = useSelector(state => state.addCase.successMessage)
    
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const profilesList = useSelector(state => state.contacts.profilesList)
    const contactInfo = useSelector(state => state.contacts.contactInfo)

    const location = useLocation()
    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(location?.state?.scrollToBottom){
            window.scrollTo({top: window.innerHeight + 3000})
        }
        else if(location?.state?.scroll){
            window.scrollTo({top: 500})
        }

        window.scrollTo({top: 0})

    }, [])

    const addCase = async () => {
        
        const data = {
            title: forms?.title?.value,
            clients,
            contacts,
            state: forms?.state,
            step: forms?.step,
            agreed_fee: forms?.agreed_fee?.split(',').join(''),
            description: forms.description.value,
            archive_code: forms.archive_code.value,
            code: forms.code.value,
            branch: forms.branch.value,
            city_id: forms.location.city.id,
            province_id: forms.location.province,
            category_id: forms.category.id,
            jurisdiction_id: forms.jurisdiction.id
        }

        const result = {
            id: null,
            title: forms?.title?.value,
            clients,
            contacts,
            state: forms?.state,
            step: forms?.step,
            agreed_fee: forms?.agreed_fee?.split(',').join(''),
            description: forms.description.value,
            archive_code: forms.archive_code.value,
            code: forms.code.value,
            branch: forms.branch.value,
            category: forms.category,
            jurisdiction: forms.jurisdiction
        }

        const created_at = moment().format('yyyy-MM-DDTHH:mm:ss.000000')

        const response = await dispatch( await addCaseToApi(data, result, created_at, cookies.authToken) )         

        if(response){

            if(homeInfoLoaded){

                // add-to-home
                const info = {
                    id: response,
                    title: result.title,
                    last_state: {
                        user_id: '',
                        data: {
                            "status": "created"
                        },
                        created_at: created_at
                    }
                }

                dispatch({
                    type: ADD_ITEM_TO_LAW_SUITS_HOME,
                    payload: info
                })

            }

            result.clients.map(item => {
                    
                if(profilesList && profilesList[item.id]){

                    dispatch({
                        type: ADD_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS,
                        payload: {
                            contactId: item.id,
                            law_data: {
                                id: response,
                                title: result.title,
                                code: result.code,
                                archive_code: result.archive_code,
                                state: result.state,
                                step: result.step,
                                branch: result.branch,
                                is_archived: false,
                                position: item.position,
                                notify: item.notify,
                            }
                        }
                    })

                    if(contactInfo && contactInfo?.id && Number(contactInfo.id) === Number(item.id)){
                        
                        dispatch({
                            type: ADD_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS,
                            payload: {
                                contactId: item.id,
                                law_data: {
                                    id: response,
                                    title: result.title,
                                    code: result.code,
                                    archive_code: result.archive_code,
                                    state: result.state,
                                    step: result.step,
                                    branch: result.branch,
                                    is_archived: false,
                                    position: item.position,
                                    notify: item.notify,
                                }
                            }
                        })

                    }

                }

            })

            navigate('/law-suits')

        }

    }

    return (
        <>
            
            <Header 
                title={'افزودن پرونده'}
                backBtn={true}
                backPath={'/law-suits'}
            >
                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={formsNotValid}
                    onClick={addCase}
                >
                    ثبت     
                </Button>

            </Header>

            <main className="main-content">

                <AddCaseForm />

                { error && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { error }

                        </div>

                    </ToastCustom>

                }

                { successMessage && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-green-600 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                            { successMessage }

                        </div>

                    </ToastCustom>

                }

                { loading && <LoadingDot /> }

            </main>

        </>
    )

}

export default AddCase