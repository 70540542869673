import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { getProfileColor } from "../../../../utils/messages"
import { useState } from "react"

import Dropdown from "../../../UI/Dropdown"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from "react-cookie"
import { caseMoveToArchive, caseMoveToFavorite, caseMoveToUnarchive, caseMoveToUnfavorite, deleteCaseFromList } from "../../../../redux/action/cases/casesAction"
import { DELETE_CASE_FROM_HOME_INFO } from "../../../../redux/action/home/homeType"
import { DELETE_CASE_FROM_CONTACTS, DELETE_CASE_FROM_CONTACT_INFO_CONTACTS } from "../../../../redux/action/contacts/typeContacts"
import { RESET_FORMS_NOT_VALID_EDIT_CASE } from "../../../../redux/action/cases/edit/type"
import ConfirmModal from "../../../UI/ConfirmModal"
import { REMOVE_FROM_ARCHIVE_CASE_IN_FILTER, REMOVE_FROM_FAVORITE_CASE_IN_FILTER, UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE, UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE } from "../../../../redux/action/cases/casesType"

const CaseListItem = (props) => {

    const dispatch = useDispatch()
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const profilesList = useSelector(state => state.contacts.profilesList)
    const contactInfo = useSelector(state => state.contacts.contactInfo)
    const singlesCase = useSelector(state => state.cases.singlesCase)
    const existFilterIsArchived = useSelector(state => state.cases.filterIsArchived)
    const existFilterIsFavorite = useSelector(state => state.cases.filterIsFavorite)

    const [dropdown, setDropdown] = useState(false)
    const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false)

    const [cookies] = useCookies(['authToken'])

    const showMoreOption = (event) => {
        event.preventDefault()

        if(props.selectedItemDelete) return

        setDropdown(true)
        document.body.classList.add('modal-opened')
    }

    const showDeleteCaseAlert = () => {
        setShowDeleteCaseModal(true)
        setDropdown(false)
    }

    const deleteCase = async () => {
    
        setDropdown(false)
        document.body.classList.remove('modal-opened')

        const data = await dispatch( await deleteCaseFromList(props.id, cookies.authToken) )
        
        if(data){

            if(homeInfoLoaded){

                dispatch({
                    type: DELETE_CASE_FROM_HOME_INFO,
                    payload: props.id
                })

            }

            props.clients.map(item => {
                
                if(profilesList && profilesList[item.id]){

                    dispatch({
                        type: DELETE_CASE_FROM_CONTACTS,
                        payload: {
                            caseId: props.id,
                            contactId: item.id
                        }
                    })

                    if(contactInfo && contactInfo?.id && Number(contactInfo.id) === Number(item.id)){
                        
                        dispatch({
                            type: DELETE_CASE_FROM_CONTACT_INFO_CONTACTS,
                            payload: props.id
                        })

                    }

                }

            })

        }

    }
    
    const selectItemForDelete = () => {
        props.onSetSelectedItemDelete(props.id)
    }

    const chooseActiveItem = (event) => {
        if(props.selectedItemDelete === null) return

        event.preventDefault()
        selectItemForDelete()
    }

    const openEditPage = () => {
        
        document.body.classList.remove('modal-opened')
        
        dispatch({
            type: RESET_FORMS_NOT_VALID_EDIT_CASE
        })
        
    }

    const toggleArchiveState = async () => {
        
        // hide dropdown
        setDropdown(false)
        document.body.classList.remove('modal-opened')

        if(props.is_archived){
            const data = await dispatch( await caseMoveToUnarchive(props.id, cookies.authToken, true) )
            
            if(data){

                if(singlesCase[props.id]){
                    
                    dispatch({
                        type: UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE,
                        payload: {
                            id: props.id,
                            status: false
                        }
                    })

                }

                // exist into the archive filter come remove
                if(existFilterIsArchived){
                    dispatch({
                        type: REMOVE_FROM_ARCHIVE_CASE_IN_FILTER,
                        payload: props.id
                    })
                }

            }
        }
        else{
            const data = await dispatch( await caseMoveToArchive(props.id, cookies.authToken, true) )
            
            if(data){

                if(singlesCase[props.id]){
                    
                    dispatch({
                        type: UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE,
                        payload: {
                            id: props.id,
                            status: true
                        }
                    })

                }

            }
        }

    }

    const toggleFavoriteState = async (event) => {
        
        event.preventDefault()

        // hide dropdown
        setDropdown(false)
        document.body.classList.remove('modal-opened')

        if(props.is_favorite){
            const data = await dispatch( await caseMoveToUnfavorite(props.id, cookies.authToken) )
            
            if(data){

                if(singlesCase[props.id]){
                    
                    dispatch({
                        type: UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE,
                        payload: {
                            id: props.id,
                            status: false
                        }
                    })

                }

                // exist into the archive filter come remove
                if(existFilterIsFavorite){
                    dispatch({
                        type: REMOVE_FROM_FAVORITE_CASE_IN_FILTER,
                        payload: props.id
                    })
                }

            }
        }
        else{
            const data = await dispatch( await caseMoveToFavorite(props.id, cookies.authToken) )
            
            if(data){

                if(singlesCase[props.id]){
                    
                    dispatch({
                        type: UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE,
                        payload: {
                            id: props.id,
                            status: true
                        }
                    })

                }

            }
        }

    }

    const getColor = () => {

        const color = getProfileColor(props.mobile.slice(10))
        
        return color
    }

    return (
        <li className="relative border-b pb-6">

            <Link to={`/law-suits/${props.id}`} onContextMenu={selectItemForDelete} onClick={chooseActiveItem}  className="bg-white shadow-custom-md flex flex-col rounded-xl h-full">
                
                <div className="flex items-center justify-between mb-4 relative border-b border-b-gray-100">

                    <div className="flex items-center flex-wrap gap-2 mb-3 pt-4 px-4">

                        { props.image ? 
                        
                            <div className="w-6 h-6 flex items-center border border-primary/20 justify-center bg-primary/10 rounded-lg">

                                <img className="w-full h-full rounded-lg" src={props.image} alt="" />

                            </div>
                            :
                            props.name ?
                                <div className={`w-6 h-6 flex items-center justify-center profile-${getColor()} border rounded-lg`}>
                                    <span className={`text-sm-13px font-medium`}>{ props.name.slice(0, 1) }</span>
                                </div>
                            :

                            <div className="w-6 h-6 flex items-center border border-primary/20 justify-center bg-primary/10 rounded-lg">

                                <ReactSVG 
                                    className="w-3 h-3 text-primary"
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                                />

                            </div>
                        }

                        <span className="text-sm text-gray-700 font-medium"> پرونده {props.title} </span>

                    </div>

                    <div className="flex flex-wrap gap-4">

                        <button onClick={ toggleFavoriteState } className=" p-4 text-sm flex items-center justify-between gap-4 translate-y-0.5 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 ml-8">

                            { props.is_favorite ? 
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite.svg`}
                                    className="w-[18px] text-gray-600 -translate-x-1"
                                />
                                :
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite-not.svg`}
                                    className="w-[18px] text-gray-600 -translate-x-1"
                                />
                            }

                        </button>

                        <button onClick={showMoreOption} className="w-12 h-12 hover:text-primary absolute left-0 top-0 flex items-start justify-end focus:outline-none p-4">
                            
                            <ReactSVG 
                                className="w-4 h-4 mt-1"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/more.svg`}
                            />
                        
                        </button>

                    </div>

                </div>

                <div className="flex items-center gap-2 text-gray-500 px-4 mb-4">

                    <ReactSVG 
                        className="w-5 h-5 text-gray-500/90 flex-shrink-0"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/ticket.svg`}
                    />

                    <span className="text-sm-13px"> شماره </span>

                    <span className="text-primary text-sm-13px font-medium"> { props.code ? Number(props.code).toLocaleString('fa').split('٬').join('') : '-'} </span>

                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-1 3xm:grid-cols-2 grid-cols-1 gap-4 mb-4 px-4">

                    <div className="flex flex-col gap-4">

                        <div className="flex items-center gap-2 text-gray-500">

                            <ReactSVG 
                                className="w-4.5 h-4.5 mr-[0.8px] text-gray-500/90 flex-shrink-0"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/folder.svg`}
                            />

                            <span className="text-sm-13px"> نوع دعوی </span>

                            <span className="text-primary text-sm-13px font-medium"> {props?.category?.name ? props?.category?.name : '-'} </span>

                        </div>

                        <div className="flex items-center gap-2 text-gray-500">

                            <ReactSVG 
                                className="w-5 h-5 flex-shrink-0"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/lawyer.svg`}
                            />

                            <span className="text-sm-13px"> شعبه </span>

                            <span className="text-primary text-sm-13px font-medium"> {props.branch ? props.branch : '-'} </span>

                        </div>

                    </div>

                    <div className="flex flex-col gap-4">

                        <div className="flex items-center gap-2 text-gray-500">

                            <ReactSVG 
                                className="w-5 h-5 text-gray-500/90 flex-shrink-0"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/archive_code.svg`}
                            />

                            <span className="text-sm-13px"> شماره بایگانی </span>

                            <span className="text-primary text-sm-13px font-medium"> { props.archive_code ? Number(props.archive_code).toLocaleString('fa').split('٬').join('') : '-'} </span>

                        </div>

                        <div className="flex items-center gap-2 text-gray-500">

                            <ReactSVG 
                                className="w-5 h-5 flex-shrink-0"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/court-2.svg`}
                            />

                            <span className="text-sm-13px"> مرحله </span>

                            <span className="text-primary text-sm-13px font-medium"> {props.step ? props.step : '-'} </span>

                        </div>

                    </div>

                </div>

                <div className="flex items-center flex-wrap justify-evenly gap-4 px-4 pb-4 border-t border-t-gray-200 pt-4 mt-auto">

                    <div className="flex items-center text-gray-600 gap-2">

                        <ReactSVG 
                            className="w-4 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/notes.svg`}
                        />

                        <div className="flex items-center gap-1 mt-1">
                            <span className="text-sm-13px"> یادداشت‌ها </span>
                            <span className="text-sm-13px"> { props.notes ? Number(props.notes).toLocaleString('fa') : '۰' } </span>
                        </div>

                    </div>

                    <div className="flex items-center text-gray-600 gap-2">

                        <ReactSVG 
                            className="w-4 text-primary rotate-45"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/tag.svg`}
                        />

                        <div className="flex items-center gap-1 mt-1">
                            <span className="text-sm-13px"> الحاق </span>
                            <span className="text-sm-13px"> { props.files ? Number(props.files).toLocaleString('fa') : '۰' } </span>
                        </div>

                    </div>

                    <div className="flex items-center text-gray-600 gap-2">

                        <ReactSVG 
                            className="w-4 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/message.svg`}
                        />

                        <div className="flex items-center gap-1 mt-0.5">
                            <span className="text-sm-13px"> گفتگو </span>
                            <span className="text-sm-13px"> { props.conversations ? Number(props.conversations).toLocaleString('fa') : '۰' } </span>
                        </div>

                    </div>

                    <div className="flex items-center text-gray-600 gap-2">

                        <ReactSVG 
                            className="w-4 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/events.svg`}
                        />

                        <div className="flex items-center gap-1">
                            <span className="text-sm-13px"> رویداد </span>
                            <span className="text-sm-13px"> { props.events ? Number(props.events).toLocaleString('fa') : '۰' } </span>
                        </div>

                    </div>

                </div>

            </Link>

            { dropdown && 
                
                <Dropdown 
                    onSetShow={setDropdown}
                    title={props.title}
                    classList="!top-0 !left-0 !right-auto !w-48"
                    backClass="bg-black/20"
                >
                    
                    <button onClick={ toggleArchiveState } className="p-4 text-sm flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300">
                    
                        { props.is_archived ? 
                            <span> برداشتن‌ از بایگانی </span>
                            :
                            <span> انتقال به بایگانی </span>
                        }

                        { props.is_archived ? 

                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/archive-back.svg`}
                                className="w-[19px] xm:-translate-x-1 text-gray-600 -translate-x-1"
                            />
                            :
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/archive-2.svg`}
                                className="w-[19px] xm:-translate-x-1 text-gray-600 -translate-x-1"
                            />
                        }

                    </button>

                    <button onClick={ toggleFavoriteState } className="p-4 text-sm flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300">

                        { props.is_favorite ? 
                            <span> برداشتن نشان </span>
                            :
                            <span> نشان کن </span>
                        }

                        { props.is_favorite ? 
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite.svg`}
                                className="w-[18px] text-gray-600 -translate-x-1"
                            />
                            :
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite-not.svg`}
                                className="w-[18px] text-gray-600 -translate-x-1"
                            />
                        }


                    </button>

                    <Link to={`/law-suits/${props.id}/update`} state={{cases: true}} onClick={openEditPage} className="p-4 relative focus:outline-none outline-none flex text-sm items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300">
                            
                        ویرایش

                        <ReactSVG
                            className="w-6 absolute left-2.5"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/edit-2.svg`}
                        />

                    </Link>

                    <button onClick={ showDeleteCaseAlert } className="p-4 text-sm flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-rose-600 duration-300">
                        حذف

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-2.svg`}
                            className="w-5 xm:-translate-x-1 text-rose-600 -translate-x-1"
                        />

                    </button>
                    
                </Dropdown>

            }

            { props.selectedItemDelete === props.id && 

                <div className="absolute top-3.5 right-3.5 bg-white">

                    <ReactSVG   
                        className="w-7 h-7 text-green-600 rounded-full"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                    />

                </div>

            }

            { showDeleteCaseAlert && 
                <ConfirmModal
                    title="حذف پرونده"
                    description="آیا میخواهید این پرونده را حذف کنید؟"
                    show={showDeleteCaseModal}
                    setShow={setShowDeleteCaseModal}
                    onConfirm={deleteCase}
                    className={''}
                    backdropClass={''}
                >   
    
                    <div className="w-14 h-14 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-rose-500">
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`} className="w-10 h-10" />
                    </div>
    
                </ConfirmModal>
            }

        </li>
    )

}

export default CaseListItem