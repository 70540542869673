const CaseAddFinanicalType = ({type, onSetType}) => {

    return (
        <div className="flex flex-col gap-3 order-1">

            <span className="text-base cursor-pointer w-full block"> نوع تراکنش : </span>

            <div className="flex items-center bg-white shadow-custom-lg border border-gray-300/60 w-fit rounded-full">

                <button onClick={() => onSetType('income') } className={`flex items-center justify-center rounded-full font-medium w-24 h-12 select-none !outline-none ${type === 'income' && 'bg-green-500 text-white'}`}>
                    دریافت
                </button>

                <button onClick={() => onSetType('expense') } className={`flex items-center justify-center rounded-full font-medium w-24 h-12 select-none !outline-none ${type === 'expense' && 'bg-rose-500 text-white'}`}>
                    هزینه
                </button>

            </div>
        </div>
    )

}

export default CaseAddFinanicalType