import { Routes } from "react-router-dom";
import DeleteHeaderCase from "../DeleteHeader"
import CaseListItem from "../Item"

const CasesFiltered = (props) => {

    return (
        <>
            
            { props.selectedItemDelete &&
                
                <DeleteHeaderCase 
                    selectedItemId={props.selectedItemDelete}
                    onSetSelectedItemDelete={props.onSetSelectedItemDelete}
                    cases={props.list}
                />

            }

            <section>

                <div className="flex mb-6 items-center justify-between flex-wrap gap-2 rounded-2xl shadow-custom-md border-[3px] border-white p-4">
                            
                    <h2 className="text-base font-medium"> 
                        <span> جستجو پرونده </span>
                        {/* <span> با فیلترهای اعمال شده </span> */}
                    </h2>

                    { props.list.length >= 1 && 
                        <span className="text-sm flex items-center gap-1"> ( <span className="font-medium">{Number(props.list.length).toLocaleString('fa')}</span> پرونده) </span>
                    }
                            
                </div>

                { props.list.length === 0 && 
                    'پرونده مورد نظر یافت نشد'
                }

                <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">
                
                    { props.list.map((item, index) => 

                        <CaseListItem 
                            key={item.id + index }

                            id={item.id}
                            title={item.title}
                            image={item?.clients[0]?.user?.image}
                            mobile={item?.clients[0]?.mobile}
                            name={item?.clients[0]?.name}
                            clients={item?.clients}

                            code={item.code}
                            archive_code={item.archive_code}
                            branch={item.branch}
                            step={item.step}
                            state={item.state}
                            category={item.category}

                            files={item.files}
                            notes={item.notes}
                            conversations={item.conversations}
                            events={item.events}
                            is_favorite={item.is_favorite}
                            is_archived={item.is_archived}

                            selectedItemDelete={props.selectedItemDelete}
                            onSetSelectedItemDelete={props.onSetSelectedItemDelete}
                        />   

                    )}

                </ul>

            </section>

        </>
    )

}

export default CasesFiltered