import { ReactSVG } from "react-svg"
import { useSelector } from "react-redux"

import AddCaseContactsHeader from "./Header"
import AddCaseContactsList from "./List"
import AddCaseContactsModal from "./Modal"

const AddCaseContacts = ({movakels, onSetMovakels, showContactsBox, contactBoxOpened, ...props}) => {

    return (
        <>
            <div className="flex flex-col gap-8 rounded-2xl shadow-custom-lg border-[3px] border-white p-4 relative">

                <div className="flex w-full flex-col gap-2">
                                    
                    <AddCaseContactsHeader 
                        path={props.path}
                        title={props.title}
                        location={props.location}
                        edit={props.edit}
                    />

                    { movakels.length <= 0 && !props.loading ?
                        
                        <div className="mt-4 text-gray-500 flex items-start justify-start gap-2 mb-1"> 
            
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/empty.svg`}
                                className="w-5"
                            />

                            هنوز فردی انتخاب نشده است 

                        </div>

                        :

                        movakels.length <= 0 && props.loading ?
                            <div className="h-10 flex items-center justify-center">

                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                    className="w-8 text-gray-600"
                                />

                            </div>
                        :

                        <AddCaseContactsList 
                            location={props.location}
                            list={movakels}
                            edit={props.edit}
                        />

                    }

                </div>

            </div>

            { showContactsBox && (contactBoxOpened === props.location) &&
                
                <AddCaseContactsModal
                    showContactsBox={showContactsBox} 
                    location={props.location}
                    edit={props.edit}
                />

            }

        </>
    )

}

export default AddCaseContacts