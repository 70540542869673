import axios from 'axios'
import { apiKey } from '../../store'
import { ADD_FIELDS_PROFILE, DELETE_FIELDS_PROFILE, SET_FAILURE_PROFILE, SET_PROFILE_PROFILE, SET_REQUEST_PROFILE, SET_REQUEST_UPDATING_PROFILE, SET_USER_LOCATION, SET_IMAGE_PROFILE, UNSET_REQUEST_UPDATING_PROFILE, SET_NAME_OF_LAWYER_IN_PROFILE } from './profileType'
import { SET_SERVER_DATE_OBJECT } from '../events/eventsType'
import { calculateLeftDaysFromActivePlan } from '../../../utils/time'

const apiKeyProfile = apiKey + 'profile'

export const applyInfoFirstRegister = async (payload, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_REQUEST_PROFILE
        })

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(apiKeyProfile, payload, config)
        .catch(error => {
            dispatch({
                type: SET_FAILURE_PROFILE,
                payload: 'عدم برقراری ارتباط با سرور'
            })
        })

        if(data){
            
            if(data.data.success){
                
                dispatch({
                    type: SET_NAME_OF_LAWYER_IN_PROFILE,
                    payload: payload
                })

                return true
            }

            else if(!data.data.success && data.data.errors){
                dispatch({
                    type: SET_FAILURE_PROFILE,
                    payload: data.data.errors[0]?.message
                })
                
                return data.data.errors[0]?.message
            }


        }
        
    }    

}

export const getProfileInfo = async (authToken) => {

    return async (dispatch) => {

        // loading
        dispatch({
            type: SET_REQUEST_PROFILE
        })

        // const data = {
        //     image: 'https://www.appahang1.com/cdn/tracks-cover/1402/04/5392168904113327461689041133613116890411339356.jpg'
        // }

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(apiKeyProfile, config)
        .catch(error => {
            dispatch({
                type: SET_FAILURE_PROFILE,
                payload: 'عدم برقراری ارتباط با سرور'
            })
        })

        if(data){

            if(data.data.success){
                
                const exipireTime = data.data.data?.active_user_plan ? data.data.data?.active_user_plan[0]?.pivot?.expire_date : 0
                const planLeftDays = calculateLeftDaysFromActivePlan(exipireTime , data.data.data.time)

                dispatch({
                    type: SET_PROFILE_PROFILE,
                    payload: {
                        planLeftDays,
                        ...data.data.data
                    }
                })

                // if exisy locaiton data come set
                if(data.data.data.city)
                    dispatch({
                        type: SET_USER_LOCATION,
                        payload: {
                            province: data.data.data.province,
                            city: data.data.data.city
                        }
                    })

                // success
                return {
                    status: true,
                    ...data.data.data
                }
            }

            else if(!data.data.success && data.data.errors){
                
                dispatch({
                    type: SET_FAILURE_PROFILE,
                    payload: data.data.errors[0]?.message
                })

            }

        }

        // not success
        return false
    }

}

// dont-work
export const updateImageProfile = async (payload = false, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_REQUEST_UPDATING_PROFILE
        })

        // make the pic by base aviable info
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        if(payload && payload.type){
            const fileReader = new FileReader()

            fileReader.onloadend = async () => {
                const url = {
                    data: fileReader.result
                }

                await updateNewImage(url)
            }
    
            fileReader.readAsDataURL(payload)
        }

        else {

            const url = {
                data: ''
            }

            const data = await axios.post(`${apiKeyProfile}/update/image`, url, config)
            .catch(error => {
                
                dispatch({
                    type: SET_FAILURE_PROFILE,
                    payload: 'عدم برقراری ارتباط با سرور'
                })

                dispatch({
                    type: UNSET_REQUEST_UPDATING_PROFILE,
                })

            })

            if(data){
    
                if(data.data.success){
                    
                    dispatch({
                        type: SET_IMAGE_PROFILE,
                        payload: ''
                    })

                    dispatch({
                        type: UNSET_REQUEST_UPDATING_PROFILE,
                    })

    
                }
    
                else if(!data.data.success && data.data.errors){
                    
                    dispatch({
                        type: SET_FAILURE_PROFILE,
                        payload: data.data.errors[0]?.message
                    })

                    dispatch({
                        type: UNSET_REQUEST_UPDATING_PROFILE,
                    })
    
                }
    
            }

        }

        const updateNewImage = async (url) => {

            const data = await axios.post(`${apiKeyProfile}/update/image`, url, config)
            .catch(error => {
                dispatch({
                    type: SET_FAILURE_PROFILE,
                    payload: 'عدم برقراری ارتباط با سرور'
                })
            })
    
            if(data){
    
                if(data.data.success){
                    
                    dispatch({
                        type: SET_IMAGE_PROFILE,
                        payload: url.data
                    })

    
                }
    
                else if(!data.data.success && data.data.errors){
                    
                    dispatch({
                        type: SET_FAILURE_PROFILE,
                        payload: data.data.errors[0]?.message
                    })
    
                }
    
            }

        }


    }

}

export const updateInfoProfile = async (payload, authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_REQUEST_UPDATING_PROFILE
        })

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyProfile}`, payload, config)
        .catch(error => {
            dispatch({
                type: SET_FAILURE_PROFILE,
                payload: 'عدم برقراری ارتباط با سرور'
            })
        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_PROFILE_PROFILE,
                    payload: payload
                })

                dispatch({
                    type: SET_USER_LOCATION,
                    payload: {
                        city: payload.city,
                        province: payload.province_id
                    }
                })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_PROFILE,
                    payload: data.data.errors[0].message
                })

            }

            return false
        }

    }

}


// do a few work without api
export const addProfileFields = (payload) => {

    return (dispatch) => {
        
        dispatch({
            type: ADD_FIELDS_PROFILE,
            payload
        })

    }

}

export const deleteProfileFields = () => {

    return (dispatch) => {
        
        dispatch({
            type: DELETE_FIELDS_PROFILE
        })

    }

}