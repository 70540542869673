import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../utils/base-data"
import { getBaseDataLawyer } from "../../../../redux/action/baseData/baseAction"
import { useCookies } from "react-cookie"
import { useEffect } from "react"
import { SET_SUCCESS_BASEDATA } from "../../../../redux/action/baseData/baseType"

const BuyPlansSupportBtn = () => {

    const dispatch = useDispatch()
    const baseDataConfig = useSelector(state => state.baseData?.info?.config)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getLinksFromBaseData()

    }, [])

    const getLinksFromBaseData = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    const goToSupportSection = () => {

    }

    return (
        <a href={baseDataConfig?.support_url ? baseDataConfig?.support_url : window.location.href.includes('.ir') ? 'https://blog.vakilsun.ir/contactus' : 'https://blog.vakilsun.com/contactus'}  className="btn bg-primary w-fit md:mx-0 mx-auto font-medium gap-2">

            <ReactSVG 
                src={`${process.env.PUBLIC_URL}/assets/images/icons/support.svg`} 
                className="w-6 translate-y-0.5" 
            />

            پشتیبانی 

        </a>
    )

}

export default BuyPlansSupportBtn