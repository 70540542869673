export const SET_SHOW_COLLAPSE_MORE = 'SET_SHOW_COLLAPSE_MORE'
export const SET_COLLAPSE_CONTENT_HEIGHT_MORE = 'SET_COLLAPSE_CONTENT_HEIGHT_MORE'

export const SET_SHOW_CONTACTS_BOX = 'SET_SHOW_CONTACTS_BOX'

export const SET_DETAIL_OF_CASE = 'SET_DETAIL_OF_CASE'
export const RESET_DETAIL_OF_CASE = 'RESET_DETAIL_OF_CASE'

export const ADD_CLIENT_IN_ADD_FORM_CASE = 'ADD_CLIENT_IN_ADD_FORM_CASE'
export const DELETE_CLIENT_FROM_ADD_FORM_CASE = 'DELETE_CLIENT_FROM_ADD_FORM_CASE'
export const EDIT_ITEM_CLIENT_IN_ADD_FORM_CASE = 'EDIT_ITEM_CLIENT_IN_ADD_FORM_CASE'

export const ADD_CONTACTS_IN_ADD_FORM_CASE = 'ADD_CONTACTS_IN_ADD_FORM_CASE'
export const DELETE_CONTACTS_FROM_ADD_FORM_CASE = 'DELETE_CONTACTS_FROM_ADD_FORM_CASE'
export const EDIT_ITEM_CONTACTS_IN_ADD_FORM_CASE = 'EDIT_ITEM_CONTACTS_IN_ADD_FORM_CASE'

export const SET_ALL_CONTACTS_ADD_CASE = 'SET_ALL_CONTACTS_ADD_CASE'
export const ADD_CONTACT_TO_UNIQ_ADD_CASE = 'ADD_CONTACT_TO_UNIQ_ADD_CASE'
export const UPDATE_CONTACT_TO_UNIQ_ADD_CASE = 'UPDATE_CONTACT_TO_UNIQ_ADD_CASE'
export const DELETE_USER_FROM_UNIQ_CONTACTS_IN_ADD = 'DELETE_USER_FROM_UNIQ_CONTACTS_IN_ADD'

export const SET_FORMS_DATA = 'SET_FORMS_DATA'
export const SET_FORMS_NOT_VALID_ADD_CASE = 'SET_FORMS_NOT_VALID_ADD_CASE'
export const RESET_FORMS_NOT_VALID_ADD_CASE = 'RESET_FORMS_NOT_VALID_ADD_CASE'

export const SET_LOADING_ADD_CASE = 'SET_LOADING_ADD_CASE'
export const SET_ERROR_ADD_CASE = 'SET_ERROR_ADD_CASE'
export const SET_SUCCSESS_MESSAGE_ADD_CASE = 'SET_SUCCSESS_MESSAGE_ADD_CASE'


export const RESTE_ALL_ADD_CASES_DATA_APP = 'RESTE_ALL_ADD_CASES_DATA_APP'