import { ReactSVG } from "react-svg"
import { useSelector } from "react-redux"

import ContactProfileHeaderActions from "./Actions"
import ContactProfileHeaderButtons from "./Buttons"

const ContactProfileHeader = (props) => {

    const loading = useSelector(state => state.contacts.loading)

    return (
        <section className="mb-10">

            <div className="bg-primary relative bg-gradient-to-br outline outline-[6px] mx-1 outline-primary/20 from-primary to-second py-5 sm:pb-7 pb-9 px-4 rounded-2xl">

                <ContactProfileHeaderActions 
                    name={props.name}
                />
                
                <div className="flex flex-col items-center justify-center gap-3">

                    <div className="w-48 h-48 mb-4 p-1 bg-primary/20 flex items-center justify-center text-white border-2 border-white border-opacity-10 shadow-custom-lg rounded-2xl">

                        { loading && !props.image ?
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                className="w-11 text-white/50"
                            />
                            :

                            props.image  ?
                                <img className="w-full h-full object-cover rounded-xl shadow-custom-sm" src={props.image} alt={props.name+' image'} /> 
                                :
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/image.svg`}
                                    className="w-14 text-white/40"
                                />
                        }
                        

                    </div>
                    
                    { loading && !props.name ?
                        <div className="h-7 flex items-center justify-center">
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                className="w-9 text-white/50"
                            />
                        </div>
                    :
                        <h1 className="bg-clip-text bg-gradient-to-r from-white to-purple-300 text-transparent 3xm:text-1.5xl text-xl font-medium">
                            {props.name}
                        </h1>
                    }
                </div>

                <ContactProfileHeaderButtons 
                    mobile={props.mobile}
                    id={props.id}
                />

            </div>

        </section>
    )

}

export default ContactProfileHeader