import { useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"

const CaseContentFilesAdd = () => {

    const fileRef = useRef()

    const params = useParams()
    const navigate = useNavigate()

    const handleSelectFile = () => {

        navigate(`/law-suits/${params.id}/add-file`, {
            state: {
                data: fileRef.current.files[0]
            }
        })

    }

    return (
        <div>

            <input onInput={handleSelectFile} ref={fileRef} id="select-file-law-siuts" type="file" className="hidden" />

            <label htmlFor="select-file-law-siuts" className="header-btn cursor-pointer fixed lg:bottom-4 lg:left-4 md:bottom-[104px] bottom-24 left-4 mx-auto z-[1] w-10 h-10">
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`} className="w-[85%] h-[85%]" />
            </label>

        </div>
    )

}

export default CaseContentFilesAdd