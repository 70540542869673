import { useEffect, useState } from "react"
import Header from "../../../../components/Header"
import Button from "../../../../components/UI/Button"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import CaseAddFinanicalTitle from "../../../../components/Cases/Case/Content/Financials/Add/Title"
import CaseAddFinanicalAmount from "../../../../components/Cases/Case/Content/Financials/Add/Amount"
import CaseAddFinanicalType from "../../../../components/Cases/Case/Content/Financials/Add/Type"
import CaseAddFinanicalCategory from "../../../../components/Cases/Case/Content/Financials/Add/Category"
import CaseAddFinanicalSelectUser from "../../../../components/Cases/Case/Content/Financials/Add/Client"
import CaseAddFinanicalClient from "../../../../components/Cases/Case/Content/Financials/Add/Client"
import { useDispatch, useSelector } from "react-redux"
import { addFinanicalItemToFinanical, getSingleCaseData } from "../../../../redux/action/cases/casesAction"
import { useCookies } from "react-cookie"
import CaseAddFinanicalDescription from "../../../../components/Cases/Case/Content/Financials/Add/Description"
import CaseAddFileToggleShow from "../../../../components/Cases/Case/Content/Files/Add/ToggleShow"
import CaseAddFinanicalDate from "../../../../components/Cases/Case/Content/Financials/Add/Date"
import { setDate } from "date-fns-jalali"
import ToastCustom from "../../../../components/UI/ToastCustom"
import { ReactSVG } from "react-svg"
import LoadingDot from "../../../../components/UI/LoadingDot"
import { ADD_FINANCIAL_TO_CASE } from "../../../../redux/action/cases/casesType"

const CaseAddFinanical = () => {

    const dispatch = useDispatch()
    const singlesCase = useSelector(state => state.cases.singlesCase)
    const singleLoading = useSelector(state => state.cases.singleCaseLoading)
    const errorMessage = useSelector(state => state.finanicalCase.errorMessage)
    const loading = useSelector(state => state.finanicalCase.loading)


    const [data, setData] = useState({})

    const [title, setTitle] = useState('')
    const [amount, setAmount] = useState('')
    const [type, setType] = useState('income')
    const [category, setCategory] = useState('')
    const [client, setClient] = useState({})
    const [description, setDescription] = useState('')
    const [showToMovakel, setShowToMovakel] = useState(false)
    const [date, setDate] = useState(new Date())


    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const [cookies, setCookies] = useCookies(['authToken'])

    useEffect(() => {

        window.scrollTo({top: 0})

    }, [])
    
    useEffect(() => {
        
        if(params.id && !singlesCase[params.id] && !singleLoading)
            getInfoSingleCase()
        
        else if(params.id && singlesCase[params.id]) 
            setData(singlesCase[params.id])

    }, [singlesCase])

    const getInfoSingleCase = async () => {

        const data = await dispatch( await getSingleCaseData(params.id, cookies.authToken) )
        
        if(data)
            setData( data )
    }

    const addItemToFinancial = async () => {
        
        let clientInfo = client?.name ? client : null

        
        if(data?.clients?.length == 1 && !clientInfo)
            clientInfo = data?.clients[0]

        let price = convertPrice()

        const info = {
            client_id: clientInfo?.id && type !== 'expense' ? clientInfo.id : null,
            client: type !== 'expense' ? clientInfo : null,
            law_suit_id: Number(params.id),
            title,
            amount: price,
            type,
            category,
            description,
            is_public: showToMovakel,
            paid_at: date ? new Date( date ).getTime() / 1000 : null,
            created_at: new Date()
        }

        // send date to api
        const response = await dispatch( await addFinanicalItemToFinanical(info, cookies.authToken) )

        if(response){
            navigate(location.key !== 'default' ? -1 : `/law-suits/${params.id}` )

            window.scrollTo({top: 0, behavior: 'smooth'})
        }

        // add to the finanical item.

    }

    const convertPrice = () => {

        if(!amount || amount == '') return

        let value = amount.split('٬').join('').split('')

        value = value.map(item => {
            item = item.replace('۰', '0')
            item = item.replace('۱', '1')
            item = item.replace('۲', '2')
            item = item.replace('۳', '3')
            item = item.replace('۴', '4')
            item = item.replace('۵', '5')
            item = item.replace('۶', '6')
            item = item.replace('۷', '7')
            item = item.replace('۸', '8')
            item = item.replace('۹', '9')
            return item
        }).join('')

        if( isNaN(value) || amount === ' ' ) return

        value = Number(value)

        return value
    }

    return (
        <>
            <Header 
                title={'ثبت تراکنش'}
                backBtn={true}
                backPath={ location.key !== 'default' ? -1 : `/law-suits/${params.id}`}
            >
                
                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={loading}
                    onClick={addItemToFinancial}
                >
                    ثبت     
                </Button>

            </Header>

            <main className="main-content">

                <div className="grid xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-8 gap-y-12 mb-12">

                    {/* <CaseAddFinanicalTitle 
                        title={title}
                        onSetTitle={setTitle}
                    /> */}

                    <CaseAddFinanicalCategory 
                        category={category}
                        onSetCategory={setCategory}
                    />

                    <CaseAddFinanicalAmount 
                        amount={amount}
                        onSetAmount={setAmount}
                    />

                    <CaseAddFinanicalDate 
                        date={date}
                        onSetDate={setDate}
                    />

                    <CaseAddFinanicalType 
                        type={type}
                        onSetType={setType}
                    />

                </div>

                <hr className="mb-12" />  

                <CaseAddFileToggleShow 
                    show={showToMovakel}
                    onSetShow={setShowToMovakel}
                    title={'نمایش تراکنش در اپلیکیشن موکل'}
                />         

                <hr className="mt-12 mb-12" />  
                
                {  data && data?.clients && data?.clients?.length >= 2 && type !== 'expense' &&
                    <div className="grid xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-8 gap-y-12 mb-12">
                       
                        <CaseAddFinanicalClient
                            client={client}
                            onSetClient={setClient}
                        />

                    </div>
                }

                <CaseAddFinanicalDescription 
                    description={description}
                    onSetDescription={setDescription}
                />


                { loading && <LoadingDot /> }


                { (errorMessage)  && 
                    
                    <ToastCustom>
                        
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { errorMessage }

                        </div>

                    </ToastCustom>

                }

            </main>

        </>
    )

}

export default CaseAddFinanical