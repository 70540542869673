import { useEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import { debounce } from 'lodash'

import Input from '../../../UI/Input'
import InputGroup from '../../../UI/InputGroup'
import Textarea from '../../../UI/Textarea'
import ContactSelectPosition from "../../SelectPosition"
import SelectCityUser from "../../../UI/SelectCity"
import { useDispatch, useSelector } from "react-redux"
import { RESET_CONTACT_DATA, RESET_LOCATION_CONTACTS, SET_CONTACT_INFO, SET_ENABLE_SUBMIT_BUTTON, SET_LOCATION_FOR_CONTACT, SET_MORE_INFO_FIELDS_CONTACT, SET_POSITION_CONTACT } from "../../../../redux/action/contacts/typeContacts"
import { validateField } from "../../../../utils/form"

const ContactMoreInfoForm = (props) => {

    const dispatch = useDispatch()
    const location = useSelector(state => state.contacts.location)
    const position = useSelector(state => state.contacts.position)
    const moreInfoFields = useSelector(state => state.contacts.moreInfoFields)
    const enableSubmitButton = useSelector(state => state.contacts.enableSubmitButton)

    const [showCollapse, setShowCollapse] = useState({
        show: false,
        loading: false
    })
    const [fieldHaveError, setFieldHaveError] = useState(false)

    const [collapseContentHeight, setCollapseContentHeight] = useState(0)
    const collapseContentRef = useRef()

    useEffect(() => {

        window.addEventListener('resize', debounceUpdateCollapseHeight)

        return () => {
            window.removeEventListener('resize', debounceUpdateCollapseHeight)
            
            resetContactData()
        }

    }, [])

    useEffect(() => {

        if(!props.mainComplete) return 

        const timeout = setTimeout(() => {

            const status = canAddUserOrNo()

            if(status)
                comeSaveNewDataToContactInfo()
            
            dispatch({
                type: SET_ENABLE_SUBMIT_BUTTON,
                payload: status
            })

        }, 400)

        return () => {
            if(timeout)
                clearTimeout(timeout)
        }

    }, [moreInfoFields])

    useEffect(() => {

        if(enableSubmitButton)
            comeSaveNewDataToContactInfo()

    }, [enableSubmitButton])

    // for updating height when resize page
    const updateCollapseHeight = () => {
        
        if(collapseContentRef.current){
            setCollapseContentHeight(collapseContentRef.current.scrollHeight)
        }

    }
    const debounceUpdateCollapseHeight = debounce(updateCollapseHeight, 400)
    // --------------------------------------

    const toggleShowCollapse = () => {
        if(showCollapse.show){

            setShowCollapse({
                show: false,
                loading: true
            })

            setTimeout(() => {
                
                setShowCollapse({
                    loading: false,
                    show: false
                })

            }, 300);

            setCollapseContentHeight(0)
        }
        else{
            setShowCollapse({
                show: true,
                loading: false
            })

            if(collapseContentRef.current){
                setCollapseContentHeight(collapseContentRef.current.scrollHeight)
            }
        }
    }

    const setLocationForContact = (location) => {

        dispatch({
            type: SET_LOCATION_FOR_CONTACT,
            payload: location
        })

        dispatch({
            type: SET_CONTACT_INFO,
            payload: {
                location
            }
        })

    }

    const resetContactData = () => {
        // come empty the fields
        Object.keys(moreInfoFields).map(item => {
            moreInfoFields[item].value = ''
            moreInfoFields[item].isValid = true
        })

        // save
        dispatch({
            type: SET_MORE_INFO_FIELDS_CONTACT,
            payload: moreInfoFields
        })

        // come do reset data
        dispatch({
            type: RESET_CONTACT_DATA
        })
    }

    const resetLocationData = () => {
        dispatch({
            type: RESET_LOCATION_CONTACTS
        })

        dispatch({
            type: SET_CONTACT_INFO,
            payload: {
                location: {
                    city: {id: '', name: ''},
                    province: ''
                }
            }
        })
    }

    const setPosition = (position) => {
        
        dispatch({
            type: SET_POSITION_CONTACT,
            payload: position
        })

        dispatch({
            type: SET_CONTACT_INFO,
            payload: {
                position
            }
        })

    }

    // ---- work in fields ----
    const updateField = (event) => {

        const value = event.target.value

        if(event.target.id === 'address' && event.target.value.length > 300) return

        // if be bigger than max do not any work 
        if(moreInfoFields[event.target.id].max && value.length > moreInfoFields[event.target.id].max) return

        if(isNaN(event.target.value) && moreInfoFields[event.target.id].type === 'tel') return

        // update field value
        moreInfoFields[event.target.id].value = value

        // check exist invalid value or no
        const valid = validateField(moreInfoFields[event.target.id])
        moreInfoFields[event.target.id].isValid = valid

        // check global validate :)
        checkAllInfoIsValid(event.target.id, valid)

        // save value 
        dispatch({
            type: SET_MORE_INFO_FIELDS_CONTACT,
            payload: moreInfoFields
        })

    }

    const checkAllInfoIsValid = (id, valid) => {

        // if be valid and not exist invalid
        if(valid && !props.invalid[id]){
            setFieldHaveError(false)
            return true
        }

        // if field is invalid come add to invalids data 
        if(!valid && !props.invalid[id]){
            props.setInvalid({
                ...props.invalid,
                [id]: true
            })

            setFieldHaveError(true)

            return false
        }

        // come remove of invalid data when field is valid
        if(valid && props.invalid[id]){
            const newInvalids = { ...props.invalid }
            delete newInvalids[id]

            props.setInvalid(newInvalids)
            setFieldHaveError(false)

            return true
        }
        
    }

    const canAddUserOrNo = () => {
        const invalids = Object.keys(props.invalid).length

        if(invalids !== 0) return false

        // when is updated dont come to check
        if(invalids === 0 && enableSubmitButton) return true

        return true
    }

    const comeSaveNewDataToContactInfo = () => {
        const fields = {}

        // check can add or no
        Object.keys(moreInfoFields).map(item => {
            fields[item] = moreInfoFields[item].value
        })

        fields.position = position

        dispatch({
            type: SET_CONTACT_INFO,
            payload: fields
        })
    }

    return (
        <div className='rounded-2xl shadow-custom-lg border-[3px] border-white'>

            <button onClick={toggleShowCollapse} className='w-full flex items-center justify-between text-gray-800 cursor-pointer py-3 px-4 hover:text-black duration-300 group outline-none focus:outline-none'>

                <span className="flex items-center gap-1.5"> 
                    { fieldHaveError && !showCollapse.show && <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-5 text-rose-500" /> }
                    اطلاعات تکمیلی 
                </span>

                <div className="header-btn bg-white outline-gray-200 flex items-center justify-center">

                    { !showCollapse.show && !showCollapse.loading ? 
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`}
                            className="w-7 text-gray-500"
                        />
                    :   
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/subtract.svg`}
                            className="w-3.5 text-red-600"
                        />
                    }

                </div>

            </button>

            <div style={ { height: showCollapse.show ? `${collapseContentHeight}px` : '0px' } } className={`collapse-content overflow-hidden`}>
                
                <div ref={collapseContentRef} className={`p-4 flex flex-col gap-6 ${showCollapse.show ? '' : 'border-none'} border-t duration-300`}>

                    <InputGroup>

                        <Input
                            id={'phone'}
                            label={moreInfoFields.phone.label}
                            max={moreInfoFields.phone.max}
                            icon={moreInfoFields.phone.icon}
                            value={moreInfoFields.phone.value}
                            isValid={moreInfoFields.phone.isValid}
                            type={moreInfoFields.phone.type}
                            onChange={ updateField }
                            className={'text-right'}
                        />

                        <SelectCityUser 
                            labelSize="sm"
                            location={location}
                            onUpdateLocation={setLocationForContact}
                            onCancleChoose={resetLocationData}
                        />

                    </InputGroup>

                    <InputGroup>

                        <Input
                            id={'email'}
                            label={moreInfoFields.email.label}
                            icon={moreInfoFields.email.icon}
                            value={moreInfoFields.email.value}
                            isValid={moreInfoFields.email.isValid}
                            onChange={ updateField }
                        />

                    </InputGroup>

                    <Textarea 
                        id={'address'}
                        label={moreInfoFields.address.label}
                        icon={moreInfoFields.address.icon}
                        value={moreInfoFields.address.value}
                        isValid={moreInfoFields.address.isValid}
                        onChange={ updateField }
                        max={'300'}
                    />

                </div>

            </div>

        </div>
    )

}

export default ContactMoreInfoForm