import { useEffect, useRef, useState } from 'react';

import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

import './index.css'
import { ReactSVG } from 'react-svg';

const CaseAddFinanicalDate = ({date, onSetDate, loading}) => {

    const datePickerRef = useRef()

    useEffect(() => {

        if(datePickerRef.current){

            datePickerRef.current.querySelector('.rmdp-input').setAttribute('readonly', 'true')

        }

    }, [datePickerRef])

    return (
        <div className="flex flex-col gap-3 xl:order-1 lg:order-2 md:order-1 order-2">
                
            <span className=''> انتخاب تاریخ :</span>

            <div className='relative'>

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/calendar-3.svg`} className={`w-5 h-5 text-primary absolute right-3 top-0 bottom-0 my-auto peer-focus:!text-primary duration-300`} />

                <DatePicker
                    value={date}
                    format="YYYY/MM/DD"
                    calendar={persian}
                    locale={persian_fa}
                    onChange={onSetDate}
                    calendarPosition="bottom-right" 
                    ref={datePickerRef}
                />

                { date && 
                    <button onClick={() => onSetDate(null)} className='w-8 flex justify-end items-center h-8 absolute left-3 top-0 bottom-0 my-auto duration-300'>
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`} className={`w-5 h-5 text-rose-500`} />
                    </button>
                }

                { loading && <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className={`absolute top-1/2 -translate-y-1/2 right-12 w-7 text-gray-500`} />  }

            </div>

        </div>
    )

}

export default CaseAddFinanicalDate