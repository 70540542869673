import { debounce } from "lodash"
import { useEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import Textarea from "../../../UI/Textarea"
import Input from "../../../UI/Input"
import AddCaseFormStepField from "./Step"
import AddCaseFormCategoryField from "./Category"
import AddCaseFormStateField from "./State"
import AddCaseFormJurisdictionField from "./Jurisdiction"
import SelectCityUser from "../../../UI/SelectCity"
import { useDispatch, useSelector } from "react-redux"
import { SET_FORMS_DATA } from "../../../../redux/action/cases/add/type"
import { updatePublicFieldCase } from "../../../../utils/add-case-form"
import { SET_CHOOSE_PROVINCE } from "../../../../redux/action/location/locationType"

const AddCaseFormMoreData = ({forms, ...props}) => {

    const dispatch = useDispatch()

    const [showCollapse, setShowCollapse] = useState({
        show: false,
        loading: false
    })

    const [errors, setErrors] = useState({})

    const [collapseContentHeight, setCollapseContentHeight] = useState(0)
    const collapseContentRef = useRef()

    useEffect(() => {

        window.addEventListener('resize', debounceUpdateCollapseHeight)

        return () => {
            window.removeEventListener('resize', debounceUpdateCollapseHeight)
        }

    }, [])

    // --------------------------------------
    // for updating height when resize page
    const updateCollapseHeight = () => {
        
        if(collapseContentRef.current){
            setCollapseContentHeight(collapseContentRef.current.scrollHeight)
        }

    }
    const debounceUpdateCollapseHeight = debounce(updateCollapseHeight, 400)
    // --------------------------------------

    const toggleShowCollapse = () => {
        if(showCollapse.show){

            setShowCollapse({
                show: false,
                loading: true
            })

            setTimeout(() => {
                
                setShowCollapse({
                    show: false,
                    loading: false
                })

            }, 500);

            setCollapseContentHeight(0)
        }
        else{

            setShowCollapse({
                show: true,
                loading: false
            })

            if(collapseContentRef.current){
                setCollapseContentHeight(collapseContentRef.current.scrollHeight)
            }
        }
    }

    const updateArchiveCodeField = (event) => {
     
        const response = updatePublicFieldCase(event.target.value, forms.archive_code)

        if(isNaN(event.target.value)) return

        if(response){

            dispatch({
                type: SET_FORMS_DATA,
                payload: {
                    ...forms,
                    archive_code: response
                }
            })

            if(!response.isValid && !errors['archive_code']){
                const error = errors
                error['archive_code'] = true
    
                setErrors({...error})
            }
            else if(response.isValid && errors['archive_code']){
                const error = errors
    
                delete error['archive_code']
    
                setErrors({...error})
            }

        }
        
    }  
    
    const updateBranchField = (event) => {
        const response = updatePublicFieldCase(event.target.value, forms.branch, false)

        if(response){

            dispatch({
                type: SET_FORMS_DATA,
                payload: {
                    ...forms,
                    branch: response
                }
            })

            if(!response.isValid && !errors['branch']){
                const error = errors
                error['branch'] = true
    
                setErrors({...error})
            }
            else if(response.isValid && errors['branch']){
                const error = errors
    
                delete error['branch']
    
                setErrors({...error})
            }

        }


    }

    const updateDescriptionField = (event) => {
        const response = updatePublicFieldCase(event.target.value, forms.description)

        if(event.target.value.length > 300) return

        if(response){

            dispatch({
                type: SET_FORMS_DATA,
                payload: {
                    ...forms,
                    description: response
                }
            })

            if(!response.isValid && !errors['description']){
                const error = errors
                error['description'] = true
    
                setErrors({...error})
            }
            else if(response.isValid && errors['description']){
                const error = errors
    
                delete error['description']
    
                setErrors({...error})
            }
        }

    }

    const updateAgreedFeeField = (event) => {
        
        const value = event.target.value.split(',').join('')
        const number =  Number(value)?.toLocaleString('en')

        if(isNaN(value)) return

        dispatch({
            type: SET_FORMS_DATA,
            payload: {
                ...forms,
                agreed_fee: (number === 'NaN' || number == 0) ? '' : number
            }
        })

    }

    const updateLocationField = (data) => {

        dispatch({
            type: SET_FORMS_DATA,
            payload: {
                ...forms,
                location: {
                    province: data.province,
                    city: data.city
                }
            }
        })

    }

    // STEP
    const updateStepField = (value) => {

        dispatch({
            type: SET_FORMS_DATA,
            payload: {
                ...forms,
                step: value
            }
        })

    }

    // CATEGORY
    const updateCategoryField = (category) => {
        
        dispatch({
            type: SET_FORMS_DATA,
            payload: {
                ...forms,
                category
            }
        })

    }

    // STATE
    const updateStateField = (state) => {
        
        dispatch({
            type: SET_FORMS_DATA,
            payload: {
                ...forms,
                state: state
            }
        })

    }

    // JURISDICTION
    const updateJurisdictionField = (jurisdiction) => {

        dispatch({
            type: SET_FORMS_DATA,
            payload: {
                ...forms,
                jurisdiction
            }
        })

    }

    return (
        <div className='rounded-2xl shadow-custom-lg border-[3px] border-white mb-1'>
            
            <button type="button" onClick={(toggleShowCollapse)} className='w-full flex items-center justify-between text-gray-800 cursor-pointer py-3 px-4 hover:text-black duration-300 group outline-none focus:outline-none'>

                <span className="flex items-center gap-1.5 font-medium"> 
                    { !showCollapse.show && Object.keys(errors).length >= 1 && <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-5 text-rose-500" /> }
                    اطلاعات تکمیلی 
                </span>

                <div className="header-btn bg-white outline-gray-200 flex items-center justify-center">

                    { !showCollapse.show && !showCollapse.loading ? 
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`}
                            className="w-7 text-gray-500"
                        />
                    :   
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/subtract.svg`}
                            className="w-3.5 text-red-600"
                        />
                    }

                </div>

            </button>

            <div style={ { height: showCollapse.show ? `${collapseContentHeight}px` : '0px' } } className={`collapse-content overflow-hidden`}>
                
                <div ref={collapseContentRef} className={`p-4 flex flex-col gap-6 ${showCollapse.show ? '' : 'border-none'} border-t duration-300`}>
                    
                    <div className="grid xm:grid-cols-2 grid-cols-1 gap-8">

                        <Input
                            id={'case-archive-code'}
                            label={'شماره بایگانی پرونده'}
                            max={ forms?.archive_code?.max ? forms?.archive_code?.max : 0 }
                            icon={'folder'}
                            type="tel"
                            className={'text-right'}
                            value={ forms?.archive_code?.value ? forms?.archive_code?.value : '' }
                            isValid={ forms?.archive_code?.isValid }
                            onChange={ updateArchiveCodeField }
                        />

                        <AddCaseFormStepField 
                            value={forms?.step}
                            onUpdateStepField={updateStepField}
                        /> 

                        <AddCaseFormCategoryField 
                            category={forms?.category}
                            onUpdateCategoryField={updateCategoryField}
                        />     

                        {/* <AddCaseFormStateField 
                            value={forms?.state}
                            onUpdateStateField={updateStateField}
                        />  */}

                        <SelectCityUser
                            data={forms?.location} 
                            onUpdateLocation={updateLocationField}
                            onCancleChoose={() => updateLocationField({province: '', city: {id: null, name: ''}})}
                        />    

                        <Input
                            id={'case-branch'}
                            label={'شعبه پرونده'}
                            max={ forms?.branch?.max ? forms?.branch?.max : 0 }
                            icon={'lawyer'}
                            value={ forms?.branch?.value ? forms?.branch?.value : '' }
                            isValid={ forms?.branch?.isValid }
                            onChange={ updateBranchField }
                        />

                        {/* <AddCaseFormJurisdictionField 
                            jurisdiction={forms?.jurisdiction}
                            onUpdateJurisdictionField={updateJurisdictionField}
                        />  */}

                    </div>
                    
                    <hr />

                    <Textarea 
                        id={'case-description'}
                        rows={4}
                        icon={'description'}
                        label={'توضیحات'}
                        max={300}
                        value={ forms?.description?.value ?  forms?.description?.value : '' }
                        isValid={ forms?.description?.isValid }
                        onChange={ updateDescriptionField }
                        loading={false}
                    /> 

                    <hr />

                    <div className="relative">

                        <Input
                            id={'case-agreed-fee'}
                            label={'حق الوکاله پرونده'}
                            icon={'money'}
                            type="tel"
                            value={ forms?.agreed_fee }
                            isValid={ true }
                            className={'text-right'}
                            onChange={ updateAgreedFeeField }
                        />
                        
                        <span className="text-primary absolute left-3 top-[42px]"> تومان </span>

                    </div>
                    
                </div>

            </div>

        </div>
    )

}

export default AddCaseFormMoreData