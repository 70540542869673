export const SET_SHOW_CONTACTS_BOX_EDIT_CASE = 'SET_SHOW_CONTACTS_BOX_EDIT_CASE'

export const SET_DETAIL_OF_CASE_EDIT_CASE = 'SET_DETAIL_OF_CASE_EDIT_CASE'
export const RESET_DETAIL_OF_CASE_EDIT_CASE = 'RESET_DETAIL_OF_CASE_EDIT_CASE'

export const SET_CLIENTS_EDIT_FORM = 'SET_CLIENTS_EDIT_FORM'
export const ADD_CLIENT_IN_EDIT_FORM_CASE = 'ADD_CLIENT_IN_EDIT_FORM_CASE'
export const DELETE_CLIENT_FROM_EDIT_FORM_CASE = 'DELETE_CLIENT_FROM_EDIT_FORM_CASE'
export const EDIT_ITEM_CLIENT_IN_EDIT_FORM_CASE = 'EDIT_ITEM_CLIENT_IN_EDIT_FORM_CASE'

export const SET_CONTACTS_EDIT_FORM = 'SET_CONTACTS_EDIT_FORM'
export const ADD_CONTACTS_IN_EDIT_FORM_CASE = 'ADD_CONTACTS_IN_EDIT_FORM_CASE'
export const DELETE_CONTACTS_FROM_EDIT_FORM_CASE = 'DELETE_CONTACTS_FROM_EDIT_FORM_CASE'
export const EDIT_ITEM_CONTACTS_IN_EDIT_FORM_CASE = 'EDIT_ITEM_CONTACTS_IN_EDIT_FORM_CASE'

export const SET_ALL_CONTACTS_EDIT_CASE = 'SET_ALL_CONTACTS_EDIT_CASE'
export const ADD_CONTACT_TO_UNIQ_EDIT_CASE = 'ADD_CONTACT_TO_UNIQ_EDIT_CASE'
export const UPDATE_CONTACT_TO_UNIQ_EDIT_CASE = 'UPDATE_CONTACT_TO_UNIQ_EDIT_CASE'
export const DELETE_USER_FROM_UNIQ_CONTACTS_IN_EDIT = 'DELETE_USER_FROM_UNIQ_CONTACTS_IN_EDIT'
export const SET_DELETED_INITIAL_CONTACTS_IN_EDIT_CASE = 'SET_DELETED_INITIAL_CONTACTS_IN_EDIT_CASE'

export const SET_FORMS_DATA_EDIT_CASE = 'SET_FORMS_DATA_EDIT_CASE'
export const SET_FORMS_NOT_VALID_EDIT_CASE = 'SET_FORMS_NOT_VALID_EDIT_CASE'
export const RESET_FORMS_NOT_VALID_EDIT_CASE = 'RESET_FORMS_NOT_VALID_EDIT_CASE'

export const SET_LOADING_EDIT_CASE = 'SET_LOADING_EDIT_CASE'
export const SET_ERROR_EDIT_CASE = 'SET_ERROR_EDIT_CASE'
export const SET_SUCCSESS_MESSAGE_EDIT_CASE = 'SET_SUCCSESS_MESSAGE_EDIT_CASE'

export const SET_CHANGED_DATA_EDIT_CASE = 'SET_CHANGED_DATA_EDIT_CASE'

export const RESTE_ALL_EDIT_CASES_DATA_APP = 'RESTE_ALL_EDIT_CASES_DATA_APP'