import { ReactSVG } from "react-svg"
import Button from "../../../../UI/Button"
import { useDispatch, useSelector } from "react-redux"
import { editCaseToApi } from "../../../../../redux/action/cases/casesAction"
import { UPDATE_ITEM_TO_LAW_SUITS_HOME } from "../../../../../redux/action/home/homeType"
import { UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS, UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS } from "../../../../../redux/action/contacts/typeContacts"
import { useCookies } from "react-cookie"
import ToastCustom from "../../../../UI/ToastCustom"
import LoadingDot from "../../../../UI/LoadingDot"

const CaseContentUpdateHeader = ({ data, firstData, onSetFirstData, onSetData, onSetChanges }) => {

    const dispatch = useDispatch()
    const loading = useSelector(state => state.editCase.loading)
    const successMessage = useSelector(state => state.editCase.successMessage)
    const updateHasError = useSelector(state => state.editCase.error)
    
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const profilesList = useSelector(state => state.contacts.profilesList)
    const contactInfo = useSelector(state => state.contacts.contactInfo)

    const [cookies] = useCookies(['authToken'])

    const resetData = () => {

        onSetData( firstData )
        onSetChanges( [] )

    }

    const editData = async () => {

        const info = {
            title: data.title,
            clients: data.clients,
            contacts: data.contacts,
            state: data?.state,
            step: data?.step,
            agreed_fee: data?.agreed_fee ? String(data?.agreed_fee).split(',').join('') : null,
            description: data?.description,
            archive_code: data?.archive_code,
            code: data?.code,
            branch: data?.branch,
            city_id: data?.city?.id,
            province_id: data?.province?.id,
            category_id: data?.category?.id,
            jurisdiction_id: data?.jurisdiction?.id
        }

        const response = await dispatch( await editCaseToApi(info, data, cookies.authToken) )         

        if(response){

            // come edit-home-case
            if(homeInfoLoaded){

                // add-to-home
                const info = {
                    id: data.id,
                    title: data.title,
                    last_state: {
                        user_id: '',
                        data: {
                            "status": "updated"
                        }
                    }
                }

                dispatch({
                    type: UPDATE_ITEM_TO_LAW_SUITS_HOME,
                    payload: info
                })

            }

            // come edit-law-suits profileList
            // come edit-law-suits contactInfo
            data.clients.map(item => {
                    
                if(profilesList && profilesList[item.id]){

                    dispatch({
                        type: UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS,
                        payload: {
                            contactId: item.id,
                            law_data: {
                                id: data.id,
                                title: data.title,
                                code: data.code,
                                archive_code: data.archive_code,
                                state: data.state,
                                step: data.step,
                                branch: data.branch,
                                is_archived: false,
                                position: item.position,
                                notify: item.notify,
                            }
                        }
                    })

                    if(contactInfo && contactInfo?.id && Number(contactInfo.id) === Number(item.id)){
                        
                        dispatch({
                            type: UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS,
                            payload: {
                                contactId: item.id,
                                law_data: {
                                    id: data.id,
                                    title: data.title,
                                    code: data.code,
                                    archive_code: data.archive_code,
                                    state: data.state,
                                    step: data.step,
                                    branch: data.branch,
                                    is_archived: false,
                                    position: item.position,
                                    notify: item.notify,
                                }
                            }
                        })

                    }

                }

            })

            onSetFirstData( data )
            onSetChanges( [] )
        }

    }

    return (
        <>
            <header className="fixed lg:pt-4 bg-custom-gray py-3 top-0 left-0 lg:w-3/4 lg:px-4 px-3 w-full z-[3]">

                <div className="bg-white border border-gray-200 flex items-center flex-wrap justify-between rounded-2xl shadow-custom-lg p-4">

                    <span className="text-four font-medium"> تغییر ایجاد کردید! </span>

                    <div className="flex items-center gap-2">

                        <button onClick={ resetData } className="text-rose-500 w-12"> لغو </button>

                        <Button  
                            className={'profile-btn bg-green-500 py-1 flex gap-1 pr-3 pl-4'}
                            onClick={ editData }
                        >
                            
                            اعمال تغییرات    
                        </Button>

                    </div>

                </div>

            </header>

            { (updateHasError) && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { updateHasError }

                        </div>

                    </ToastCustom>

                }

                { successMessage && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-green-600 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                            { successMessage }

                        </div>

                    </ToastCustom>

                }

                { loading && <LoadingDot /> }

        </>
    )

}

export default CaseContentUpdateHeader