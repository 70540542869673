import axios from 'axios'
import { apiKey } from '../../store'
import { SET_FAILURE_HOME, SET_INFO_HOME, SET_LOADING_HOME } from './homeType'

const apiKeyHome = apiKey + 'lawyer/home'

export const getHomeInfo = async (authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_HOME,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyHome}`, config)
        .catch(error => {
            
            dispatch({
                type: SET_FAILURE_HOME,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_INFO_HOME,
                    payload: data.data.data
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_HOME,
                    payload: data.data.errors[0]?.message
                })

            }

        }

    }

}