import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { useCookies } from "react-cookie"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css";

import {  SET_UPDATE_FAILURE_CONTACTS } from "../../../redux/action/contacts/typeContacts"
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast"

import LoadingDot from "../../../components/UI/LoadingDot"
import Header from "../../../components/Header"
import Button from "../../../components/UI/Button"
import ContactEditSection from "../../../components/Contacts/Edit";
import { useParams } from "react-router-dom";
import { editContact } from "../../../redux/action/contacts/contactsAction";
import { SET_SINGLES_CASES } from "../../../redux/action/cases/casesType";

const ContactEdit = () => {

    const dispatch = useDispatch()
    const contactInfo = useSelector(state => state.contacts.contactInfoForUseInUpdate)
    const enableSubmitButton = useSelector(state => state.contacts.enableSubmitButton)
    const error = useSelector(state => state.contacts.updateError)
    const loading = useSelector(state => state.contacts.updateLoading)
    const singlesCase = useSelector(state => state.cases.singlesCase)

    const [cookies] = useCookies(['authToken'])

    const params = useParams()

    useEffect(() => {

        window.scrollTo({top: 0})

        return () => {
            if(error)
                dispatch({
                    type: SET_UPDATE_FAILURE_CONTACTS,
                    payload: null
                })
        }

    }, [])

    useEffect(() => {

        if(error){
            showErrorMessage(error)

            // destroy the error
            dispatch({
                type: SET_UPDATE_FAILURE_CONTACTS,
                payload: null
            })
        }

    }, [error])

    const doEditContact = async () => {

        contactInfo.city_id = contactInfo.location?.city?.id || null
        contactInfo.province_id = contactInfo.location?.province?.id || null

        // send to api
        const success = await dispatch( await editContact(params.id, contactInfo, cookies.authToken) )
        
        if(success?.name){
            showSuccessMessage('مخاطب با موفقیت ویرایش شد')

            // update cases :))
            const result = {}

            for( const item in singlesCase ){

                result[item] = {
                    ...singlesCase[item],
                    clients: singlesCase[item].clients.map(client => {
                    
                        if(client.id === Number(params.id)){
    
                            return {
                                ...client,
                                ...success,
                            }
    
                        }
    
                        return client
    
                    }),
                    contacts: singlesCase[item].contacts.map(contacts => {
                    
                        if(contacts.id === Number(params.id)){
    
                            return {
                                ...contacts,
                                ...success,
                            }
    
                        }
    
                        return contacts
    
                    })
                }

            }

            dispatch({
                type: SET_SINGLES_CASES,
                payload: result
            })

            // edit singles-case
            // singlesCase: {
            //     ...Object.keys()
            // }
        }

    }

    return (
        <>
            <Header 
                title={'ویرایش مخاطب'}
                backBtn={true}
            >

                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={!enableSubmitButton}
                    onClick={doEditContact}
                >
                    ثبت     
                </Button>

            </Header>

            <main className="main-content">

                <ContactEditSection />

                { loading && <LoadingDot /> }

                <ToastContainer className={'lg:!bottom-0 !bottom-[78px]'} />

            </main>

        </>
    ) 

}

export default ContactEdit