import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"

const PlansDescription = () => {

    const description = useSelector(state => state.plans.description)
    const loading = useSelector(state => state.plans.loading)

    return (
        <section className="mb-12">
            
            {/* <div className="bg-blue-400/30 outline-blue-500/10 outline outline-4 leading-7 flex items-center gap-4 font-medium  p-4 rounded-xl">

                <div className="flex-shrink-0 outline outline-2 outline-blue-500/30 w-10 h-10 shadow-custom-md bg-blue-500 text-white rounded-xl flex items-center justify-center">
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/megaphone.svg`} 
                        className="w-7" 
                    />
                </div>

                { description }

            </div> */}

            { description && 
                <div className="flex justify-between shadow-custom-sm items-center gap-5 bg-gradient-to-br outline-4 mx-1 outline-third/20 from-white to-white rounded-2xl py-6 lg:p-6 md:p-5 p-4 mb-12">
                            
                    <div className="flex flex-col gap-2">
                        
                        { !loading ?
                            <p className="leading-9">  { description ? description : 'یکی از طرح های اشتراکی را انتخاب نمائید' } </p>
                            :
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} 
                                className="w-14 mt-5 text-gray-600" 
                            />
                        }
            
                    </div>
                    
                    <div className="mr-auto flex-shrink-0">
                        
                        <img className="w-20 scale-110" src={`/assets/images/pictures/download (7).svg`} alt="" />
            
                    </div>
            
                </div>
            }
        

        </section>
    )
    
}

export default PlansDescription