export const updatePublicFieldCase = (value, field, required = false) => {

    const length = value.length

    // come back
    if(length > field.max)
        return

    // validate
    if((length >= 1 || required) && length < field.min)
        field.isValid = false
    else 
        field.isValid = true

    field.value = value    

    return field
}