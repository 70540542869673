import { useState } from "react"
import Input from "../../../../../UI/Input"
import { ReactSVG } from "react-svg"
import { useDispatch, useSelector } from "react-redux"
import ToastCustom from "../../../../../UI/ToastCustom"
import { useCookies } from "react-cookie"
import { UPDATE_ITEM_TO_LAW_SUITS_HOME } from "../../../../../../redux/action/home/homeType"
import { UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS, UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS } from "../../../../../../redux/action/contacts/typeContacts"
import { editCaseToApi } from "../../../../../../redux/action/cases/casesAction"
import { UPDATE_CASE_FROM_CASES } from "../../../../../../redux/action/cases/casesType"

const CaseFinancialsSetMoney = ({data, ...props}) => {

    const dispatch = useDispatch()
    const loading = useSelector(state => state.editCase.loading)
    const successMessage = useSelector(state => state.editCase.successMessage)
    const updateHasError = useSelector(state => state.editCase.error)

    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const profilesList = useSelector(state => state.contacts.profilesList)
    const contactInfo = useSelector(state => state.contacts.contactInfo)

    const [money, setMoney] = useState()

    const [cookies] = useCookies(['authToken'])

    const updateMoneyField = (event) => {

        let value = event.target.value.split('٬').join('').split('')

        value = value.map(item => {
            item = item.replace('۰', '0')
            item = item.replace('۱', '1')
            item = item.replace('۲', '2')
            item = item.replace('۳', '3')
            item = item.replace('۴', '4')
            item = item.replace('۵', '5')
            item = item.replace('۶', '6')
            item = item.replace('۷', '7')
            item = item.replace('۸', '8')
            item = item.replace('۹', '9')
            return item
        }).join('')

        if( isNaN(value) || event.target.value === ' ' ) return

        value = Number(value)

        setMoney( value >= 1 ? `${value.toLocaleString('fa')}` : '' )

    }

    const setMoneyInServer = async () => {

        let value = money.split('٬').join('').split('')

        value = value.map(item => {
            item = item.replace('۰', '0')
            item = item.replace('۱', '1')
            item = item.replace('۲', '2')
            item = item.replace('۳', '3')
            item = item.replace('۴', '4')
            item = item.replace('۵', '5')
            item = item.replace('۶', '6')
            item = item.replace('۷', '7')
            item = item.replace('۸', '8')
            item = item.replace('۹', '9')
            return item
        }).join('')

        if( isNaN(value) || money === ' ' ) return

        value = Number(value)
        
        if(value === 0 || money === '') {

        }

        const info = {
            title: data.title,
            clients: data.clients,
            contacts: data.contacts,
            state: data?.state,
            step: data?.step,
            agreed_fee: value ? String(value).split(',').join('') : null,
            description: data?.description,
            archive_code: data?.archive_code,
            code: data?.code,
            branch: data?.branch,
            city_id: data?.city?.id,
            province_id: data?.province?.id,
            category_id: data?.category?.id,
            jurisdiction_id: data?.jurisdiction?.id
        }

        const response = await dispatch( await editCaseToApi(info, data, cookies.authToken) )         

        if(response){

            dispatch({
                type: UPDATE_CASE_FROM_CASES,
                payload: {
                    ...data,
                    ...info
                }
            })

            // come edit-home-case
            if(homeInfoLoaded){

                // add-to-home
                const info = {
                    id: data.id,
                    title: data.title,
                    last_state: {
                        user_id: '',
                        data: {
                            "status": "updated"
                        }
                    }
                }

                dispatch({
                    type: UPDATE_ITEM_TO_LAW_SUITS_HOME,
                    payload: info
                })

            }

            // come edit-law-suits profileList
            // come edit-law-suits contactInfo
            data.clients.map(item => {
                    
                if(profilesList && profilesList[item.id]){

                    dispatch({
                        type: UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS,
                        payload: {
                            contactId: item.id,
                            law_data: {
                                id: data.id,
                                title: data.title,
                                code: data.code,
                                archive_code: data.archive_code,
                                state: data.state,
                                step: data.step,
                                branch: data.branch,
                                is_archived: false,
                                position: item.position,
                                notify: item.notify,
                            }
                        }
                    })

                    if(contactInfo && contactInfo?.id && Number(contactInfo.id) === Number(item.id)){
                        
                        dispatch({
                            type: UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS,
                            payload: {
                                contactId: item.id,
                                law_data: {
                                    id: data.id,
                                    title: data.title,
                                    code: data.code,
                                    archive_code: data.archive_code,
                                    state: data.state,
                                    step: data.step,
                                    branch: data.branch,
                                    is_archived: false,
                                    position: item.position,
                                    notify: item.notify,
                                }
                            }
                        })

                    }

                }

            })

            props.onSetData( {
                ...data,
                ...info
            } )
            props.onSetFirstData( {
                ...data,
                ...info
            }  )
            props.onSetChanges( [] )
        }

    }
    
    return (
        <>
            <form onSubmit={setMoneyInServer} className="max-w-xl mx-auto">
                
                <p className="text-gray-700 mb-8"> برای استفاده از این بخش باید حق‌الوکاله خود را تنظیم کنید </p>

                <div className="relative">

                    <Input
                        id={'case-agreed-fee'}
                        icon={'money'}
                        type="tel"
                        value={ money }
                        isValid={ true }
                        className={'text-right shadow-custom-lg'}
                        onChange={ updateMoneyField }
                    />
                    
                    <span className="text-primary absolute left-3 top-3"> تومان </span>

                </div>

                <button onClick={setMoneyInServer} disabled={loading} className="btn disabled:opacity-90 w-full md:mt-14 mt-12 h-12 mb-1 outline font-medium rounded-xl outline-[3px] outline-primary/20"> 
                    { !loading ? 
                        'تنظیم' 
                        :
                        <ReactSVG 
                            className="w-12"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        />
                    }

                </button>            

            </form>
                    
            { (updateHasError) && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { updateHasError }

                        </div>

                    </ToastCustom>

                }

                { successMessage && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-green-600 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                            { successMessage }

                        </div>

                    </ToastCustom>

                }

        </>
    )

}

export default CaseFinancialsSetMoney