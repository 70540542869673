import SelectClientTypeContentItem from "./Item"

const SelectClientTypeContent = ({type, onSetType}) => {

    return (
        <div className="w-full flex flex-col gap-6">
            
            <SelectClientTypeContentItem 
                title={'وکیل هستم'}   
                icon={'lawyer-2'} 
                color={'purple'}
                type={0}

                activeType={type}         
                onSetType={onSetType}         
            />

            <SelectClientTypeContentItem 
                title={'موکل هستم'} 
                icon={'user-2'}
                color={'orange'}
                type={1}

                activeType={type}         
                onSetType={onSetType}             
            />
            
        </div>
    )

}

export default SelectClientTypeContent