import { ReactSVG } from "react-svg"

import Button from "../../../../../../UI/Button"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { useCookies } from "react-cookie"
import { deleteFiancialFromCase } from "../../../../../../../redux/action/cases/casesAction"

const DeleteHeaderFinancial = ({selectedItemId, onSetSelectedItemDelete, items}) => {
    
    const dispatch = useDispatch()

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    const destroyDeleteMode = () => {
        onSetSelectedItemDelete(null)
    }

    const deleteItem = async () => {
        const item =  items.filter(item => item.id === selectedItemId)[0]
    
        if(!item) return

        const response = await dispatch( await deleteFiancialFromCase(item.id, params.id, item.amount, item.type, cookies.authToken) )

        if(response){

            destroyDeleteMode()

            window.scrollTo({top: 0, behavior: 'smooth'})

        }
        
    }

    return (
        <header className="fixed lg:pt-4 bg-custom-gray py-3 top-0 left-0 lg:w-3/4 lg:px-4 px-3 w-full z-[3]">

            <div className="bg-white border border-gray-200 flex items-center flex-wrap justify-between rounded-2xl shadow-custom-lg p-4">

                <button onClick={destroyDeleteMode} className="text-gray-600 h-full">
                    <ReactSVG 
                        className="w-8 h-8"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`}
                    />
                </button>

                <div className="flex items-center gap-1">

                    <Link to={`/law-suits/${params.id}/edit-finanical/${selectedItemId}`} className="w-10 h-8 flex items-center justify-end">

                        <ReactSVG
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/edit-2.svg`}
                            className="w-7 text-primary"
                        />

                    </Link>

                    <Button  
                        className={'profile-btn bg-rose-600 py-1 flex gap-1 pr-3 pl-4 mr-2'}
                        onClick={deleteItem}
                    >
                        <ReactSVG
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                            className="w-4"
                        />
                        حذف     
                    </Button>

                </div>

            </div>

        </header>
    )

}

export default DeleteHeaderFinancial