import { ReactSVG } from "react-svg"
import { getProfileColor } from "../../../../../../utils/messages"
import { useDispatch } from "react-redux"
import { DELETE_CLIENT_FROM_ADD_FORM_CASE, DELETE_CONTACTS_FROM_ADD_FORM_CASE } from "../../../../../../redux/action/cases/add/type"
import { Link, useLocation, useParams } from "react-router-dom"
import { DELETE_CLIENT_FROM_EDIT_FORM_CASE, DELETE_CONTACTS_FROM_EDIT_FORM_CASE } from "../../../../../../redux/action/cases/edit/type"

const AddCaseContactsListItem = (props) => {

    const dispatch = useDispatch()

    const location = useLocation()
    const params = useParams()

    const deleteItem = () => {
        
        // is client-movakel
        if(props.location && !props.edit){

            dispatch({
                type: DELETE_CLIENT_FROM_ADD_FORM_CASE,
                payload: props.id
            })

        }
        else if(!props.location && !props.edit){
         
            dispatch({
                type: DELETE_CONTACTS_FROM_ADD_FORM_CASE,
                payload: props.id
            })
            
        }

        else if(props.location && props.edit){
         
            dispatch({
                type: DELETE_CLIENT_FROM_EDIT_FORM_CASE,
                payload: props.id
            })
            
        }

        else if(!props.location && props.edit){
            
            dispatch({
                type: DELETE_CONTACTS_FROM_EDIT_FORM_CASE,
                payload: props.id
            })

        }

    }

    const getColor = () => {
        
        const color = getProfileColor(props.mobile.slice(10))
        
        return color
    }

    return (
        <div className="bg-white gap-3.5 shadow-custom-md border border-primary/10 rounded-xl p-3 flex items-center justify-between flex-wrap relative before:absolute before:w-0.5 before:h-14 before:rounded-xl before:my-auto before:bottom-0 before:bg-primary before:top-0 before:right-0">

            <div className="flex-shrink-0 flex items-center gap-3">

                <div className={`flex-shrink-0 w-11 h-11 ${ props.mobile && !props.image ? `profile-${getColor()}` : 'bg-slate-200' }  border-2 border-slate-200 shadow-lg rounded-xl`}>
                    { props.image ?
                        <img className="rounded-xl w-full h-full" src={props.image} alt={props.name} />
                        :
                        props.name &&
                            <span className="font-medium text-center flex items-center justify-center h-full w-full">
                                {props.name[0]}
                            </span> 
                    }
                </div>

                <div className="flex flex-col gap-2">

                    <span className="font-medium"> {props.name} </span>

                    { props.position &&
                        <span className="text-gray-500 text-sm"> {props.position} </span>
                    }
 
                </div>

            </div>

            <div className="flex items-center gap-1 sm:h-full h-11 mr-auto">

                <Link to={`/law-suits/add/contacts/${props.id}`} state={{forMovakels: props.location, data: props.item, edit: true, updatedPaege: props.edit, caseId: props.edit ? params.id : '', cases: location?.state?.cases}} type="button" className="h-full w-12 flex items-center justify-end text-primary duration-300 outline-none">
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/edit-2.svg`}
                        className="w-7"
                    />
                </Link>

                <button onClick={deleteItem} type="button" className="h-full w-9 flex items-center justify-end text-red-500 hover:text-red-600 duration-300 outline-none">
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`}
                        className="w-6"
                    />
                </button>

            </div>

        </div>
    )

}

export default AddCaseContactsListItem