import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { SET_EXIST_FILTER_IS_ARCHIVE, SET_EXIST_FILTER_IS_FAVORITE, SET_FILEDS_OF_FILTER, SET_FILTER_FOR_CASES, SET_FILTER_SECTION_LOADING_CASES } from "../../../../redux/action/cases/casesType"
import { searchInCases } from "../../../../utils/search"

import Input from "../../../UI/Input"
import CasesFilterSectionCategory from "./Category"
import CasesFilterSectionJudicial from "./Judicial"
import CasesFilterSectionState from "./State"
import CasesFilterSectionStep from "./Step"
import CasesFilterSectionProvince from "./Province"
import { ReactSVG } from "react-svg"

const CasesFilterSectionContent = (props) => {

    const dispatch = useDispatch()
    const fields = useSelector(state => state.cases.fieldsFilter)
    const allCases = useSelector(state => state.cases.allCases)
    const loading = useSelector(state => state.baseData.loading)

    useEffect(() => {

        const timeout = setTimeout(() => {
            
            doSearch(fields.archive_code.value, 'archive_code')

        }, 900)

        return () => {
            clearTimeout(timeout)
        }

    }, [fields])

    const updateArchiveCodeField = (event) => {
        
        const length = event.target.value.length

        if(length > 7) return

        if(isNaN(event.target.value)) return

        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                archive_code: {
                    value: event.target.value,
                    applied: length >= 1 ? true : false
                }
            }
        })
        dispatch({
            type: SET_FILTER_SECTION_LOADING_CASES,
            payload: true
        })
    }

    const updateClientField = (event) => {

        const length = event.target.value.length

        if(length > 100) return

        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                client: {
                    value: event.target.value,
                    applied: length >= 1 ? true : false
                }
            }
        })
        dispatch({
            type: SET_FILTER_SECTION_LOADING_CASES,
            payload: true
        })

    }

    const updateCodeField = (event) => {

        const length = event.target.value.length

        if(length > 16) return

        if(isNaN(event.target.value)) return

        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                code: {
                    value: event.target.value,
                    applied: length >= 1 ? true : false
                }
            }
        })
        dispatch({
            type: SET_FILTER_SECTION_LOADING_CASES,
            payload: true
        })

    }

    const updateBranchField = (event) => {

        const length = event.target.value.length

        if(length > 60) return

        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                branch: {
                    value: event.target.value,
                    applied: length >= 1 ? true : false
                }
            }
        })
        dispatch({
            type: SET_FILTER_SECTION_LOADING_CASES,
            payload: true
        })

    }

    const updateIsArchivedField = (event) => {

        dispatch({
            type: SET_EXIST_FILTER_IS_ARCHIVE,
            payload: event.target.checked
        })

        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                is_archived: {
                    value: event.target.checked,
                    applied: event.target.checked
                }
            }
        })
        dispatch({
            type: SET_FILTER_SECTION_LOADING_CASES,
            payload: true
        })

    }

    const updateIsFavoriteField = (event) => {

        dispatch({
            type: SET_EXIST_FILTER_IS_FAVORITE,
            payload: event.target.checked
        })

        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                is_favorite: {
                    value: event.target.checked,
                    applied: event.target.checked
                }
            }
        })
        dispatch({
            type: SET_FILTER_SECTION_LOADING_CASES,
            payload: true
        })

    }

    const resetArchiveCodeField = () => {
        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                archive_code: {
                    value: '',
                    applied: false
                }
            }
        })
    }
    const resetClientField = () => {
        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                client: {
                    value: '',
                    applied: false
                }
            }
        })
    }
    const resetCodeField = () => {
        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                code: {
                    value: '',
                    applied: false
                }
            }
        })
    }
    const resetCategoryField = () => {
        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                category: {
                    value: '',
                    applied: false
                }
            }
        })
    }
    const resetJudicialField = () => {
        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                judicial: {
                    value: '',
                    applied: false
                }
            }
        })
    }
    const resetBranchField = () => {
        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                branch: {
                    value: '',
                    applied: false
                }
            }
        })
    }
    const resetStepField = () => {
        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                step: {
                    value: '',
                    applied: false
                }
            }
        })
    }
    const resetStateField = () => {
        dispatch({
            type: SET_FILEDS_OF_FILTER,
            payload: {
                ...fields,
                state: {
                    value: '',
                    applied: false
                }
            }
        })
    }

    const doSearch = () => {

        let list = allCases

        const applied = Object.keys(fields).filter(item => fields[item].applied)

        applied.map(item => {

            const data = searchInCases(list, fields[item].value, item)

            if(data){
                
                list = data

            }

        })

        if(list){
            
            dispatch({
                type: SET_FILTER_FOR_CASES,
                payload: list
            })

        }

        dispatch({
            type: SET_FILTER_SECTION_LOADING_CASES,
            payload: false
        })

    }

    return (
        <>
            <div className="mt-4 flex flex-col gap-4">

                <label htmlFor={`toggle-archive-filter`} className="small-switch flex items-center justify-between w-full cursor-pointer py-1">
                    
                    <div className="flex items-center gap-2 5xm:text-base text-sm">
                    
                        <ReactSVG 
                            className="w-5 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/archive.svg`}
                        />

                        <span className="text-gray-500"> پرونده‌های بایگانی شده : </span>
                    
                    </div>

                    <div className="relative">
                        <input onChange={ updateIsArchivedField } type="checkbox" id={`toggle-archive-filter`} className="sr-only" checked={fields?.is_archived?.value ? fields?.is_archived?.value : false} />
                        <div className="dot-wrapper block bg-slate-400 w-[52px] h-6 duration-300 rounded-full"></div>
                        <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                    </div>

                </label>

                <hr className="pt-1" />

                <label htmlFor={`toggle-favorite-filter`} className="small-switch flex items-center justify-between w-full cursor-pointer pb-1">
                    
                    <div className="flex items-center gap-2 5xm:text-base text-sm">
                    
                        <ReactSVG 
                            className="w-5 text-primary"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite.svg`}
                        />

                        <span className="text-gray-500"> پرونده‌های نشان شده : </span>
                    
                    </div>

                    <div className="relative">
                        <input onChange={ updateIsFavoriteField } type="checkbox" id={`toggle-favorite-filter`} className="sr-only" checked={fields?.is_favorite?.value ? fields?.is_favorite?.value : false} />
                        <div className="dot-wrapper block bg-slate-400 w-[52px] h-6 duration-300 rounded-full"></div>
                        <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                    </div>

                </label>

                <hr />

                <Input
                    id={'event-title'}
                    label={'شماره بایگانی'}
                    max={7}
                    type={'tel'}
                    icon={'archive_code'}
                    value={ fields?.archive_code?.value }
                    isValid={ true }
                    onChange={ updateArchiveCodeField }
                    className={'shadow-custom-lg text-right border-gray-300/70'}
                    showCloseBtn={fields.archive_code.applied}
                    closeBtnClicked={resetArchiveCodeField}
                />

                <hr />

                <CasesFilterSectionCategory
                    value={ fields?.category?.value } 
                    loading={!loading}
                    onSetActiveItemState={props.onSetActiveItemState}
                    onResetCategoryField={resetCategoryField}
                />            
            
                <hr />

                {/* <CasesFilterSectionJudicial
                    loading={!loading}
                    value={ fields?.judicial?.value } 
                    onSetActiveItemState={props.onSetActiveItemState}
                    onResetJudicialField={resetJudicialField}
                />            
            
                <hr /> */}

                <Input
                    id={'event-branch'}
                    label={'شعبه'}
                    max={60}
                    type={'text'}
                    icon={'lawyer'}
                    value={ fields?.branch?.value }
                    isValid={ true }
                    onChange={ updateBranchField }
                    className={'shadow-custom-lg text-right border-gray-300/70'}
                    showCloseBtn={fields?.branch?.applied}
                    closeBtnClicked={resetBranchField}
                />
            
                <hr />

                {/* <CasesFilterSectionProvince
                    loading={!loading}
                />             */}

                <Input
                    id={'event-client'}
                    label={'نام موکل'}
                    max={100}
                    icon={'user'}
                    value={ fields?.client?.value }
                    isValid={ true }
                    onChange={ updateClientField }
                    className={'shadow-custom-lg border-gray-300/70'}
                    
                    showCloseBtn={fields.client.applied}
                    closeBtnClicked={resetClientField}
                />

                <hr />

                <Input
                    id={'event-code'}
                    label={'شماره پرونده'}
                    max={16}
                    type={'tel'}
                    icon={'ticket'}
                    value={ fields?.code?.value }
                    isValid={ true }
                    onChange={ updateCodeField }
                    className={'shadow-custom-lg text-right border-gray-300/70'}
                    
                    showCloseBtn={fields.code.applied}
                    closeBtnClicked={resetCodeField}
                />

                <hr />

                {/* <CasesFilterSectionState
                    loading={!loading}
                    value={ fields?.state?.value } 
                    onSetActiveItemState={props.onSetActiveItemState}
                    onResetStateField={resetStateField}
                />   

                <hr /> */}

                <CasesFilterSectionStep
                    loading={!loading}
                    value={ fields?.step?.value } 
                    onSetActiveItemState={props.onSetActiveItemState}
                    onResetStepField={resetStepField}
                />   

            </div>
        </>
    )

}

export default CasesFilterSectionContent  