import moment from "jalali-moment"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import HomeEventItem from "./Item"

const HomeEvents = ({items}) => {

    const loading = useSelector(state => state.home.loading)

    const [todayItems, setTodayItems] = useState([])
    const [tomorrowItems, setTomorrowItems] = useState([])

    useEffect(() => {

        moveItemsToSelfDate()

    }, [items])


    const moveItemsToSelfDate = () => {

        if(!items || items?.length === 0) return

        const date = moment().utcOffset('+0330').format('jYYYY-jMM-jDD')

        const today = []
        const tomorrow = []

        items.map(item => {

            const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
            const dateSplit = date.split('-')
            const itemSplit = itemDate.split('-')
    
            if(itemDate === date){
                today.push(item)
                return
            }
            else if( dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) + 1) === Number(itemSplit[2]) ) ){
                tomorrow.push(item)
                return
            }

        })

        setTodayItems( today )
        setTomorrowItems( tomorrow )

    }

    return (
        <section className="mb-10">

            { todayItems?.length >= 1 &&
                <>
                    <h2 className="text-lg font-medium mb-6"> رویداد‌های امروز </h2>

                    <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                        {todayItems.slice(0, 4).map((item, index) => 
                            
                            <HomeEventItem 
                                key={item.id + '-home-event'}
                                type={item.type}
                                title={item.title}
                                body={item.description}
                                time={item.starts_at}
                                case={item.law_suit ? item.law_suit.title : ''}
                                count={index + 1}
                                id={`${item.id}`}
                                item={item}
                                isPublic={item?.is_public}
                                send_sms={item?.send_sms}
                            />
                            
                        )}

                    </ul>
                </>
            }

            {  tomorrowItems?.length >= 1 &&
                <>
                    <h2 className={`text-lg font-medium mb-6 ${todayItems.length >= 1 && 'mt-12'}`}> رویداد‌های فردا </h2>

                    <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                        {tomorrowItems.slice(0, 4).map((item, index) => 
                            
                            <HomeEventItem 
                                key={item.id + '-home-event'}
                                type={item.type}
                                title={item.title}
                                body={item.description}
                                time={item.starts_at}
                                case={item.law_suit ? item.law_suit.title : ''}
                                count={index + 1}
                                id={`${item.id}`}
                                item={item}
                                isPublic={item?.is_public}
                                send_sms={item?.send_sms}
                            />
                            
                        )}

                    </ul>
                </>
            }

            { loading &&
                
                <div className="w-full h-20 flex items-center justify-center">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        className="text-gray-500 w-10"
                    />
                    
                </div>

            }

        </section>
    )

}

export default HomeEvents