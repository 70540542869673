import { ReactSVG } from "react-svg"
import CaseContentFilesItem from "./Item";
import CaseContentFilesAdd from "./Add";
import { useSelector } from "react-redux";
import ToastCustom from "../../../../UI/ToastCustom";
import LoadingDot from "../../../../UI/LoadingDot";

const CaseContentFiles = ({files = [], ...props}) => {

    const message = useSelector(state => state.cases.fileMessage)
    const loadingDelete = useSelector(state => state.cases.fileLoading)


    return (
        <section>

            {files && files.length >= 1 ?

                <ul className="grid sm:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-4 gap-5">

                    { files.length >= 1 && files.map(item => 
                        
                        <CaseContentFilesItem 
                            key={item.id+"-file"}
                            info={item}
                        />
                    
                    )}

                </ul>

                :
                <div className="text-gray-600 flex items-center justify-center h-36 gap-4 flex-col">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/tag.svg`}
                        className="w-10 text-gray-500 text-opacity-80 "
                    />

                    <div>
                        هیچ <span className="font-medium">فایلی</span> برای پرونده ثبت نشده است 
                    </div>

                </div>

            }

            <CaseContentFilesAdd />

            { (message && message?.message && message?.type === 'success') &&
                
                <ToastCustom>
                        
                        <div className="flex items-center gap-2">
                            
                            <ReactSVG
                                className="text-green-600 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                            { message?.message }

                        </div>

                </ToastCustom>

            }

            { (message && message?.message && message?.type === 'error') &&
                
                <ToastCustom>
                        
                    <div className="flex items-center gap-2">
                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                        { message?.message }

                    </div>

                </ToastCustom>

            }

            { loadingDelete && <LoadingDot /> }

        </section>
    )

}

export default CaseContentFiles