import moment from "jalali-moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg"
import EventDateItem from "../../../../Events/DateItems/Item";
import { Link, useParams } from "react-router-dom";
import { SET_SORTED_INTO_SINGLE_CASE } from "../../../../../redux/action/cases/casesType";
import DeleteHeader from "../../../../Events/DateItems/DeleteHeader";

const CaseContentEvents = ({events = [], title, sortedEvents = false, ...props}) => {

    const dispatch = useDispatch()
    const [sorted, setSorted] = useState({})

    const [selectedItemDelete, setSelectedItemDelete] = useState(null)

    const params = useParams()

    useEffect(() => {

        if(!sortedEvents && events.length >= 1)
            handleSortEvents(events)
        else if(sortedEvents && events.length >= 1)
            handleSortEvents(events, false)

    }, [events])

    const handleSortEvents = (events, reverse = true) => {

        const date = moment().utcOffset('+0330').format('jYYYY-jMM-jDD')

        const sortedData = {
            today: [],
            tomorrow: [],
            yesterday: [],
        }

        if(reverse)
            events.reverse().map(item => {
                const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
                const dateSplit = date.split('-')
                const itemSplit = itemDate.split('-')

                if(itemDate === date){
                    sortedData.today.push(item)
                    return
                }
                else {
                    
                    if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) + 1) === Number(itemSplit[2]) ) ){
                        sortedData.tomorrow.push(item)
                        return
                    }

                    else if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) - 1) === Number(itemSplit[2]) ) ){
                        sortedData.yesterday.push(item)
                        return
                    }
                    
                    else {
                        
                        if(!sortedData[itemDate]){
                            sortedData[itemDate] = []
                        }

                        sortedData[itemDate].push(item)
                    }
                    
                }
            })
        else
            events.map(item => {
                const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
                const dateSplit = date.split('-')
                const itemSplit = itemDate.split('-')

                if(itemDate === date){
                    sortedData.today.push(item)
                    return
                }
                else {
                    
                    if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) + 1) === Number(itemSplit[2]) ) ){
                        sortedData.tomorrow.push(item)
                        return
                    }

                    else if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) - 1) === Number(itemSplit[2]) ) ){
                        sortedData.yesterday.push(item)
                        return
                    }
                    
                    else {
                        
                        if(!sortedData[itemDate]){
                            sortedData[itemDate] = []
                        }

                        sortedData[itemDate].push(item)
                    }
                    
                }
            })

        setSorted(sortedData)

        if(!sortedEvents)
            dispatch({
                type: SET_SORTED_INTO_SINGLE_CASE,
                payload: {
                    id: params.id,
                    status: true
                }
            })

    }

    return (
        <section>

            { selectedItemDelete &&
                <DeleteHeader 
                    selectedItemId={selectedItemDelete}
                    onSetSelectedItemDelete={setSelectedItemDelete}
                    law_suit_id={params.id}
                />
            }

            { events && events.length >= 1 ?

                <>

                    { Object.keys(sorted).map(item => 
                
                        sorted[item]?.length >= 1 &&

                            <div key={item+'events'} className="border-b pb-8 mb-5 last:border-b-0">
                                
                                <h2 className="text-lg font-medium mb-4"> { item === 'today' ? 'امروز' : item === 'yesterday' ? 'دیروز' : item === 'tomorrow' ? 'فردا' : `${Number(item.split('-')[0]).toLocaleString('fa').replace('٬', '')}/${Number(item.split('-')[1]) <= 9 ? '۰' + Number(item.split('-')[1]).toLocaleString('fa') : Number(item.split('-')[1]).toLocaleString('fa')}/${Number(item.split('-')[2]) <= 9 ? '۰' + Number(item.split('-')[2]).toLocaleString('fa') : Number(item.split('-')[2]).toLocaleString('fa')}` } </h2>

                                <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                                    { sorted[item].map((item, index) => 
                                    
                                        <EventDateItem 
                                            key={item.id}
                                            type={item.type}
                                            title={item.title}
                                            body={item.description}
                                            time={item.starts_at}
                                            case={null}
                                            count={index + 1}
                                            id={`${item.id}`}
                                            item={{ ...item, law_suit: {id: params.id, title} }}
                                            selectedItemDelete={selectedItemDelete}
                                            onSetSelectedItemDelete={setSelectedItemDelete}
                                            isStarted={item?.is_started}
                                            isPublic={item?.is_public}
                                            send_sms={item?.send_sms}
                                        />

                                    )}

                                </ul>

                            </div>
                    )}

                </>

                :

                <div className="text-gray-600 flex items-center justify-center h-36 gap-4 flex-col">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/events.svg`}
                        className="w-10 text-gray-500 text-opacity-80"
                    />

                    <div>
                        هیچ <span className="font-medium">رویدادی</span> برای پرونده ثبت نشده است 
                    </div>

                </div>

            }

            <Link to={ `/events` } state={{ caseInfo: {id: params.id, text: title}, forAddCase: true }} className={`header-btn fixed lg:bottom-4 lg:left-4 md:bottom-[104px] bottom-24 left-4 mx-auto z-[1] w-10 h-10`}>

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`} className="w-[85%] h-[85%]" />

            </Link>

        </section>
    )

}

export default CaseContentEvents