import { ADD_CLIENT_IN_ADD_FORM_CASE, ADD_CONTACTS_IN_ADD_FORM_CASE, ADD_CONTACT_TO_UNIQ_ADD_CASE, DELETE_CLIENT_FROM_ADD_FORM_CASE, DELETE_CONTACTS_FROM_ADD_FORM_CASE, DELETE_USER_FROM_UNIQ_CONTACTS_IN_ADD, EDIT_ITEM_CLIENT_IN_ADD_FORM_CASE, EDIT_ITEM_CONTACTS_IN_ADD_FORM_CASE, RESET_DETAIL_OF_CASE, RESET_FORMS_NOT_VALID_ADD_CASE, RESTE_ALL_ADD_CASES_DATA_APP, SET_ALL_CONTACTS_ADD_CASE, SET_COLLAPSE_CONTENT_HEIGHT_MORE, SET_DETAIL_OF_CASE, SET_ERROR_ADD_CASE, SET_FORMS_DATA, SET_FORMS_NOT_VALID_ADD_CASE, SET_LOADING_ADD_CASE, SET_SHOW_COLLAPSE_MORE, SET_SHOW_CONTACTS_BOX, SET_SUCCSESS_MESSAGE_ADD_CASE, UPDATE_CONTACT_TO_UNIQ_ADD_CASE } from "../../action/cases/add/type"

const initalState = {
    
    showCollapseMore: {
        show: false,
        loading: false
    },
    collapseContentHeightMore: 0,

    showContactsBox: false,
    contactBoxOpened: false,

    detail: {
        id: null,
        position: '',
        notify: true,
    },

    forms: {
        title: {value: '', max: 60, min: 3, isValid: true, require: true},
        code: {value: '', isValid: true, min: 16, max: 18, require: true},
        archive_code: {value: '', isValid: true, min: 3, max: 7},
        step: '',
        category: {
            id: null,
            name: ''
        },
        state: '',
        location: {
            city: {id: null, name: ''},
            province: ''
        },
        branch: {value: '', min: 3, max: 60, isValid: true},
        description: {value: '', min: 3, isValid: true},
        agreed_fee: '',
        jurisdiction: {id: null, name: ''}
    },
    formsNotValid: true,
    
    clients: [],
    contacts: [],

    uniqContacts: [],
    deletedContacts: [],

    loading: false,
    error: null,
    successMessage: null
}

const addCaseReducer = (state = initalState, action) => {

    switch(action.type){
        
        case SET_SHOW_COLLAPSE_MORE :
            return {
                ...state,
                showCollapseMore: action.payload
            }

        case SET_COLLAPSE_CONTENT_HEIGHT_MORE :
            return {
                ...state,
                collapseContentHeightMore: action.payload
            }


        case SET_SHOW_CONTACTS_BOX :
            return {
                ...state,
                showContactsBox: action.payload.show,
                contactBoxOpened: action.payload.movakel
            }

        case SET_DETAIL_OF_CASE:
            return {
                ...state,
                detail: action.payload
            }

        case RESET_DETAIL_OF_CASE :
            return {
                ...state,
                detail: {
                    id: null,
                    position: '',
                    notify: true,
                }
            }

        case ADD_CLIENT_IN_ADD_FORM_CASE:
            return {
                ...state,
                clients: [
                    ...state.clients,
                    action.payload
                ],
                deletedContacts: [
                    ...state.deletedContacts,
                    state.uniqContacts.filter(item => item.id == action.payload.id)[0]
                ],
                uniqContacts: state.uniqContacts.filter(item => item.id != action.payload.id)
            }

        case DELETE_CLIENT_FROM_ADD_FORM_CASE:
            return {
                ...state,
                clients: state.clients.filter(item => item.id != action.payload),
                uniqContacts: [
                    state.deletedContacts.filter(item => item.id == action.payload)[0],
                    ...state.uniqContacts
                ],
                deletedContacts: state.deletedContacts.filter(item => item.id != action.payload)
            }

        case EDIT_ITEM_CLIENT_IN_ADD_FORM_CASE :
            return {
                ...state,
                clients: state.clients.map(item => {
                    
                    if(item.id === action.payload.id){
                        item = action.payload
                    }

                    return item
                })
            }


        case ADD_CONTACTS_IN_ADD_FORM_CASE :
            return {
                ...state,
                contacts: [
                    ...state.contacts,
                    action.payload
                ],
                deletedContacts: [
                    ...state.deletedContacts,
                    state.uniqContacts.filter(item => item.id == action.payload.id)[0]
                ],
                uniqContacts: state.uniqContacts.filter(item => item.id != action.payload.id)
            }

        case DELETE_CONTACTS_FROM_ADD_FORM_CASE :
            return {
                ...state,
                contacts: state.contacts.filter(item => item.id != action.payload),
                uniqContacts: [
                    state.deletedContacts.filter(item => item.id == action.payload)[0],
                    ...state.uniqContacts
                ],
                deletedContacts: state.deletedContacts.filter(item => item.id != action.payload)
            }

        case EDIT_ITEM_CONTACTS_IN_ADD_FORM_CASE :
            return {
                ...state,
                contacts: state.contacts.map(item => {
                    
                    if(item.id === action.payload.id){
                        item = action.payload
                    }

                    return item
                })
            } 


        case SET_ALL_CONTACTS_ADD_CASE :
            return {
                ...state,
                uniqContacts: action.payload
            }

        case ADD_CONTACT_TO_UNIQ_ADD_CASE :
            return {
                ...state,
                uniqContacts: [
                    action.payload,
                    ...state.uniqContacts,
                ]
            }

        case UPDATE_CONTACT_TO_UNIQ_ADD_CASE :
            return {
                ...state,
                uniqContacts: state.uniqContacts.map(item => {
                    if(item.id === action.payload.id)
                        return {
                            id: action.payload.id,
                            ...action.payload.data
                        }

                    return item 
                }),
                clients: state.clients.map(item => {
                    if(item.id == action.payload.id)
                        return {
                            ...item,
                            name: action.payload.data.name
                        }

                    return item
                }),
                contacts: state.contacts.map(item => {
                    if(item.id == action.payload.id)
                        return {
                            ...item,
                            name: action.payload.data.name
                        }

                    return item
                })
            }
        
        case DELETE_USER_FROM_UNIQ_CONTACTS_IN_ADD :
            return {
                ...state,
                uniqContacts: state.uniqContacts.filter(item => Number(item.id) !== Number(action.payload)),
                deletedContacts: state.deletedContacts.filter(item => Number(item.id) !== Number(action.payload))
            }



        case SET_FORMS_DATA :
            return {
                ...state,
                forms: action.payload
            }
            
        case SET_FORMS_NOT_VALID_ADD_CASE :
            return {
                ...state,
                formsNotValid: action.payload
            }

        case RESET_FORMS_NOT_VALID_ADD_CASE :
            return {
                ...state,
                forms: {
                    title: {value: '', max: 60, min: 3, isValid: true, require: true},
                    code: {value: '', isValid: true, min: 16, max: 18, require: true},
                    archive_code: {value: '', isValid: true, min: 3, max: 7},
                    step: '',
                    category: {
                        id: null,
                        name: ''
                    },
                    state: '',
                    location: {
                        city: {id: null, name: ''},
                        province: ''
                    },
                    branch: {value: '', min: 3, max: 60, isValid: true},
                    description: {value: '', min: 3, isValid: true},
                    agreed_fee: '',
                    jurisdiction: {id: null, name: ''}
                },
                formsNotValid: true,
                clients: [],
                contacts: [],
                uniqContacts: [
                    ...state.deletedContacts,
                    ...state.uniqContacts,
                ],
                deletedContacts: [],
            }


        case SET_LOADING_ADD_CASE :
            return {
                ...state,
                loading: action.payload
            }

        case SET_ERROR_ADD_CASE :
            return {
                ...state,
                error: action.payload,
                loading: false,
                successMessage: null
            }

        case SET_SUCCSESS_MESSAGE_ADD_CASE :
            return {
                ...state,
                successMessage: action.payload,
                loading: false,
                error: null
            }

        case RESTE_ALL_ADD_CASES_DATA_APP :
            return {
                ...state,
                ...initalState
            }

        default :
            return state
    
    }

}

export default addCaseReducer