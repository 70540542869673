import { useEffect, useState } from "react"
import ConfirmModal from "../../../UI/ConfirmModal"
import { ReactSVG } from "react-svg"
import { useDispatch } from "react-redux"
import { SET_SUCCSESS_MESSAGE_ADD_CASE } from "../../../../redux/action/cases/add/type"

const SuccessBoxCases = (props) => {

    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(true)

    useEffect(() => {
        
        document.body.classList.add('modal-opened')

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    useEffect(() => {
        setTimeout(() => {

            if(showModal === false){
                
                dispatch({
                    type: SET_SUCCSESS_MESSAGE_ADD_CASE,
                    payload: null
                })

            }

        }, 300)

    }, [showModal])

    const hide = () => {
        setShowModal(false)
    }

    return (
        <ConfirmModal
            title="برگشت هزینه به کیف پول شما"
            description={props.successMessage ? props.successMessage : "به‌دلیل حذف پرونده قبل از 1 ساعت، مبلغ 20000 تومان به کیف پول شما بازگردانده شد."}
            show={showModal}
            setShow={setShowModal}
            onConfirm={hide}
        >   

            <div className="w-14 h-14 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-rose-500">
                <ReactSVG
                    className="text-green-600 4xm:w-9 w-6"
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                />
            </div>

        </ConfirmModal>
    )

}

export default SuccessBoxCases