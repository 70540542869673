const CaseFinancialsTabs = ({activeTab, onSetActiveTab}) => {

    return (
        <section className="mt-10 mb-8 flex flex-col gap-8">

            <ul className="flex items-center flex-wrap  justify-evenly bg-gray-200/70 w-full p-2 rounded-full"> 

                <li className="4xm:text-base 5xm:text-sm text-xs 5xm:w-1/3 2xm:pl-1.5">
                    <button onClick={() => onSetActiveTab(0)} className={`w-full py-2.5 px-4 rounded-full duration-300 select-none !outline-none text-gray-600 font-medium ${activeTab === 0 && '!bg-white shadow-custom-md !text-primary'}`}> کل تراکنش‌ها </button>
                </li>

                <li className="4xm:text-base 5xm:text-sm text-xs 5xm:w-1/3 2xm:pl-1.5 2xm:pr-1.5">
                    <button onClick={() => onSetActiveTab(2)} className={`w-full py-2.5 px-4 rounded-full duration-300 select-none !outline-none text-gray-600 font-medium ${activeTab === 2 && '!bg-white shadow-custom-md !text-primary'}`}> دریافت‌ها </button>
                </li>

                <li className="4xm:text-base 5xm:text-sm text-xs 5xm:w-1/3 2xm:pr-1.5">
                    <button onClick={() => onSetActiveTab(1)} className={`w-full py-2.5 px-4 rounded-full duration-300 select-none !outline-none text-gray-600 font-medium ${activeTab === 1 && '!bg-white shadow-custom-md !text-primary'}`}> هزینه‌ها </button>
                </li>
            
            </ul>

            <h3 className="font-medium text-lg"> { activeTab === 0 ? 
                    'لیست تراکنش‌ها'
                    :
                    activeTab === 1 ? 
                    'لیست هزینه‌ها'
                    :
                    'لیست دریافت‌ها'
                } 
            </h3>            

        </section>
    )

}

export default CaseFinancialsTabs