import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Header from "../../../components/Header"
import { ReactSVG } from "react-svg"
import { useEffect, useState } from "react"
import Dropdown from "../../../components/UI/Dropdown"
import { caseMoveToArchive, caseMoveToFavorite, caseMoveToUnarchive, caseMoveToUnfavorite, deleteCaseFromList, getSingleCaseData } from "../../../redux/action/cases/casesAction"
import CaseHeaderTabs from "../../../components/Cases/Case/Tabs"
import CaseContent from "../../../components/Cases/Case/Content"
import LoadingDot from "../../../components/UI/LoadingDot"
import CaseContentTitle from "../../../components/Cases/Case/Content/Title"
import { REMOVE_FROM_ARCHIVE_CASE_IN_FILTER, REMOVE_FROM_FAVORITE_CASE_IN_FILTER, SET_ACTIVE_TAB_SINGLE_CASE, SET_LAST_OPENED_SINGLE_CASE, SET_LOADING_SINGLE_CASE, UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE, UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE } from "../../../redux/action/cases/casesType"
import HeaderAddBtn from "../../../components/Header/AddBtn"
import { DELETE_CASE_FROM_HOME_INFO } from "../../../redux/action/home/homeType"
import { DELETE_CASE_FROM_CONTACTS, DELETE_CASE_FROM_CONTACT_INFO_CONTACTS } from "../../../redux/action/contacts/typeContacts"
import CaseContentUpdateHeader from "../../../components/Cases/Case/Content/UpdateHeader"
import ConfirmModal from "../../../components/UI/ConfirmModal"
import ToastCustom from "../../../components/UI/ToastCustom"

const Case = () => {

    const dispatch = useDispatch()
    const deleteLoading = useSelector(state => state.cases.deleteLoading)
    const singleCaseLoading = useSelector(state => state.cases.singleCaseLoading)
    const singlesCase = useSelector(state => state.cases.singlesCase)
    const activeTab = useSelector(state => state.cases.singleCaseActiveTab)
    const lastOpenedSingleCase = useSelector(state => state.cases.singleCaseLastOpened)
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const profilesList = useSelector(state => state.contacts.profilesList)
    const contactInfo = useSelector(state => state.contacts.contactInfo)
    const loading = useSelector(state => state.cases.singleCaseLoading)
    
    const existFilterIsArchived = useSelector(state => state.cases.filterIsArchived)
    const existFilterIsFavorite = useSelector(state => state.cases.filterIsFavorite)
    const archiveLikeLoading = useSelector(state => state.cases.archiveLikeLoading)
    const errorMessageArchiveLike = useSelector(state => state.cases.errorArchiveLike)
    const successMessageArchiveLike = useSelector(state => state.cases.successArchiveLike)


    const [showDropdownMore, setShowDropdownMore] = useState(false)
    const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false)

    const [showTitle, setShowTitle] = useState(window.innerWidth > 393)

    const [data, setData] = useState()
    const [firstData, setFirstData] = useState()
    const [tabs] = useState(['اطلاعات', 'مدارک', 'یادداشت‌های', 'رویدادهای', 'امور‌مالی'])
    const [changes, setChanges] = useState([])

    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        selectLastOpenedSingleCase()

        if(params.id && !singlesCase[params.id])
            getInfoSingleCase()
        else {
            setData( singlesCase[params.id] )
            setFirstData( singlesCase[params.id] )
        }

        window.scrollTo({top: 0})

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
            
            window.removeEventListener('resize', setCanIShowTitle)
        }

    }, [])

    useEffect(() => {

        if(params.id && singlesCase[params.id]){
            setData( {...singlesCase[params.id]} )
            setFirstData( {...singlesCase[params.id]} )
        }

    }, [ singlesCase ])

    useEffect(() => {

        if(data){
            window.addEventListener('resize', setCanIShowTitle)
            setCanIShowTitle()
        }

        return () => {
            window.removeEventListener('resize', setCanIShowTitle)
        }

    }, [data])

    const setCanIShowTitle = () => {
        if((window.innerWidth <= 420 && data?.title?.length >= 20)){
            setShowTitle( false )
        }
        else 
            setShowTitle( window.innerWidth >= 393 || Number(data?.title?.length) <= 15 )
    }

    const selectLastOpenedSingleCase = () => {
        
        if(lastOpenedSingleCase !== params.id){
            
            dispatch({
                type: SET_LAST_OPENED_SINGLE_CASE,
                payload: params.id
            })
 
            if(activeTab !== 0){
                
                dispatch({
                    type: SET_ACTIVE_TAB_SINGLE_CASE,
                    payload: 0
                })

            }

            window.scrollTo({top: 0})

        }

    }

    const getInfoSingleCase = async () => {

        const data = await dispatch( await getSingleCaseData(params.id, cookies.authToken) )

        if(data){
            setData( data )
            setFirstData( data )
        }

    }

    const handleShowDropdownMore = () => {

        setShowDropdownMore( !showDropdownMore )

        document.body.classList.add('modal-opened')

    }

    const showDeleteCaseAlert = () => {
        setShowDeleteCaseModal( true )
        setShowDropdownMore( false )
    }

    const deleteCase = async () => {

        setShowDropdownMore( false )
        setShowDeleteCaseModal( false )

        document.body.classList.remove('modal-opened')

        const response = await dispatch( await deleteCaseFromList(params.id, cookies.authToken) )
        
        if(response){

            if(homeInfoLoaded){

                dispatch({
                    type: DELETE_CASE_FROM_HOME_INFO,
                    payload: params.id
                })

            }

            data.clients.map(item => {
                
                if(profilesList && profilesList[item.id]){

                    dispatch({
                        type: DELETE_CASE_FROM_CONTACTS,
                        payload: {
                            caseId: params.id,
                            contactId: item.id
                        }
                    })

                    if(contactInfo && contactInfo?.id && Number(contactInfo.id) === Number(item.id)){
                        
                        dispatch({
                            type: DELETE_CASE_FROM_CONTACT_INFO_CONTACTS,
                            payload: params.id
                        })

                    }

                }

            })

            navigate('/law-suits', {replace: true})
        
        }

    }

    const changeActiveTab = (index) => {

        if(singleCaseLoading || index === activeTab) return

        dispatch({
            type: SET_ACTIVE_TAB_SINGLE_CASE,
            payload: index
        })

        dispatch({
            type: SET_LOADING_SINGLE_CASE,
            payload: true
        })

        setTimeout(() => {

            dispatch({
                type: SET_LOADING_SINGLE_CASE,
                payload: false
            })

        }, 200)

    }

    const toggleFavoriteState = async () => {
        
        // hide dropdown
        setShowDropdownMore(false)
        document.body.classList.remove('modal-opened')


        if(data?.is_favorite){
            const data = await dispatch( await caseMoveToUnfavorite(params.id, cookies.authToken) )
            
            if(data){

                if(singlesCase[params.id]){
                    
                    dispatch({
                        type: UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE,
                        payload: {
                            id: params.id,
                            status: false
                        }
                    })

                }

                // exist into the archive filter come remove
                if(existFilterIsFavorite){
                    dispatch({
                        type: REMOVE_FROM_FAVORITE_CASE_IN_FILTER,
                        payload: params.id
                    })
                }

            }
        }
        else{
            const data = await dispatch( await caseMoveToFavorite(params.id, cookies.authToken) )
            
            if(data){

                if(singlesCase[params.id]){
                    
                    dispatch({
                        type: UPDATE_FAVORITE_DATA_FROM_SINGLE_CASE,
                        payload: {
                            id: params.id,
                            status: true
                        }
                    })

                }

            }
        }

    }

    const toggleArchiveState = async () => {
        
        // hide dropdown
        setShowDropdownMore(false)
        document.body.classList.remove('modal-opened')

        if(data?.is_archived){
            const result = await dispatch( await caseMoveToUnarchive(params.id, cookies.authToken, false) )
            
            if(result){

                // update data into the singlesCase
                if(singlesCase[params.id]){
               
                    dispatch({
                        type: UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE,
                        payload: {
                            id: params.id,
                            status: false
                        }
                    })

                }

            }

        }
        else{
            const result = await dispatch( await caseMoveToArchive(params.id, cookies.authToken, false) )
            
            if(result){

                // update data into the singlesCase
                dispatch({
                    type: UPDATE_ARCHIVE_DATA_FROM_SINGLE_CASE,
                    payload: {
                        id: params.id,
                        status: true
                    }
                })

            }

        }

    }

    return (
        <>
            
            <Header 
                title={(data?.title && showTitle) ? data?.title : (!data?.title && loading) ? <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-9 mr-3" /> : 'جزئیات پرونده'}
                backBtn={true}
                backPath={!location?.state?.home ? '/law-suits' : '/'}
            >

                <button onClick={toggleFavoriteState} disabled={!data?.title} className="6xm:flex 5xm:w-12 w-8 hidden items-center justify-end h-8 -translate-x-2 group">
                            
                    { data?.is_favorite ? 
                    
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite.svg`}
                            className="w-6  text-gray-600 group-disabled:text-gray-400 "
                        />
                        :
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite-not.svg`}
                            className="w-6 text-gray-600 group-disabled:text-gray-400"
                        />  
                        
                    }

                </button>

                <button onClick={handleShowDropdownMore} className="w-8 h-8 flex items-center justify-end">
                    <ReactSVG
                        className="w-5"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/more.svg`}
                    />
                </button>


                { showDropdownMore && 
                    <Dropdown 
                        onSetShow={setShowDropdownMore}
                        classList="!left-3 right-auto xm:w-48 overflow-hidden"
                    >

                        <button onClick={ toggleArchiveState } disabled={!data?.title} className="p-4 text-sm flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 disabled:text-gray-400">
                                            
                            { data?.is_archived ? 
                                <span> برداشتن‌ از بایگانی </span>
                                :
                                <span> انتقال به بایگانی </span>
                            }

                            { data?.is_archived ? 

                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/archive-back.svg`}
                                    className="w-[19px] text-gray-600"
                                />
                                :
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/archive-2.svg`}
                                    className="w-[19px] text-gray-600"
                                />
                            }

                        </button>

                        <button onClick={ toggleFavoriteState } disabled={!data?.title} className="p-4 text-sm flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 disabled:text-gray-400 6xm:hidden">

                            { data?.is_favorite ? 
                                <span> برداشتن نشان </span>
                                :
                                <span> نشان کن </span>
                            }

                            { data?.is_favorite ? 
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite.svg`}
                                    className="w-[18px] text-gray-600 -translate-x-1"
                                />
                                :
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite-not.svg`}
                                    className="w-[18px] text-gray-600 -translate-x-1"
                                />
                        }


                        </button>

                        <Link to={'update'} className="p-4 relative focus:outline-none outline-none flex text-sm items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-primary duration-300">
                            
                            ویرایش

                            <ReactSVG
                                className="w-6 absolute left-3.5"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/edit-2.svg`}
                            />

                        </Link>

                        <button onClick={ showDeleteCaseAlert } className="p-4 focus:outline-none outline-none flex text-sm items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-rose-600 duration-300">
                            
                            حذف

                            <ReactSVG
                                className="w-5"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-2.svg`}
                            />

                        </button>
                        
                    </Dropdown>
                }

            </Header>
            
            <main className="main-content">

                { changes.length >= 1 && 
                    
                    <CaseContentUpdateHeader 
                        data={ data }
                        firstData={ firstData }
                        onSetFirstData={ setFirstData }
                        onSetData={ setData }
                        onSetChanges={ setChanges } 
                    />

                }

                { !showTitle &&
                    
                    <CaseContentTitle 
                        label={`${tabs[activeTab]} پرونده`}
                        title={data?.title}
                    />
                
                }

                <CaseHeaderTabs 
                    activeTab={activeTab}
                    onSetActiveTab={changeActiveTab}
                    fixedTop={showTitle}
                />

                <CaseContent 
                    activeTab={activeTab}
                    spaceTop={showTitle}
                    
                    firstData={ firstData }
                    onSetFirstData={ setFirstData }
                    
                    changes={changes}
                    onSetChanges={ setChanges }

                    data={ data }
                    onSetData={ setData }
                />

                { (deleteLoading || archiveLikeLoading) && <LoadingDot /> }

                { showDeleteCaseAlert && 
                    <ConfirmModal
                        title="حذف پرونده"
                        description="آیا میخواهید این پرونده را حذف کنید؟"
                        show={showDeleteCaseModal}
                        setShow={setShowDeleteCaseModal}
                        onConfirm={deleteCase}
                        className={''}
                        backdropClass={''}
                    >   
        
                        <div className="w-14 h-14 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-rose-500">
                            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`} className="w-10 h-10" />
                        </div>
        
                    </ConfirmModal>
                }

                { (errorMessageArchiveLike)  && 
                    
                    <ToastCustom>
                        
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { errorMessageArchiveLike }

                        </div>

                    </ToastCustom>

                }

                { (successMessageArchiveLike)  && 
                    
                    <ToastCustom>
    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-green-600 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                            { successMessageArchiveLike }

                        </div>

                    </ToastCustom>

                }

            </main>

        </>
    )

}

export default Case