import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { loadMoreMessageToChat } from "../../../../redux/action/messages/chat/chatAction"
import { useState } from "react"

const LoadMoreMessageBtn = () => {

    const dispatch = useDispatch()
    const pagination = useSelector(state => state.messages.chat.pagination)
    const receiver = useSelector(state => state.messages.chat.receiver)
    const expendData = useSelector(state => state.messages.chat.loadedChatListExpendData)

    const [loading, setLoading] = useState(false)
    const [currentScrollPoint, setCurrentScrollPoint] = useState()

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    const loadMore = async () => {
        setLoading(true)

        const lastScroll =  document.documentElement.scrollHeight
        setCurrentScrollPoint( lastScroll )         

        const data = await dispatch( await loadMoreMessageToChat(params.phone, params.id, pagination.current_page, cookies.authToken) )
        
        if(data){
            setLoading(false)
            
            setTimeout(() => {

                window.scrollTo({
                    top: (window.document.body.scrollHeight - lastScroll) - 200,
                })
                    
            }, 10);

            // if(!currentScrollPoint || currentScrollPoint === lastScroll)
            //     window.scrollTo({top: lastScroll - 250})
            // else {
            //     window.scrollTo({top: lastScroll - currentScrollPoint - 155})
            // }

        }
        else if(!data){
            setLoading(false)
        }
    }

    if(params.id == receiver.id && pagination.total_pages >= 2 && pagination.total_pages !== pagination.current_page){

        return (
            <div className="flex items-center justify-center mb-6">
                
                <button onClick={loadMore} disabled={loading} className="py-2 px-4 btn gap-2  bg-sky-500 shadow-custom-xl outline outline-4 outline-sky-500/20 rounded-md">

                    مشاهده بیشتر

                    { !loading ?
                     
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/more.svg`}
                            className="w-[17px] rotate-90"
                        />
                        :
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            className="w-4"
                        />
                    }
                
                </button>

            </div>
        )

    }

}

export default LoadMoreMessageBtn