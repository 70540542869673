import axios from 'axios'
import { apiKey } from '../../../store'
import { ADD_ITEM_TO_CHATMESSAGES_LIST, ADD_MORE_MESSAGE_TO_MESSAGES_IN_CHAT, ADD_TEXT_MESSAGE_ITEM_TO_CHAT, CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT, DELETE_MESSAGE_ITEM_FROM_CHAT, SET_FAILURE_CHAT, SET_LIST_CHAT, SET_LIST_CHAT_WITH_SORTED_DATE, SET_LOADING_CHAT, SET_PAGINATION_IN_CHAT, SET_RECEIVER_CHAT, SET_SENDING_MESSAGE_FAIL_CHAT, UNSET_LOADING_CHAT, UPDATE_ITEM_TO_CHATMESSAGES_LIST, UPDATE_LIST_CHAT } from './chatType'
import { SET_COUNT_OF_MESSAGE_IN_HOME } from '../../home/homeType'

const apiKeyMessages = apiKey + 'conversations/with'

export const getChatMessages = async (phone, id, authToken) => {
    
    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_CHAT
        })

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyMessages}/${phone}/${id}?app_type=lawyer&check_seen=1`, config)
        .catch(error => {
            
            dispatch({
                type: SET_FAILURE_CHAT,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                if(!data.data.data.invite){

                    const messages = data.data.data.messages.reverse()
                    
                    // Apply Dating on Messages
                    const object = {}

                    messages?.map(item => {

                        const date = new Date( item?.created_at )?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'}) 

                        if(!object[date]){
                            object[date] = [item]
                        }
                        else {
                            object[date] = [...object[date], item]
                        }

                    })

                    dispatch({
                        type: SET_LIST_CHAT,
                        payload: messages
                    })

                    // Save Dating Messages.
                    dispatch({
                        type: SET_LIST_CHAT_WITH_SORTED_DATE,
                        payload: object
                    })
    
                    dispatch({
                        type: SET_RECEIVER_CHAT,
                        payload: data.data.data.receiver
                    })
    
                    // Save Chat Message => for future if i came to chat with him, not neet to get data.
                    dispatch({
                        type: ADD_ITEM_TO_CHATMESSAGES_LIST,
                        payload: {
                            id: data.data.data.receiver.id,
                            data: { 
                                messages,
                                messagesSortByDate: object,
                                receiver: data.data.data.receiver,
                            },
                            pagination: data.data.pagination,
                            loadedTime: new Date()
                        }
                    })
    
                    dispatch({
                        type: SET_PAGINATION_IN_CHAT,
                        payload: data.data.pagination
                    })

                }

                dispatch({
                    type: UNSET_LOADING_CHAT
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_CHAT,
                    payload: data.data.errors[0].message
                })

            }

        }

    }

}

export const getChatMessagesWithoutLoading = async (phone, id, authToken, itsFirstGetOrNo = false) => {
    
    return async (dispatch) => {

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyMessages}/${phone}/${id}?app_type=lawyer&check_seen=1`, config)
        .catch(error => {
        })

        if(data){

            if(data.data.success){

                if(!data.data.data.invite){

                    const messages = data.data.data.messages.reverse()
    
                    const object = {}

                    messages?.map(item => {

                        const date = new Date( item?.created_at )?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'}) 

                        if(!object[date]){
                            object[date] = [item]
                        }
                        else {
                            object[date] = [...object[date], item]
                        }

                    })

                    if(itsFirstGetOrNo){
    
                        dispatch({
                            type: SET_LIST_CHAT,
                            payload: messages
                        })
        
                        dispatch({
                            type: SET_RECEIVER_CHAT,
                            payload: data.data.data.receiver
                        })
        
                        dispatch({
                            type: ADD_ITEM_TO_CHATMESSAGES_LIST,
                            payload: {
                                id: data.data.data.receiver.id,
                                data: { 
                                    messages,
                                    receiver: data.data.data.receiver,
                                    messagesSortByDate: object
                                },
                                pagination: data.data.pagination,
                                loadedTime: new Date()
                            }
                        })
        
                        dispatch({
                            type: SET_PAGINATION_IN_CHAT,
                            payload: data.data.pagination
                        })
    
                    }
                    else {
    
                        dispatch({
                            type: UPDATE_LIST_CHAT,
                            payload: {
                                messages,
                                id: data.data.data.receiver.id,
                                messagesSortByDate: object
                            }
                        })
        
                        dispatch({
                            type: SET_RECEIVER_CHAT,
                            payload: data.data.data.receiver
                        })
        
                        dispatch({
                            type: UPDATE_ITEM_TO_CHATMESSAGES_LIST,
                            payload: {
                                id: data.data.data.receiver.id,
                                data: { 
                                    messages,
                                    receiver: data.data.data.receiver,
                                    messagesSortByDate: object
                                },
                                loadedTime: new Date(),
                            }
                        })
    
                    }

                }

                return data.data.data

            }

        }

    }

}

export const loadMoreMessageToChat = (phone, id, currentPage, authToken) => {
    
    return async (dispatch) => {

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyMessages}/${phone}/${id}?app_type=lawyer&page=${currentPage+1}&check_seen=1`, config)
        .catch(error => {

            dispatch({
                type: SET_SENDING_MESSAGE_FAIL_CHAT,
                payload: 'نتونستم لود کنم'
            })

            setTimeout(() => {
                    
                dispatch({
                    type: SET_SENDING_MESSAGE_FAIL_CHAT,
                    payload: null
                })

            }, 1000);

            return false

        })

        if(data){

            if(data.data.success){

                const messages = data.data.data.messages.reverse()

                const object = {}

                messages?.map(item => {

                    const date = new Date( item?.created_at )?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'}) 

                    if(!object[date]){
                        object[date] = [item]
                    }
                    else {
                        object[date] = [...object[date], item]
                    }

                })

                dispatch({
                    type: ADD_MORE_MESSAGE_TO_MESSAGES_IN_CHAT,
                    payload: {
                        messages,
                        id: data.data.data.receiver.id,
                        pagination: data.data.pagination,
                        messagesSortByDate: object
                    }
                })

                return data.data.data

            }

            else if(!data.data.success){
                
                dispatch({
                    type: SET_SENDING_MESSAGE_FAIL_CHAT,
                    payload: 'نتونستم لود کنم'
                })

                setTimeout(() => {
                    
                    dispatch({
                        type: SET_SENDING_MESSAGE_FAIL_CHAT,
                        payload: null
                    })

                }, 1000);

                return false
            }

        }

    }

}

// send data
export const sendMessageToChat = async (id, body, authToken, fakeId) => {

    return async (dispatch) => {

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 30000
        }

        const post = {
            type: 'text',
            body
        }

        const data = await axios.post(`${apiKeyMessages}/${id}`, post, config)
        .catch(error => {
            
            dispatch({
                type: CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT,
                payload: {
                    fakeId,
                    id: fakeId,
                    receiver_id: id ? id : '',
                    status: 'error',
                    date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'})
                }
            })

            dispatch({
                type: SET_SENDING_MESSAGE_FAIL_CHAT,
                payload: 'پیام ارسال نشد'
            })

            setTimeout(() => {
                    
                dispatch({
                    type: SET_SENDING_MESSAGE_FAIL_CHAT,
                    payload: null
                })

            }, 1000);
            
        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT,
                    payload: {
                        fakeId,
                        id: data.data.data.id,
                        receiver_id: id ? id : '',
                        status: 'success',
                        date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'}) 
                    }
                })

                return true

            }

            else if(!data.data.success){

                dispatch({
                    type: CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT,
                    payload: {
                        fakeId,
                        id: fakeId,
                        receiver_id: id ? id : '',
                        status: 'error',
                        date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'})
                    }
                })

                dispatch({
                    type: SET_SENDING_MESSAGE_FAIL_CHAT,
                    payload: 'پیام ارسال نشد'
                })

                setTimeout(() => {
                    
                    dispatch({
                        type: SET_SENDING_MESSAGE_FAIL_CHAT,
                        payload: null
                    })

                }, 1000);

            }

        }

    }

}

// delete a message
export const deleteMessageFromChat = async (messageId, userId, scope, authToken) => {

    return async (dispatch) => {

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const post = {
            scope
        }

        const data = await axios.post(`${apiKeyMessages}/${userId}/messages/${messageId}/delete`, post, config)
        .catch(error => {
            
            dispatch({
                type: SET_SENDING_MESSAGE_FAIL_CHAT,
                payload: 'پیام حذف نشد'
            })

            setTimeout(() => {
                    
                dispatch({
                    type: SET_SENDING_MESSAGE_FAIL_CHAT,
                    payload: null
                })

            }, 1000);
            
        })

        if(data){

            if(data.data.success){

                // delete from chat :)
                dispatch({
                    type: DELETE_MESSAGE_ITEM_FROM_CHAT,
                    payload: {
                        messageId,
                        userId,
                        date: new Date()?.toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: '+0330'}),
                    }
                })

                return true

            }

            else if(!data.data.success){

                dispatch({
                    type: SET_SENDING_MESSAGE_FAIL_CHAT,
                    payload: 'پیام حذف نشد'
                })

                setTimeout(() => {
                    
                    dispatch({
                        type: SET_SENDING_MESSAGE_FAIL_CHAT,
                        payload: null
                    })

                }, 1000);

            }

        }

    }

}


export const getUnSeenMessageCount = async (authToken) => {

    return async (dispatch) => {

        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }


        const data = await axios.get(`${apiKey}conversations/check`, config)
        .catch(error => {
        })

        if(data){

            if(data.data.success){

                // delete from chat :)
                dispatch({
                    type: SET_COUNT_OF_MESSAGE_IN_HOME,
                    payload: data?.data?.data?.unseen_count
                })

                return true

            }

        }

    }

}