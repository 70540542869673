import { ReactSVG } from "react-svg"

import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"

import Button from "../../../UI/Button"
import { deleteCaseFromList } from "../../../../redux/action/cases/casesAction"
import { DELETE_CASE_FROM_HOME_INFO } from "../../../../redux/action/home/homeType"
import { DELETE_CASE_FROM_CONTACTS, DELETE_CASE_FROM_CONTACT_INFO_CONTACTS } from "../../../../redux/action/contacts/typeContacts"
import ConfirmModal from "../../../UI/ConfirmModal"
import { useEffect, useState } from "react"

const DeleteHeaderCase = (props) => {

    const dispatch = useDispatch()
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const profilesList = useSelector(state => state.contacts.profilesList)
    const contactInfo = useSelector(state => state.contacts.contactInfo)

    const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false)

    const [caseInfo, setCaseInfo] = useState({})

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        const caseInfo = props.cases.find(item => item.id === props.selectedItemId)

        setCaseInfo( caseInfo )

    }, [props.selectedItemId])

    const destroyDeleteMode = () => {
        props.onSetSelectedItemDelete(null)
    }

    const showDeleteCaseAlert = () => {
        setShowDeleteCaseModal(true)
    }

    const doDelete = async (event) => {
        event.stopPropagation()

        const data = await dispatch( await deleteCaseFromList(props.selectedItemId, cookies.authToken) )

        if(data){

            if(homeInfoLoaded){

                dispatch({
                    type: DELETE_CASE_FROM_HOME_INFO,
                    payload: props.selectedItemId
                })

            }

            if(props.cases && props.cases.length >= 1){

                const item = props.cases.filter(item => item.id == props.selectedItemId)

                if(item && item[0]){

                    const caseItem = item[0]

                    caseItem?.clients?.map(item => {
                
                        if(profilesList && profilesList[item.id]){
        
                            dispatch({
                                type: DELETE_CASE_FROM_CONTACTS,
                                payload: {
                                    caseId: props.selectedItemId,
                                    contactId: item.id
                                }
                            })
        
                            if(contactInfo && contactInfo?.id && Number(contactInfo.id) === Number(item.id)){
                                
                                dispatch({
                                    type: DELETE_CASE_FROM_CONTACT_INFO_CONTACTS,
                                    payload: props.selectedItemId
                                })
        
                            }
        
                        }
        
                    })                    

                }

            }

        }
        
        props.onSetSelectedItemDelete(null)

        document.body.classList.remove('modal-opened')
    }

    return (
        <>

            <header className="fixed lg:pt-4 bg-custom-gray py-3 top-0 left-0 lg:w-3/4 lg:px-4 px-3 w-full z-[3]">

                <div className="bg-white border border-gray-200 flex items-center flex-wrap justify-between rounded-2xl shadow-custom-lg p-4">

                    <button onClick={destroyDeleteMode} className="text-gray-600 h-full">
                        <ReactSVG 
                            className="w-8 h-8"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/times.svg`}
                        />
                    </button>

                    <div className="flex items-center gap-1">

                        <button className="5xm:w-12 w-8 flex items-center justify-center h-8">
                            
                            { caseInfo && caseInfo.is_favorite ? 
                            
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite.svg`}
                                    className="w-6  text-gray-500 "
                                />
                                :
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/favorite-not.svg`}
                                    className="w-6 text-gray-500"
                                />  
                                
                            }

                        </button>

                        <button className="5xm:w-12 w-8 flex items-center justify-center h-8">
                            
                            { caseInfo && caseInfo.is_archived ? 
                            
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/archive-back.svg`}
                                    className="w-6 text-gray-600"
                                />
                                :
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/archive-2.svg`}
                                    className="w-6 text-gray-600"
                                />  
                                
                            }

                        </button>

                        <Button  
                            className={'profile-btn bg-rose-600 py-1 flex gap-1 pr-3 pl-4 mr-2'}
                            onClick={showDeleteCaseAlert}
                        >
                            <ReactSVG
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                className="w-4"
                            />
                            حذف     
                        </Button>

                    </div>

                </div>

            </header>

            { showDeleteCaseAlert && 
                <ConfirmModal
                    title="حذف پرونده"
                    description="آیا میخواهید این پرونده را حذف کنید؟"
                    show={showDeleteCaseModal}
                    setShow={setShowDeleteCaseModal}
                    onConfirm={doDelete}
                    className={''}
                    backdropClass={''}
                >   
    
                    <div className="w-14 h-14 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-rose-500">
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`} className="w-10 h-10" />
                    </div>
    
                </ConfirmModal>
            }

        </>
    )

}

export default DeleteHeaderCase