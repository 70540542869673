import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/Header"
import Button from "../../../components/UI/Button"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useCookies } from "react-cookie"
import LoadingDot from "../../../components/UI/LoadingDot"
import ToastCustom from "../../../components/UI/ToastCustom"
import { ReactSVG } from "react-svg"
import { editCaseToApi, getSingleCaseData } from "../../../redux/action/cases/casesAction"
import EditCaseForm from "../../../components/Cases/Edit"
import { SET_ERROR_SINGLE_CASE } from "../../../redux/action/cases/casesType"
import { UPDATE_ITEM_TO_LAW_SUITS_HOME } from "../../../redux/action/home/homeType"
import { UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS, UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS } from "../../../redux/action/contacts/typeContacts"

const EditCase = () => {

    const dispatch = useDispatch()
    const forms = useSelector(state => state.editCase.forms)
    const formsNotValid = useSelector(state => state.editCase.formsNotValid)
    const error = useSelector(state => state.cases.singleCaseError)
    const singlesCase = useSelector(state => state.cases.singlesCase)
    const changedData = useSelector(state => state.editCase.changedData)
    const clients = useSelector(state => state.editCase.clients)
    const contacts = useSelector(state => state.editCase.contacts)
    const loading = useSelector(state => state.editCase.loading)
    const successMessage = useSelector(state => state.editCase.successMessage)
    const updateHasError = useSelector(state => state.editCase.error)

    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const profilesList = useSelector(state => state.contacts.profilesList)
    const contactInfo = useSelector(state => state.contacts.contactInfo)

    const [data, setData] = useState()

    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getCaseInfo()

        if(location?.state?.scrollToBottom){
            window.scrollTo({top: window.innerHeight + 3000})
        }
        else if(location?.state?.scroll){
            window.scrollTo({top: 500})
        }

        window.scrollTo({top: 0})

    }, [])

    useEffect(() => {

        if(error){

            setTimeout(() => {
                
                dispatch({
                    type: SET_ERROR_SINGLE_CASE,
                    payload: false
                })

            }, 3000);

        }

    }, [error])

    const getCaseInfo = async () => {
        
        if(singlesCase[params.id]){

            setData(singlesCase[params.id])

        }

        else if(params.id && !singlesCase[params.id]) {

            const data = await dispatch( await getSingleCaseData(params.id, cookies.authToken) )

            if(data)
                setData( data )

        }

    }

    const doUpdateCaseInfo = async () => {

        const data = {
            title: forms?.title?.value,
            clients,
            contacts,
            // state: forms?.state,
            step: forms?.step,
            agreed_fee: forms?.agreed_fee ? String(forms?.agreed_fee).split(',').join('') : '',
            description: forms.description.value,
            archive_code: forms.archive_code.value,
            code: forms.code.value,
            branch: forms.branch.value,
            city_id: forms.location.city.id,
            province_id: forms.location.province,
            category_id: forms.category.id,
            // jurisdiction_id: forms.jurisdiction.id
        }

        const dataExport = {
            id: params.id,
            title: forms?.title?.value,
            clients,
            contacts,
            // state: forms?.state,
            step: forms?.step,
            agreed_fee: forms?.agreed_fee ? String(forms?.agreed_fee).split(',').join('') : '',
            description: forms.description.value,
            archive_code: forms.archive_code.value,
            code: forms.code.value,
            branch: forms.branch.value,
            category: forms.category,
            // jurisdiction: forms.jurisdiction
        }

        const response = await dispatch( await editCaseToApi(data, dataExport, cookies.authToken) )         

        if(response){

            // come edit-home-case
            if(homeInfoLoaded){

                // add-to-home
                const info = {
                    id: dataExport.id,
                    title: dataExport.title,
                    last_state: {
                        user_id: '',
                        data: {
                            "status": "updated"
                        }
                    }
                }

                dispatch({
                    type: UPDATE_ITEM_TO_LAW_SUITS_HOME,
                    payload: info
                })

            }

            // come edit-law-suits profileList
            // come edit-law-suits contactInfo
            dataExport.clients.map(item => {
                    
                if(profilesList && profilesList[item.id]){

                    dispatch({
                        type: UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS,
                        payload: {
                            contactId: item.id,
                            law_data: {
                                id: dataExport.id,
                                title: dataExport.title,
                                code: dataExport.code,
                                archive_code: dataExport.archive_code,
                                state: dataExport.state,
                                step: dataExport.step,
                                branch: dataExport.branch,
                                is_archived: false,
                                position: item.position,
                                notify: item.notify,
                            }
                        }
                    })

                    if(contactInfo && contactInfo?.id && Number(contactInfo.id) === Number(item.id)){
                        
                        dispatch({
                            type: UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS,
                            payload: {
                                contactId: item.id,
                                law_data: {
                                    id: dataExport.id,
                                    title: dataExport.title,
                                    code: dataExport.code,
                                    archive_code: dataExport.archive_code,
                                    state: dataExport.state,
                                    step: dataExport.step,
                                    branch: dataExport.branch,
                                    is_archived: false,
                                    position: item.position,
                                    notify: item.notify,
                                }
                            }
                        })

                    }

                }

            })

            setData( dataExport )

        }

    }

    return (
        <>
            
            <Header 
                title={'ویرایش پرونده'}
                backBtn={true}
                backPath={location?.state?.cases ? '/law-suits' : `/law-suits/${params.id}`}
            >
                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={ formsNotValid || error }
                    onClick={ doUpdateCaseInfo }
                >
                    اعمال     
                </Button>

            </Header>

            <main className="main-content">

                <EditCaseForm 
                    data={data}
                />

                { (error || updateHasError) && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { error || updateHasError }

                        </div>

                    </ToastCustom>

                }

                { successMessage && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-green-600 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                            { successMessage }

                        </div>

                    </ToastCustom>

                }

                { loading && <LoadingDot /> }

            </main>

        </>
    )

}

export default EditCase