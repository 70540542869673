import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { SET_DETAIL_OF_CASE } from "../../../../../../redux/action/cases/add/type"
import { ReactSVG } from "react-svg"
import { useEffect } from "react"
import { SET_DETAIL_OF_CASE_EDIT_CASE } from "../../../../../../redux/action/cases/edit/type"

const SelectContactCaseDetailPostion = (props) => {

    const dispatch = useDispatch()
    const baseDataPositionslawsuit = useSelector(state => state.baseData.info?.misc?.lawsuit_client_positions)
    const baseDataPositions = useSelector(state => state.baseData.info?.misc?.positions)
    const detailInfo = useSelector(state => state.addCase.detail)
    const detailInfoEdit = useSelector(state => state.editCase.detail)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const location = useLocation()

    useEffect(() => {

        if(location?.state?.edit && !location?.state?.editMode && !props.updatedPage){
            dispatch({
                type: SET_DETAIL_OF_CASE,
                payload: {
                    ...detailInfo,
                    position: location?.state?.data?.position,
                    notify: location?.state?.data?.notify
                }
            })
            return
        }
        else if(location?.state?.edit && location?.state?.editMode && !props.updatedPage){
            dispatch({
                type: SET_DETAIL_OF_CASE_EDIT_CASE,
                payload: {
                    ...detailInfoEdit,
                    position: location?.state?.data?.position,
                    notify: location?.state?.data?.notify
                }
            })
            return
        }
        else if(location?.state?.edit && !location?.state?.editMode && props.updatedPage){
            
            dispatch({
                type: SET_DETAIL_OF_CASE_EDIT_CASE,
                payload: {
                    ...detailInfoEdit,
                    position: location?.state?.data?.position,
                    notify: location?.state?.data?.notify
                }
            })

            return
        }

        // set default positon
        if(!detailInfo.position && baseDataLoaded && location.state?.forMovakels && !location?.state?.editMode){
            dispatch({
                type: SET_DETAIL_OF_CASE,
                payload: {
                    ...detailInfo,
                    position: baseDataPositionslawsuit[0],
                }
            })
        }
        else if(!detailInfo.position && baseDataLoaded && !location.state?.forMovakels && !location?.state?.editMode){
            dispatch({
                type: SET_DETAIL_OF_CASE,
                payload: {
                    ...detailInfo,
                    position: baseDataPositions[0],
                }
            })
        }

        if(!detailInfoEdit.position && baseDataLoaded && location.state?.forMovakels && location?.state?.editMode){
            
            dispatch({
                type: SET_DETAIL_OF_CASE_EDIT_CASE,
                payload: {
                    ...detailInfoEdit,
                    position: baseDataPositionslawsuit[0],
                }
            })

        }
        else if(!detailInfoEdit.position && baseDataLoaded && !location.state?.forMovakels && location?.state?.editMode){
            
            dispatch({
                type: SET_DETAIL_OF_CASE_EDIT_CASE,
                payload: {
                    ...detailInfoEdit,
                    position: baseDataPositions[0],
                }
            })

        }

    }, [baseDataLoaded])

    const comeSetPosition = (event) => {

        if(!location?.state?.editMode && !props.updatedPage)
            dispatch({
                type: SET_DETAIL_OF_CASE,
                payload: {
                    ...detailInfo,
                    position: event.target.value,
                }
            })

        else {
            
            dispatch({
                type: SET_DETAIL_OF_CASE_EDIT_CASE,
                payload: {
                    ...detailInfoEdit,
                    position: event.target.value,
                }
            })

        }
    }

    if(baseDataLoading){
        return (
            <section className="flex items-center justify-center h-20 mt-4">
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )
    }

    return (
        <ul className="grid xm:grid-cols-2 grid-cols-1 gap-4">
            
            { location.state?.forMovakels ? 
                
                baseDataPositionslawsuit?.map((item, index) => 
                    <li key={item} className={`flex cursor-pointer duration-300 items-center ps-3 border border-gray-200 rounded-lg w-full ${(!props.editMode && !props.updatedPage) ? item === detailInfo?.position && 'bg-white shadow-custom-sm' : (props.editMode || props.updatedPage) && item === detailInfoEdit?.position && 'bg-white shadow-custom-sm'}`}>
                        
                        <input onChange={comeSetPosition} checked={(!props.editMode && !props.updatedPage) ? item === detailInfo?.position && 'bg-white shadow-custom-sm' : (props.editMode || props.updatedPage) && item === detailInfoEdit?.position && 'bg-white shadow-custom-sm'} id={`bordered-radio-${index}`} type="radio" value={item} name="bordered-radio" className="w-4 cursor-pointer text-primary bg-gray-100 border-gray-300 rounded-full h-full" />
                        
                        <label htmlFor={`bordered-radio-${index}`} className="w-full py-3 ms-2 cursor-pointer text-sm font-medium text-gray-900"> {item} </label>

                    </li>
                )
                
                :
                
                baseDataPositions?.map((item, index) => 
                    <li key={item} className={`flex cursor-pointer items-center ps-3 duration-300 border border-gray-200 rounded-lg w-full ${(!props.editMode && !props.updatedPage) ? item === detailInfo?.position && 'bg-white shadow-custom-sm' : (props.editMode || props.updatedPage) && item === detailInfoEdit?.position && 'bg-white shadow-custom-sm'}`}>
                        
                        <input onChange={comeSetPosition} checked={(!props.editMode && !props.updatedPage) ? item === detailInfo?.position && 'bg-white shadow-custom-sm' : (props.editMode || props.updatedPage) && item === detailInfoEdit?.position && 'bg-white shadow-custom-sm'} id={`bordered-radio-${index}`} type="radio" value={item} name="bordered-radio" className="w-4 cursor-pointer text-primary bg-gray-100 border-gray-300 rounded-full h-full" />
                        
                        <label htmlFor={`bordered-radio-${index}`} className="w-full py-3 ms-2 cursor-pointer text-sm font-medium text-gray-900"> {item} </label>

                    </li>
                )

            }


        </ul>
    )

}

export default SelectContactCaseDetailPostion