import { useDispatch, useSelector } from "react-redux"
import CaseListItem from "./Item"
import { useEffect, useState } from "react"

import { getCasesLawyer } from "../../../redux/action/cases/casesAction"
import { ReactSVG } from "react-svg"
import { useCookies } from "react-cookie"
import CasesFiltered from "./Filtered"
import DeleteHeaderCase from "./DeleteHeader"
import { useNavigate } from "react-router-dom"

const CasesList = () => {

    const dispatch = useDispatch()
    const cases = useSelector(state => state.cases.cases)
    const loading = useSelector(state => state.cases.loading)
    const error = useSelector(state => state.cases.error)
    const loaded = useSelector(state => state.cases.loaded)
    const filtered = useSelector(state => state.cases.filtered)    
    const fields = useSelector(state => state.cases.fieldsFilter)

    const [selectedItemDelete, setSelectedItemDelete] = useState(null)

    const navigate = useNavigate()

    const [cookies, ,removeCookie] = useCookies(['authToken'])

    useEffect(() => {

        if(!loaded){
            getCases()
        }

    }, [])

    useEffect(() => {

        if(error){

            if(error === 'ابتدا وارد حساب کاربری خود شوید.'){
                // come go to authPage and remove cookies
                removeCookie('authToken')                

                navigate('/auth/login', {replace: true})
            }     

        }

    }, [error])

    const getCases = async () => {
        await dispatch( await getCasesLawyer(cookies.authToken) )
    }

    if(loading){
        
        return (
            <section className="flex items-center justify-center h-20">
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )

    }

    if(error){
        return (
            <section className="flex items-center gap-2">

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {error} </p>

            </section>
        )
    }

    if(filtered.searchedValue){
        return (
            <CasesFiltered 
                search={filtered.searchedValue}
                list={filtered.list}
                
                selectedItemDelete={selectedItemDelete}
                onSetSelectedItemDelete={setSelectedItemDelete}
            />
        )
    }

    return (
        <>
            
            { selectedItemDelete &&
                
                <DeleteHeaderCase 
                    selectedItemId={selectedItemDelete}
                    onSetSelectedItemDelete={setSelectedItemDelete}
                    cases={cases}
                />

            }

            <section>

                { cases && cases.length === 0 && 
                    <div className="flex items-center gap-2">

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                            className="w-4 text-gray-700"
                        />

                        <span> پرونده ای موجود نیست </span>
                    
                    </div>
                }

                <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                    {cases && cases.map(item => 

                       (!item.is_archived || (item?.is_archived && fields?.is_archived?.applied) ) &&

                            <CaseListItem 
                                key={item.id}

                                id={item.id}
                                title={item.title}
                                image={item?.clients[0]?.user?.image}
                                mobile={item?.clients[0]?.mobile}
                                name={item?.clients[0]?.name}
                                clients={item?.clients}

                                code={item.code}
                                archive_code={item.archive_code}
                                branch={item.branch}
                                step={item.step}
                                state={item.state}
                                category={item.category}

                                files={item.files}
                                notes={item.notes}
                                conversations={item.conversations}
                                events={item.events}
                                is_favorite={item.is_favorite}
                                is_archived={item.is_archived}

                                selectedItemDelete={selectedItemDelete}
                                onSetSelectedItemDelete={setSelectedItemDelete}
                            />
                        
                    )}

                </ul>

            </section>

        </>
    )

}

export default CasesList