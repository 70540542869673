import { RESTE_ALL_CONTACTS_DATA_APP } from "../../action/cases/casesType"
import { SET_CONTACTS, SET_FAILURE_CONTACTS, SET_LOADING_CONTACTS, SET_FILTER_CONTACTS, UNSET_LOADING_CONTACTS, SET_DROPDOWN_CONTACTS, UNSET_DROPDOWN_CONTACTS, SET_UPDATE_LOADING_CONTACTS, UNSET_UPDATE_LOADING_CONTACTS, SET_UPDATE_FAILURE_CONTACTS, SET_LOCATION_FOR_CONTACT, RESET_CONTACT_DATA, RESET_LOCATION_CONTACTS, SET_POSITION_CONTACT, SET_MAIN_FIELDS_CONTACT, SET_MORE_INFO_FIELDS_CONTACT, SET_ENABLE_SUBMIT_BUTTON, SET_CONTACT_INFO, RESET_CONTACT_INFO, ADD_TO_CONTACTS, RESET_FIELDS_DATA, SET_CONTACT_INFO_IN_UPDATE, SET_ITEM_CONTACT_INFO_IN_UPDATE, REPLACE_CONTACT_INFO, SET_PROFILES_LIST, ADD_ITEM_TO_PROFILES_LIST, DELETE_CASE_FROM_CONTACTS, DELETE_CASE_FROM_CONTACT_INFO_CONTACTS, EDIT_ITEM_FROM_PROFILE_LIST, ADD_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS, ADD_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS, UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS, UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS, DELETE_USER_FROM_LIST } from "../../action/contacts/typeContacts"

const initalState = {
    loading: false,
    error: null,

    contacts: [],
    filtered: {
        searchedValue: '',
        list: []
    },
    getedContacts: false,

    dropdown: null,
    
    updateLoading: false,
    updateError: null,

    // fields
    mainFields: {
        name: {
            value: '',
            label: 'نام و نام خانوادگی',
            max: 40,
    
            validate: {
                min: 3,
                required: true
            },
            
            icon: 'user',
            isValid: true
        },
    
        mobile: {
            value: '',
            label: 'موبایل',
            max: 11,
            type: 'tel',
    
            validate: {
                length: 11,
                required: true
            },
            
            icon: 'mobile',
            isValid: true
        },
    
        description: {
            value: '',
            label: 'توضیحات',
    
            validate: {
                min: 2,
            },

            max: 300,
            
            icon: 'description',
            isValid: true
        }
    },

    moreInfoFields: {
        phone: {
            value: '',
            label: 'تلفن',
            max: 11,
            type: 'tel',
    
            validate: {
                length: 11,
            },
            
            icon: 'telephone',
            isValid: true
        },
    
        email: {
            value: '',
            label: 'ایمیل',
    
            validate: {
                email: true,
            },
            
            icon: 'email',
            isValid: true
        },
    
        address:  {
            value: '',
            label: 'آدرس',
    
            validate: {
                min: 2,
            },
            
            icon: 'map',
            isValid: true
        }
    },
    location: {
        province:  {id: ''},
        city: {id: '', name: ''}
    },
    position: '',

    enableSubmitButton: false,

    contactInfo: {},
    contactInfoForUseInUpdate: {},

    profilesList: {},
}

const contactsReducer = (state = initalState, action) => {

    switch(action.type){

        case SET_CONTACTS :
            return {
                ...state,
                contacts: action.payload,
                loading: false,
                error: null,
                updateLoading: false,
                updateError: null,
                getedContacts: true
            }

        case SET_LOADING_CONTACTS :
            return {
                ...state,
                loading: true,
            }

        case SET_FAILURE_CONTACTS :
            return {
                ...state,
                loading: false,
                error: action.payload,
                updateError: null,
                updateLoading: false
            }

        case DELETE_USER_FROM_LIST :
            return {
                ...state,
                contacts: state.contacts?.filter(item => item.id != action.payload),
                filtered: {
                    ...state.filtered,
                    list: state.filtered.list?.filter(item => item.id != action.payload),
                }
            }

        case SET_FILTER_CONTACTS :
            return {
                ...state,
                filtered: {
                    searchedValue: action.payload.value,
                    list: action.payload.list
                },
                loading: false,
                updateLoading: false,
                updateError: null
            }

        case UNSET_LOADING_CONTACTS :
            return {
                ...state,
                loading: false
            }

        case SET_DROPDOWN_CONTACTS :
            return {
                ...state,
                dropdown: action.payload
            }

        case UNSET_DROPDOWN_CONTACTS :
            return {
                ...state,
                dropdown: null
            }

        case SET_UPDATE_LOADING_CONTACTS :
            return {
                ...state,
                updateLoading: true
            }

        case UNSET_UPDATE_LOADING_CONTACTS :
            return {
                ...state,
                updateLoading: false
            }

        case SET_UPDATE_FAILURE_CONTACTS :
            return {
                ...state,
                updateLoading: false,
                updateError: action.payload,
            }

        case SET_LOCATION_FOR_CONTACT :
            return {
                ...state,
                location: {
                    province: {id: action.payload.province},
                    city: action.payload.city
                }
            }

        case RESET_LOCATION_CONTACTS :
            return {
                ...state,
                location: {
                    province:  {id: ''},
                    city: {id: '', name: ''}
                },
            }

        case RESET_CONTACT_DATA :
            return {
                ...state,
                location: {
                    province:  {id: ''},
                    city: {id: '', name: ''}
                },
                dropdown: null,
                updateLoading: false,
                updateError: null,
                position: '',
            }

        case SET_POSITION_CONTACT :
            return {
                ...state,
                position: action.payload
            }

        case SET_MAIN_FIELDS_CONTACT: 
            return {
                ...state,
                mainFields: {...action.payload},
            }

        case SET_MORE_INFO_FIELDS_CONTACT: 
            return {
                ...state,
                moreInfoFields: {...action.payload},
            }
        
        case SET_ENABLE_SUBMIT_BUTTON :
            return {
                ...state,
                enableSubmitButton: action.payload
            }

        case SET_CONTACT_INFO :
            return {
                ...state,
                contactInfo: {
                    ...state.contactInfo,
                    ...{...action.payload}
                }
            }

        case REPLACE_CONTACT_INFO :
            return {
                ...state,
                contactInfo: {...action.payload},
                contacts: state.contacts.map(item => {
                    if(item.id == action.payload?.id){
                        return {
                            ...item,
                            ...action.payload,
                        }
                    }
                    
                    return item
                })
            }

        case RESET_CONTACT_INFO :
            return {
                ...state,
                contactInfo: {}
            }

        case ADD_TO_CONTACTS :
            return {
                ...state,
                contacts: [
                    {...action.payload},
                    ...state.contacts,
                ],
                updateLoading: false,
                updateError: null
            }

        case RESET_FIELDS_DATA :
            return {
                ...state,
                mainFields: {
                    name: {
                        value: '',
                        label: 'نام و نام خانوادگی',
                        max: 40,
                
                        validate: {
                            min: 3,
                            required: true
                        },
                        
                        icon: 'user',
                        isValid: true
                    },
                
                    mobile: {
                        value: '',
                        label: 'موبایل',
                        max: 11,
                        type: 'tel',
                
                        validate: {
                            length: 11,
                            required: true
                        },
                        
                        icon: 'mobile',
                        isValid: true
                    },
                
                    description: {
                        value: '',
                        label: 'توضیحات',
                
                        validate: {
                            min: 2,
                        },
                        
                        icon: 'description',
                        isValid: true
                    }
                },
                moreInfoFields: {
                    phone: {
                        value: '',
                        label: 'تلفن',
                        max: 11,
                        type: 'tel',
                
                        validate: {
                            length: 11,
                        },
                        
                        icon: 'telephone',
                        isValid: true
                    },
                
                    email: {
                        value: '',
                        label: 'ایمیل',
                
                        validate: {
                            email: true,
                        },
                        
                        icon: 'email',
                        isValid: true
                    },
                
                    address:  {
                        value: '',
                        label: 'آدرس',
                
                        validate: {
                            min: 2,
                        },
                        
                        icon: 'map',
                        isValid: true
                    }
                },
                enableSubmitButton: false
            }

        case SET_CONTACT_INFO_IN_UPDATE :
            return {
                ...state,
                contactInfoForUseInUpdate: action.payload
            }

        case SET_ITEM_CONTACT_INFO_IN_UPDATE :
            return {
                ...state,
                contactInfoForUseInUpdate: {
                    ...state.contactInfoForUseInUpdate,
                    ...{...action.payload}
                }
            }

        case SET_PROFILES_LIST :
            return {
                ...state,
                profilesList: action.payload
            }

        case ADD_ITEM_TO_PROFILES_LIST :
            return {
                ...state,
                profilesList: {
                    ...state.profilesList,
                    ...{...action.payload},
                }
            }

        case EDIT_ITEM_FROM_PROFILE_LIST :
            return {
                ...state,
                profilesList: {
                    ...state.profilesList,
                    [action.payload.id]: {
                        ...state.profilesList[action.payload.id],
                        ...action.payload.data
                    }
                },
                contactInfo: {
                    ...state.contactInfo,
                    ...action.payload.data
                }
            }

        case DELETE_CASE_FROM_CONTACTS :
            return {
                ...state,
                profilesList: {
                    ...state.profilesList,
                    [action.payload.contactId]: {
                        ...state.profilesList[action.payload.contactId],
                        law_suits: state.profilesList[action.payload.contactId].law_suits.filter(item => item.id != action.payload.caseId)
                    }
                }
            }

        case DELETE_CASE_FROM_CONTACT_INFO_CONTACTS :
            return {
                ...state,
                contactInfo: {
                    ...state.contactInfo,
                    law_suits: state.contactInfo.law_suits.filter(item => item.id != action.payload)
                }
            }

        case ADD_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS :
            return {
                ...state,
                profilesList: {
                    ...state.profilesList,
                    [action.payload.contactId]: {
                        ...state.profilesList[action.payload.contactId],
                        law_suits: [
                            action.payload.law_data,
                            ...state.profilesList[action.payload.contactId].law_suits
                        ]
                    }
                }
            }

        case ADD_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS :
            return {
                ...state,
                contactInfo: {
                    ...state.contactInfo,
                    law_suits: [
                        action.payload.law_data,
                        ...state.contactInfo.law_suits
                    ]
                }
            }

        case UPDATE_LAW_SUITS_FROM_PROFILE_LIST_IN_CONTACTS :
            return {
                ...state,
                profilesList: {
                    ...state.profilesList,
                    [action.payload.contactId]: {
                        ...state.profilesList[action.payload.contactId],
                        law_suits: state.profilesList[action.payload.contactId].law_suits.map(item => {
                            if( Number(item.id) === Number(action.payload.law_data.id) ){
                                return action.payload.law_data
                            }
                            return item
                        })
                    }
                }
            }

        case UPDATE_LAW_SUITS_FROM_CONTACTS_INFO_IN_CONTACTS :
            return {
                ...state,
                contactInfo: {
                    ...state.contactInfo,
                    law_suits: state.contactInfo.law_suits.map(item => {
                        if( Number(item.id) === Number(action.payload.law_data.id) ){
                            return action.payload.law_data
                        }
                        return item
                    })
                }
            }

        case RESTE_ALL_CONTACTS_DATA_APP :
            return {
                ...state,
                ...initalState
            }

        default :
            return state

    }

}

export default contactsReducer