import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../../utils/base-data"
import { getBaseDataLawyer } from "../../../../../redux/action/baseData/baseAction"
import { SET_SUCCESS_BASEDATA } from "../../../../../redux/action/baseData/baseType"
import { useEffect } from "react"
import { ReactSVG } from "react-svg"

const NoteAddFormSubjectList = (props) => {

    const dispatch = useDispatch()
    const baseDataSubjects = useSelector(state => state.baseData.info?.misc?.note_subject)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataError = useSelector(state => state.baseData.error)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getSubjectFromBaseData()

    }, [])

    const getSubjectFromBaseData = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }
    
    const selectedSubject = (subject) => {
        // update method
        props.onSelectedSubject(subject)
    }

    if(baseDataLoading){
        return (
            <div className="flex items-center justify-center h-20">  
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 text-gray-500" />
            </div>
        )
    }

    else if(baseDataError){
        return (
            <div className="flex items-center justify-center h-20 text-gray-600">
                {baseDataError}
            </div>
        )
    }

    else if (props.filteredList.searched){
        return (
            <>
                { props.filteredList.list.length === 0 &&
                    <div className="flex items-center justify-center h-20 text-gray-600">
                        هیچ دسته‌ای ای یافت نشد
                    </div>
                }

                <ul className="mt-4">

                    {props.filteredList?.list?.map(item => 
                        <li
                            key={'note_subject_'+item}
                            className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                            onClick={() => props.onSelectCase(item)}
                        >
                            {item}

                            <span className="text-sm text-primary"> انتخاب </span>
                        </li>    
                    )}

                </ul>
            </>
        )
    }

    return (
        <div className="mt-4">
            
            { (!baseDataSubjects || baseDataSubjects?.length <= 0) && 
                <div className="flex items-center justify-center h-20 text-gray-600">
                    هیچ دسته‌ای ای وجود ندارد 
                </div>
            }

            <ul className="mt-4">

                {baseDataSubjects?.map((item, index) => 
                    <li
                        key={item}
                        className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                        onClick={() => selectedSubject(item) }
                    >
                        <div className="flex items-center gap-4 flex-wrap">

                            {item}

                        </div>


                        <span className="text-sm text-primary"> انتخاب </span>

                    </li>    
                )}

            </ul>

        </div>
    )

}

export default NoteAddFormSubjectList