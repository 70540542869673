import { useState } from "react"
import ModalImage from "../../../../UI/ModalImage"
import DropdownModal from "../../../../UI/Dropdown"
import { ReactSVG } from "react-svg"
import ConfirmModalDelete from "../../../../UI/ConfirmModal/Delete"
import { Link, useParams } from "react-router-dom"
import ResendFileInChat from "./ReSend"
import { useDispatch } from "react-redux"
import { useCookies } from "react-cookie"
import { deleteMessageFromChat } from "../../../../../redux/action/messages/chat/chatAction"

const MessageContentImage = (props) => {

    const dispatch = useDispatch()

    const [showingBigImage, setShowingBigImage] = useState(false)
    const [showBigImage, setShowBigImage] = useState(false)
    const [showMoreOption, setShowMoreOption] = useState(false)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteBoth, setDeleteBoth] = useState(true)

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    const showImageInBigSize = () => {
        // show image
        setShowBigImage(true)

        document.body.classList.add('modal-opened')
    }

    const handleShowMoreOption = (event) => {

        if(event.target.localName != 'img'){

            setShowMoreOption(true)
    
            document.body.classList.add('modal-opened')
        
        }

    }

    const closeShowMoreOption = (value) => {
        setShowMoreOption(value)

        document.body.classList.remove('modal-opened')
    }

    const downloadImage = async () => {
        setTimeout(() => {
            closeShowMoreOption(false)
        }, 50)

        var element = document.createElement("a");
        var file = new Blob(
        [
            props.src
        ],
        { type: "image/*" }
        );
        element.href = props.src;
        element.download = props.name + '-image';
        element.click();

    }

    const handleShowMessage = (event) => {
        event.stopPropagation()

        setShowDeleteModal(true)
        setShowMoreOption(false)
    }

    const deleteMessage = async () => {
        setShowDeleteModal(false)

        document.body.classList.remove('modal-opened')

        const scope = deleteBoth ? 'both' : 'self'
        await dispatch( await deleteMessageFromChat(props.id, params.id, scope, cookies.authToken) )
    }

    if(props.self)
        return (
            <>
                <li 
                    className="flex select-none flex-col gap-1.5 w-full relative"
                    onClick={handleShowMoreOption}
                    onContextMenu={handleShowMoreOption}
                >

                    <div className="w-44 h-44 cursor-pointer" onClick={showImageInBigSize}>
                        <img className="w-full shadow-custom-md bg-white rounded-3xl rounded-tr-none h-full object-cover" src={props.src} alt="" />
                    </div>

                    <div className="select-none flex justify-between items-center w-44">
                                        
                        <span className="text-sm-13px text-gray-500"> {new Date(props.time)?.toLocaleTimeString('fa', {hour: '2-digit', minute: '2-digit', timeZone: '+0330'})} </span>

                        <div className="-translate-y-1 text-gray-500">
                            
                            { props.sendingStatus ?
                                props.sendingStatus === 'loading' ?
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-circle.svg`}
                                        className="w-4 h-4 animate-spin"
                                    />
                                    :
                                    props.sendingStatus === 'error' ?
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                                        className="w-4 h-4 text-rose-500"
                                    />
                                    :
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/unseen.svg`}
                                        className="w-4 h-4 ml-1"
                                    />
                                :
                                ''
                            }

                            { props.seen ?

                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/seen.svg`}
                                    className="w-4 h-4"
                                />
                                : !props.sendingStatus &&
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/unseen.svg`}
                                        className="w-4 h-4 ml-1"
                                    />
                            }

                        </div>
        
                    </div>

                    { showMoreOption && 
                        
                        <DropdownModal
                            onSetShow={closeShowMoreOption}
                            classList={'border-gray-300 xm:!w-48 !border-1 !right-3 !top-0'}
                        >

                            
                            { props.sendingStatus && props.sendingStatus === 'error' &&
                            
                                <ResendFileInChat 
                                    item={props.item}
                                    onSetShowMoreOption={setShowMoreOption}
                                />
                                
                            }

                            <Link to={props.src} target="_blank" download={true} className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/save.svg`} className="5xm:w-4 w-3.5 text-gray-500" />

                                ذخیره در گالری

                            </Link>

                            { props.sendingStatus !== 'loading' && 
                                
                                <button onClick={handleShowMessage} className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                                    
                                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-3.svg`} className="5xm:w-[18px] w-3.5 text-gray-500" />

                                    حذف

                                </button>
                            }

                        </DropdownModal>

                    }

                    { showDeleteModal &&
                        
                        <ConfirmModalDelete
                            title="حذف پیام"
                            description="آیا از حذف این پیام اطمینان دارید؟"
                            show={showDeleteModal}
                            onSetShow={setShowDeleteModal}
                            onConfirm={deleteMessage}
                            selectCount={1}
                        >   

                            <div className="flex justify-center text-gray-600">
                            
                                <input type="checkbox" id="deleteBothInput" className="accent-primary rounded-lg w-5 cursor-pointer" onChange={() => setDeleteBoth(!deleteBoth) } checked={deleteBoth} />

                                <label htmlFor="deleteBothInput" className="select-none cursor-pointer pr-2.5">حذف همچنین برای {props.name}</label>

                            </div>

                        </ConfirmModalDelete>

                    }

                </li>

                {showBigImage && 
                    
                    <ModalImage 
                        show={showBigImage}
                        showing={showingBigImage}
                        onSetShow={setShowBigImage}
                        onSetShowing={setShowingBigImage}
                        name={props.name}
                        time={props.time}
                        src={props.src}
                        id={props.id}
                    />
                    
                }
            </>
        )

    return (
        <>
            <li 
                className="flex flex-col gap-1.5 select-none"
                onClick={handleShowMoreOption}
                onContextMenu={handleShowMoreOption}
            >

                <div className="flex flex-col gap-1.5 w-fit relative lg:max-w-md md:max-w-sm max-w-xs mr-auto">

                    <div className="w-44 h-44 cursor-pointer" onClick={showImageInBigSize}>
                        <img className="w-full shadow-custom-md bg-white rounded-3xl rounded-tl-none h-full object-cover" src={props.src} alt="" />
                    </div>

                    <div className="select-none flex justify-between items-center">

                        <span className="text-sm-13px text-gray-500"> {new Date(props.time)?.toLocaleTimeString('fa', {hour: '2-digit', minute: '2-digit', timeZone: '+0330'})} </span>

                    </div>

                    { showMoreOption && 
                            
                        <DropdownModal
                            onSetShow={closeShowMoreOption}
                            classList={'border-gray-300 xm:!w-48 !border-1 !-right-1/3 !top-0'}
                        >

                            <Link to={props.src} target="_blank" download={true} className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/save.svg`} className="5xm:w-4 w-3.5 text-gray-500" />

                                ذخیره در گالری

                            </Link>


                        </DropdownModal>

                    }

                </div>

            </li>

            {showBigImage && 
                    
                <ModalImage 
                    show={showBigImage}
                    showing={showingBigImage}
                    onSetShow={setShowBigImage}
                    onSetShowing={setShowingBigImage}
                    name={props.name}
                    time={props.time}
                    src={props.src}
                    id={props.id}
                />
                
            }

            { showDeleteModal &&
                        
                <ConfirmModalDelete
                    title="حذف پیام"
                    description="آیا از حذف این پیام اطمینان دارید؟"
                    show={showDeleteModal}
                    onSetShow={setShowDeleteModal}
                    onConfirm={deleteMessage}
                    selectCount={1}
                >   

                    <div className="flex justify-center text-gray-600">
                    
                        <input type="checkbox" id="deleteBothInput" className="accent-primary rounded-lg w-5 cursor-pointer" onChange={() => setDeleteBoth(!deleteBoth) } checked={deleteBoth} />

                        <label htmlFor="deleteBothInput" className="select-none cursor-pointer pr-2.5">حذف همچنین برای {props.name}</label>

                    </div>

                </ConfirmModalDelete>

            }
        </>
    )

}

export default MessageContentImage