import { combineReducers } from "redux"

import authReducer from "./auth/authReducer"
import profileReducer from "./profile/profileReducer"
import menuReducer from "./menu/menuReducer"
import locationReducer from "./location/locationReducer"
import searchReducer from "./search/searchReducer"
import contactsReducer from "./contacts/contactsReducer"
import messagesReducer from "./messages/messagesReudcer"
import notesReducer from "./notes/notesReducer"
import homeReducer from "./home/homeReducer"
import eventsReducer from "./events/eventsReducer"
import baseDataReducer from "./baseData/baseDataReducer"
import casesReducer from "./cases/casesReducer"
import addCaseReducer from "./cases/addCaseReducer"
import editCaseReducer from "./cases/editCase"
import walletReducer from "./wallet/walletReducer"
import plansReducer from "./plans/plansReducer"
import casesFinancialsReducer from "./cases/financials"

const rootReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    menu: menuReducer,
    location: locationReducer,
    search: searchReducer,
    contacts: contactsReducer,
    messages: messagesReducer,
    notes: notesReducer,
    home: homeReducer,
    events: eventsReducer,
    baseData: baseDataReducer,
    cases: casesReducer,
    addCase: addCaseReducer,
    editCase: editCaseReducer,
    finanicalCase: casesFinancialsReducer,
    wallet: walletReducer,
    plans: plansReducer
})

export default rootReducer