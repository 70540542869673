import { useRef } from "react"
import { ReactSVG } from "react-svg"

const SelectClientTypeContentItem = ({type, activeType, onSetType, icon, title, color}) => {

    const handleActiveItem = () => {
        onSetType(type)
    }

    return (
        <div onClick={handleActiveItem} className={`flex items-center justify-between bg-white p-4 w-full shadow-custom-lg rounded-lg cursor-pointer duration-300 border-2 ${activeType === type ? 'border-[#26a256] shadow-[#26a256]/[0.15]' : 'border-gray-200'}`}>

            <div className="flex items-center flex-wrap gap-3">

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/${icon}.svg`} className={`w-8 ${color === 'purple' ? 'text-primary' : color === 'orange' ? 'text-orange-500' : 'text-gray-500'}`} />

                <h2 className={`font-medium ${activeType === type ? '' : 'text-gray-600'} duration-300 text-lg`}> {title} </h2>

            </div>

            <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`} className={`w-7 text-[#26a256] ${type === activeType ? 'opacity-100 duration-300' : 'opacity-0'}`} />
            
        </div>
    )

}

export default SelectClientTypeContentItem