import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { getSingleCaseData } from "../../../../../../../../redux/action/cases/casesAction"
import { useParams } from "react-router-dom"
import { getProfileColor } from "../../../../../../../../utils/messages"

const CaseAddFinanicalClientList = (props) => {

    const dispatch = useDispatch()
    const singlesCase = useSelector(state => state.cases.singlesCase)
    const singleLoading = useSelector(state => state.cases.singleCaseLoading)

    const [items, setItems] = useState([])

    const params = useParams()
    const [cookies] = useCookies(['authToken'])

    useEffect(() => {
        
        if(params.id && !singlesCase[params.id] && !singleLoading)
            getInfoSingleCase()
        
        else if(params.id && singlesCase[params.id]) 
            setItems(singlesCase[params.id]?.clients)

    }, [singlesCase])

    const getInfoSingleCase = async () => {

        const data = await dispatch( await getSingleCaseData(params.id, cookies.authToken) )
        
        if(data)
            setItems( data?.clients )

    }

    const selectedClient = (value) => {
        // update method
        props.onSelectedClient(value)
    }



    const getColor = (item) => {
        
        const color = getProfileColor(item.mobile.slice(10))
        
        return color
    }

    if(singleLoading){
        return (
            <div className="flex items-center justify-center h-20">  
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 text-gray-500" />
            </div>
        )
    }

    return (
        <div className="mt-4">
            
            <ul className="mt-4">

                {items?.map((item) => 
                    <li
                        key={item.id}
                        className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                        onClick={() => selectedClient(item) }
                    >
                        <div className="flex items-center gap-4 flex-wrap">

                            <div className={`flex-shrink-0 w-8 h-8 ${ item.mobile && !item.image ? `profile-${getColor(item)}` : 'bg-slate-200' }  border-2 border-slate-200 shadow-lg rounded-xl`}>
                                { item.image ?
                                    <img className="rounded-xl w-full h-full" src={item.image} alt={item.name} />
                                    :
                                    item.name &&
                                        <span className="font-medium text-sm text-center flex items-center justify-center h-full w-full">
                                            {item.name[0]}
                                        </span> 
                                }
                            </div>

                            <span>
                                {item.name}
                            </span>

                        </div>

                        <span className="text-sm text-primary"> انتخاب </span>

                    </li>    
                )}

            </ul>

        </div>
    )

}

export default CaseAddFinanicalClientList