const AuthLogo = () => {

    return (
        <div className="absolute -top-1/4 left-0 backdrop-blur-xl right-0 w-10/12 mx-auto bg-primary flex items-center justify-center max-w-md rounded-3xl shadow-custom-sm p-4 mb-8">

            <div className="flex flex-col items-center justify-center gap-3">

                <img className="w-20" src={`${process.env.PUBLIC_URL}/assets/images/logo/logo.png`} alt="تصویر لوگوی وکیل سان" />

                <h2 className="bg-clip-text bg-gradient-to-r from-white to-third text-transparent font-medium text-2xl"> وکیل‌سان </h2>

            </div>

        </div>
    )

}

export default AuthLogo