import { Link } from "react-router-dom"
import { getProfileColor } from "../../../../utils/messages"
import { ReactSVG } from "react-svg"

import ConatctShareDropdown from "../../../UI/ShareDropdown"
import { useState } from "react"
import { showErrorMessage, showSuccessMessage } from "../../../../utils/toast"

const MessageContactItem = (props) => {

    const [showShareDropdown, setShowShareDropdown] = useState(false)

    const showShareBox = () => {

        if(!navigator.share){
            // show share dropdown
            setShowShareDropdown(true)
            document.body.classList.add('modal-opened')
        }
        else {
            navigator.share({
                title: 'اشتراک گذاری برنامه با',
                url: 'www.vakilsun.com',
            }).then(() => {
                showSuccessMessage('تشکر بابت اشتراک گذاری')
            }).catch(error => {
                showErrorMessage('متاسفانه این قابلیت کار نمیکند')
            })
        }
    }

    // ==== Render ===

    const handleNoImage = () => {

        const color = getProfileColor(props.mobile[10])

        return (
            <div className={`flex-shrink-0 w-12 h-12 profile-${color} border-[2px] shadow-lg rounded-xl flex items-center justify-center`}>
                <span>{props.name[0]}</span>
            </div> 
        )
    }

    return (
        <li className="flex w-full 5xm:items-center md:hover:shadow-custom-xl duration-500 bg-white 4xm:py-0 py-4 p-4 shadow-custom-sm rounded-xl 4xm:flex-nowrap justify-between 5xm:flex-wrap 5xm:flex-row flex-col gap-4 relative"> 
            
            <Link to={props.isUser && `/messages/${props.id}/${props.mobile}`} className="flex items-center gap-4 4xm:w-full 4xm:h-full 4xm:py-4">
                
                { props.image ? 
                    <div className="flex-shrink-0 w-12 h-12 bg-slate-200 border-2 border-slate-200 shadow-lg rounded-xl flex items-center justify-center">
                        <img className="rounded-xl" src={props.image} alt={props.name} /> 
                    </div>
                    :
                    handleNoImage()               
                }
                <div className="flex flex-col gap-1">
                    <h3 className="text-gray-700"> {props.name} </h3>
                    <span className="text-xs text-gray-500 flex gap-1.5 mr-auto" dir="ltr">
                        {props.mobile.slice(0,4)} {props.mobile.slice(4,7)} {props.mobile.slice(7)}  
                    </span>
                </div>

            </Link>

            <div className="flex mr-auto items-center flex-wrap gap-4 flex-shrink-0">

                <div className="flex items-center gap-1">

                    { !props.isUser &&
                        <button onClick={showShareBox} className="flex items-center justify-center text-primary duration-300 gap-1.5">
                            دعوت
                            <ReactSVG
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/share.svg`}
                                className="w-4"
                            />
                        </button> 
                    }

                </div>

            </div> 

            { !props.isUser && !navigator.share && showShareDropdown &&
                <ConatctShareDropdown 
                    name={props.name}
                    show={showShareDropdown}
                    setShow={setShowShareDropdown}
                />
            }

        </li>
    )

}

export default MessageContactItem