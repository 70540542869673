import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { getContactsList } from "../../../../../../redux/action/contacts/contactsAction"
import { ReactSVG } from "react-svg"
import { useEffect, useState } from "react"
import { SET_CONTACTS } from "../../../../../../redux/action/contacts/typeContacts"
import AddCaseContactsModalListItem from "./Item"
import { SET_ALL_CONTACTS_ADD_CASE } from "../../../../../../redux/action/cases/add/type"
import { SET_ALL_CONTACTS_EDIT_CASE, SET_DELETED_INITIAL_CONTACTS_IN_EDIT_CASE } from "../../../../../../redux/action/cases/edit/type"

const AddCaseContactsModalList = (props) => {

    const dispatch = useDispatch()
    const getedContacts = useSelector(state => state.contacts.getedContacts)
    const contacts = useSelector(state => state.contacts.contacts)
    const error = useSelector(state => state.contacts.error)
    const loading = useSelector(state => state.contacts.loading)
    const filtered = useSelector(state => state.contacts.filtered)
    const uniqContacts =  useSelector(state => state.addCase.uniqContacts)
    const uniqContactsEdit = useSelector(state => state.editCase.uniqContacts)
    const editContacts = useSelector(state => state.editCase.contacts)
    const editClients = useSelector(state => state.editCase.clients)

    const [cookies] = useCookies(['authToken'])
    
    useEffect(() => {

        if(!getedContacts && !loading)
            getContacts()

        else if(getedContacts && uniqContacts.length === 0 && !props.edit){
            
            dispatch({
                type: SET_ALL_CONTACTS_ADD_CASE,
                payload: contacts
            })

        }
        else if( getedContacts && (uniqContactsEdit.length === 0 || uniqContactsEdit[0] === undefined) && props.edit){

            const deletedList = []

            const list = contacts.filter(contact => {

                let show = true

                editClients.map(item => {
                    
                    if(item.id === contact.id){
                        deletedList.push(item)
                        show = false
                    }
                })

                editContacts.map(item => {
                    if(item.id === contact.id){
                        deletedList.push(item)
                        show = false
                    } 
                })

                if(show)
                    return contact
            })

            dispatch({
                type: SET_ALL_CONTACTS_EDIT_CASE,
                payload: list
            })
            
            dispatch({
                type: SET_DELETED_INITIAL_CONTACTS_IN_EDIT_CASE,
                payload: deletedList
            })

        }

    }, [])

    const getContacts = async () => {
        const data = await dispatch( await getContactsList(cookies.authToken) )
        
        if(data){
            sortContactsByBaseIsUser(data)
        }
    }

    const sortContactsByBaseIsUser = (contacts) => {
        
        contacts.sort((a, b) => {
            if (a.user && !b.user) {
                return -1; // a قبلی از b قرار می‌گیرد
            } else if (!a.user && b.user) {
                return 1; // b قبلی از a قرار می‌گیرد
            } else {
                return 0; // ترتیب مانند قبلی باقی می‌ماند
            }
        })

        dispatch({
            type: SET_CONTACTS,
            payload: [...contacts]
        })

        if(!props.edit){
            
            dispatch({
                type: SET_ALL_CONTACTS_ADD_CASE,
                payload: contacts
            })

        }
        else if(props.edit){

            const deletedList = []

            const list = contacts.filter(contact => {

                let show = true

                editClients.map(item => {
                    
                    if(item.id === contact.id){
                        deletedList.push(item)
                        show = false
                    }
                })

                editContacts.map(item => {
                    if(item.id === contact.id){
                        deletedList.push(item)
                        show = false
                    } 
                })

                if(show)
                    return contact
            })

            dispatch({
                type: SET_ALL_CONTACTS_EDIT_CASE,
                payload: list
            })
            

            dispatch({
                type: SET_DELETED_INITIAL_CONTACTS_IN_EDIT_CASE,
                payload: deletedList
            })
            
        }
        
    } 

    if(loading){
        
        return (
            <section className="flex items-center justify-center h-20 mt-4">
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )

    }

    else if(error){
        return (
            <section className="flex items-center gap-2 mt-4">

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {error} </p>

            </section>
        )
    }

    else if( filtered.searchedValue ){
        return (
            <section className="mt-4">

                { filtered.list.length <= 0 && <p className="text-gray-600 flex items-center"> هیچ مخاطبی یافت نشد </p> }

                <ul className="grid grid-cols-1 gap-4">

                    {filtered.list.map(item => 

                        <AddCaseContactsModalListItem 
                            key={item?.id}
                            id={item?.id}
                            item={item}
                            name={item?.name}
                            image={item?.user?.image}
                            mobile={item?.mobile}
                            location={props?.location}
                            edit={props?.edit}
                        />

                    )}

                </ul>

            </section>
        )
    }

    return (
        <section className="mt-4">

            { contacts.length <= 0 || (uniqContacts.length <= 0 && !props.edit) || (uniqContactsEdit.length <= 0 && props.edit) ? 

                <div className="flex items-center gap-2">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/empty.svg`}
                        className="w-4 text-gray-700"
                    />

                    <span> هنوز مخاطبی ندارید </span>

                </div>

                :
                
                !props.edit && uniqContacts && uniqContacts.length >= 1 && uniqContacts[0] && uniqContacts[0] !== 'undefined' ?
                    <ul className="grid grid-cols-1 gap-5">
                        
                        { uniqContacts.map((item, index) => 
                        
                            <AddCaseContactsModalListItem 
                                key={item.id}
                                id={item?.id}
                                item={item}
                                name={item?.name}
                                image={item?.user?.image}
                                mobile={item?.mobile}
                                location={props?.location}
                                edit={props.edit}
                            />

                        )}

                    </ul>
                :
                
                props.edit && uniqContactsEdit && uniqContactsEdit.length >= 1 && uniqContactsEdit[0] && uniqContactsEdit[0] !== 'undefined' &&
                    <ul className="grid grid-cols-1 gap-5">
                        
                        { uniqContactsEdit.map((item, index) => 
                        
                            <AddCaseContactsModalListItem 
                                key={item?.id}
                                id={item?.id}
                                item={item}
                                name={item?.name}
                                image={item?.user?.image}
                                mobile={item?.mobile}
                                location={props?.location}
                                edit={props?.edit}
                            />

                        )}

                    </ul>
            }


        </section>
    )

}

export default AddCaseContactsModalList