import { useDispatch, useSelector } from "react-redux"

import Input from "../../../UI/Input"
import InputGroup from "../../../UI/InputGroup"
import Textarea from "../../../UI/Textarea"
import SelectCityUser from "../../../UI/SelectCity"
import ContactSelectPosition from "../../SelectPosition"
import { RESET_LOCATION_CONTACTS, SET_CONTACT_INFO, SET_CONTACT_INFO_IN_UPDATE, SET_ENABLE_SUBMIT_BUTTON, SET_ITEM_CONTACT_INFO_IN_UPDATE, SET_LOCATION_FOR_CONTACT, SET_MAIN_FIELDS_CONTACT, SET_MORE_INFO_FIELDS_CONTACT, SET_POSITION_CONTACT } from "../../../../redux/action/contacts/typeContacts"
import { validateField } from "../../../../utils/form"
import { useEffect, useState } from "react"

const ContactEditForm = () => {

    const dispatch = useDispatch()
    const loading = useSelector(state => state.contacts.loading)
    const mainFields = useSelector(state => state.contacts.mainFields)
    const moreInfoFields = useSelector(state => state.contacts.moreInfoFields)
    const position = useSelector(state => state.contacts.position)
    const location = useSelector(state => state.contacts.location)
    const contactInfo = useSelector(state => state.contacts.contactInfo)
    const enableSubmitButton = useSelector(state => state.contacts.enableSubmitButton)

    const [invalids, setInvalids] = useState({})
    const [updated, setUpdated] = useState(false)

    useEffect(() => {

        window.scrollTo({top: 0})
        
        return () => {
            resetFieldsData()
        }

    }, [])

    useEffect(() => {

        // if exist content in contactInfo come intial
        if(contactInfo.name){
            // set values
            setInitialFieldValue()
        }

    }, [contactInfo])

    useEffect(() => {

        const timeout = setTimeout(() => {

            if(contactInfo.name) 
                setUpdateStatus()

        }, 500);

        return () => {
            if(timeout)
                clearTimeout(timeout)
        }

    }, [mainFields, moreInfoFields, position, location])

    useEffect(() => {

        if(updated && !enableSubmitButton){
            dispatch({
                type: SET_ENABLE_SUBMIT_BUTTON,
                payload: true
            })
        }
        
        else if(!updated && enableSubmitButton){
            dispatch({
                type: SET_ENABLE_SUBMIT_BUTTON,
                payload: false
            })
        }

    }, [updated])

    useEffect(() => {

        if(loading)
            resetFieldsData()

    }, [loading])

    const updateMainField = (event) => {
        // check can update or no
        const value = event.target.value
        if(mainFields[event.target.id].max && value.length > mainFields[event.target.id].max) return

        updateField(mainFields, event)

        // save value 
        dispatch({
            type: SET_MAIN_FIELDS_CONTACT,
            payload: mainFields
        })

    }  
    
    const updateMoreInfoField = (event) => {
        // check can update or no
        const value = event.target.value

        if(event.target.id === 'address' && event.target.value.length > 300) return

        if(moreInfoFields[event.target.id].max && value.length > moreInfoFields[event.target.id].max) return

        updateField(moreInfoFields, event)

        // save value 
        dispatch({
            type: SET_MORE_INFO_FIELDS_CONTACT,
            payload: moreInfoFields
        })
    }

    const updateField = (fields, event) => {
        const value = event.target.value

        if(isNaN(event.target.value) && fields[event.target.id].type === 'tel') return

         // update field value
        fields[event.target.id].value = value
 
         // check exist invalid value or no
        const valid = validateField(fields[event.target.id])
        fields[event.target.id].isValid = valid
        
        setInfoIsValid(event.target.id, valid)
    }

    const setInfoIsValid = (id, valid) => {

        // if be valid and not exist invalid
        if(valid && !invalids[id])
            return true

        // if field is invalid come add to invalids data 
        if(!valid && !invalids[id]){
            setInvalids({
                ...invalids,
                [id]: true
            })

            return false
        }

        // come remove of invalid data when field is valid
        if(valid && invalids[id]){
            const newInvalids = { ...invalids }
            delete newInvalids[id]

            setInvalids(newInvalids)

            return true
        }

    }

    const canUpdateOrNo = () => {
        // check validate
        const invalid = Object.keys(invalids).length
        
        // have error and dont access to update
        if(invalid >= 1) return false

        // its correct
        if(invalids === 0 && updated) return true

        // i dont know is update or no
        // so i should test exist any change with before info
        for(const item in contactInfo){

            if( contactInfo[item] && (mainFields[item] || moreInfoFields[item]) && contactInfo[item] !== mainFields[item]?.value && moreInfoFields[item]?.value !== contactInfo[item] ){
                return true
            }
            else if(!contactInfo[item] && (mainFields[item]?.value || moreInfoFields[item]?.value ) ){
                return true
            }

        }

        // check position changed or no
        if(position !== '' && ( position !== contactInfo?.position || !contactInfo.position) ){
            console.log('slam');
            return true
        }

        // check location changed or no
        if( ( location?.city?.id && ( location?.city?.id !== contactInfo?.city?.id || !contactInfo?.city?.id) ) || (contactInfo?.city?.id && location?.city?.id === '') ){
            return true
        }

        return false
    }

    const setUpdateStatus = () => {
        // come disable submit button
        if(!mainFields.name.value || !mainFields.mobile.value){
            setUpdated(false)

            return
        } 
        const status = canUpdateOrNo()

        if(status){
            // set info to the conatctInfoUpdate
            saveUpdatedInfoInContactInfo()
        }

        setUpdated(status)
    }

    const setInitialFieldValue = () => {

        Object.keys(mainFields).map(item => {
            if(contactInfo[item])
                mainFields[item].value = contactInfo[item]
        })

        Object.keys(moreInfoFields).map(item => {
            if(contactInfo[item])
                moreInfoFields[item].value = contactInfo[item]
        })

        // set location
        if(contactInfo?.city?.id){

            const location = {
                city: contactInfo?.city,
                province: contactInfo?.province?.id
            }

            dispatch({
                type: SET_LOCATION_FOR_CONTACT,
                payload: location
            })
        }

        // set position
        if(contactInfo.position){
            dispatch({
                type: SET_POSITION_CONTACT,
                payload: contactInfo.position
            })   
        }

        // set fields
        dispatch({
            type: SET_MAIN_FIELDS_CONTACT,
            payload: mainFields
        })

        dispatch({
            type: SET_MORE_INFO_FIELDS_CONTACT,
            payload: moreInfoFields
        })

    }

    const saveUpdatedInfoInContactInfo = () => {
        const info = {}

        Object.keys(mainFields).map(item => {
            info[item] = mainFields[item].value
        })

        Object.keys(moreInfoFields).map(item => {
            info[item] = moreInfoFields[item].value
        })

        info.position = position
        info.location = location
                
        dispatch({
            type: SET_CONTACT_INFO_IN_UPDATE,
            payload: info
        })
    }

    const resetFieldsData = () => {
        // reset fields
        Object.keys(mainFields).map(item => {
            mainFields[item].value = ''
            mainFields[item].isValid = true
        })

        Object.keys(moreInfoFields).map(item => {
            moreInfoFields[item].value = ''
            moreInfoFields[item].isValid = true
        })

        dispatch({
            type: RESET_LOCATION_CONTACTS
        })

        dispatch({
            type: SET_POSITION_CONTACT,
            payload: ''
        })

        dispatch({
            type: SET_MAIN_FIELDS_CONTACT,
            payload: mainFields
        })

        dispatch({
            type: SET_MORE_INFO_FIELDS_CONTACT,
            payload: moreInfoFields
        })

    }

    // for location and position
    const setLocationForContact = (location) => {

        dispatch({
            type: SET_LOCATION_FOR_CONTACT,
            payload: location
        })
    }

    const resetLocationData = () => {

        dispatch({
            type: RESET_LOCATION_CONTACTS
        })
    }

    const setPosition = (position) => {
        
        dispatch({
            type: SET_POSITION_CONTACT,
            payload: position
        })

    }

    return (
        <form className="rounded-2xl shadow-custom-lg border-[3px] border-white p-4 flex flex-col gap-5 relative">
            
            <InputGroup className="xm:!grid-cols-2">

                <Input
                    id={'name'}
                    label={mainFields.name.label}
                    max={mainFields.name.max}
                    icon={mainFields.name.icon}
                    value={mainFields.name.value}
                    isValid={mainFields.name.isValid}
                    onChange={ updateMainField }
                    loading={loading}
                    deactive={loading}
                />

                <Input
                    id={'mobile'}
                    label={mainFields.mobile.label}
                    max={mainFields.mobile.max}
                    icon={mainFields.mobile.icon}
                    value={mainFields.mobile.value}
                    type={mainFields.mobile.type}
                    isValid={mainFields.mobile.isValid}
                    onChange={ updateMainField }
                    loading={loading}
                    deactive={loading}
                    className={'text-right'}
                />

            </InputGroup>

            <hr className='my-3.5' />

            <Textarea 
                id={'description'}
                label={mainFields.description.label}
                max={mainFields.description.max}
                icon={mainFields.description.icon}
                value={mainFields.description.value}
                isValid={mainFields.description.isValid}
                rows="3"
                onChange={ updateMainField }
                loading={loading}
                deactive={loading}
            />

            <hr className='my-3.5' />

            <div className="flex flex-col gap-7">

                <InputGroup>

                    <Input
                        id={'phone'}
                        label={moreInfoFields.phone.label}
                        max={moreInfoFields.phone.max}
                        icon={moreInfoFields.phone.icon}
                        value={moreInfoFields.phone.value}
                        isValid={moreInfoFields.phone.isValid}
                        type={moreInfoFields.phone.type}
                        onChange={ updateMoreInfoField }
                        loading={loading}
                        deactive={loading}
                        className={'text-right'}
                    />

                    <SelectCityUser 
                        labelSize="sm"
                        location={location}
                        onUpdateLocation={setLocationForContact}
                        onCancleChoose={resetLocationData}
                        loadingMain={loading}
                    />

                </InputGroup>

                <InputGroup>

                    <Input
                        id={'email'}
                        label={moreInfoFields.email.label}
                        icon={moreInfoFields.email.icon}
                        value={moreInfoFields.email.value}
                        isValid={moreInfoFields.email.isValid}
                        onChange={ updateMoreInfoField }
                        loading={loading}
                        deactive={loading}
                    />

                </InputGroup>

                <Textarea 
                    id={'address'}
                    label={moreInfoFields.address.label}
                    icon={moreInfoFields.address.icon}
                    value={moreInfoFields.address.value}
                    isValid={moreInfoFields.address.isValid}
                    onChange={ updateMoreInfoField }
                    loading={loading}
                    deactive={loading}
                    max={'300'}
                />

            </div>

        </form>
    )

}

export default ContactEditForm