import { memo, useEffect, useState } from "react"
import { connect } from "react-redux"

import { addProfileFields, deleteProfileFields } from "../../../redux/action/profile/profileAction"
import { SET_UPDATE_TEXT_PROFILE, SET_USER_LOCATION, UPDATE_FIELD_INFO_PROFILE } from "../../../redux/action/profile/profileType"

import { validateField } from "../../../utils/form"

import Input from "../../UI/Input"
import InputGroup from "../../UI/InputGroup"
import ToggleSwitch from "../../UI/ToggleSwitch"
import SelectCity from "../SelectCity"

const ProfileForma = ({ loading, addFieldsToForm, deleteFieldsFromForm, fields, updateFieldInfo, setUpdatedText, profile, userLocation, updatedText, setNewCityToProfile }) => { 
    
    const [fieldsData, setFieldsData] = useState({
        first_name: {
            value: '',
            max: 40
        },
        last_name: {
            value: '',
            max: 40
        },
        national_code: {
            value: '',
            max: 10
        },
        mobile: {
            value: '',
            max: 11
        },
        email: {
            value: '',
        },
        gender: {
            value: '',
        }
    })
    const [invalids, setInValids] = useState({})
    const [letToUpdate, setLetToUpdate] = useState(false)

    useEffect(() => {

        // set fields
        addFieldsToForm({
            'first_name': {
                value: '',
                max: 40,
                label: 'نام',
                validate: {
                    min: 3,
                    required: true
                },
                isValid: true,
                icon: 'user'
            },
            'last_name': {
                value: '',
                max: 40,
                label: 'نام خانوادگی',
                validate: {
                    min: 3,
                    required: true
                },
                isValid: true,
                icon: 'user'
            },
            'national_code': {
                value: '',
                max: 10,
                label: 'کد ملی',
                type: 'tel',
                validate: {
                    length: 10,
                    required: false
                },
                isValid: true
            },
            'mobile': {
                value: '',
                max: 11,
                type: 'tel',
                label: 'موبایل',
                validate: {
                    length: 11,
                    required: true
                },
                isValid: true,
                deactive: true,
                icon: 'mobile'
            },
            'email': {
                value: '',
                label: 'ایمیل',
                validate: {
                    email: true,
                    required: false
                },
                icon: 'email',
                cancle: true,
                isValid: true
            },
            'gender': {
                value: 'male'
            }
        })

        return () => {
            deleteFieldsFromForm()
        }

    }, [])

    useEffect(() => {

        let checkCanUpdated = null;

        // when start the change field - this let is published
        if(letToUpdate){
            checkCanUpdated = setTimeout(() => {
    
                // check can update or no
                let updated = false
                
                if(Object.keys(invalids).length === 0){
                    updated = checkUpdatedFileds()
                }   

                // if can update come set fields
                if(updated){
                    updateFieldInfo(fields, updated)
                }
                // and if can not update come set only we can not update
                else {
                    setUpdatedText(updated)
                }
    
            }, 400)
        }

        return () => {
            if(checkCanUpdated)
                clearTimeout(checkCanUpdated)
        }

    }, [fieldsData])

    useEffect(() => {

        setProfileDataToFields()

    }, [profile, fields])

    useEffect(() => {

        if(profile){
            checkLocationIsUpdatedOrNo()
        }

    }, [userLocation])

    const updateField = (event) => {

        const field = { ...fieldsData[event.target.id] }

        if( (event.target.id === 'national_code' ||  event.target.id === 'mobile') && isNaN(event.target.value) ) return

        if(!field.max || event.target.value.length <= field.max){
            field.value = event.target.value

            setFieldsData({
                ...fieldsData,
                [event.target.id]: {
                    value: field.value,
                    max: field.max ? field.max : null
                }
            })
            
            // check exist invalid value or no
            checkHaveInvalidField(event.target.id, event.target.value)

            fields[event.target.id].value = event.target.value

            setLetToUpdate(true)
        }

    }

    const checkHaveInvalidField = (id, value) => {
        
        // check isValid or no
        const validState = validateField({
            validate: fields[id].validate,
            value
        })

        // be valid and not invalid dont do any work
        if(validState && !invalids[id])
            return


        // else --> do check up and set new status
        if(!validState && !invalids[id]){
            setInValids({
                ...invalids,
                [id]: true
            })

            return
        }

        else if(invalids[id] && validState){
            const newInvalids = { ...invalids }
            delete newInvalids[id]

            setInValids({...newInvalids})

            return
        }

    }

    const checkUpdatedFileds = () => {

        const fieldsList = Object.keys(fieldsData)

        for(const item of fieldsList){
            
            if( ( profile[item] && (profile[item] !== fieldsData[item].value) ) || (!profile[item] && fieldsData[item].value !== '') )
                return true
        }

        if(userLocation.city && userLocation.city.id !== profile.city.id){
            return true
        }

        return false
    }

    const setProfileDataToFields = () => {
        
        // if exist fields come do
        if(profile.first_name && fields.first_name){

            const newFieldsData = { ...fieldsData }
 
            // come add value to fields
            Object.keys(fieldsData).map(item => {
                newFieldsData[item].value = profile[item] ? profile[item] : ''
                fields[item].value = newFieldsData[item].value
            })

            setFieldsData(newFieldsData)

        }

    }

    const updateGenderField = (event) => {
        // get gender field value
        const value = event.target.id === 'female-field' ? 'female' : 'male'

        // set value
        const field = { ...fieldsData['gender'] }
        
        field.value = value
        fields['gender'].value = value

        setFieldsData({
            ...fieldsData,
            gender: field
        })

        // let to fields for updating. ---> enable update ability
        setLetToUpdate(true)
    }

    const checkLocationIsUpdatedOrNo = () => {
        
        const invalidLength = Object.keys(invalids).length

        if(invalidLength <= 0 && userLocation?.city && userLocation?.city?.id !== profile?.city?.id){
            setUpdatedText(true)
        }
        else if(invalidLength >= 1) {
            setUpdatedText(false)
        }
        else{
            
            let status = false

            if(profile && fieldsData && updatedText){
                status = checkUpdatedFileds()
            }

            setUpdatedText(status)
            
        }

    }

    // ---------render---------
    if(!fields.first_name) return (<></>)

    const fieldsList = Object.keys(fieldsData)

    return (
        <form className="flex flex-col gap-8">

            <InputGroup className="mt-10">

                {fieldsList.slice(0, 2).map(item => 
                    <Input
                        key={item}
                        label={fields[item].label}
                        id={item}
                        max={fields[item].max}
                        value={fieldsData[item].value}
                        deactive={fields[item].deactive}
                        type={fields[item].type}
                        isValid={!invalids[item]}
                        icon={fields[item].icon}
                        labelSize='base'
                        loading={loading}
                        cancle={fields[item].cancle}
                        onChange={updateField}
                        className={'text-right'}
                    />
                )}

            </InputGroup>
                
            <InputGroup>

                {fieldsList.slice(2, 4).map(item => 
                    <Input
                        key={item}
                        label={fields[item].label}
                        id={item}
                        max={fields[item].max}
                        value={fieldsData[item].value}
                        deactive={fields[item].deactive}
                        type={fields[item].type}
                        isValid={!invalids[item]}
                        icon={fields[item].icon}
                        labelSize='base'
                        loading={loading}
                        onChange={updateField}
                        className={'text-right'}
                    />
                )}

            </InputGroup>
            
            <InputGroup>
                
                <Input
                    key={fieldsList[4]}
                    label={fields[fieldsList[4]].label}
                    id={fieldsList[4]}
                    max={fields[fieldsList[4]].max}
                    value={fieldsData[fieldsList[4]].value}
                    deactive={fields[fieldsList[4]].deactive}
                    type={fields[fieldsList[4]].type}
                    isValid={!invalids[fieldsList[4]]}
                    icon={fields[fieldsList[4]].icon}
                    labelSize='base'
                    loading={loading}
                    onChange={updateField}
                    className={'text-right pl-4'}
                />

                <SelectCity 
                    loading={loading}
                    onUpdateLocation={setNewCityToProfile}
                />

            </InputGroup>

            <ToggleSwitch 
                fieldsId={['male-field', 'female-field']}
                name={'gender'}
                loading={loading}
                label={'جنسیت'}
                value={profile.gender === 'female' ? 1 : 0}
                onChange={updateGenderField}
            />

        </form>
    )

}

const mapStateToProps = (state) => {
    return {
        profile: state.profile.profile,
        loading: state.profile.loading,
        fields: state.profile.fields,
        userLocation: state.profile.userLocation,
        updatedText: state.profile.updatedText
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
        addFieldsToForm: (fields) => {
            dispatch( addProfileFields(fields) )
        },

        deleteFieldsFromForm: () => {
            dispatch( deleteProfileFields() )
        },

        updateFieldInfo: (fields, updated) => {
            
            dispatch({
                type: UPDATE_FIELD_INFO_PROFILE,
                payload: {
                    fields,
                    updated
                }
            })

        },

        setUpdatedText: (status) => {
            dispatch({
                type: SET_UPDATE_TEXT_PROFILE,
                payload: status
            })
        },

        setNewCityToProfile: (location) => {
            
            dispatch({
                type: SET_USER_LOCATION,
                payload: location
            })
    
        }

    }
}

const ProfileFormaMemo = memo(connect(mapStateToProps, mapDispatchToProps)(ProfileForma))

export default ProfileFormaMemo