import Switch from "../../../../../../UI/Switch"

const CaseAddFileToggleShow = ({show, onSetShow, title, description}) => {

    return (
        <>
            <div className="bg-white px-4 py-6 rounded-xl border-gray-300/60 border shadow-custom-md">

                <Switch 
                    name={'showEventToMovakel'}

                    title={ title ? title : 'نمایش فایل در اپلیکیشن موکل' }
                    description={ description ? description : ' فقط به موکل هایی که مجوز مشاهده دارند. ' }

                    value={show}
                    onUpdateSwitchValue={onSetShow}

                    loading={false} 
                />

            </div>
        </>
    )

}

export default CaseAddFileToggleShow