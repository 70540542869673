import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import moment from "jalali-moment"

import { getEventsList, getMoreEventsList } from "../../../redux/action/events/eventsAction"

import DeleteHeader from "../DateItems/DeleteHeader"
import EventDateItem from "../DateItems/Item"
import { useCookies } from "react-cookie"
import { SET_DELETE_ITEM_STATUS_EVENTS, SET_EVENTS_LIST, SET_EVENTS_LIST_SORTED, SET_FAILURE_EVENTS, SET_FILTER_SORT_EVENTS, SET_HAVE_CHANGE_IN_STORAGE } from "../../../redux/action/events/eventsType"
import LoadMoreEventsList from "./LoadMoreBtn"
import ToastCustom from "../../UI/ToastCustom"

import InfiniteScroll from "react-infinite-scroller"

const EventsLayoutList = () => {

    const dispatch = useDispatch()
    const events = useSelector(state => state.events.events)
    const loading = useSelector(state => state.events.loading)
    const error = useSelector(state => state.events.error)
    const loaded = useSelector(state => state.events.loaded)
    const eventsSorted = useSelector(state => state.events.eventsSorted)
    const pagination = useSelector(state => state.events.pagination)
    const filtered = useSelector(state => state.events.filtered)
    const filterSorted = useSelector(state => state.events.filterSorted)
    const filterError = useSelector(state => state.events.filterError)
    const haveAChangeInStorage = useSelector(state => state.events.haveAChangeInStorage)
    const deletedItem = useSelector(state => state.events.deletedItem)
    const loadMoreLoading = useSelector(state => state.events.loadMoreLoading)

    const [selectedItemDelete, setSelectedItemDelete] = useState(null)
    const [loadingMore, setLoadingMore] = useState(false)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(!loaded){
            getEvents()
        }
        else if(haveAChangeInStorage.list){
            getEvents()
        }

    }, [])

    useEffect(() => {

        if(deletedItem) {

            handleSortEvents(events.reverse())

            dispatch({
                type: SET_HAVE_CHANGE_IN_STORAGE,
                payload: {
                    list: false,
                    calendar: haveAChangeInStorage.calendar,
                    eventDate: haveAChangeInStorage.eventDate,
                }
            })

            dispatch({
                type: SET_DELETE_ITEM_STATUS_EVENTS,
                payload: false
            })

            const date = moment().utcOffset('+0330').format('jYYYY-jMM-jDD')

            const sortedData = {
                today: [],
                yesterday: []
            }

            filtered.list.reverse().map(item => {
                const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
                const dateSplit = date.split('-')
                const itemSplit = itemDate.split('-')

                if(itemDate === date){
                    sortedData.today.push(item)
                    return
                }
                else {
                    
                    if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) - 1) === Number(itemSplit[2]) ) ){
                        sortedData.yesterday.push(item)
                        return
                    }
                    
                    else {
                        
                        if(!sortedData[itemDate]){
                            sortedData[itemDate] = []
                        }

                        sortedData[itemDate].push(item)
                    }
                    
                }
            })

            dispatch({
                type: SET_FILTER_SORT_EVENTS,
                payload: sortedData
            })

        }

    }, [deletedItem])

    const getEvents = async () => {

        const data = await dispatch( await getEventsList(cookies.authToken) )

        if(data){
            
            handleSortEvents(data)
            
        }

        dispatch({
            type: SET_HAVE_CHANGE_IN_STORAGE,
            payload: {
                list: false,
                calendar: haveAChangeInStorage.calendar,
                eventDate: haveAChangeInStorage.eventDate,
            }
        })

    }

    const loadMore = async () => {

        if(window.scrollY >= 600){

            if( events && events.length >= 12 && !loading && !loadMoreLoading && !loadingMore && (window.innerHeight + window.scrollY) - document.body.offsetHeight <= 100 ) { 
    
                setLoadingMore(true)
    
                const data = await dispatch( await getMoreEventsList(!filtered.searchedValue ? pagination.current_page : filtered?.pagination?.current_page ? filtered?.pagination?.current_page : 1, filtered.searchedValue ? filtered.searchedValue : '', cookies.authToken) )
                
                if(data){
    
                    setLoadingMore(false)
                    
                    if(!filtered.searchedValue)
                        handleSortLoadMoreEvents(data)
                    else    
                        handleSortSearchedEvents(data)
                }
    
            }

        }

    }
    
    const handleSortEvents = (data) => {

        const date = moment().utcOffset('+0330').format('YYYY-MM-DD')
        const dateNumber = date.split('-').join('')

        const sortedData = {
            today: [],
            tomorrow: [],
            yesterday: [],
        }

        data.map(item => {
            item.starts_at_number = item.starts_at.slice(0, 19)
            item.starts_at_number = item.starts_at.split('-').join('').replace('T', '').split(':').join('')
        })

        data = data.sort((a, b) => b.starts_at_number - a.starts_at_number)

        data.map(item => {
            
            // get english and persian date
            let itemDatePersian = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
            let itemDateEnglish = moment(item.starts_at).utcOffset('+0330').format('YYYY-MM-DD')
            itemDateEnglish = itemDateEnglish.split('-').join('')

            let itemEnglish = item.starts_at_number?.slice(0, 8)

            console.log(String(dateNumber) , String(itemEnglish)  );

            if(String(dateNumber) === String(itemDateEnglish)){
                sortedData.today.push(item)
                return
            }
            else {
                const yearDate = itemDateEnglish.slice(0, 4)
                const monthDate = itemDateEnglish.slice(4, 6)
                const dayDate = itemDateEnglish.slice(6, 8)
                const yearCurrent = itemDateEnglish.slice(0, 4)
                const monthCurrent = itemDateEnglish.slice(4, 6)
                const dayCurrent = itemDateEnglish.slice(6, 8)

                if(yearDate === yearCurrent && monthCurrent === monthDate && ( (Number(dayDate) + 1) === Number(dayCurrent) ) ){
                    sortedData.tomorrow.push(item)
                    return
                }

                else if( yearDate === yearCurrent && monthCurrent === monthDate && ( (Number(dayDate) - 1) === Number(dayCurrent) ) ){
                    sortedData.yesterday.push(item)
                    return
                }
                
                else {
                    
                    if(!sortedData[itemDatePersian]){
                        sortedData[itemDatePersian] = []
                    }

                    sortedData[itemDatePersian].push(item)
                }
                
            }

        })

        dispatch({
            type: SET_EVENTS_LIST_SORTED,
            payload: {
                ...sortedData
            }
        })

    }

    const handleSortLoadMoreEvents = (events) => {

        const date = moment().utcOffset('+0330').format('jYYYY-jMM-jDD')

        events.map(item => {
            const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
            const dateSplit = date.split('-')
            const itemSplit = itemDate.split('-')

            if(itemDate === date){
                eventsSorted.today.push(item)
                return
            }
            else {
                
                if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) + 1) === Number(itemSplit[2]) ) ){
                    eventsSorted.tomorrow.push(item)
                    return
                }

                else if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) - 1) === Number(itemSplit[2]) ) ){
                    eventsSorted.yesterday.push(item)
                    return
                }
                
                else {
                    
                    if(!eventsSorted[itemDate]){
                        eventsSorted[itemDate] = []
                    }

                    eventsSorted[itemDate].push(item)
                }
                
            }
            
        })

        dispatch({
            type: SET_EVENTS_LIST_SORTED,
            payload: {...eventsSorted}
        })
    }

    const handleSortSearchedEvents = (events) => {
        const date = moment().utcOffset('+0330').format('jYYYY-jMM-jDD')

        events.map(item => {
            const itemDate = moment(item.starts_at).utcOffset('+0330').format('jYYYY-jMM-jDD')
            const dateSplit = date.split('-')
            const itemSplit = itemDate.split('-')

            if(itemDate === date){
                filterSorted.today.push(item)
                return
            }
            else {
                
                                
                if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) + 1) === Number(itemSplit[2]) ) ){
                    filterSorted.tomorrow.push(item)
                    return
                }

                else if(dateSplit[0] === itemSplit[0] && dateSplit[1] === itemSplit[1] && ( (Number(dateSplit[2]) - 1) === Number(itemSplit[2]) ) ){
                    filterSorted.yesterday.push(item)
                    return
                }

                else {
                    
                    if(!filterSorted[itemDate]){
                        filterSorted[itemDate] = []
                    }

                    filterSorted[itemDate].push(item)
                }
                
            }
        })

        dispatch({
            type: SET_FILTER_SORT_EVENTS,
            payload: filterSorted
        })
    }

    if(loading){

        return (
            <section className="flex items-center justify-center h-20">
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )

    }

    if((error && events.length <= 0) || filterError) {
        return (
            <section className="flex items-center gap-2">

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {error || filterError} </p>

            </section>
        )
    }
    else if(error) {

        setTimeout(() => {
            
            dispatch({
                type: SET_FAILURE_EVENTS,
                payload: null
            })

        }, 1000);

        return (
            <ToastCustom>
                    
                <div className="flex items-center gap-2">
                    <ReactSVG
                        className="text-rose-500/90 4xm:w-8 w-6"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                    />

                    { error }

                </div>

            </ToastCustom>
        )
    }

    if(filtered?.searchedValue){
        return (
            <>

                { selectedItemDelete &&
                    <DeleteHeader 
                        selectedItemId={selectedItemDelete}
                        onSetSelectedItemDelete={setSelectedItemDelete}
                        events={filtered?.list}
                    />
                }

                {filtered?.list?.length === 0 && 
                    <p className="text-gray-600 flex items-center gap-2"> 
                        هیچ رویدادی یافت نشد
                    </p>
                }

                <div className="min-h-screen"> 
                
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={loadMore}
                        threshold={0}
                        hasMore={filtered.pagination?.has_more_pages}
                        loader={ loadMoreLoading &&
                            <ReactSVG
                                key={0}
                                className="w-10 text-primary mx-auto block mt-10"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            />
                        }
                    >
                        
                        { Object.keys(filterSorted).map(item => 
                        
                            filterSorted[item]?.length >= 1  &&

                                <div key={item} className="border-b pb-8 mb-5 last:!border-b-0">
                                    
                                    <h2 className="text-lg font-medium mb-4"> { item === 'today' ? 'امروز' : item === 'yesterday' ? 'دیروز' : item === 'tomorrow' ? 'فردا' : `${Number(item.split('-')[0]).toLocaleString('fa').replace('٬', '')}/${Number(item.split('-')[1]) <= 9 ? '۰' + Number(item.split('-')[1]).toLocaleString('fa') : Number(item.split('-')[1]).toLocaleString('fa')}/${Number(item.split('-')[2]) <= 9 ? '۰' + Number(item.split('-')[2]).toLocaleString('fa') : Number(item.split('-')[2]).toLocaleString('fa')}` } </h2>

                                    <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                                        { filterSorted[item].map((item, index) => 
                                        
                                            <EventDateItem 
                                                key={item.id}
                                                type={item.type}
                                                title={item.title}
                                                body={item.description}
                                                time={item.starts_at}
                                                case={item.law_suit ? item.law_suit.title : ''}
                                                count={index + 1}
                                                id={`${item.id}`}
                                                item={item}
                                                selectedItemDelete={selectedItemDelete}
                                                onSetSelectedItemDelete={setSelectedItemDelete}
                                                isStarted={item?.is_started}
                                                isPublic={item.is_public}
                                                send_sms={item?.send_sms}
                                            />

                                        )}

                                    </ul>

                                </div>

                        )}

                    </InfiniteScroll>

                </div>

            </>
        )
    }

    return (
        <>

            { selectedItemDelete &&
                <DeleteHeader 
                    selectedItemId={selectedItemDelete}
                    onSetSelectedItemDelete={setSelectedItemDelete}
                    events={events}
                />
            }

            {/*  EMPTY  */}
            {events.length <= 0 &&
                <p className="text-gray-600 text-center h-20 flex items-center justify-center  rounded-2xl shadow-custom-md border-[3px] border-white p-4"> هنوز رویدادی ثبت نشده است </p>
            }

            <div className="min-h-screen"> 
                
                <InfiniteScroll
                    pageStart={1}
                    loadMore={loadMore}
                    hasMore={pagination.has_more_pages}
                    threshold={0}
                    loader={ loadMoreLoading &&
                        <ReactSVG
                            key={0}
                            className="w-10 text-primary mx-auto block mt-10"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                        />
                    }
                >
                    { Object.keys(eventsSorted).map(item => 
                        
                        eventsSorted[item]?.length >= 1  &&

                            <div key={item} className="border-b pb-8 mb-5 last:border-b-0">
                                
                                <h2 className="text-lg font-medium mb-4"> { item === 'today' ? 'امروز' : item === 'yesterday' ? 'دیروز' : item === 'tomorrow' ? 'فردا' : `${Number(item.split('-')[0]).toLocaleString('fa').replace('٬', '')}/${Number(item.split('-')[1]) <= 9 ? '۰' + Number(item.split('-')[1]).toLocaleString('fa') : Number(item.split('-')[1]).toLocaleString('fa')}/${Number(item.split('-')[2]) <= 9 ? '۰' + Number(item.split('-')[2]).toLocaleString('fa') : Number(item.split('-')[2]).toLocaleString('fa')}` } </h2>
                                
                                <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">

                                    { eventsSorted[item].map((item, index) => 
                                    
                                        <EventDateItem 
                                            key={item.id}
                                            type={item.type}
                                            title={item.title}
                                            body={item.description}
                                            time={item.starts_at}
                                            case={item.law_suit ? item.law_suit.title : ''}
                                            count={index + 1}
                                            id={`${item.id}`}
                                            item={item}
                                            selectedItemDelete={selectedItemDelete}
                                            onSetSelectedItemDelete={setSelectedItemDelete}
                                            isStarted={item?.is_started}
                                            isPublic={item.is_public}
                                            send_sms={item?.send_sms}
                                        />

                                    )}

                                </ul>

                            </div>

                    )}
                </InfiniteScroll>

            </div>
        </>
    )

}

export default EventsLayoutList