import axios from 'axios'
import { apiKey } from '../../store'
import { ADD_ITEMS_TO_FILTER_NOTES, ADD_ITEM_TO_NOTES, EDIT_ITEM_FROM_NOTES, INCRAESE_NOTES, REMOVE_LIST_OF_NOTES_FROM_NOTES, REMOVE_NOTE_FROM_NOTES, SET_DELETE_ERROR_NOTES, SET_FAILURE_NOTES, SET_FILTER_ERROR_NOTES, SET_FILTER_NOTES, SET_LOADING_ERROR_NOTES, SET_LOADING_NOTES, SET_LOAD_MORE_LOADING_NOTES, SET_NOTES, SET_NOTE_ERROR, SET_NOTE_INFO, SET_NOTE_LOADING, SET_PAGINATION_NOTES } from './notesType'

const apiKeyNotes = apiKey + 'notes'

export const getNotesList = async (authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_NOTES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyNotes}`, config)
        .catch(error => {
            
            dispatch({
                type: SET_FAILURE_NOTES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                let list =  data.data.data.map(item => {
                    if(item.updated_at){
                        item.updated_at = item.updated_at.slice(0, 19)
                        item.updated_at = item.updated_at.split('-').join('').replace('T', '').split(':').join('')
                    }
                    return item
                })

                list = list.sort((a, b) => b.updated_at - a.updated_at)

                dispatch({
                    type: SET_NOTES,
                    payload: list
                })

                dispatch({
                    type: SET_PAGINATION_NOTES,
                    payload: data.data.pagination
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_NOTES,
                    payload: data.data.errors[0]?.message
                })

            }

        }

    }

}

export const loadMoreNotes = async (currentPage, search = '', authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOAD_MORE_LOADING_NOTES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyNotes}?page=${currentPage+1}&query=${search ? search : ''}`, config)
        .catch(error => {
            
            dispatch({
                type: SET_DELETE_ERROR_NOTES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            dispatch({
                type: SET_LOAD_MORE_LOADING_NOTES,
                payload: false
            })

            setTimeout(() => {
                    
                dispatch({
                    type: SET_DELETE_ERROR_NOTES,
                    payload: null
                })

            }, 1100);

        })

        if(data){

            if(data.data.success){

                if(!search){

                    dispatch({
                        type: INCRAESE_NOTES,
                        payload: data.data.data
                    })

                    dispatch({
                        type: SET_PAGINATION_NOTES,
                        payload: data.data.pagination
                    })

                }
                else {

                    dispatch({
                        type: ADD_ITEMS_TO_FILTER_NOTES,
                        payload: {
                            list: data.data.data,
                            pagination: data.data.pagination
                        }
                    })                    

                    dispatch({
                        type: SET_LOAD_MORE_LOADING_NOTES,
                        payload: false
                    })

                }

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_DELETE_ERROR_NOTES,
                    payload: 'نتونستم بارگذاری کنم!'
                })

                dispatch({
                    type: SET_LOAD_MORE_LOADING_NOTES,
                    payload: false
                })

                setTimeout(() => {
                    
                    dispatch({
                        type: SET_DELETE_ERROR_NOTES,
                        payload: null
                    })

                }, 1100);

            }

        }

    }

}

export const addNoteInListNotes = async (info, authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_NOTE_LOADING,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyNotes}`, info, config)
        .catch(error => {
            
            dispatch({
                type: SET_NOTE_ERROR,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {
                    
                dispatch({
                    type: SET_NOTE_ERROR,
                    payload: null
                })

            }, 1100);

            return false

        })

        if(data){

            if(data.data.success){

                // set note info
                dispatch({
                    type: SET_NOTE_INFO,
                    payload: data.data.data
                })

                // add to notes
                if(data.data.data.updated_at){
                    // add note to list
                    dispatch({
                        type: ADD_ITEM_TO_NOTES,
                        payload: {
                            ...data.data.data,
                            updated_at: data.data.data?.updated_at.split('-').join('').replace('T', '').split(':').join('')
                        }
                    })
                }
                else{
                    // add note to list
                    dispatch({
                        type: ADD_ITEM_TO_NOTES,
                        payload: data.data.data
                    })
                }

                dispatch({
                    type: SET_NOTE_LOADING,
                    payload: false
                })

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_NOTE_ERROR,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {
                    
                    dispatch({
                        type: SET_NOTE_ERROR,
                        payload: null
                    })

                }, 1100);

                return false
            }

        }

    }

}

export const deleteNoteFromNotes = async (id, authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_ERROR_NOTES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyNotes}/${id}/delete`, null, config)
        .catch(error => {
            
            dispatch({
                type: SET_DELETE_ERROR_NOTES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {
                    
                dispatch({
                    type: SET_DELETE_ERROR_NOTES,
                    payload: null
                })

            }, 1100);

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: REMOVE_NOTE_FROM_NOTES,
                    payload: id
                })

                return true

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_DELETE_ERROR_NOTES,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {
                    
                    dispatch({
                        type: SET_DELETE_ERROR_NOTES,
                        payload: null
                    })

                }, 1100);

                return false
            }

        }

    }

}

export const deleteListOfNoteFromNotes = async (ids, authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_ERROR_NOTES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const idsString = ids.join(',')

        const data = await axios.post(`${apiKeyNotes}/deleteall`, {ids: idsString}, config)
        .catch(error => {
            
            dispatch({
                type: SET_DELETE_ERROR_NOTES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {
                    
                dispatch({
                    type: SET_DELETE_ERROR_NOTES,
                    payload: null
                })

            }, 1100);

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: REMOVE_LIST_OF_NOTES_FROM_NOTES,
                    payload: ids
                })

                return true

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_DELETE_ERROR_NOTES,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {
                    
                    dispatch({
                        type: SET_DELETE_ERROR_NOTES,
                        payload: null
                    })

                }, 1100);

                return false
            }

        }

    }

}

export const getNoteFromSearchId = async (noteId, authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_NOTES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyNotes}?id=${noteId}`, config)
        .catch(error => {
            
            dispatch({
                type: SET_FAILURE_NOTES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_NOTE_INFO,
                    payload: data.data.data[0]
                })

                dispatch({
                    type: SET_LOADING_NOTES,
                    payload: false
                })

                return data.data.data[0]

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_NOTES,
                    payload: 'خطایی در گرفتن اطلاعات ب وجود اومده'
                })

                return false

            }

        }

    }

}

export const getNotesFromSearchBody = async (noteBody, authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_NOTES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyNotes}?query=${noteBody}`, config)
        .catch(error => {
            
            dispatch({
                type: SET_FILTER_ERROR_NOTES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_FILTER_NOTES,
                    payload: {
                        value: noteBody,
                        list: data.data.data,
                        pagination: data.data.pagination
                    }
                })        

                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FILTER_ERROR_NOTES,
                    payload: 'نتونستم جستجو کنم، به مشکل خوردم!'
                })

                return false

            }

        }

    }

}

export const editNoteInListNotes = async (info, authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_NOTE_LOADING,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        console.log(`${apiKeyNotes}/${info.id}`);
        const data = await axios.post(`${apiKeyNotes}/${info.id}`, info, config)
        .catch(error => {
            
            dispatch({
                type: SET_NOTE_ERROR,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {
                    
                dispatch({
                    type: SET_NOTE_ERROR,
                    payload: null
                })

            }, 1100);

            return false

        })

        if(data){

            if(data.data.success){
                
                info = {
                    ...info,
                    created_at: new Date()
                }

                // set note info
                dispatch({
                    type: SET_NOTE_INFO,
                    payload: info
                })

                // edit note to list
                dispatch({
                    type: EDIT_ITEM_FROM_NOTES,
                    payload: info
                })

                dispatch({
                    type: SET_NOTE_LOADING,
                    payload: false
                })

                return true

            }

            else if(!data.data.success && data.data.errors){

                console.log(data.data);

                dispatch({
                    type: SET_NOTE_ERROR,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {
                    
                    dispatch({
                        type: SET_NOTE_ERROR,
                        payload: null
                    })

                }, 1100);

                return false
            }

        }

    }

}
