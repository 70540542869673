import { useEffect, useRef, useState } from "react"
import Header from "../../../../components/Header"
import Button from "../../../../components/UI/Button"
import Input from "../../../../components/UI/Input"
import Textarea from "../../../../components/UI/Textarea"
import { useDispatch, useSelector } from "react-redux"
import Switch from "../../../../components/UI/Switch"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import CaseAddFileCaption from "../../../../components/Cases/Case/Content/Files/Add/Caption"
import CaseAddFileDescription from "../../../../components/Cases/Case/Content/Files/Add/Description"
import CaseAddFileToggleShow from "../../../../components/Cases/Case/Content/Files/Add/ToggleShow"
import CaseAddFileContext from "../../../../components/Cases/Case/Content/Files/Add/Context"
import CaseContentFilesItem from "../../../../components/Cases/Case/Content/Files/Item"
import LoadingDot from "../../../../components/UI/LoadingDot"
import ToastCustom from "../../../../components/UI/ToastCustom"
import { ReactSVG } from "react-svg"
import { ADD_FILE_TO_FILES_IN_CASE, SET_FILE_FAILURE, SET_FILE_LOADING, SET_FILE_SUCCESSFULLY } from "../../../../redux/action/cases/casesType"
import { useCookies } from "react-cookie"
import { addFileToFilesCase } from "../../../../redux/action/cases/casesAction"

const CaseAddFile = () => {

    const dispatch = useDispatch()
    const loading = useSelector(state => state.cases.fileLoading)
    const message = useSelector(state => state.cases.fileMessage)

    const singlesCase = useSelector(state => state.cases.singlesCase)

    const [caption, setCaption] = useState()
    const [context, setContext] = useState()
    const [description, setDescription] = useState()
    const [showToMovakel, setShowToMovakel] = useState(false)
    const [file, setFile] = useState()

    const fileRef = useRef()

    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        window.scrollTo({top: 0})

        if(!location.state?.data)
            navigate( location.key !== 'default' ? -1 : '/law-suits/', {replace: true})

        else {

            setFile( location.state?.data )

        }

    }, [])

    const addToFiles = () => {

        const data = {
            caption,
            context, 
            description,
            is_public: showToMovakel,
            data: '',
            mimetype: file.type
        }

        const fileReader = new FileReader()

        fileReader.onloadend = async () => {
            data.data = fileReader.result

            saveToFiles(data)
        }
        fileReader.readAsDataURL(file)
    }

    const saveToFiles = async (data) => {
        
        const response = await dispatch( await addFileToFilesCase(data, params.id, cookies.authToken) )
        
        if(response){
        
            // add file to the case.
            if( params.id && singlesCase[params.id] ){

                dispatch({
                    type: ADD_FILE_TO_FILES_IN_CASE,
                    payload: {
                        data: response,
                        id: params.id
                    }
                })

            }

            // go to the files of case.
            navigate( location.key !== 'default' ? -1 : `/law-suits/${params.id}`, {replace: true})
        }

    }

    return (
        <>

            <Header 
                title={'افزودن فایل به پرونده'}
                backBtn={true}
            >
                
                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={false}
                    onClick={addToFiles}
                >
                    ثبت     
                </Button>

            </Header>

            <main className="main-content">


                <div className="grid xl:grid-cols-2 gap-10 lg:grid-cols-1 md:grid-cols-2">
                    
                    <div className="flex flex-col gap-14">
 
                        <CaseAddFileCaption 
                            caption={caption}
                            onSetCaption={setCaption}
                        />

                        <CaseAddFileContext
                            context={context}
                            onSetContext={setContext}
                        />

                        <CaseAddFileDescription 
                            description={description}
                            onSetDescription={setDescription}
                        />

                        <CaseAddFileToggleShow 
                            show={showToMovakel}
                            onSetShow={setShowToMovakel}
                        />         

                    </div>

                    <div className="h-full xl:block lg:hidden md:block hidden xl:!border-r xl:border-t-0 xl:pt-0 xl:!pr-8  md:border-r md:pr-8 md:pt-0 md:border-t-0 border-third/30">

                        <div className="sticky h-fit top-28">
                            
                            <span className="block mb-8"> نمایش خروجی:  </span>

                            <CaseContentFilesItem 
                                info={{
                                    caption: caption ? caption : 'بدون عنوان',
                                    description,
                                    context,
                                    is_public: showToMovakel,
                                    created_at: new Date(),
                                    size: file?.size,
                                    mimetype: file?.type
                                }}
                                dontShowAction={true}
                            />
                            

                            <div>

                                <input onInput={() => { setFile( fileRef.current.files[0] ) }} ref={fileRef} id="select-file-law-siuts" type="file" className="hidden" />

                                <Button
                                    className={'rounded-xl mt-10 mx-auto gap-2'}    
                                    onClick={() => {fileRef.current.click()}}
                                > 
                                    <ReactSVG 
                                        className="w-5"
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/file.svg`}
                                    />
                                    
                                    تغییر فایل 

                                </Button>

                            </div>

                        </div>

                    </div>

                </div>

                { loading && <LoadingDot /> }

                { (message && message?.message && message?.type === 'error') &&

                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { message?.message }

                        </div>

                    </ToastCustom>

                }

            </main>
        </>
    )

}

export default CaseAddFile