import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { useCookies } from "react-cookie";

import { ToastContainer, toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.min.css";

import AuthLogo from "./SubSection/Logo";
import AuthBackBtn from "./SubSection/BackBtn";

const Auth = () => {

    const error = useSelector(state => state.auth.error)

    const [cookies, setCookies] = useCookies(['authToken'])

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        checkSelectedClientType()

        if(!navigator.onLine){
            navigate('/offline', {state: {url: location.pathname}})
        }

        // if user logined dont show this page
        if(cookies.authToken){
            navigate('/', {replace: true})
        }

    }, [])

    useEffect(() => {
        
        if(error){
            // show error message
            showErrorMessage(error)
        }

    }, [error])

    const checkSelectedClientType = () => {
        
        const clienyType = localStorage.getItem('selected-client-type')

        // if not selected lawyer -> go for select
        if(!clienyType)
            navigate('/select-client-type', {replace: true})

        else if(clienyType && clienyType !== 'lawyer'){
            
            if(window.localStorage.href.includes('.ir')){
                window.location.href = 'https://client.vakilsun.ir/auth/login'
            }
            else
                window.location.href = 'https://client.vakilsun.com/auth/login'

        }

    }

    const showErrorMessage = (error) => {
        // show error message
        toast.error(error, {
            position: toast.POSITION.BOTTOM_CENTER,
            'className': '!font-iransans !bg-rose-500',
            rtl: true,
            theme: 'colored',
            autoClose: 3750
        })
    }

    const showSuccessMessage = (message = 'با موفقیت انجام شد') => {
        // show sucess message
        toast.success(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            'className': '!font-iransans',
            rtl: true,
            theme: 'colored',
            autoClose: 800,
            delay: 0
        })
    }

    return (
       <main>
            
            <section className="container w-screen min-h-screen flex items-center justify-center flex-col pt-20 pb-7">
                
                <AuthBackBtn />

                <div className="bg-white relative p-4 pb-6 shadow-custom-md w-full max-w-lg rounded-3xl pt-24 mt-14">

                    <AuthLogo />

                    <Outlet 
                        context={ [showSuccessMessage] }
                    />

                    <ToastContainer />

                </div>

            </section>

        </main>
    )

}

export default Auth