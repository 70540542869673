export const SET_LOADING_CHAT = 'SET_LOADING_CHAT'
export const UNSET_LOADING_CHAT = 'UNSET_LOADING_CHAT'

export const SET_FILTER_CHAT = 'SET_FILTER_CHAT'

export const SET_FAILURE_CHAT = 'SET_FAILURE_CHAT'

export const SET_LIST_CHAT = 'SET_LIST_CHAT'
export const UPDATE_LIST_CHAT = 'UPDATE_LIST_CHAT'

export const SET_RECEIVER_CHAT = 'SET_RECEIVER_CHAT'

export const ADD_ITEM_TO_CHATMESSAGES_LIST = 'ADD_ITEM_TO_CHATMESSAGES_LIST'
export const UPDATE_ITEM_TO_CHATMESSAGES_LIST = 'UPDATE_ITEM_TO_CHATMESSAGES_LIST'
export const DELETE_ITEM_LOADEDCHATLIST = 'DELETE_ITEM_LOADEDCHATLIST'

export const ADD_TEXT_MESSAGE_ITEM_TO_CHAT = 'ADD_TEXT_MESSAGE_ITEM_TO_CHAT'

export const SET_SENDING_MESSAGE_FAIL_CHAT = 'SET_SENDING_MESSAGE_FAIL_CHAT'

export const SET_PAGINATION_IN_CHAT = 'SET_PAGINATION_IN_CHAT'

export const ADD_MORE_MESSAGE_TO_MESSAGES_IN_CHAT = 'ADD_MORE_MESSAGE_TO_MESSAGES_IN_CHAT'

export const DELETE_MESSAGE_ITEM_FROM_CHAT = 'DELETE_MESSAGE_ITEM_FROM_CHAT'
export const DELETE_ALL_MESSAGES_IN_CHAT = 'DELETE_ALL_MESSAGES_IN_CHAT'

export const RESTE_ALL_MESSAGES_CHAT_DATA_APP = 'RESTE_ALL_MESSAGES_CHAT_DATA_APP'

export const CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT = 'CHANGE_STATUS_TEXT_MESSAGE_ITEM_IN_CHAT'
export const SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE = 'SVAE_SCROLL_POINT_USER_IN_CHAT_PAGE'
export const SVAE_MESSAGE_INPUT_USER_IN_CHAT_PAGE = 'SVAE_MESSAGE_INPUT_USER_IN_CHAT_PAGE'
export const SVAE_HAVE_NEW_MESSAGE_IN_CHAT_PAGE = 'SVAE_HAVE_NEW_MESSAGE_IN_CHAT_PAGE'

export const SET_SUCCESS_FILE_IN_CHAT_USER = 'SET_SUCCESS_FILE_IN_CHAT_USER'
export const SET_ERROR_FOR_FILE_IN_CHAT_USER = 'SET_ERROR_FOR_FILE_IN_CHAT_USER'
export const ADD_FILE_TO_CHAT_USER = 'ADD_FILE_TO_CHAT_USER'
export const SET_LOADING_FILE_IN_CHAT_USER = 'SET_LOADING_FILE_IN_CHAT_USER'
export const REMOVE_ERROR_CHAT_FILE = 'REMOVE_ERROR_CHAT_FILE'

export const SET_LIST_CHAT_WITH_SORTED_DATE = 'SET_LIST_CHAT_WITH_SORTED_DATE'

export const SET_ERROR_CHAT_FILE = 'SET_ERROR_CHAT_FILE'