import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { getProfileColor } from "../../../../utils/messages"
import { calculateLeftTime } from "../../../../utils/time"
import { useDispatch, useSelector } from "react-redux"
import { SUBTRACT_OF_HOME_INFO_CONVERSATION_COUNT } from "../../../../redux/action/home/homeType"
import { SEEN_MESSAGE_ITEM_COUNT } from "../../../../redux/action/messages/list/listType"

const MessagesListItem = ( { message, selectedItemDelete, onSetSelectedItemDelete } ) => {

    const dispatch = useDispatch()
    const homeInfoState = useSelector(state => state.home.info.stats)

    const selectItemForDelete = () => {
        onSetSelectedItemDelete(message.id)
    }

    const chooseActiveItem = (event) => {

        if(homeInfoState && homeInfoState?.conversations && selectedItemDelete === null){
            
            if(homeInfoState?.conversations &&  Number( homeInfoState?.conversations) >= 1 && message?.unseen_count && Number(message?.unseen_count) >= 1){

                dispatch({
                    type: SUBTRACT_OF_HOME_INFO_CONVERSATION_COUNT,
                    payload: Number(message?.unseen_count)
                })

                dispatch({
                    type: SEEN_MESSAGE_ITEM_COUNT,
                    payload: message.id
                })

            }

        }

        if(selectedItemDelete === null) return

        event.preventDefault()
        selectItemForDelete()
    }

    const handleNoImage = () => {

        const color = getProfileColor(message.mobile[10])

        return (
            <div className={`flex-shrink-0 w-14 h-14 profile-${color} border-[2px] shadow-lg rounded-xl flex items-center justify-center relative`}>
                <span>{message.name[0]}</span>

                { selectedItemDelete === message.id && 
                            
                    <ReactSVG
                        className="w-[26px] h-[26px] text-green-600 rounded-full absolute -bottom-3 -right-3"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                    />
                    
                }

            </div> 
        )
    }

    const putTime = () => {
        return calculateLeftTime(message.last_message.created_at)
    }

    return (
        <li>
            
            <Link to={`/messages/${message.id}/${message.mobile}`} state={{image: message.image, name: message.name}} onClick={chooseActiveItem} onContextMenu={selectItemForDelete} className="flex w-full hover:shadow-custom-xl duration-500 bg-white p-4 shadow-custom-sm rounded-xl gap-3 border border-gray-100">
                
                { message.image ?
                    <div className="flex-shrink-0 w-14 h-14 bg-slate-200 border-[2px] border-slate-200 shadow-lg rounded-xl flex items-center justify-center relative">

                        <img className="w-full h-full rounded-xl" src={message.image} />
                
                        { selectedItemDelete === message.id && 
                        
                            <ReactSVG
                                className="w-[26px] h-[26px] text-green-600 rounded-full absolute -bottom-3 -right-3"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                        }

                    </div> 
                    :
                    handleNoImage()
                }

                <div className="flex w-full flex-col gap-2">

                    <div className="flex items-center justify-between w-full">
                        <span> {message.name} </span>
                        
                        <span className="flex-shrink-0 text-gray-500 text-xs">
                            { putTime() }
                        </span>

                    </div>

                    <div className="flex items-center justify-between w-full">

                        <p className="text-sm text-gray-500 2xm:whitespace-nowrap 2xm:max-h-max max-h-5 xl:max-w-2xl md:max-w-xl sm:max-w-md max-w-xs overflow-hidden overflow-ellipsis ml-1.5"> 
                            { message.last_message.body }
                        </p>

                        { message.unseen_count > 0 &&
                            <span className="bg-primary flex-shrink-0 rounded-lg py-1 text-sm text-white flex items-center justify-center w-6 h-6">
                                { message.unseen_count }
                            </span>
                        }


                    </div>

                </div>

            </Link>

        </li>
    )

}

export default MessagesListItem