import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../../../../../utils/base-data"
import { getBaseDataLawyer } from "../../../../../../../../redux/action/baseData/baseAction"
import { SET_SUCCESS_BASEDATA } from "../../../../../../../../redux/action/baseData/baseType"
import { ReactSVG } from "react-svg"

const CaseAddFinanicalCategoryList = (props) => {

    const dispatch = useDispatch()
    const baseDataCategory = useSelector(state => state.baseData.info?.misc?.financials)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataError = useSelector(state => state.baseData.error)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getCategoryFromBaseData()

    }, [])

    const getCategoryFromBaseData = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    const selectedCategory = (value) => {
        // update method
        props.onSelectedCategory(value)
    }

    if(baseDataLoading){
        return (
            <div className="flex items-center justify-center h-20">  
                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`} className="w-10 text-gray-500" />
            </div>
        )
    }

    else if(baseDataError){
        return (
            <div className="flex items-center justify-center h-20 text-gray-600">
                {baseDataError}
            </div>
        )
    }

    return (
        <div className="mt-4">
            
            <ul className="mt-4">

                {baseDataCategory?.map((item, index) => 
                    <li
                        key={item}
                        className="text-gray-600 cursor-pointer py-4 first:pt-0 last:pb-0 border-b last:border-b-0 border-b-gray-200 flex items-center justify-between"
                        onClick={() => selectedCategory(item) }
                    >
                        <div className="flex items-center gap-4 flex-wrap">

                            {item}

                        </div>

                        <span className="text-sm text-primary"> انتخاب </span>

                    </li>    
                )}

            </ul>

        </div>
    )

}

export default CaseAddFinanicalCategoryList