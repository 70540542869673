import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { useCookies } from "react-cookie"

import { getNotesList, loadMoreNotes } from "../../../redux/action/notes/notesAction"

import NoteItem from "./Item"
import DeleteNotesHeader from "./DeleteHeader"
import LoadMoreNotes from "./LoadMore"
import ToastCustom from "../../UI/ToastCustom"
import { useNavigate } from "react-router-dom"

import InfiniteScroll from "react-infinite-scroller"

const NotesList = () => {

    const dispatch = useDispatch()
    const notes = useSelector(state => state.notes.notes)
    const loading = useSelector(state => state.notes.loading)
    const error = useSelector(state => state.notes.error)
    const filterError = useSelector(state => state.notes.filterError)
    const filtered = useSelector(state => state.notes.filtered)
    const loaded = useSelector(state => state.notes.loaded)
    const pagination = useSelector(state => state.notes.pagination)
    const deleteError = useSelector(state => state.notes.deleteError)
    const loadMoreLoading = useSelector(state => state.notes.loadMoreLoading)

    const [selectedItemDelete, setSelectedItemDelete] = useState([])

    const navigate = useNavigate()

    const [cookies, , removeCookie] = useCookies(['authToken'])

    useEffect(() => {

        if( (!loaded) ){
            getNotes()
        }

    }, [])

    useEffect(() => {

        if(error){

            if(error === 'ابتدا وارد حساب کاربری خود شوید.'){
                // come go to authPage and remove cookies
                removeCookie('authToken')                

                navigate('/auth/login', {replace: true})
            }     

        }

    }, [error])

    const getNotes = async () => {
        await dispatch( await getNotesList(cookies.authToken) )
    }

    const loadMore = async () => {

        if( notes && notes.length >= 1 && !loading && !loadMoreLoading) {

            await dispatch( await loadMoreNotes(pagination.current_page, filtered.searchedValue ? filtered.searchedValue : '', cookies.authToken) )
        
        }

    }

    if(loading){

        return (
            <section className="flex items-center justify-center h-20">
                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                    className="w-10 text-second"
                />
            </section>
        )

    }

    if(error || filterError) {
        return (
            <section className="flex items-center gap-2">

                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`} className="w-6 text-rose-500" />

                <p className="text-gray-700 leading-7"> {error || filterError} </p>

            </section>
        )
    }

    return (
        <section>
            
            { selectedItemDelete.length >= 1 &&
                <DeleteNotesHeader 
                    selectedItemId={selectedItemDelete}
                    onSetSelectedItemDelete={setSelectedItemDelete}
                />
            }

            { filtered?.searchedValue  ?

                <>
                    {filtered?.list?.length === 0 && 
                        <p className="text-gray-600 flex items-center gap-2"> 
                            هیچ یادداشتی یافت نشد
                        </p>
                    }
                    
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={loadMore}
                        hasMore={filtered?.pagination.has_more_pages}
                        
                        loader={
                            <ReactSVG
                                key={0}
                                className="w-10 text-primary mx-auto block mt-10"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            />
                        }

                    >

                        <ul className="grid xl:grid-cols-3 4xm:grid-cols-2 sm:gap-4 4xm:gap-3 gap-4">
                            
                            { filtered?.list?.map(item => 

                                <NoteItem 
                                    key={item.id+"-filter-note"}
                                    id={item.id}
                                    body={item.body}
                                    subject={item?.subject}
                                    law={item?.law_suit?.title}
                                    lawData={item.law_suit}
                                    time={item.updated_at ? item.updated_at : item.created_at}
                                    selectedItemDelete={selectedItemDelete}
                                    onSetSelectedItemDelete={setSelectedItemDelete}
                                    color={item.color}
                                />

                            )}

                        </ul>

                    </InfiniteScroll>

                </>

                :

                <>

                    {notes?.length === 0 && 
                    
                        <div className="flex items-center gap-2">

                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/notes.svg`}
                                className="w-4 text-gray-700"
                            />

                            <span> هیچ یادداشتی یافت نشد </span>
                        
                        </div>
                    }


                    <InfiniteScroll
                        pageStart={1}
                        loadMore={loadMore}
                        hasMore={pagination.has_more_pages}
                        
                        loader={
                            <ReactSVG
                                key={0}
                                className="w-10 text-primary mx-auto block mt-10"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            />
                        }

                    >

                        <ul className="grid xl:grid-cols-3 4xm:grid-cols-2 sm:gap-4 4xm:gap-3 gap-4">

                            { notes.map(item => 

                                <NoteItem 
                                    key={item.id+"-main-note"}
                                    id={item.id}
                                    body={item.body}
                                    law={item?.law_suit?.title}
                                    subject={item?.subject}
                                    lawData={item.law_suit}
                                    time={item.updated_at ? item.updated_at : item.created_at}
                                    selectedItemDelete={selectedItemDelete}
                                    onSetSelectedItemDelete={setSelectedItemDelete}
                                    color={item.color}
                                />

                            )}

                        </ul>

                    </InfiniteScroll>

                    {/* {pagination.has_more_pages &&
                        <LoadMoreNotes />
                    }        */}

                </>

            }

            { deleteError &&
                <ToastCustom>
                    
                    <div className="flex items-center gap-2">
                        <ReactSVG
                            className="text-rose-500/90 4xm:w-8 w-6"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                        />

                        { deleteError }

                    </div>

                </ToastCustom>
            }

        </section>
    )

}

export default NotesList