import { useEffect, useState } from "react"
import ModalBottom from "../../../../../../UI/ModalBottom"
import { ReactSVG } from "react-svg"
import AddCaseCategoryList from "../../../../../Add/MoreData/Category/List"

const CaseContentInfoDetailCategory = ({value, firstData = '', onUpdateCategoryField, ...props}) => {

    const [showModal, setShowModal] = useState(false)
    const [showingModal, setShowingModal] = useState(false)
    
    const [haveChild, setHaveChild] = useState(false)

    useEffect(() => {

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const openModal = () => {
        setShowModal(true)

        document.body.classList.add('modal-opened')
    }

    const hideModal= () => {
        setShowingModal(false)

        setTimeout(() => {
            setShowModal(false)
        }, 300);

        document.body.classList.remove('modal-opened')
    }

    const backToMainMenu = () => {
        if(haveChild){
            setHaveChild(false)
        }

        else {
            hideModal()   
        }
    }

    const resetDataAndHideModal = () => {
        hideModal()

        const category = {
            id: null,
            name: ''
        }

        onUpdateCategoryField( category )

    }

    const restoreDataAndHideModal = (event) => {
        event.preventDefault()

        hideModal()


        onUpdateCategoryField(firstData)
    }

    const selectedCategory = (data) => {

        onUpdateCategoryField( data )

        hideModal()

    }

    return (
        <>
            
            <div onClick={ openModal } className="py-4 cursor-pointer">
                
                <span className="text-primary font-medium ">
                    { value?.name ? value?.name : '-'}
                </span>

            </div>

            { showModal &&
                <ModalBottom
                    show={showModal}
                    onSetShow={setShowModal}
                    className="overflow-y-auto no-scrollbar h-full"
                    showing={showingModal}
                    onSetShowing={setShowingModal}
                >
                    
                    <div className="sticky top-0 left-0 flex flex-col bg-white w-full z-[1] border-b border-b-slate-200">

                        <div className="py-2 bg-white z-[1] mb-2">
                            <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                        </div>

                        <div className="flex items-center justify-between gap-4 mb-4">

                            <button onClick={backToMainMenu} type="button" className="w-10 group">
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 text-gray-600 hover:text-primary duration-300 rotate-180 mr-1.5 group-disabled:text-gray-400 group-disabled:opacity-70" />

                            </button>

                            <div className="flex items-center gap-0.5">

                                <span className="font-medium flex items-center gap-1.5">
                                    
                                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/folder.svg`} className="w-[18px] text-gray-500" />

                                    { value?.name ? value?.name : 'انتخاب نشده' }

                                </span>
                
                                { (firstData?.id && firstData?.id !== value?.id) ?
                                    <button onClick={restoreDataAndHideModal} type="button" className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                        <ReactSVG 
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/turn-back.svg`}
                                            className="w-4 text-primary"
                                        />
                                    </button>
                                    :
                                    value?.id &&
                                        <button onClick={resetDataAndHideModal} type="button" className="h-full focus:outline-none outline-none w-8 flex justify-end">
                                            
                                            <ReactSVG 
                                                src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`}
                                                className="w-5 text-rose-600"
                                            />

                                        </button>
                                }

                            </div>

                        </div>

                    </div>

                    <div className="text-base">

                        <AddCaseCategoryList 
                            onSelectCategory={ selectedCategory }
                            onSetHaveChild={ setHaveChild }
                            haveChild={ haveChild }
                        />

                    </div>

                </ModalBottom>
            }

        </>
    )

}

export default CaseContentInfoDetailCategory