import { useState } from "react"
import Form from "../../UI/Form"
import { useSelector } from "react-redux"

const WelcomeForm = (props) => {

    const [fields, setFields] = useState([
        {
            id: 'name_field',
            value: '',
            count: 0,
            max: 40,
            label: 'نام',
            validate: {
                min: 3,
                required: true
            },
            isValid: false
        },
        {
            id: 'family_field',
            value: '',
            count: 0,
            max: 40,
            label: 'نام خانوادگی',
            validate: {
                min: 3,
                required: true
            },
            isValid: false
        },
        // {
        //     id: 'reffer_field',
        //     value: '',
        //     count: 0,
        //     max: 8,
        //     label: 'کد معرف',
        //     placeholder: 'اختیاری',
        //     validate: {
        //         length: 8,
        //         required: false
        //     },
        //     isValid: false
        // }
    ])

    const loading = useSelector(state => state.profile.loading)

    const makeApplyInfo = () => {
        const data = {
            first_name: fields[0].value,
            last_name: fields[1].value,
            gender: 'male',
        }

        props.onSubmit(data)
    }

    return (
        <Form 
            fields={fields}
            buttonText='ثبت'
            onSetFields={setFields}
            onSubmit={makeApplyInfo}
            loading={loading}
        />
    )

}

export default WelcomeForm