import { useSelector } from "react-redux"
import AddCaseContactsListItem from "./Item"

const AddCaseContactsList = (props) => {

    if(props.location){

        return (
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4">
    
                { props.list.map(item => 
                    
                    <AddCaseContactsListItem 
                        key={item?.id}
                        position={item.position}
                        id={item.id}
                        name={item.name}
                        image={item.image}
                        mobile={item.mobile}
                        location={props.location}
                        item={item}
                        edit={props.edit}
                    />
    
                )}
    
            </div>
        )

    }

    return (
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4">

            { props.list.map(item => 
                
                <AddCaseContactsListItem 
                    key={item?.id}
                    position={item.position}
                    id={item.id}
                    name={item.name}
                    image={item.image}
                    mobile={item.mobile}
                    location={props.location}
                    item={item}
                    edit={props.edit}
                />

            )}

        </div>
    )

}

export default AddCaseContactsList