import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"

const WalletBalanceTop = () => {

    const balance = useSelector(state => state.wallet.wallet.balance)
    const loading = useSelector(state => state.wallet.loading)

    return (
        <>

            <section className="mb-12">

                <div className="flex flex-wrap items-center justify-between gap-8 bg-gradient-to-br outline outline-[6px] mx-1 outline-primary/20 from-primary to-second py-6 px-4 rounded-2xl">

                    <div className="flex flex-col 6xm:items-start items-center 6xm:w-auto w-full">
                        
                        <h3 className="bg-clip-text bg-gradient-to-r from-white to-purple-300 text-transparent text-lg font-medium mb-6"> موجودی کیف پول </h3>

                        <div className="flex items-center gap-2 text-white">

                            { !loading ? 
                                <span className="sm:text-3xl text-2xl font-medium"> {balance?.total ? Number(balance.total).toLocaleString('fa') : '۰'} </span>
                                :
                                <span className="sm:text-3xl text-2xl font-medium">
                                    <ReactSVG
                                        className="w-10"
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                    />
                                </span>
                            }

                            <span className="sm:text-3xl text-2xl font-medium"> تومان </span>

                        </div>

                    </div>

                    <div className="bg-primary/20 flex items-center justify-center text-white border border-white border-opacity-10 shadow-custom-lg sm:w-24 sm:h-24 w-20 h-20 rounded-full 6xm:!ml-0 mx-auto">
                        
                        {/* svg-icon */}
                        <ReactSVG 
                            className="sm:w-16 sm:h-16 w-14 h-14"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/wallet-2.svg`}
                        />
                    </div>

                </div>

            </section>

        </>
    )

}

export default WalletBalanceTop