import { useEffect } from "react"
import Input from "../../UI/Input"
import AddCaseContacts from "../Add/Contacts"
import { useDispatch, useSelector } from "react-redux"

import { SET_CHANGED_DATA_EDIT_CASE, SET_CLIENTS_EDIT_FORM, SET_CONTACTS_EDIT_FORM, SET_FORMS_DATA_EDIT_CASE, SET_FORMS_NOT_VALID_EDIT_CASE } from "../../../redux/action/cases/edit/type"
import { updatePublicFieldCase } from "../../../utils/add-case-form"
import EditCaseFormMoreData from "./MoreData"
import { useLocation } from "react-router-dom"
import { type } from "@testing-library/user-event/dist/type"

const EditCaseForm = ({data}) => {

    const dispatch = useDispatch()
    const forms = useSelector(state => state.editCase.forms)
    const clients = useSelector(state => state.editCase.clients)
    const contacts = useSelector(state => state.editCase.contacts)
    const loading = useSelector(state => state.cases.singleCaseLoading)
    const changedData = useSelector(state => state.editCase.changedData)

    const showContactsBox = useSelector(state => state.editCase.showContactsBox)
    const contactBoxOpened = useSelector(state => state.editCase.contactBoxOpened)

    const location = useLocation()

    useEffect(() => {

        if(data){

            if( (!location?.state?.dontResetContactsInEdit) || ( forms?.title?.value === '' && data?.title !== '' && location?.state?.dontResetContactsInEdit ) )
                dispatch({
                    type: SET_FORMS_DATA_EDIT_CASE,
                    payload: {
                        title: {value: data?.title, max: 60, min: 3, isValid: true, require: true},
                        code: {value: data?.code, isValid: true, min: 16, max: 18, require: true},
                        archive_code: {value: data?.archive_code ? data.archive_code : '', isValid: true, min: 3, max: 7},
                        step: data?.step ? data.step : '',
                        category: {
                            id: data?.category?.id,
                            name: data?.category?.name
                        },
                        state: data?.state ? data.state : '',
                        location: {
                            city: {id: data?.city?.id, name: data?.city?.name},
                            province: data?.province?.id
                        },
                        branch: {value: data?.branch, min: 3, max: 60, isValid: true},
                        description: {value: data?.description, min: 3, isValid: true},
                        agreed_fee: data?.agreed_fee,
                        jurisdiction: {id: data?.jurisdiction?.id, name: data?.jurisdiction?.name}
                    }
                })

            if(!location.state?.dontResetContactsInEdit || (clients.length === 0 && !location.state?.dontResetContactsInEdit) ){

                dispatch({
                    type: SET_CLIENTS_EDIT_FORM,
                    payload: data?.clients
                })
    
                dispatch({
                    type: SET_CONTACTS_EDIT_FORM,
                    payload: data?.contacts
                })

            }

            if( ( (data?.contacts?.length !== 0 && contacts?.length === 0) || (clients?.length === 0 && data?.clients?.length !== 0) ) && location.state?.dontResetContactsInEdit){
                
                dispatch({
                    type: SET_CLIENTS_EDIT_FORM,
                    payload: data?.clients
                })
    
                dispatch({
                    type: SET_CONTACTS_EDIT_FORM,
                    payload: data?.contacts
                })

            }

        }

    }, [data])

    useEffect(() => {

        const timeout = setTimeout(() => {

            checkIsValidOrNo()

            checkChangedData('forms')

        }, 800)

        return () => {
            clearTimeout(timeout)
        }

    }, [forms])

    useEffect(() => {

        const timeout = setTimeout(() => {

            checkChangedData('contacts')

        }, 100)

        return () => {
            clearTimeout(timeout)
        }

    }, [clients, contacts, data])

    const updateTitleField = (event) => {
     
        const response = updatePublicFieldCase(event.target.value, forms.title, true)

        if(response){

            dispatch({
                type: SET_FORMS_DATA_EDIT_CASE,
                payload: {
                    ...forms,
                    title: response
                }
            })

        }
        
    }

    const updateCodeField = (event) => {
     
        const response = updatePublicFieldCase(event.target.value, forms.code, true)

        if(isNaN(event.target.value)) return

        if(response){

            dispatch({
                type: SET_FORMS_DATA_EDIT_CASE,
                payload: {
                    ...forms,
                    code: response
                }
            })

        }
        
    }

    const checkIsValidOrNo = () => {

        let isValid = true

        for ( const item in forms ){

            if( ( forms[item]?.require && (forms[item]?.value === '' || !forms[item]?.isValid) ) || ( (typeof forms[item] !== 'string' && item !== 'category' && item !== 'location' && item !== 'jurisdiction' && item !== 'agreed_fee') && forms[item]?.value !== '' && !forms[item]?.isValid) ){

                dispatch({
                    type: SET_FORMS_NOT_VALID_EDIT_CASE,
                    payload: true
                })

                isValid = false

                return
            }

        }

        if(isValid){
            
            dispatch({
                type: SET_FORMS_NOT_VALID_EDIT_CASE,
                payload: false
            })

        }

    }

    const checkChangedData = (whichChanged) => {

        let changed = false

        if(whichChanged === 'forms' && data){
            
            for( const item in forms ){

                if(forms[item]?.value){
                    
                    if( forms[item]?.value !== data[item] ){
                        
                        changed = true

                        dispatch({
                            type: SET_CHANGED_DATA_EDIT_CASE,
                            payload: [...changedData, 'forsm']
                        })

                        return
                        
                    }

                }
                else if(forms[item]?.name !== undefined){
                    
                    if( (forms[item]?.name !== data[item]?.name) || (!forms[item]?.name && data[item]?.name) ){
                        
                        changed = true

                        dispatch({
                            type: SET_CHANGED_DATA_EDIT_CASE,
                            payload: [...changedData, 'forsm']
                        })

                        return
                    }

                }
                else if(forms[item]?.city){

                    if( forms[item]?.city?.name !== data['city']?.name ){
                        
                        changed = true

                        dispatch({
                            type: SET_CHANGED_DATA_EDIT_CASE,
                            payload: [...changedData, 'forsm']
                        })

                        return
                    }

                }
                else if((item === 'agreed_fee' ) && ( (String(forms[item]).split(',').join('') != data[item] && data[item]) || (forms[item] !== '' && forms[item] !== null && !data[item])) ){

                    console.log(item, forms[item], data[item]);

                    changed = true

                    dispatch({
                        type: SET_CHANGED_DATA_EDIT_CASE,
                        payload: [...changedData, 'forsm']
                    })

                    return
                }
                else {

                    if(typeof forms[item] === 'string' && forms[item] !== data[item] && ( forms[item] !== '' && data[item] !== null ) && item !== 'agreed_fee' ){
                        
                        changed = true

                        dispatch({
                            type: SET_CHANGED_DATA_EDIT_CASE,
                            payload: [...changedData, 'forsm']
                        })

                        return
                    }

                }

            }

        }
        else if(whichChanged === 'contacts' && data?.title){
            
            if( (clients?.length !== data?.clients?.length) || (contacts?.length !== data?.contacts?.length)){
                changed = true
                
                dispatch({
                    type: SET_CHANGED_DATA_EDIT_CASE,
                    payload: [...changedData, 'contacts']
                })

                return
            }

            else {

                clients.map(item => {
                    
                    const client = data?.clients?.find(clientItem => clientItem.id === item.id)
                    
                    if(!client) return

                    if( (client.notify !== item.notify) || (client.position !== item.position) ){
                        changed = true
                
                        dispatch({
                            type: SET_CHANGED_DATA_EDIT_CASE,
                            payload: [...changedData, 'contacts']
                        })

                        return
                    }
                    
                })  

                contacts.map(item => {
                    
                    const contact = data?.contacts?.find(contactItem => contactItem.id === item.id)
                    
                    if(!contact) return

                    if( (contact.position !== item.position) ){
                        changed = true
                
                        dispatch({
                            type: SET_CHANGED_DATA_EDIT_CASE,
                            payload: [...changedData, 'contacts']
                        })

                        return
                    }
                    
                })  

            }

        }

        if(!changed && changedData?.length >= 1){

            // do this work
            dispatch({
                type: SET_CHANGED_DATA_EDIT_CASE,
                payload: []
            })

        }
        
    }

    return (
        <>
            
            <form className="flex flex-col gap-8">

                <div className="flex flex-col gap-8 rounded-2xl shadow-custom-lg border-[3px] border-white p-4 relative">

                    <div className="grid xm:grid-cols-2 grid-cols-1 gap-8 mb-2">

                        <Input
                            id={'case-title-edit'}
                            label={'عنوان'}
                            max={ forms?.title?.max }
                            icon={'title'}
                            value={ forms?.title?.value ? forms?.title?.value : '' }
                            isValid={ forms?.title?.isValid }
                            onChange={updateTitleField}
                            loading={loading}
                        />

                        <Input
                            id={'case-code-edit'}
                            type="tel"
                            className={'text-right'}
                            label={'شماره پرونده'}
                            max={ forms?.code?.max ? forms?.code?.max : 0 }
                            icon={'ticket'}
                            value={ forms?.code?.value ? forms?.code?.value : '' }
                            isValid={ forms?.code?.isValid }
                            onChange={ updateCodeField }
                            loading={loading}
                        />

                    </div>

                </div>

                <EditCaseFormMoreData 
                    forms={forms}
                    loading={loading}
                    data={data}
                />

                <AddCaseContacts 
                    title={'موکلین پرونده'}
                    movakels={ clients }
                    location={ true }
                    loading={ loading }
                    showContactsBox={showContactsBox}
                    contactBoxOpened={contactBoxOpened}
                    edit={true}
                />

                <AddCaseContacts 
                    title={'افراد مرتبط با پرونده'}
                    movakels={ contacts }
                    location={ false }
                    loading={ loading }
                    showContactsBox={showContactsBox}
                    contactBoxOpened={contactBoxOpened}
                    edit={true}
                />

            </form>

        </>
    )

}

export default EditCaseForm