import { useEffect, useState } from "react"
import Input from "../../UI/Input"
import AddCaseContacts from "./Contacts"
import AddCaseFormMoreData from "./MoreData"
import { useDispatch, useSelector } from "react-redux"

import { updatePublicFieldCase } from "../../../utils/add-case-form"
import { SET_FORMS_DATA, SET_FORMS_NOT_VALID_ADD_CASE } from "../../../redux/action/cases/add/type"

const AddCaseForm = () => {

    const dispatch = useDispatch()
    const forms = useSelector(state => state.addCase.forms)
    const clients = useSelector(state => state.addCase.clients)
    const contacts = useSelector(state => state.addCase.contacts)

    const showContactsBox = useSelector(state => state.addCase.showContactsBox)
    const contactBoxOpened = useSelector(state => state.addCase.contactBoxOpened)

    useEffect(() => {

        const timeout = setTimeout(() => {

            checkIsValidOrNo()

        }, 800)

        return () => {
            clearTimeout(timeout)
        }

    }, [forms])

    const updateTitleField = (event) => {
     
        const response = updatePublicFieldCase(event.target.value, forms.title, true)

        if(response){

            dispatch({
                type: SET_FORMS_DATA,
                payload: {
                    ...forms,
                    title: response
                }
            })

        }
        
    }

    const updateCodeField = (event) => {
     
        const value = Number(event.target.value)

        if(isNaN(value)) return

        const response = updatePublicFieldCase(event.target.value, forms.code, true)

        if(response){

            dispatch({
                type: SET_FORMS_DATA,
                payload: {
                    ...forms,
                    code: response
                }
            })

        }
        
    }

    const checkIsValidOrNo = () => {
        
        let isValid = true

        for ( const item in forms ){

            if( ( forms[item].require && (forms[item].value === '' || !forms[item].isValid) ) || ( (typeof forms[item] !== 'string' && item !== 'category' && item !== 'location' && item !== 'jurisdiction')  && forms[item]?.value !== '' && !forms[item]?.isValid) ){

                dispatch({
                    type: SET_FORMS_NOT_VALID_ADD_CASE,
                    payload: true
                })

                isValid = false

                return
            }

        }

        if(isValid){
            
            dispatch({
                type: SET_FORMS_NOT_VALID_ADD_CASE,
                payload: false
            })

        }

    }

    return (
        <>
            
            <form className="flex flex-col gap-8">

                <div className="flex flex-col gap-8 rounded-2xl shadow-custom-lg border-[3px] border-white p-4 relative">

                    <div className="grid xm:grid-cols-2 grid-cols-1 gap-8 mb-2">

                        <Input
                            id={'case-title'}
                            label={'عنوان'}
                            max={ forms?.title?.max }
                            icon={'title'}
                            value={ forms?.title?.value }
                            isValid={ forms?.title?.isValid }
                            onChange={updateTitleField}
                        />

                        <Input
                            id={'case-code'}
                            type="tel"
                            className={'text-right'}
                            label={'شماره پرونده'}
                            max={ forms?.code?.max }
                            icon={'ticket'}
                            value={ forms?.code?.value }
                            isValid={ forms?.code?.isValid }
                            onChange={ updateCodeField }
                        />

                    </div>

                </div>

                <AddCaseFormMoreData 
                    forms={forms}
                />

                <AddCaseContacts 
                    title={'موکلین پرونده'}
                    movakels={clients}
                    path={'contacts'}
                    location={ true }
                    showContactsBox={showContactsBox}
                    contactBoxOpened={contactBoxOpened}
                />

                <AddCaseContacts 
                    title={'افراد مرتبط با پرونده'}
                    movakels={contacts}
                    path={'contacts'}
                    location={ false }
                    showContactsBox={showContactsBox}
                    contactBoxOpened={contactBoxOpened}
                />

            </form>

        </>
    )

}

export default AddCaseForm