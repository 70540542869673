import axios from 'axios'
import { apiKey } from '../../store'
import { ADD_CASE_TO_CASES, ADD_CASE_TO_SINGLE_CASE, ADD_FINANCIAL_TO_CASE, DELETE_ERROR_CASE, DELETE_FILE_FROM_FILES_IN_CASE, DELETE_FINANCIAL_FROM_CASE, DELETE_ITEM_OF_CASES, EDIT_FINANCIAL_TO_CASE, SET_CASES_CASES, SET_DELETE_ERROR_CASE, SET_DELETE_LOADING_CASE, SET_ERROR_ARCHIVE_LIKE_CASE, SET_ERROR_SINGLE_CASE, SET_FAILURE_LIST_CASES, SET_FILE_FAILURE, SET_FILE_LOADING, SET_FILE_SUCCESSFULLY, SET_FINANCIAL_DELETE_LOADING, SET_FINANCIAL_DELETE_SUCCESSFULLY, SET_FINANCIAL_FAILURE, SET_FINANCIAL_LOADING, SET_LIST_CASES, SET_LOADING_ARCHIVE_LIKE_CASE, SET_LOADING_LIST_CASES, SET_LOADING_SINGLE_CASE, SET_SUCCSESS_ARCHIVE_LIKE_CASE, UPDATE_ARCHIVE_DATA_FROM_CASE, UPDATE_CASE_FROM_CASES, UPDATE_FAVORITE_DATA_FROM_CASE } from './casesType'
import { RESET_FORMS_NOT_VALID_ADD_CASE, SET_ERROR_ADD_CASE, SET_LOADING_ADD_CASE, SET_SUCCSESS_MESSAGE_ADD_CASE } from './add/type'
import { ADD_ITEM_TO_LAW_SUITS_HOME } from '../home/homeType'
import { RESET_FORMS_NOT_VALID_EDIT_CASE, SET_ERROR_EDIT_CASE, SET_LOADING_EDIT_CASE, SET_SUCCSESS_MESSAGE_EDIT_CASE } from './edit/type'

const apiKeyCases = apiKey + 'lawyer/law-suits'

export const getCasesLawyer = async (authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_LIST_CASES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyCases}/all`, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_LIST_CASES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_CASES_CASES,
                    payload: {
                        list: data.data.data,
                        pagination: {}
                    }
                })

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_LIST_CASES,
                    payload: data.data.errors[0]?.message
                })

            }

        }

    }

}

export const getCasesList = async (authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_LIST_CASES,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyCases}`, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_LIST_CASES,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_LIST_CASES,
                    payload: {
                        list: data.data.data,
                        pagination: data.data.pagination
                    }
                })

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_LIST_CASES,
                    payload: 'خطایی در گرفتن اطلاعات ب وجود اومده'
                })

            }

        }

    }

}

// -------------------------------------
// -------------------------------------
// -------------------------------------
export const addCaseToApi = async (info, result, created_at, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_ADD_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}`, info, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_ADD_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_ADD_CASE,
                    payload: null
                })

            }, 1500)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_MESSAGE_ADD_CASE,
                    payload: 'پرونده با موفقیت ثبت شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_MESSAGE_ADD_CASE,
                        payload: null
                    })  

                }, 1500)

                dispatch({
                    type: RESET_FORMS_NOT_VALID_ADD_CASE
                })

                dispatch({
                    type: ADD_CASE_TO_CASES,
                    payload: {
                        ...result,
                        id: data.data.data?.id
                    }
                })

                return data.data.data?.id
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_ADD_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_ADD_CASE,
                        payload: null
                    })
    
                }, 1500)

            }

        }

    }

}


export const deleteCaseFromList = async (id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_DELETE_LOADING_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/delete`, null, config)
        .catch(error => {

            dispatch({
                type: SET_DELETE_ERROR_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_DELETE_ERROR_CASE,
                    payload: null
                })

            }, 1500)

        })

        if(data){

            if(data.data.success){

                if(data.data.data.returned){
                    
                    dispatch({
                        type: SET_SUCCSESS_MESSAGE_ADD_CASE,
                        payload: data.data.data.return_message
                    })  
                }
                else {
                    
                    dispatch({
                        type: SET_SUCCSESS_MESSAGE_ADD_CASE,
                        payload: 'پرونده با موفقیت حذف شد'
                    })  

                    setTimeout(() => {
    
                        dispatch({
                            type: SET_SUCCSESS_MESSAGE_ADD_CASE,
                            payload: null
                        })  
    
                    }, 1500)

                }

                dispatch({
                    type: DELETE_ITEM_OF_CASES,
                    payload: id
                })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_DELETE_ERROR_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_DELETE_ERROR_CASE,
                        payload: null
                    })
    
                }, 1500)

            }

        }

    }

}

export const editCaseToApi = async (info, exportData, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_EDIT_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${exportData.id}`, info, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_EDIT_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_EDIT_CASE,
                    payload: null
                })

            }, 2000)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_MESSAGE_EDIT_CASE,
                    payload: 'پرونده با موفقیت ویرایش شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_MESSAGE_EDIT_CASE,
                        payload: null
                    })  

                }, 1500)

                // edit-cases
                dispatch({
                    type: UPDATE_CASE_FROM_CASES,
                    payload: exportData
                })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_EDIT_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_EDIT_CASE,
                        payload: null
                    })
    
                }, 2200)

            }

        }

    }

}

// -------------------------------------
// -------------------------------------
// -------------------------------------
export const getSingleCaseData = async (id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_SINGLE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyCases}/${id}`, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_SINGLE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: ADD_CASE_TO_SINGLE_CASE,
                    payload: {
                        id,
                        data: data.data.data
                    }
                })

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_SINGLE_CASE,
                    payload: data.data.errors[0].message
                })

            }

        }

    }

}

// -------------------------------------
// -------------------------------------
// -------------------------------------
export const caseMoveToArchive = async (id, authToken, listLoaded) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_ARCHIVE_LIKE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/archive`, null, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_ARCHIVE_LIKE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: null
                })

            }, 2000)

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                    payload: 'پرونده به لیست بایگانی اضافه شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                        payload: null
                    })  

                }, 1500)

                // update case by base archive data
                if(listLoaded)
                    dispatch({
                        type: UPDATE_ARCHIVE_DATA_FROM_CASE,
                        payload: {
                            id,
                            status: true
                        }
                    })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_ARCHIVE_LIKE_CASE,
                        payload: null
                    })
    
                }, 2200)

                return false
            }

        }

    }

}

export const caseMoveToUnarchive = async (id, authToken, listLoaded) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_ARCHIVE_LIKE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/unarchive`, null, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_ARCHIVE_LIKE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: null
                })

            }, 2000)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                    payload: 'پرونده از لیست بایگانی خارج شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                        payload: null
                    })  

                }, 1500)

                // update case by base archive data
                if(listLoaded)
                    dispatch({
                        type: UPDATE_ARCHIVE_DATA_FROM_CASE,
                        payload: {
                            id,
                            status: false
                        }
                    })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_ARCHIVE_LIKE_CASE,
                        payload: null
                    })
    
                }, 2200)

                return false

            }

        }

    }

}

export const caseMoveToFavorite = async (id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_ARCHIVE_LIKE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/favorite`, null, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_ARCHIVE_LIKE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: null
                })

            }, 2000)

            return false

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                    payload: 'به لیست نشان‌شده‌ها اضافه شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                        payload: null
                    })  

                }, 1500)

                // update case by base archive data
                dispatch({
                    type: UPDATE_FAVORITE_DATA_FROM_CASE,
                    payload: {
                        id,
                        status: true
                    }
                })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_ARCHIVE_LIKE_CASE,
                        payload: null
                    })
    
                }, 2200)

                return false
            }

        }

    }

}

export const caseMoveToUnfavorite = async (id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_ARCHIVE_LIKE_CASE,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/unfavorite`, null, config)
        .catch(error => {

            dispatch({
                type: SET_ERROR_ARCHIVE_LIKE_CASE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: null
                })

            }, 2000)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                    payload: 'از لیست نشان‌شده‌ها حذف شد'
                })  

                setTimeout(() => {

                    dispatch({
                        type: SET_SUCCSESS_ARCHIVE_LIKE_CASE,
                        payload: null
                    })  

                }, 1500)

                // update case by base archive data
                dispatch({
                    type: UPDATE_FAVORITE_DATA_FROM_CASE,
                    payload: {
                        id,
                        status: false
                    }
                })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_ARCHIVE_LIKE_CASE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_ERROR_ARCHIVE_LIKE_CASE,
                        payload: null
                    })
    
                }, 2200)

                return false

            }

        }

    }

}

// -------------------------------------
// -------------------------------------
// -------------------------------------

export const addFileToFilesCase = async (info, id, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_FILE_LOADING,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${id}/files`, info, config)
        .catch(error => {

            dispatch({
                type: SET_FILE_FAILURE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FILE_FAILURE,
                    payload: null
                })

            }, 1500)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_FILE_LOADING,
                    payload: false
                })

                dispatch({
                    type: SET_FILE_SUCCESSFULLY,
                    payload: 'موفقیت آمیز بود'
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FILE_SUCCESSFULLY,
                        payload: null
                    })
    
                }, 1500)

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

               
                dispatch({
                    type: SET_FILE_FAILURE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FILE_FAILURE,
                        payload: null
                    })
    
                }, 1500)

            }

        }

    }

}




export const deleteFileFromCase = async (caseId, fileId, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_FILE_LOADING,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKeyCases}/${caseId}/files/${fileId}/delete`, null, config)
        .catch(error => {

            dispatch({
                type: SET_FILE_FAILURE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FILE_FAILURE,
                    payload: null
                })

            }, 1500)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_FILE_LOADING,
                    payload: false
                })

                dispatch({
                    type: DELETE_FILE_FROM_FILES_IN_CASE,
                    payload: {
                        caseId: caseId,
                        fileId: fileId
                    }
                })

                dispatch({
                    type: SET_FILE_SUCCESSFULLY,
                    payload: 'موفقیت آمیز بود'
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FILE_SUCCESSFULLY,
                        payload: null
                    })
    
                }, 1500)

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

               
                dispatch({
                    type: SET_FILE_FAILURE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FILE_FAILURE,
                        payload: null
                    })
    
                }, 1500)

            }

        }

    }

}

export const deleteFiancialFromCase = async (id, lawId, amount, type, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_FINANCIAL_DELETE_LOADING,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKey}lawyer/financials/${id}/delete`, null, config)
        .catch(error => {

            dispatch({
                type: SET_FINANCIAL_FAILURE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FINANCIAL_FAILURE,
                    payload: null
                })

            }, 1500)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_FINANCIAL_DELETE_LOADING,
                    payload: false
                })

                dispatch({
                    type: DELETE_FINANCIAL_FROM_CASE,
                    payload: {
                        lawId,
                        
                        id,
                        amount,
                        type
                    }
                })

                dispatch({
                    type: SET_FINANCIAL_DELETE_SUCCESSFULLY,
                    payload: 'موفقیت آمیز بود'
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FINANCIAL_DELETE_SUCCESSFULLY,
                        payload: null
                    })
    
                }, 1500)

                return true
            }

            else if(!data.data.success && data.data.errors){

               
                dispatch({
                    type: SET_FINANCIAL_FAILURE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FINANCIAL_FAILURE,
                        payload: null
                    })
    
                }, 1500)

            }

        }

    }

}

export const addFinanicalItemToFinanical = async (info, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_FINANCIAL_LOADING,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKey}lawyer/financials`, info, config)
        .catch(error => {

            dispatch({
                type: SET_FINANCIAL_FAILURE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FINANCIAL_FAILURE,
                    payload: null
                })

            }, 1500)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_FINANCIAL_LOADING,
                    payload: false
                })

                dispatch({
                    type: ADD_FINANCIAL_TO_CASE,
                    payload: {
                        ...info,
                        paid_at: info.paid_at * 1000,
                        id: data.data.data.id
                    }
                })

                dispatch({
                    type: SET_FINANCIAL_DELETE_SUCCESSFULLY,
                    payload: 'موفقیت آمیز بود'
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FINANCIAL_DELETE_SUCCESSFULLY,
                        payload: null
                    })
    
                }, 1500)

                return true
            }

            else if(!data.data.success && data.data.errors){

               
                dispatch({
                    type: SET_FINANCIAL_FAILURE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FINANCIAL_FAILURE,
                        payload: null
                    })
    
                }, 1500)

            }

        }

    }

}

export const editFinanicalItemToFinanical = async (info, authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_FINANCIAL_LOADING,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.post(`${apiKey}lawyer/financials/${info.info.id}`, info.info, config)
        .catch(error => {

            dispatch({
                type: SET_FINANCIAL_FAILURE,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FINANCIAL_FAILURE,
                    payload: null
                })

            }, 1500)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_FINANCIAL_LOADING,
                    payload: false
                })

                dispatch({
                    type: EDIT_FINANCIAL_TO_CASE,
                    payload: {
                        info: {
                            ...info.info,
                            paid_at: info.info.paid_at * 1000
                        },
                        
                        amountChange: info.amountChange,
                        incomeChange: info.incomeChange,
                        expenseChange: info.expenseChange
                    }
                })

                dispatch({
                    type: SET_FINANCIAL_DELETE_SUCCESSFULLY,
                    payload: 'موفقیت آمیز بود'
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FINANCIAL_DELETE_SUCCESSFULLY,
                        payload: null
                    })
    
                }, 1500)

                return true
            }

            else if(!data.data.success && data.data.errors){

               
                dispatch({
                    type: SET_FINANCIAL_FAILURE,
                    payload: data.data.errors[0].message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FINANCIAL_FAILURE,
                        payload: null
                    })
    
                }, 1500)

            }

        }

    }

}
