import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import { calculateLeftDaysFromActivePlan } from "../../../utils/time"

const PlansStatus = () => {

    const planStatus = useSelector(state => state.profile.profile.active_user_plan)
    const loading = useSelector(state => state.profile.loading)
    const planLeftDays = useSelector(state => state.profile.profile.planLeftDays)
    const nowTime = useSelector(state => state.profile.profile.time)

    const [leftDays, setLeftDays] = useState()
    const [deg, setDeg] = useState(0)

    useEffect(() => {

        if( (planLeftDays === 0 ) && !loading){
            calculateLeftDays()
            caluclateDeg()
        }
        else if(planStatus && planStatus[0] && planStatus[0]?.id){

            setLeftDays(planLeftDays)
            caluclateDeg()
            
        }

    }, [planLeftDays, planStatus, loading])

    const calculateLeftDays = () => {
        const result = calculateLeftDaysFromActivePlan(planStatus ? planStatus[0]?.pivot?.expire_date : 0, nowTime)
        setLeftDays( result )
    }

    const caluclateDeg = () => {
        if( planStatus && planStatus[0] && planStatus[0]?.duration_days )
            setDeg( (planLeftDays / (Number(planStatus[0]?.duration_days) + 1) ) * 100  )
        else    
            setDeg( (planLeftDays / 31) * 100 )
    }

    if( (planStatus && planStatus[0] && planStatus[0]?.id) || loading ){
        return (
            <section className="mb-12">
    
                <div className="flex flex-wrap items-center justify-between gap-8  bg-gradient-to-br outline outline-[6px] mx-1 outline-primary/20 from-primary to-second py-6 px-4 rounded-2xl">
                     
                    <div className="flex flex-col 6xm:items-start items-center 6xm:w-auto w-full">
                        
                        <div className="flex items-start gap-2 mb-6">
    
                            <ReactSVG 
                                className="3xm:w-6 w-5 translate-y-0.5 text-purple-50"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/plans-box.svg`}
                            />

                            <div className=" flex items-center gap-1.5">

                                <h3 className="bg-clip-text bg-gradient-to-r from-white to-cyan-50 text-transparent 3xm:text-lg text-base font-medium"> وضعیت اشتراک: 
                                    { !loading &&
                                        <span className="bg-white/90 text-primary text-base rounded-full 3xm:px-5 px-3 py-0.5 inline-block mr-1.5">فعال</span> 
                                    }
                                </h3>
                                { loading && 
                                
                                    <ReactSVG
                                        className="3xm:w-10 w-8 text-white/90 block"
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                    /> 
                                }

                            </div>
                        
                        </div>
                        
                        <div className="flex items-center gap-2 text-white">
                        
                            <ReactSVG 
                                className="3xm:w-6 w-5 text-purple-50"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/calendar-4.svg`}
                            />
                        
                            <span className="bg-clip-text bg-gradient-to-r from-white to-cyan-50 text-transparent 3xm:text-lg text-base font-medium"> زمان باقی‌مانده:  </span>
                            
                            { !loading ? 
                                <span className="3xm:text-xl text-lg font-medium"> {Number(leftDays)?.toLocaleString('fa')} روز </span>
                                : 
                                <ReactSVG
                                    className="3xm:w-10 w-8 text-white/90"
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                /> 
                            }
    
                        </div>
    
                    </div>
    
                    <div className="flex-shrink-0 w-fit relative 4xm:!ml-0 mx-auto">
    
                        <div className="skill-bar w-full h-full relative max-w-[80px] max-h-[80px] rotate-[270deg] -translate-y-1">
    
                            <svg className="w-full h-full  relative"> 
                                <circle className="stroke-white/30 shadow-custom-xl translate-x-[3px] translate-y-[3px]" fill="transparent" style={{strokeWidth: 8}} cx="33" cy="33" r="32"></circle>
                                { deg >= 1 && !loading  &&
                                    <circle className="stroke-white shadow-custom-lg translate-x-[3px] translate-y-[3px]" style={{strokeDasharray: 200,strokeDashoffset: `calc((201 - (201 * ${deg}) / 100)`, strokeLinecap: 'round', strokeWidth: 8 }} fill="transparent" cx="33" cy="33" r="32"></circle>
                                }    
                                </svg>
    
                            <div className="-rotate-[270deg] text-white font-medium">
                                { deg >= 10 && !loading && deg <= 99 ?
                                    <span className="absolute -left-full -translate-x-2 -top-1.5"><b className="font-bold">{Number((Number(deg).toFixed(0))).toLocaleString('fa')}</b>٪</span>
                                    :
                                    deg <= 9 && !loading ?
                                    <span className="absolute -left-full -translate-x-1 -top-1.5"><b className="font-bold">{Number((Number(deg).toFixed(0))).toLocaleString('fa')}</b>٪</span>
                                    :
                                    deg >= 99.01  && !loading ?
                                    <span className="absolute -left-full -translate-x-[8.5px] -top-[7px]"><b className="font-bold">۱۰۰</b>٪</span>
                                    :
                                    loading ?
                                        <ReactSVG
                                            className="w-6 text-white/90 absolute -left-full -translate-x-1.5 top-0.5"
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                        /> 
                                    :
                                    <span className="absolute -left-full -translate-x-[8.5px] -top-[7px]"><b className="font-bold">۱۰۰</b>٪</span>
                                }
                            </div>
    
                        </div>

                        <span className="block text-center mr-1.5 text-white"> <span className="text-lg font-medium">{ (planStatus && planStatus[0] &&  planStatus[0]?.duration_month) ? Number(planStatus[0]?.duration_month).toLocaleString('fa') : '-' }</span> { (planStatus && planStatus[0] && planStatus[0]?.title) ? planStatus[0]?.title?.split(' ')[1] : '-'} </span>
    
                    </div>                
    
                </div>
    
            </section>
        )
    }
    else {
        return (
            <section className="mb-12">

                <div className="flex flex-wrap items-center justify-between gap-8  bg-gradient-to-br outline outline-[6px] mx-1 outline-red-600/20 from-red-400 to-red-600 py-6 px-4 rounded-2xl">
                    
                    <div className="flex flex-col 6xm:items-start items-center 6xm:w-auto w-full">
                        
                        <div className="flex items-start gap-2">

                            <ReactSVG 
                                className="w-6 translate-y-0.5 text-cyan-50"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/plans-box.svg`}
                            />

                            <h3 className="bg-clip-text bg-gradient-to-r from-white to-cyan-50 text-transparent text-lg font-medium mb-6"> وضعیت اشتراک: - </h3>
                        
                        </div>
                        
                        <div className="flex items-center gap-2 text-white">
                        
                            <ReactSVG 
                                className="w-6 text-cyan-50"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/calendar-4.svg`}
                            />
                        
                                { !loading ? 
                                    <span className="bg-clip-text bg-gradient-to-r from-white to-cyan-50 text-transparent text-lg font-medium"> زمان باقی‌مانده:  </span>
                                    :
                                    <span className="sm:text-3xl text-2xl font-medium">
                                        <ReactSVG
                                            className="w-10"
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                        />
                                    </span>
                                }

                                <span className="text-xl font-medium"> - </span>

                        </div>

                    </div>

                    <div className="flex-shrink-0 w-fit relative">

                        <div className="skill-bar w-full h-full relative max-w-[80px] max-h-[80px] rotate-[270deg] -translate-y-1">

                            <svg className="w-full h-full  relative"> 
                                <circle className="stroke-white/30 shadow-custom-xl translate-x-[3px] translate-y-[3px]" fill="transparent" style={{strokeWidth: 6}} cx="32" cy="32" r="32"></circle>
                                {/* <circle className="stroke-white shadow-custom-lg translate-x-[3px] translate-y-[3px]" style={{strokeDasharray: 200,strokeDashoffset: `calc((201 - (201 * 1) / 100)`, strokeLinecap: 'round', strokeWidth: 6 }} fill="transparent" cx="32" cy="32" r="32"></circle> */}
                            </svg>

                            <div className="-rotate-[270deg] text-white font-medium !translate-y-2 translate-x-[0.75px]">
                                <span className="absolute -left-full -translate-x-2.5 -top-1.5"><b className="font-bold">۰</b>٪</span>
                            </div>

                        </div>

                    </div>                

                </div>

            </section>
        )
    }

}

export default PlansStatus