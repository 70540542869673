import { useDispatch, useSelector } from "react-redux"
import ModalBottom from "../../../../UI/ModalBottom"
import { useEffect, useState } from "react"
import { SET_SHOW_CONTACTS_BOX } from "../../../../../redux/action/cases/add/type"
import { ReactSVG } from "react-svg"
import { Link } from "react-router-dom"
import ContactsSearch from "./Search"
import AddCaseContactsModalList from "./List"
import { SET_SHOW_CONTACTS_BOX_EDIT_CASE } from "../../../../../redux/action/cases/edit/type"

const AddCaseContactsModal = ({showContactsBox, ...props}) => {

    const dispatch = useDispatch()
    const [showingModal, setShowingModal] = useState(false)

    useEffect(() => {

        if(showContactsBox){
            document.body.classList.add('modal-opened')
        }

        return () => {
            if(document.body.classList.contains('modal-opened'))
                document.body.classList.remove('modal-opened')
        }

    }, [])

    const setShowModal = (value) => {
        
        if(!props.edit){

            dispatch({
                type: SET_SHOW_CONTACTS_BOX,
                payload: value
            })

        }
        else {

            dispatch({
                type: SET_SHOW_CONTACTS_BOX_EDIT_CASE,
                payload: value
            })

        }

    }

    const hideModal = () => {

        setShowingModal(false)

        setTimeout(() => {
            
            if(!props.edit){

                dispatch({
                    type: SET_SHOW_CONTACTS_BOX,
                    payload: false
                })

            }
            else {

                dispatch({
                    type: SET_SHOW_CONTACTS_BOX_EDIT_CASE,
                    payload: false
                })

            }


        }, 300);

        document.body.classList.remove('modal-opened')
    }

    const scrollToTop = () => {
        window.scrollTo({top: 0})
    }

    return (
        <ModalBottom
            show={showContactsBox}
            onSetShow={setShowModal}
            className="overflow-y-auto no-scrollbar h-full"
            showing={showingModal}
            onSetShowing={setShowingModal}
        >
            
            <div className="sticky top-0 left-0 flex flex-col bg-white w-full z-[1] border-b border-b-slate-200">

                <div className="py-2 bg-white z-[1] mb-2">
                    <div className="mx-auto w-20 h-2 bg-gray-200 rounded-2xl"></div>
                </div>

                <div className="flex items-center justify-between gap-4 mb-4">

                    <button onClick={hideModal} type="button" className="w-10 group">
                        
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/back.svg`} className="w-5 text-gray-600 hover:text-primary duration-300 rotate-180 mr-1.5 group-disabled:text-gray-400 group-disabled:opacity-70" />

                    </button>

                    <div className="flex items-center gap-0.5">

                        <Link to={'/add-contact'} state={{isNotMovakel: props?.location === false}} onClick={scrollToTop} className="w-7 h-7 shadow-custom-sm shadow-primary/10 rounded-lg text-white bg-primary flex items-center justify-center gap-2 profile-btn outline outline-2 outline-primary/10">

                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`}
                                className="w-6"
                            />

                        </Link>
        
                    </div>

                </div>

                <ContactsSearch 
                    edit={props.edit}
                />
 
            </div>

            <AddCaseContactsModalList 
                location={props.location}
                edit={props.edit}
            />

        </ModalBottom>
    )

}

export default AddCaseContactsModal