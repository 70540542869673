import { useCookies } from "react-cookie"
import { useEffect } from "react"
import BuyPlansTitle from "../../../components/Plans/Buy/Title"
import BuyPlansItems from "../../../components/Plans/Buy/Items"
import { ReactSVG } from "react-svg"
import Header from "../../../components/Header"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import { getWalletInfo } from "../../../redux/action/wallet/action"
import { getPlanItems } from "../../../redux/action/plans/action"
import PlansHeaderAction from "../../../components/Plans/HeaderAction"
import BuyPlansSupportBtn from "../../../components/Plans/Buy/SupportBtn"

const BuyPlans = () => {

    const dispatch = useDispatch()
    const activePlan = useSelector(state => state.profile.profile.active_user_plan)
    const loaded = useSelector(state => state.plans.loaded)
    const loading = useSelector(state => state.plans.loading)

    const location = useLocation()
    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        window.scrollTo({top: 0})

    }, [])

    useEffect(() => {

        if(activePlan && activePlan[0] && activePlan[0]?.id){
            
            const path = localStorage.getItem('last-path-of-user')

            navigate( path ? path : location.key !== 'default' ? -1 : '/', {replace: true})

            if(path)
                localStorage.removeItem('last-path-of-user')

            return
        }

        if(!loading && !loaded){
            getPlansData()
        }

    }, [activePlan])


    const getPlansData = async () => {

        await dispatch( await getPlanItems(cookies.authToken) )

    }

    return (

        <>
            <Header 
                title={'خرید اشتراک'}
                backBtn={true}
            >
                
                <PlansHeaderAction 
                    onGetPlansData={getPlansData}
                />

            </Header>

            <main className="main-content">

                <BuyPlansTitle />

                <BuyPlansItems />

                <BuyPlansSupportBtn />
                
            </main>
        </>
    )

}

export default BuyPlans
