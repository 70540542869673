import { useState } from "react"
import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import DropdownModal from "../../../UI/DropdownModal"
import { useDispatch } from "react-redux"
import { useCookies } from "react-cookie"
import { deleteContact } from "../../../../redux/action/contacts/contactsAction"
import { getProfileColor } from "../../../../utils/messages"

import ConatctShareDropdown from '../../../UI/ShareDropdown'
import { showErrorMessage, showSuccessMessage } from "../../../../utils/toast"

const ContactItem = (props) => {

    const dispatch = useDispatch()
    const [dropdown, setDropdown] = useState(false)
    const [showShareDropdown, setShowShareDropdown] = useState(false)

    const [cookies] = useCookies(['authToken'])

    const showDropdown = () => {
        setDropdown(true)

        document.body.classList.add('modal-opened')
    }

    const doDelete = async () => {
        // close the dropdown
        setDropdown(false)
        document.body.classList.remove('modal-opened')

        // delete the contact
        const success = await dispatch( await deleteContact(props.item.id, cookies.authToken) )
        
        if(success){
            // remove item
            props.onDeleteItem(props.item.id)
        }

    }

    const handleNoImage = () => {

        const color = getProfileColor(props.item.mobile[10])

        return (
            <div className={`flex-shrink-0 w-12 h-12 profile-${color} border-[2px] shadow-lg rounded-xl flex items-center justify-center`}>
                <span>{props.item.name[0]}</span>
            </div> 
        )
    }

    const showShareBox = () => {
        // close dropdown
        setDropdown(false)

        if(!navigator.share){
            // show share dropdown
            setShowShareDropdown(true)
            document.body.classList.add('modal-opened')
        }
        else {
            navigator.share({
                title: 'اشتراک گذاری برنامه با',
                url: 'www.VakilSun.com/'
            }).then(() => {
                showSuccessMessage('تشکر بابت اشتراک گذاری')
            }).catch(error => {
                showErrorMessage('متاسفانه این قابلیت کار نمیکند')
            })
        }
    }

    return (
        <li className="contact-item group flex w-full pl-2 5xm:items-center md:hover:shadow-custom-xl duration-500 bg-white 4xm:py-0 py-4 p-4 shadow-custom-sm rounded-xl 4xm:flex-nowrap justify-between 5xm:flex-wrap 5xm:flex-row flex-col gap-4 relative"> 
            
            <Link to={`/contact/${props.item.id}`} state={{userInfo: props.item}} className="flex items-center gap-4 4xm:w-full 4xm:h-full 4xm:py-4">
                
                { props.image ? 
                    <div className="flex-shrink-0 w-12 h-12 bg-slate-200 border-2 border-slate-200 shadow-lg rounded-xl flex items-center justify-center">
                        <img className="rounded-xl w-full h-full object-cover" src={props.image} alt={props.item.name} /> 
                    </div>
                    :
                    handleNoImage()               
                }
                <div className="flex flex-col gap-1">
                    <h3 className="text-gray-700"> {props.item.name} </h3>
                    <span className="text-xs text-gray-500 flex gap-1.5 mr-auto" dir="ltr">
                        {props.item.mobile.slice(0,4)} {props.item.mobile.slice(4,7)} {props.item.mobile.slice(7)}  
                    </span>
                </div>

            </Link>

            <div className="flex mr-auto items-center flex-wrap gap-4 flex-shrink-0">

                <div className="flex items-center gap-1">

                    { props.isUser &&
                        <Link to={`/messages/${props.item?.user?.id}/${props.item.mobile}`} className="w-8 h-8 flex items-center justify-center text-gray-700 hover:text-primary duration-300">
                            
                            <ReactSVG 
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/message-2.svg`}
                                className="w-6"
                            />

                        </Link> 
                    }

                    <a href={`tel:${props.item.mobile}`} className="w-8 h-8 flex items-center justify-center text-gray-700 hover:text-primary duration-300">
                        
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/mobile.svg`}
                            className="w-6"
                        />

                    </a>

                    <button onClick={showDropdown} className="w-8 h-8 text-gray-700 hover:text-primary duration-300 flex items-center justify-center outline-none focus:outline-none">
                        
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/more.svg`}
                            className="w-5"
                        />

                    </button>

                </div>

            </div>  

            { dropdown && 
                
                <DropdownModal 
                    onSetShow={setDropdown}
                    title={props.item.name}
                    classList="contact-item-dropdown group-last:md:!top-0 group-first:md:!top-full"
                >
                    { !props.isUser && 
                        <button onClick={showShareBox} className="p-4 md:text-base text-lg flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full hover:text-gray-700 text-gray-600 duration-300">
                        دعوت

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/share.svg`}
                            className="w-6"
                        />

                    </button>
                    }

                    <Link to={`/edit-contact/${props.item.id}`} className="p-4 md:text-base flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-primary duration-300 relative">
                        ویرایش

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/edit-2.svg`}
                            className="w-7 absolute left-[15px]"
                        />

                    </Link>

                    <button onClick={doDelete} className="p-4 md:text-base flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full hover:text-rose-600 text-rose-500 duration-300">
                        حذف

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-2.svg`}
                            className="w-6"
                        />

                    </button>
                    
                </DropdownModal>

            }

            { !props.isUser && !navigator.share && showShareDropdown &&
                <ConatctShareDropdown 
                    name={props.item.name}
                    show={showShareDropdown}
                    setShow={setShowShareDropdown}
                />
            }

        </li>
    )

}

export default ContactItem