import { useState } from "react"
import CaseFinancialsDetailPrice from "./DetailPrice"
import CaseFinancialsPercentage from "./Percentage"
import CaseFinancialsSetMoney from "./SetMoney"
import CaseFinancialsTabs from "./Tabs"
import CaseFinancialsItems from "./Items"
import { useSelector } from "react-redux"

import LoadingDot from "../../../../UI/LoadingDot"
import ToastCustom from "../../../../UI/ToastCustom"
import { ReactSVG } from "react-svg"
import { Link } from "react-router-dom"


const CaseFinancials = ({financials, financials_total_income, financials_total_expense, money, data, ...props}) => {

    const deleteLoading = useSelector(state => state.finanicalCase.deleteLoading)
    const errorMessage = useSelector(state => state.finanicalCase.errorMessage)
    const successMessage = useSelector(state => state.finanicalCase.successMessage)

    const [activeTab, setActiveTab] = useState(0)

    if(money){

        return (
            <>

                <CaseFinancialsPercentage 
                    income={financials_total_income}
                    expense={financials_total_expense}
                    money={Number(money)}
                />

                <hr className="mt-10" />  

                <CaseFinancialsTabs 
                    activeTab={activeTab}
                    onSetActiveTab={setActiveTab}
                />

                <CaseFinancialsItems 
                    activeTab={activeTab}
                    financials={financials}
                /> 

                <Link to={ `add-finanical` } className={`header-btn fixed lg:bottom-4 lg:left-4 md:bottom-[104px] bottom-24 left-4 mx-auto z-[1] w-10 h-10`}>

                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`} className="w-[85%] h-[85%]" />

                </Link>




                { deleteLoading && <LoadingDot /> }

                { (errorMessage)  && 
                    
                    <ToastCustom>
                        
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { errorMessage }

                        </div>

                    </ToastCustom>

                }

                { successMessage && 

                    <ToastCustom>
                                            
                        <div className="flex items-center gap-2">
                            
                            <ReactSVG
                                className="text-green-600 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                            />

                            { successMessage }

                        </div>

                    </ToastCustom>

                }
    
            </>
        )

    }

    return (
        <>
            <CaseFinancialsSetMoney 
                data={data}

                firstData={ props?.firstData }
                onSetFirstData={  props?.onSetFirstData }
                
                changes={ props?.changes}
                onSetChanges={  props?.onSetChanges }
                
                onSetData={  props?.onSetData }
            />
        </>
    )

}

export default CaseFinancials