import { useState } from "react"
import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import moment from "jalali-moment"

import Dropdown from "../../../UI/Dropdown"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { deleteEventFromList } from "../../../../redux/action/events/eventsAction"
import { DELETE_ITEM_EVENT, DELETE_ITEM_EVENT_DAY } from "../../../../redux/action/events/eventsType"
import { DELETE_EVENT_FROM_HOME_INFO } from "../../../../redux/action/home/homeType"
import { DELETE_EVENT_FROM_SINGLE_CASE } from "../../../../redux/action/cases/casesType"
import PublicStatusForClient from "./PublicStatus"
import SmsNotificationStatus from "./SmsNotificationStatus"

const EventDateItem = (props) => {

    const dispatch = useDispatch()
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const singlesCase = useSelector(state => state.cases.singlesCase)

    const [dropdown, setDropdown] = useState(false)
    const [time] = useState( moment(props.time).utcOffset('+0330').format('HH:mm').split(':') )
    const [showTooltipClient, setShowTooltipClient] = useState(false)
    const [showTooltipSms, setShowTooltipSms] = useState(false)

    const [cookies] = useCookies(['authToken'])

    const dontGoToAnotherPage = (event) => {
        event.preventDefault()

        setDropdown(true)
        document.body.classList.add('modal-opened')
    }

    const deleteEvent = async () => {

        setDropdown(false)
        document.body.classList.remove('modal-opened')

        const data = await dispatch( await deleteEventFromList(props.id, props.date, cookies.authToken) )
        
        if(data){

            // remove from home-page
            if(homeInfoLoaded){

                dispatch({
                    type: DELETE_EVENT_FROM_HOME_INFO,
                    payload: props.id
                })

            }

            // remove of single-case
            if(props?.item?.law_suit && props?.item?.law_suit?.id && singlesCase[props?.item?.law_suit?.id]){

                dispatch({
                    type: DELETE_EVENT_FROM_SINGLE_CASE,
                    payload: {
                        id: props?.item?.law_suit?.id,
                        eventId: props.id
                    }
                })

            }

        }

    }

    const chooseIconType = () => {
        if(props.type === 'دادگاه')
            return 'court'

        else if(props.type === 'قرارملاقات')
            return 'time'

        else if(props.type === 'جلسه' || props.type === 'جلسه رسیدگی') 
            return 'calculate'

        else if( props.type === 'تنظیم لایحه' || props.type === 'تنظیم لایحه یا هرمتنی' )
            return 'calculate'

        else
            return 'bell'

    }

    const chooseTextType = () => {
        if(props.type === 'دادگاه')
            return 'دادگاه'

        else if( props.type === 'قرارملاقات' )
            return 'قرار ملاقات'
        
        else if(props.type === 'جلسه' || props.type === 'جلسه رسیدگی') 
            return 'جلسه'

        else if( props.type === 'تنظیم لایحه' || props.type === 'تنظیم لایحه یا هرمتنی')
            return 'تنظیم لایحه'

        else
            return 'رویداد'
    }

    const selectItemForDelete = () => {
        props.onSetSelectedItemDelete(props.id)
    }

    const chooseActiveItem = (event) => {
        
        if( (props.selectedItemDelete === null) && (!event.target.closest('.tooltip-for-client') && !event.target.closest('.tooltip-for-sms')) ) return

        event.preventDefault()

        if(!event.target.closest('.tooltip-for-client') && !event.target.closest('.tooltip-for-sms'))
            selectItemForDelete()

        else if(!showTooltipClient && event.target.closest('.tooltip-for-client')){
            setShowTooltipClient(true)

            setTimeout(() => {
                
                setShowTooltipClient(false)
                
            }, 1500);
        }
        else if(!showTooltipSms && event.target.closest('.tooltip-for-sms') ){

            setShowTooltipSms(true)

            setTimeout(() => {
                
                setShowTooltipSms(false)

            }, 1500);

        }
    }

    return (
        <li className="relative w-full h-auto">
            
            <Link 
                to={`/events/${props.id}`}
                state={props.item}
                className={`group w-full h-full shadow-custom-md bg-white border border-gray-50 rounded-2xl p-4 flex flex-col gap-2 ${props.isStarted && 'opacity-80'}`}
                onContextMenu={selectItemForDelete}   
                onClick={chooseActiveItem} 
            >

                <div className="flex items-center justify-between flex-wrap gap-4 mb-1 border-b borde-b-gray-200 pb-3 relative">

                    <div className={`flex items-center gap-1.5 text-primary text-lg font-medium ${props.isStarted && 'opacity-[0.85]'}`}>

                        <div className="flex items-center justify-center text-xs bg-primary w-6 h-6 rounded-lg outline outline-2 outline-primary/10 text-white">
                            <span className="mt-0.5"> {props.count} </span>
                        </div>

                        <span> | </span>

                        <ReactSVG 
                            className="w-4"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/${chooseIconType()}.svg`}
                        />

                        <span className="text-base"> {chooseTextType()} </span>                  

                        <span> - </span>      

                        <span> {`${Number(time[0]) <= 9 ? '۰' + Number(time[0]).toLocaleString('fa') : Number(time[0]).toLocaleString('fa')}:${Number(time[1]) <= 9 ? '۰' + Number(time[1]).toLocaleString('fa') : Number(time[1]).toLocaleString('fa')}`} </span>
                        
                    </div>

                    <div className="flex items-center gap-2">

                        { props?.send_sms ?
                            <SmsNotificationStatus
                                showTooltipSms={showTooltipSms}
                            />
                            : ''
                        }


                        <button onClick={dontGoToAnotherPage} className="w-10 h-10 hover:text-primary absolute left-0 top-0 flex items-start justify-end">
                        
                            <ReactSVG 
                                className="w-4 h-4 mt-1"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/more.svg`}
                            />
                        
                        </button>

                    </div>
                
                </div>    
                
                <div className={`border-b-0 flex flex-col gap-1.5 ${((props.case || props.isPublic)) && '!border-b border-b-gray-200 pb-3 mb-3 h-full'}`}>

                    {props.title && 
                        <span className={`group-hover:text-second duration-300 ${props.isStarted && 'line-through decoration-gray-500'}`}> {props.title} </span>            
                    }

                    {props.body && 
                        <p className="text-gray-500 text-justify text-sm-13px leading-6"> {props.body} </p>
                    }
                    
                </div>

                { (props.case || props.isPublic) && 
                
                    <div className={`flex items-center flex-wrap gap-4 justify-between mt-auto`}>

                        <div>
                            { props.case &&
                                <span className={`text-sm px-3 py-1.5 bg-third text-white border-third rounded-lg flex items-center justify-center gap-1`}>
                                    
                                    <ReactSVG 
                                        className="w-4"
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/cases.svg`}
                                    />

                                    {props.case}

                                </span>
                            }
                        </div>
                        
                        { props.isPublic && 
                            <PublicStatusForClient 
                                showTooltipClient={showTooltipClient}
                            />
                        }
                         
                    </div>

                }

                <ReactSVG 
                    className="w-20 h-20 absolute left-0 right-0 top-0 bottom-0 m-auto text-primary opacity-5"
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/${chooseIconType()}.svg`}
                />

            </Link>


            { dropdown && 
                
                <Dropdown 
                    onSetShow={setDropdown}
                    title={props.title}
                    classList="!top-0 !left-0 !right-auto !w-48"
                >

                    <button onClick={deleteEvent} className="p-4 5xm:text-sm text-xs flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300">
                        حذف

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-2.svg`}
                            className="5xm:w-5 w-4 xm:-translate-x-1 text-gray-600 -translate-x-0.5"
                        />

                    </button>
                    
                </Dropdown>

            }

            { props.selectedItemDelete === props.id && 
                
                <ReactSVG
                    className="w-7 h-7 text-green-600 rounded-full absolute bottom-3 left-3"
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                />

            }

        </li>
    )

}

export default EventDateItem