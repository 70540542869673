import axios from "axios"
import { apiKey } from '../../store'

import { FAILURE_LOCATION, REQUEST_LOCATION, SUCCESS_LOCATION } from "./locationType"

const apiKeyLocation = apiKey + 'lawyer/base-data'

export const getLocation = async (authToken) => {

    return async (dispatch) => {

        dispatch({
            type: REQUEST_LOCATION
        })

        const config = {
            timeout: 15000,
            'header_app_type': 'lawyer'
        }

        const data = await axios.get(apiKeyLocation, config)
        .catch(error => {
            
            dispatch({
                type: FAILURE_LOCATION,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SUCCESS_LOCATION,
                    payload: data.data.data?.locations
                })

            }

            else if(!data.data.success && data.data.errros){

                dispatch({
                    type: FAILURE_LOCATION,
                    payload: data.data.errros[0]?.message
                })

            }

            return data.data.success
        }

    }

}