import Input from "../../../../../../UI/Input"

const CaseAddFileCaption = ({caption, onSetCaption}) => {

    const updateField = (event) => {

        if(event.target.value.length <= 100)
            onSetCaption(event.target.value)

    }

    return (
        <div className="flex gap-3 flex-col-reverse">
                        
            <Input 
                id="caption" 
                className="peer bg-white shadow-custom-lg rounded-xl p-3 px-4 w-full !outline-none border-gray-300/60 focus:!border-primary border transition-colors duration-300" 
                max={100}
                value={caption}
                onInput={updateField}
                labelSize=""
                label={'عنوان'}
                labelColor=""
                labelClass={'w-full block'}
                icon={'title'}
            />

        </div>
    )

}

export default CaseAddFileCaption