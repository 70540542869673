import { useEffect, useState } from "react";
import CaseFinancialsItem from "./Item";
import DeleteHeaderFinancial from "./DeleteHeader";

const CaseFinancialsItems = ({activeTab, financials}) => {

    const [items, setItems] = useState([]) 
    const [selectedItemDelete , setSelectedItemDelete] = useState(null)

    useEffect(() => {

        return () => {
            document.body.classList.remove('modal-opened')
        }

    }, [])

    useEffect(() => {

        if(activeTab === 0){
            setItems(financials)
        }
        else if(activeTab === 1){

            const newFinancials = financials.filter(item => item.type === 'expense')

            setItems(newFinancials)

        }
        else if(activeTab === 2){
            const newFinancials = financials.filter(item => item.type === 'income')

            setItems(newFinancials)
        }

    }, [activeTab, financials])

    return (
        <>

            { selectedItemDelete &&
                
                <DeleteHeaderFinancial
                    selectedItemId={selectedItemDelete}
                    onSetSelectedItemDelete={setSelectedItemDelete}

                    items={items}
                />

            }

            <ul className="grid md:grid-cols-2 grid-cols-1 gap-6">
                
                { items.map(item => 
                    
                    <CaseFinancialsItem 
                        key={`case-financials-${item.id}`}
                        data={item}

                        selectedItemDelete={selectedItemDelete}
                        onSetSelectedItemDelete={setSelectedItemDelete}
                    />  

                )}

            </ul>
        </>
    )

}

export default CaseFinancialsItems