import { useEffect, useState } from "react"
import Header from "../components/Header"
import HeaderProfileBtn from "../components/Header/ProfileBtn"
import HomeContent from "../components/Home"
import HeaderSupportBtn from "../components/Header/SupportBtn"
import { useLocation } from "react-router-dom"
import { add } from "date-fns-jalali"
import { useCookies } from "react-cookie"
import HomeWelcomeMessage from "../components/Home/WelcomeMessage"

const Home = () => {

    const [showWelcomeModal, setShowWelcomeModal] = useState(false)

    const location = useLocation()

    const [cookies, setCookies] = useCookies(['authToken'])
    
    useEffect(() => {

        window.scrollTo({top: 0})

        if( (location.state && location.state?.token) ){
            const token = location.state?.token

            if(token && token !== ''){
                const expireDate = add( new Date(), {months: 3} )
                setCookies('authToken',  token, {path: '/',  expires: expireDate, sameSite: "lax"})
            }
            
        }

        if(location.state && location.state?.newUser){
            setShowWelcomeModal(true)
        }

    }, [])

    return (
        <>
            <Header 
                title={'وکیل‌ سان'}
            >

                <HeaderSupportBtn />
                <HeaderProfileBtn />

            </Header>

            <main className="main-content">
                
                <HomeContent />

                { showWelcomeModal && 
                    
                    <HomeWelcomeMessage 
                        onSetWelcomeMessage={setShowWelcomeModal}
                    />

                }

            </main>

        </>
    )

}

export default Home