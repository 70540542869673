import { ReactSVG } from "react-svg"

const PublicStatusForClient = ({showTooltipClient, color = null, ...props}) => {

    return (
        <div className="tooltip-for-client cursor-pointer flex items-center gap-1 relative" onClick={props.onClick}>
            <span className={`tooltip-for-client ${ color ? color : 'text-rose-500' } text-sm font-medium`}>  موکل </span>
            <ReactSVG 
                src={`${process.env.PUBLIC_URL}/assets/images/icons/eyes.svg`}
                className={`tooltip-for-client w-4 h-4 ${ color ? color : 'text-rose-500' }`}
            />

            { showTooltipClient &&
                <span className="text-sm bg-white border z-10 border-gray-200 p-1 absolute block w-40 text-center rounded-md left-0 top-full shadow-custom-lg mt-1.5 text-gray-800">قابل مشاهده توسط موکل</span>
            }
            
        </div>
    )

}

export default PublicStatusForClient