import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg";

const CaseFinancialsPercentage = ({income, expense, money}) => {
    
    const [wentPercentage, setWentPercentage] = useState(0)
    
    const [givedMoneyPercentage, setGivedMoneyPercentage] = useState(0)

    useEffect(() => {

        if( expense > income ){
            const came = Math.round(income / Math.abs( expense / 100 ))
            const went = 100 - came

            setWentPercentage(went)
        }
        else if(income !== 0 && income > expense){
            
            const per = (expense / (income)) * 100
            
            setWentPercentage(per)

        }

        setGivedMoneyPercentage( (income - expense) > 1 ? ((income - expense) / ((money))) * 100 : ((income - expense) / (money)) * 100 )

    }, [income, expense])

    return (
        <section>

            <div className="flex w-fit justify-between flex-col items-center gap-5 mx-auto mb-8"> 

                <div className="flex select-none items-center ml-auto justify-start gap-1 text-gray-500 relative">

                    <ReactSVG
                        className="text-primary w-6"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/money.svg`}
                    />


                    <span> حق‌الوکاله : </span>
                    <span className="font-medium text-lg text-gray-700"> {Math.round(Number(money)).toLocaleString('fa')} </span>
                    <span> تومان </span>

                </div>

                <div className="order-2">

                    <ul className="flex flex-col gap-3.5 select-none">

                        <li className="flex items-center gap-1 text-gray-500 relative pr-5 before:absolute before:right-0 before:top-1.5 before:w-3 before:h-3 before:bg-primary before:rounded-sm">

                            <span className="flex-shrink-0"> جمع دریافت : </span>
                            <span className="font-medium text-gray-700"> {Math.round(Number(income)).toLocaleString('fa')} </span>
                            <span> تومان </span>


                            {/* <>
                                <span> </span>
                                <span> </span>

                                <span className="text-primary"> ( </span>
                                <span className="font-medium text-lg text-primary"> {Math.round(Number(income > expense ? '100' : camePercentage)).toLocaleString('fa')} ٪ </span>
                                <span className="text-primary"> ) </span>
                            </> */}

                        </li>

                        <li className="flex items-center flex-wrap gap-1 text-gray-500 relative pr-5 before:absolute before:right-0 before:top-1.5 before:w-3 before:h-3 before:bg-red-500 before:rounded-sm">

                            <span className="flex-shrink-0"> جمع هزینه : </span>
                            <span className="font-medium text-gray-700"> {Math.round(Number(expense)).toLocaleString('fa')} </span>
                            <span> تومان </span>

                            <>
                                <span> </span>
                                <span> </span>

                                <div className="flex items-center gap-1">

                                    <span className="text-red-500"> ( </span>
                                    <span className="font-medium text-lg text-red-600"> {Math.round(Number(wentPercentage)).toLocaleString('fa')} ٪ </span>
                                    <span className="text-red-500"> ) </span>
                                
                                </div>
                            </>

                        </li>

                        <li className="flex items-center flex-wrap gap-1 text-gray-500 relative pr-5 before:absolute before:right-0 before:top-1.5 before:w-3 before:h-3 before:bg-green-500 before:rounded-sm">

                            <span className="flex-shrink-0"> درآمد خالص : </span>
                            <span className={`font-medium text-gray-700 ${givedMoneyPercentage <= -1 && 'text-rose-600'}`}> {Math.round(Number(income - expense)).toLocaleString('fa')} </span>
                            <span> تومان </span>


                            <>
                                <span> </span>
                                <span> </span>

                                <div className="flex items-center gap-1">
                                    <span className={`${givedMoneyPercentage <= -1 ? 'text-gray-600' : 'text-green-600'} drop-shadow-xl`}> ( </span>
                                    <span className={`${givedMoneyPercentage <= -1 ? 'text-gray-600' : 'text-green-600 '} font-medium text-lg drop-shadow-xl`}> {Math.round(Number(givedMoneyPercentage)).toLocaleString('fa')} ٪ </span>
                                    <span className={`${givedMoneyPercentage <= -1 ? 'text-gray-600' : 'text-green-600'} drop-shadow-xl`}> ) </span>
                                </div>
                            </>

                        </li>

                        <li className="flex items-center flex-wrap gap-1 text-gray-500 relative pr-5 before:absolute before:right-0 before:top-1.5 before:w-3 before:h-3 before:bg-gray-300 before:border before:border-gray-400/20 before:rounded-sm">

                            <span className="flex-shrink-0"> مانده تسویه : </span>
                            <span className={`font-medium text-gray-700 `}> {Math.round(Number(money - income + expense)).toLocaleString('fa')} </span>
                            <span> تومان </span>


                            <>
                                <span> </span>
                                <span> </span>

                                <div className="flex items-center gap-1">
                                    <span className={`text-gray-600 drop-shadow-xl`}> ( </span>
                                    <span className={`text-gray-600 font-medium text-lg drop-shadow-xl`}> {Math.round(Number(100 - givedMoneyPercentage)).toLocaleString('fa')} ٪ </span>
                                    <span className={`text-gray-600  drop-shadow-xl`}> ) </span>
                                </div>
                            </>

                        </li>

                    </ul>

                </div>

                <div className="order-1 skill relative w-36 h-36">

                    <div className="shadow-percentage flex items-center justify-center w-full h-full rounded-full">

                        <div className="shadow-percentage-inner select-none flex items-center justify-center w-28 h-28 rounded-full">

                            { income < expense  ?
                                <span className="text-lg text-gray-600 translate-y-0.5"> <b className="font-medium">{Math.round(Number(-wentPercentage)).toLocaleString('fa')}</b> ٪ </span>
                                :
                                <span className="text-lg text-gray-600 translate-y-0.5"> <b className="font-medium">{Math.round(Number(givedMoneyPercentage)).toLocaleString('fa')}</b> ٪ </span>
                            }

                        </div>  

                    </div>
                    
                    { expense > income && wentPercentage >= 100 ? 
                        <svg className="w-36 h-36 absolute top-0 left-0 rotate-[270deg]">
                            <circle style={{strokeDasharray: 400, strokeDashoffset: `calc(400 - (400 * ${100}) / 100)`, strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent stroke-rose-500" cx="71" cy="73" r="64"></circle>
                        </svg>
                        :
                        expense > income && wentPercentage >= 1 &&
                            <svg className="w-36 h-36 absolute top-0 left-0 rotate-[270deg]">
                                <circle style={{strokeDasharray: 400, strokeDashoffset: `calc(400 - (400 * ${wentPercentage}) / 100)`, strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent stroke-rose-500" cx="71" cy="73" r="64"></circle>
                            </svg>
                    }

                    { expense < income && givedMoneyPercentage >= 100 ? 
                        <svg className="w-36 h-36 absolute top-0 left-0 rotate-[270deg]">
                            <circle style={{strokeDasharray: 400, strokeDashoffset: `calc(400 - (400 * ${100}) / 100)`, strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent stroke-green-500" cx="71" cy="73" r="64"></circle>
                        </svg>
                        :
                        expense < income && givedMoneyPercentage >= 6 ?
                            <svg className="w-36 h-36 absolute top-0 left-0 rotate-[270deg]">
                                <circle style={{strokeDasharray: 400, strokeDashoffset: `calc(400 - (400 * ${givedMoneyPercentage}) / 100 )`, strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent stroke-green-500" cx="71" cy="73" r="64"></circle>
                            </svg>
                        :
                        expense < income && givedMoneyPercentage >= 1 &&
                            <svg className="w-36 h-36 absolute top-0 left-0 rotate-[270deg]">
                                <circle style={{strokeDasharray: 400, strokeDashoffset: `calc(400 - (400 * ${givedMoneyPercentage}) / 100)`, strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent stroke-green-500" cx="71" cy="73" r="64"></circle>
                            </svg>
                    }

                    { expense < income && givedMoneyPercentage >= 100 ? 
                        <svg className="w-36 h-36 absolute top-0 left-0 rotate-[270deg]">
                            <circle style={{strokeDasharray: 400, strokeDashoffset: `calc(400 - (400 * ${100}) / 100)`, strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent stroke-green-500" cx="71" cy="73" r="64"></circle>
                        </svg>
                        :
                        expense < income && givedMoneyPercentage >= 1 &&
                            <svg className="w-36 h-36 absolute top-0 left-0 rotate-[270deg]">
                                <circle style={{strokeDasharray: 400, strokeDashoffset: `calc(400 - (400 * ${givedMoneyPercentage}) / 100)`, strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent stroke-green-500" cx="71" cy="73" r="64"></circle>
                            </svg>
                            
                    }

                    { expense < income && (100 - givedMoneyPercentage) >= 100 ? 
                        <svg className="w-36 h-36 absolute top-0 left-0 rotate-[255deg]">
                            <circle style={{strokeDasharray: 400, strokeDashoffset: -(400 - (400 * (100 - givedMoneyPercentage)) / 100), strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent  stroke-gray-200" cx="71" cy="73" r="64"></circle>
                        </svg>
                        :
                        expense < income && (100 - givedMoneyPercentage) >= 6 ?
                            <svg className="w-36 h-36 absolute top-0 left-0 rotate-[255deg]">
                                <circle style={{strokeDasharray: 400, strokeDashoffset: -(400 - (400 * (100 - givedMoneyPercentage)) / 100) - 33, strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent  stroke-gray-200" cx="71" cy="73" r="64"></circle>
                            </svg>
                        :
                        expense < income && (100 - givedMoneyPercentage) >= 1 &&
                            <svg className="w-36 h-36 absolute top-0 left-0 rotate-[270deg]">
                                <circle style={{strokeDasharray: 400, strokeDashoffset: -(400 - (400 * (100 - givedMoneyPercentage)) / 100), strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent  stroke-gray-200" cx="71" cy="73" r="64"></circle>
                            </svg>
                    }

                    { (expense > income || expense === 0 && income === 0) && (100 - wentPercentage) >= 100 ? 
                        <svg className="w-36 h-36 absolute top-0 left-0 rotate-[255deg]">
                            <circle style={{strokeDasharray: 400, strokeDashoffset: -(400 - (400 * (100 - wentPercentage)) / 100), strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent  stroke-gray-200" cx="71" cy="73" r="64"></circle>
                        </svg>
                        :
                        (expense > income || expense === 0 && income === 0) && (100 - wentPercentage) >= 6 ?
                            <svg className="w-36 h-36 absolute top-0 left-0 rotate-[255deg]">
                                <circle style={{strokeDasharray: 400, strokeDashoffset: -(400 - (400 * (100 - wentPercentage)) / 100) - 33, strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent  stroke-gray-200" cx="71" cy="73" r="64"></circle>
                            </svg>
                        :
                        (expense > income || expense === 0 && income === 0) && (100 - wentPercentage) >= 1 &&
                            <svg className="w-36 h-36 absolute top-0 left-0 rotate-[270deg]">
                                <circle style={{strokeDasharray: 400, strokeDashoffset: -(400 - (400 * (100 - wentPercentage)) / 100), strokeWidth: 10, strokeLinecap: "round"}} className="fill-transparent  stroke-gray-200" cx="71" cy="73" r="64"></circle>
                            </svg>
                    }


                </div>
            
            </div>
                    
            {/* <div className="order-2 leading-9 bg-white p-4 rounded-xl shadow-custom-md my-6">
                
                <div className="flex items-center gap-1">

                    <ReactSVG
                        className="text-second w-5"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/question.svg`}
                    />

                    <span className="font-medium text-second"> راهنما </span>
                
                </div>

                { income - expense >= 1 ?
                    
                    <div className="text-gray-700">
                        تاکنون مبلغی که باید (با احتساب هزینه‌ها) دریافت کنید <span className="text-primary font-medium"> {Number(money + expense).toLocaleString('fa')} تومان </span> می‌باشد و شما تنها <span className="text-green-600 font-medium"> {Number(income).toLocaleString('fa')} تومان </span> دریافت کردید.  برای تسویه کامل پرونده <span className="text-rose-500 font-medium"> {Number((money + expense) - income).toLocaleString('fa')} تومان </span> آن باقی‌مانده است.
                    </div>
                    
                    :

                    <div className="text-gray-700">
                        تاکنون مبلغی که باید برای این پرونده (با احتساب هزینه‌ها) دریافت کنید <span className="text-primary font-medium"> {Number(money + expense).toLocaleString('fa')} تومان </span> می‌باشد و شما <span className="text-rose-600 font-medium"> {Number(income - expense).toLocaleString('fa')} تومان </span> در ضرر هستید.
                    </div>

                }

            </div> */}

        </section>
    )

}

export default CaseFinancialsPercentage