import { useEffect, useState } from "react"
import AddEventFormSuggest from "./Suggest"
import moment from "jalali-moment";
import { useDispatch, useSelector } from "react-redux";

import { SET_CAN_I_SEND_DATA_EVENTS, SET_ENTERED_DATA_EVENTS, SET_FIELDS_EVENTS } from "../../../../redux/action/events/eventsType";

import Switch from "../../../UI/Switch"
import Input from "../../../UI/Input"
import AddEventFormCustomTimeListColck from "./CustomTime/List/Clock";
import AddEventFormCustomTimeField from "./CustomTime"
import { useLocation } from "react-router-dom";
import Textarea from "../../../UI/Textarea";
import AddEventFormCasesField from "./Cases";
import { ReactSVG } from "react-svg";
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../utils/base-data";
import { getBaseDataLawyer } from "../../../../redux/action/baseData/baseAction";
import { SET_SUCCESS_BASEDATA } from "../../../../redux/action/baseData/baseType";
import { useCookies } from "react-cookie";

const AddEventForm = (props) => {

    const dispatch = useDispatch()
    const enteredData = useSelector(state => state.events.enteredData)
    const differentDate = useSelector(state => state.events.computerDifferentTime)
    const reciveTimeFromServer = useSelector(state => state.events.serverDateObject)
    const fields = useSelector(state => state.events.fields)
    
     // for switch messages
     const baseDataEventSwitchs = useSelector(state => state.baseData.info?.config?.terms_event)
     const baseDataLoading = useSelector(state => state.baseData.loading)
     const baseDataError = useSelector(state => state.baseData.error)
     const baseDataLoaded = useSelector(state => state.baseData.loaded)

    const [time] = useState(moment( new Date().valueOf() + (differentDate ? differentDate : 0) ).format('HH:mm'))


    const [loaded, setLoaded] = useState(false) 
    const [error, setError] = useState({})

    const location = useLocation()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        getSwitchMessagesFromBaseData()

    }, [])

    useEffect(() => {

        const dateClock = moment( new Date().valueOf() + differentDate ).format('HH:mm')

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                title: {value: '', max: 50, isValid: true, prefix: ''},
                showEventToMovakel: false,
                caseInfo: location?.state?.caseInfo ? location?.state?.caseInfo : {},
                description: {value: '', min: 3, isValid: true},
                alaram_at: {
                    clock: '',
                    date: '',
                    label: '',
                    englishDate: '',
                },
                clock: dateClock,
                sendSms: false
            }
        })

    }, [ differentDate, loaded ])

    useEffect(() => {

        const timeout = setTimeout(() => {
            
            checkExistError()

        }, 500)

        return () => {
            if(timeout)
                clearTimeout(timeout)
        }

    }, [fields])

    const getSwitchMessagesFromBaseData = async () => {
        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }
    }

    // do a bit work
    const addSuggestToTitleFields = (text) => {
        
        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                title: {
                    ...fields.title,
                    value: text,
                    isValid: true,
                }
            }
        })

        dispatch({
            type: SET_ENTERED_DATA_EVENTS,
            payload: true
        })

        if(error && error.title){
                
            delete error['title']

            setError({
                ...error,
            })
            
        }

    }

    // direct update fields  
    const updateTitleFields = (event) => {
        
        if(event.target.value.length >= 51)
            return

        if(event.target.value.length <= 2){

            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    title: {
                        ...fields.title,
                        value: event.target.value,
                        isValid: false,
                    }
                }
            })

            setError({
                ...error,
                title: true
            })

            dispatch({
                type: SET_ENTERED_DATA_EVENTS,
                payload: true
            })
        }
        else {

            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    title: {
                        ...fields.title,
                        value: event.target.value,
                        isValid: true,
                    }
                }
            })

            if(error && error.title){
                
                delete error['title']

                setError({
                    ...error,
                })
                
            }
        }

    }

    const updateShowToMovakelField = (value) => {

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                showEventToMovakel: value
            }
        })

    }

    const updateSendSmsField = (value) => {

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                sendSms: value
            }
        })

    }

    const updateDescriptionFields = (event) => {
        
        if(event.target.value.length > 300) return

        if(event.target.value.length >= 1 && event.target.value.length <= 2){

            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    description: {value: event.target.value, min: fields.description.min, isValid: false}
                }
            })

            setError({
                ...error,
                description: true
            })            

        }
        
        else {

            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    description: {
                        value: event.target.value, 
                        min: fields.description.min, 
                        isValid: true
                    }
                }
            })

            if(error && error.description){
                
                delete error['description']

                setError({
                    ...error,
                })
                
            }

        }
    }

    const updateCaseInfoField = (id, text) => {

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                caseInfo: {
                    id,
                    text
                }
            }
        })

    }

    const updateClockField = ({hour, min}) => {

        if(hour){
            
            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    clock: hour + ':' + fields.clock.split(':')[1]
                }
            })

        }

        else if(min){
            
            dispatch({
                type: SET_FIELDS_EVENTS,
                payload: {
                    ...fields,
                    clock: fields.clock.split(':')[0] + ':' + min
                }
            })

        }

    }

    // alaram_at
    const updateAlarmClock = (time, label, englishDate) => {
  
        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                alaram_at: {
                    clock: time,
                    date: fields.alaram_at.date,
                    label: label ? label : '',
                    englishDate:  englishDate ? englishDate : ''
                }
            }
        })

    }

    const updateAlaramDate = (date, time, label, englishDate) => {
        
        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                alaram_at: {
                    clock: time ? time : fields.alaram_at.clock,
                    date,
                    label: label ? label : '',
                    englishDate: englishDate ? englishDate : ''
                }
            }
        })

    }

    const resetAlarmData = () => {

        dispatch({
            type: SET_FIELDS_EVENTS,
            payload: {
                ...fields,
                alaram_at: {
                    clock: '',
                    date : '',
                    label: '',
                    englishDate: ''
                }
            }
        })
        
    }

    const checkExistError = () => {

        if(!enteredData || Object.keys(error).length >= 1 || fields.title.value === ''){
            dispatch({
                type: SET_CAN_I_SEND_DATA_EVENTS,
                payload: false
            })
        }
        else {
            dispatch({
                type: SET_CAN_I_SEND_DATA_EVENTS,
                payload: true
            })
        }

    }

    // const timestamp = 1697796983572 / 1000; // تبدیل به ثانیه

    // const jalaliDate = fromUnixTime(1628620819);
    // const jalaliDates = Date.parse('2021-08-10T18:40:19.000Z') / 1000;

    // console.log(jalaliDate);
    // console.log(jalaliDates);

    return (
        <form className="flex flex-col gap-8">
            
            <div className="flex flex-col gap-8 rounded-2xl shadow-custom-lg border-[3px] border-white p-4 relative mt-6">

                <div className="rounded-2xl bg-custom-gray w-fit mx-auto left-0 right-0 top-0 absolute -translate-y-1/2 shadow-custom-lg border-[3px] font-medium border-white px-4 p-3 text-gray-700">
                    { `${Number(props.persianDate.split('/')[0]).toLocaleString('fa').split('٬').join('')}/${Number(props.persianDate.split('/')[1]) < 10 ? '۰' + Number(props.persianDate.split('/')[1]).toLocaleString('fa') : Number(props.persianDate.split('/')[1]).toLocaleString('fa') }/${Number(props.persianDate.split('/')[2]) < 10 ? '۰' + Number(props.persianDate.split('/')[2]).toLocaleString('fa') : Number(props.persianDate.split('/')[2]).toLocaleString('fa')}` }
                </div>

                <div className="md:w-fit md:ml-auto flex flex-col gap-2.5">

                    <span className="text-sm text-gray-600"> زمان : </span>

                    {fields.clock && reciveTimeFromServer?.year ?
                    
                        <AddEventFormCustomTimeListColck 
                            time={ moment( new Date().valueOf() +  differentDate ).format('HH:mm') }
                            onSetHourOfTime={updateClockField}
                            onSetMinOfTime={updateClockField}
                        />
                        :
                        <div className="h-48 w-64 bg-white relative overflow-hidden mx-auto flex items-center justify-between border border-gray-200 rounded-2xl">
                            <ReactSVG 
                                className="w-12 mx-auto text-gray-400"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            />
                        </div>
                    }

                </div>

                <div className="grid xm:grid-cols-2 grid-cols-1 gap-8">

                    <Input
                        id={'event-title'}
                        label={'عنوان'}
                        max={fields.title.max}
                        icon={'title'}
                        value={ fields.title.prefix + fields.title.value}
                        isValid={fields.title.isValid}
                        onChange={ updateTitleFields }
                    />

                </div>

                <AddEventFormSuggest 
                    onAddToTitle={addSuggestToTitleFields}
                    isActivePrefix={fields.title.value}
                />

                <hr />

                <div className="grid xm:grid-cols-2 grid-cols-1 gap-8">
                    
                    <AddEventFormCustomTimeField
                        persianDate={props.persianDate}
                        clock={fields.clock}
                        alaram_at={fields.alaram_at}

                        OnUpdateAlaramClock={updateAlarmClock}
                        onUpdateAlaramDate={updateAlaramDate}
                        onResetAlarmData={resetAlarmData}  
                    />

                    <AddEventFormCasesField 
                        caseInfo={fields.caseInfo}
                        onUpdateCaseInfoField={updateCaseInfoField}
                        loading={false}
                        mainCase={ props.info && props?.info?.law_suit?.title ? {text: props.info.law_suit.title, id: props.info.law_suit.id} : null }
                    />

                </div>

                <hr />

                <Textarea 
                    rows={4}
                    icon={'description'}
                    label={'توضیحات'}
                    max={300}
                    value={fields.description.value}
                    isValid={fields.description.isValid}
                    onChange={(event) => updateDescriptionFields(event)}
                    loading={false}
                /> 

                <hr />

                    <div className="grid md:grid-cols-2 grid-cols-1 items-center 3xm:gap-4 gap-5">
                                    
                        <Switch 
                            name={'sendSms'}
    
                            title={ (baseDataError || baseDataLoading || !baseDataEventSwitchs?.sms_term) ? 'ارسال پیامک یادآوری در زمان هشدار' : baseDataEventSwitchs?.sms_term }
                            description={ (baseDataError || baseDataLoading || !baseDataEventSwitchs?.sms_term) ? 'با کسر هزینه 200 تومان از کیف پول ' : baseDataEventSwitchs?.sms_desc }
                            
                            value={fields.sendSms}
                            onUpdateSwitchValue={updateSendSmsField}
                        />

                    </div>

                <hr />

                <div className="grid md:grid-cols-2 grid-cols-1 items-center 3xm:gap-4 gap-5">
                                
                    <Switch 
                        name={'showEventToMovakel'}

                        title={ (baseDataError || baseDataLoading || !baseDataEventSwitchs?.event_term) ? 'نمایش رویداد در اپلیکیشن موکل' : baseDataEventSwitchs?.event_term }
                        description={ (baseDataError || baseDataLoading || !baseDataEventSwitchs?.event_desc) ? ' فقط به موکل هایی که مجوز مشاهده دارند. ' : baseDataEventSwitchs?.event_desc }

                        value={fields.showEventToMovakel}
                        onUpdateSwitchValue={updateShowToMovakelField}
                        loading={false} 
                    />

                </div>

            </div>

        </form>
    )

}

export default AddEventForm