import { SET_FAILURE_AUTH, SET_MOBILE_AUTH, SET_REQUEST_AUTH, SET_SUCCESS_AUTH, DISABLE_LOADING_AUTH } from './authType'

import { apiKey } from '../../store'
import axios from 'axios'
const apiKeyAuth = apiKey + 'auth'

export const loginRequest = async (payload) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_REQUEST_AUTH
        })

        const config = {
            timeout: 15000,
            'header_app_type': 'lawyer'
        }

        // go to api and send mobile 
        const data = await axios.post(`${apiKeyAuth}/login`, payload, config)
        .catch(error => {
            // handle error
            dispatch({
                type: SET_FAILURE_AUTH,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        // if my function was confirm by server
        if(data){
            
            if(data.data.success){
                
                dispatch({
                    type: SET_MOBILE_AUTH,
                    payload: payload.mobile
                })   

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_AUTH,
                    payload: data.data.errors[0]?.message
                })

            }

            // tel me was my function success or no
            return {
                success: data.data?.success,
                isNewUser: data.data?.data?.new_user
            }

        }

    }

}

export const verifyRequest = async (payload) => {

    return async (dispacth) => {
        
        dispacth({
            type: SET_REQUEST_AUTH
        })

        const data =  await axios.post(`${apiKeyAuth}/verify`, payload)
        .catch(error => {
            dispacth({
                type: SET_FAILURE_AUTH,
                error: 'عدم برقراری ارتباط با سرور'
            })
        })

        // if can go to server and could get info
        if(data){

            if(data.data.success){

                dispacth({
                    type: SET_SUCCESS_AUTH,
                    payload: {
                        token: data.data.data.token,
                        mobile: payload.mobile
                    }
                })

            }

            else if(!data.data.success && data.data.errors){
                dispacth({
                    type: SET_FAILURE_AUTH,
                    payload: data.data.errors[0]?.message
                })
            }

            // give token to him
            return data.data?.data?.token
        }

    }

}

export const logoutRequest = (authToken) => {

    return () => {

        const config = {
            
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            }

        }

        const data = axios.post(`${apiKeyAuth}/logout`, null ,config)
        .catch(error => {})

    }

}