import { useEffect, useState } from "react"

import SelectClientTypeButton from "../../components/SelectClientType/Button"
import SelectClientTypeContent from "../../components/SelectClientType/Content"
import { useCookies } from "react-cookie"
import { useLocation, useNavigate } from "react-router-dom"
import SelectClientTypeTrustImages from "../../components/SelectClientType/TrustImages"

const SelectClientType = () => {

    const [type, setType] = useState(0)

    const [cookies] = useCookies(['authToken'])
    
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        if( isTWA() ){
            localStorage.setItem('selected-client-type', 'lawyer')
            navigate('/auth/login', {replace: true})
        }

        checkLogged()

        checkOnlineStatus()

    }, [])

    const isTWA = () => {
        // Check if display-mode is standalone (used for TWA)
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        
        // Additional check for userAgent (optional, based on common patterns)
        const isAndroid = navigator.userAgent.includes('Android');
        const isInTWA = navigator.userAgent.includes('TWA');
    
        // If either condition is true, consider it TWA
        return isStandalone || (isAndroid && isInTWA);
    };

    const checkLogged = () => {
            
        // is logged -> go gome
        if(cookies.authToken){
            navigate('/', {replace: true})
        }

        // is not login
        else {
            const clienyType = localStorage.getItem('selected-client-type')

            // if is selected lawyer -> go to lawyer login
            if(clienyType && clienyType === 'lawyer')
                navigate('/auth/login', {replace: true})

            else if(clienyType){

                if(window.localStorage.href.includes('.ir')){
                    window.location.href = 'https://client.vakilsun.ir/auth/login'
                }
                else
                    window.location.href = 'https://client.vakilsun.com/auth/login'

            }

        }

    }

    const checkOnlineStatus = () => {
        
        if(!navigator.onLine){
            navigate('/offline', {state: {url: location.pathname}})
        }

    }

    return (

        <section className="flex flex-col gap-6 w-full items-center py-10 px-5 max-w-lg mx-auto">
                
            <h1 className="font-medium text-gray-900 mb-3 text-lg">  به وکیل‌سان خوش آمدید!  </h1>

            <h2 className="text-lg mb-6 text-gray-700"> نوع کاربری خود را انتخاب کنید </h2>

            <SelectClientTypeContent 
                type={type}
                onSetType={setType}
            />

            <SelectClientTypeButton 
                type={type}
            />

            <SelectClientTypeTrustImages />

            <a href="https://blog.vakilsun.com" target="_blank" className="text-blue-500 mt-2 flex items-center gap-2 justify-center underline underline-offset-8">

                <span> آشنایی با وکیل‌سان </span>

            </a>

        </section>

    )

}

export default SelectClientType