import { useLocation, useNavigate, useParams } from "react-router-dom"
import Header from "../../../../components/Header"
import Button from "../../../../components/UI/Button"
import { useDispatch, useSelector } from "react-redux"
import SelectContactCaseDetail from "../../../../components/Cases/Add/Contacts/SelectContact/Detail"
import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import { ADD_CLIENT_IN_ADD_FORM_CASE, ADD_CONTACTS_IN_ADD_FORM_CASE, DELETE_CLIENT_FROM_ADD_FORM_CASE, DELETE_CONTACTS_FROM_ADD_FORM_CASE, EDIT_ITEM_CLIENT_IN_ADD_FORM_CASE, EDIT_ITEM_CONTACTS_IN_ADD_FORM_CASE, RESET_DETAIL_OF_CASE, SET_SHOW_CONTACTS_BOX } from "../../../../redux/action/cases/add/type"
import { ADD_CLIENT_IN_EDIT_FORM_CASE, ADD_CONTACTS_IN_EDIT_FORM_CASE, DELETE_CLIENT_FROM_EDIT_FORM_CASE, DELETE_CONTACTS_FROM_EDIT_FORM_CASE, EDIT_ITEM_CLIENT_IN_EDIT_FORM_CASE, EDIT_ITEM_CONTACTS_IN_EDIT_FORM_CASE, RESET_DETAIL_OF_CASE_EDIT_CASE, SET_SHOW_CONTACTS_BOX_EDIT_CASE } from "../../../../redux/action/cases/edit/type"

const SelectContactsCaseDetail = () => {

    const dispatch = useDispatch()
    const contacts = useSelector(state => state.contacts.contacts)
    const detailInfo = useSelector(state => state.addCase.detail)
    const detailInfoEdit = useSelector(state => state.editCase.detail)

    const [contact, setContact] = useState('')

    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {

        window.scrollTo({top: 0})

        if(location.state?.data && contacts.length >= 1){
            setContact( location.state.data )
        }

        else if(contacts.length >= 1 && params.id){
            const contact =  contacts.filter(item => item.id == params.id)

            if(contact[0])
                setContact( contact )
        }

        else if( location.state.updatedPaege ){
            setContact( location.state.data )
        }

        else {
            navigate('/law-suits/add')
        }

    }, [])

    const addToCases = () => {
        
        // come add
        const data = {
            id: contact.id,
            name: contact.name,
            mobile: contact.mobile,
            notify: detailInfo?.notify,
            position: detailInfo?.position, 

            image: contact?.user?.image
        }

        if( location.state?.edit && location.state?.forMovakels){

            dispatch({
                type: EDIT_ITEM_CLIENT_IN_ADD_FORM_CASE,
                payload: data
            })

        }
        else if(location.state?.edit && !location.state?.forMovakels){

            dispatch({
                type: EDIT_ITEM_CONTACTS_IN_ADD_FORM_CASE,
                payload: data
            })

        }
        else if(location.state?.forMovakels){

            dispatch({
                type: ADD_CLIENT_IN_ADD_FORM_CASE,
                payload: data
            })

        }  
        else {

            dispatch({
                type: ADD_CONTACTS_IN_ADD_FORM_CASE,
                payload: data
            })

        }

        // close show-contacts-box
        dispatch({
            type: SET_SHOW_CONTACTS_BOX,
            payload: false
        })

        // reset detail-info
        dispatch({
            type: RESET_DETAIL_OF_CASE,
        })

        navigate('/law-suits/add', {state: {scroll: true, scrollToBottom: !location.state?.forMovakels}, replace: true})

    }

    const deleteItem = () => {
        
        if(location.state?.forMovakels && params.id && !location.state?.updatedPaege){

            dispatch({
                type: DELETE_CLIENT_FROM_ADD_FORM_CASE,
                payload: params.id
            })

        }
        else if(params.id && !location.state?.forMovakels && !location.state?.updatedPaege){
         
            dispatch({
                type: DELETE_CONTACTS_FROM_ADD_FORM_CASE,
                payload: params.id
            })
            
        }
        else if(location.state?.forMovakels && params.id && location?.state?.updatedPaege){

            dispatch({
                type: DELETE_CLIENT_FROM_EDIT_FORM_CASE,
                payload: params.id
            })

        }
        else if(params.id && !location.state?.forMovakels && location?.state?.updatedPaege){
         
            dispatch({
                type: DELETE_CONTACTS_FROM_EDIT_FORM_CASE,
                payload: params.id
            })
            
        }

        dispatch({
            type: RESET_DETAIL_OF_CASE,
        })

        dispatch({
            type: RESET_DETAIL_OF_CASE_EDIT_CASE,
        })

        navigate(location?.state?.caseId ? `/law-suits/${location?.state?.caseId}/update` : -1, {state: {scroll: true, scrollToBottom: !location.state?.forMovakels, dontResetContactsInEdit: true, cases: location?.state?.cases}, replace: true})
    }

    const editFromCases = () => {
        
        // come add
        const data = {
            id: contact.id,
            name: contact.name,
            mobile: contact.mobile,
            notify: detailInfoEdit?.notify,
            position: detailInfoEdit?.position, 

            image: contact?.user?.image
        }

        if( location.state?.edit && location.state?.forMovakels){

            dispatch({
                type: EDIT_ITEM_CLIENT_IN_EDIT_FORM_CASE,
                payload: data
            })

        }
        else if(location.state?.edit && !location.state?.forMovakels){

            dispatch({
                type: EDIT_ITEM_CONTACTS_IN_EDIT_FORM_CASE,
                payload: data
            })

        }
        else if(location.state?.forMovakels){

            dispatch({
                type: ADD_CLIENT_IN_EDIT_FORM_CASE,
                payload: data
            })

        }  
        else {

            dispatch({
                type: ADD_CONTACTS_IN_EDIT_FORM_CASE,
                payload: data
            })

        }

        // close show-contacts-box
        dispatch({
            type: SET_SHOW_CONTACTS_BOX_EDIT_CASE,
            payload: false
        })

        // reset detail-info
        dispatch({
            type: RESET_DETAIL_OF_CASE_EDIT_CASE,
        })

        navigate(location?.state?.caseId ? `/law-suits/${location?.state?.caseId}/update` : -1, {state: {scroll: true, scrollToBottom: !location.state?.forMovakels, dontResetContactsInEdit: true, cases: location?.state?.cases}, replace: true})

    }

    return (
        <>
            
            <Header 
                title={ (location.state?.edit && location.state?.forMovakels) ? 'ویرایش موکل' : (location.state?.edit && !location.state?.forMovakels) ? 'ویرایش مخاطب پرونده' : location.state?.forMovakels ? 'افزودن موکل' : 'افزودن مخاطب پرونده' }
                backBtn={true}
            >

                { location.state?.edit &&
                 
                    <Button 
                        className={'header-btn p-0 bg-white outline-white border-0 text-rose-600'}
                        disabled={false}
                        onClick={deleteItem}
                    >

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-2.svg`} 
                            className="w-[70%] h-[70%]" 
                        />
                            
                    </Button>

                }
                
                <Button 
                    className={'header-btn p-0'}
                    disabled={false}
                    onClick={ (!location?.state?.editMode && !location?.state?.updatedPaege)  ? addToCases : editFromCases }
                >

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/tick-2.svg`} 
                        className="w-[70%] h-[70%]" 
                    />
                         
                </Button>

            </Header>

            <main className="main-content">
                
                <SelectContactCaseDetail
                    user={contact}
                    editMode={location?.state?.editMode}
                    updatedPage={location?.state?.updatedPaege}
                />

            </main>

        </>
    )

}

export default SelectContactsCaseDetail