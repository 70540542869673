import axios from 'axios'
import { apiKey } from '../../store'
import { SET_FAILURE_BASEDATA, SET_LOADING_BASEDATA, SET_SUCCESS_BASEDATA } from './baseType'

const apiKeyBaseData = apiKey + 'lawyer/base-data'

export const getBaseDataLawyer = async (authToken) => {

    return async (dispatch) => {

        dispatch({
            type: SET_LOADING_BASEDATA,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyBaseData}`, config)
        .catch(error => {

            dispatch({
                type: SET_FAILURE_BASEDATA,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_SUCCESS_BASEDATA,
                    payload: data.data.data
                })

                return data.data.data
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FAILURE_BASEDATA,
                    payload: 'خطایی در گرفتن اطلاعات ب وجود اومده'
                })

            }

        }

    }

}