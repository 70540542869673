import { format, fromUnixTime, parse, setMinutes, sub, add } from "date-fns-jalali";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { useCookies } from "react-cookie";

import { getBaseDataLawyer } from "../../../redux/action/baseData/baseAction";
import { AddEventToList } from "../../../redux/action/events/eventsAction";

import Header from "../../../components/Header";
import Button from "../../../components/UI/Button";
import AddEventForm from "../../../components/Events/Add/Form";
import ToastCustom from "../../../components/UI/ToastCustom";
import LoadingDot from "../../../components/UI/LoadingDot";
import moment from "jalali-moment";
import { ADD_EVENT_TO_HOME_INFO } from "../../../redux/action/home/homeType";
import { ADD_EVENT_TO_SINGLE_CASE } from "../../../redux/action/cases/casesType";

const AddEvent = () => {

    const dispatch = useDispatch()
    const canISendData = useSelector(state => state.events.canISendData)
    const fields = useSelector(state => state.events.fields)
    const error = useSelector(state => state.events.error)
    const loading = useSelector(state => state.events.loading)
    const homeInfoLoaded = useSelector(state => state.home.loaded)
    const singlesCase = useSelector(state => state.cases.singlesCase)
    const differentServerDate = useSelector(state => state.events.computerDifferentTime)

    const [persianDate, setPersianDate] = useState('')
    const [englishDate, setEnglishDate] = useState('')

    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        setEnglishDate( params.date.split('-').join('/') )
        
        if(location?.state?.persianEnglishDate){
            setPersianDate(location?.state?.persianEnglishDate)
            return
        }

        else
            getPersianDate()
        
            
        window.scrollTo({top: 0})

    }, [])

    const getPersianDate = () => {

        let month = params.date.slice(5, 7)
        month = Number(month) - 1
        const time = new Date( params.date.slice(0, 4), month , params.date.slice(8) )
        const date = format(time, 'yyyy/MM/dd', { jalali: true })

        setPersianDate( date )
    }

    const addEvent = async () => {

        // make data
        const data = {
            title: fields.title.value,
            type: fields.type ? fields.type : '',
            description: fields.description.value,
            law_suit_id: fields.caseInfo.id ? fields.caseInfo.id : null,
            is_public: fields.showEventToMovakel,
            send_sms: fields.sendSms,
            
            alaram_at: null,
            alarm_at: null,
            starts_at: null,
            finishes_at: null,
            is_started: false,

            // my structure
            event_date: null,
            event_alaram_at: null,
        }

        // get Clock
        const clock = fields.clock.split(':')
        const hours = clock[0].trim()
        const mins =  clock[1].trim()
        

        // Set Event Time To English
        let startEventDateEnglish = moment(params.date).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 30), seconds: 0}).format('YYYY:MM:DD HH:mm:ss')
        // const startEventDateEnglishValue = moment(params.date).set({hours: (Number(hours)), minutes: (Number(mins))}).valueOf()
        data.starts_at = startEventDateEnglish
        data.event_date = startEventDateEnglish

        // if exist alarm
        if( fields.alaram_at.clock ){
            // Set Alarm Event Time To English
            const alarmClock = fields.alaram_at.clock.split(':')
            const alarmHours = alarmClock[0].trim()
            const alarmMins =  alarmClock[1].trim()

            let alarmEventTimeEnglish 
            let alarmEventTimeEnglishValue

            const date = fields.alaram_at.englishDate.split('/')

            // custom
            if(fields.alaram_at.label !== 'یک روز قبل' && fields.alaram_at.label === ''){
                alarmEventTimeEnglish = moment( fields.alaram_at.englishDate.split('/').join('-') ).set({hours: (Number(alarmHours) - 3), minutes: (Number(alarmMins) - 30), seconds: 0}).format('YYYY:MM:DD HH:mm:ss')
                alarmEventTimeEnglishValue = moment( fields.alaram_at.englishDate.split('/').join('-') ).set({hours: (Number(alarmHours) - 3), minutes: (Number(alarmMins) - 30), seconds: 0}).valueOf()
            }
            // moment
            else if (fields.alaram_at.label === 'در لحظه'){
                alarmEventTimeEnglish = moment( params.date ).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 31), seconds: 0}).format('YYYY:MM:DD HH:mm:ss')
                alarmEventTimeEnglishValue = moment( params.date ).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 31), seconds: 0}).valueOf()
            }
            // hours
            else if(fields.alaram_at.label.includes('ساعت')){
                const result = getRefreshedAlarmDateHours(fields.alaram_at.englishDate.split('/').join('-'), Number(hours), Number(mins), fields.alaram_at.label)
                alarmEventTimeEnglish = result.time
                alarmEventTimeEnglishValue = result.timeValue
            }
            // 1 day
            else if( Number(hours) <= 2 ){
                alarmEventTimeEnglish = moment( `${date[0]}-${date[1]}-${Number(date[2])}` ).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 30), seconds: 0}).format('YYYY:MM:DD HH:mm:ss')
                alarmEventTimeEnglishValue = moment( `${date[0]}-${date[1]}-${Number(date[2])}` ).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 30), seconds: 0}).valueOf()
            }
            else{
                alarmEventTimeEnglish = moment( fields.alaram_at.englishDate.split('/').join('-') ).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 30), seconds: 0}).format('YYYY:MM:DD HH:mm:ss')
                alarmEventTimeEnglishValue = moment( fields.alaram_at.englishDate.split('/').join('-') ).set({hours: (Number(hours) - 3), minutes: (Number(mins) - 30), seconds: 0}).valueOf()
            }            
            
            // add to data info
            data.alarm_at = alarmEventTimeEnglish
            data.alaram_at = alarmEventTimeEnglish
            
            data.event_alaram_at = alarmEventTimeEnglish

        }

        // send data to server
        const repsonse = await dispatch( await AddEventToList(data, cookies.authToken) )
        
        if(repsonse){

            // // add event to Home and SingleCase
            if(homeInfoLoaded){
                // check if is time for today, or, tomorrow come add to home
                let now = add(new Date( new Date().valueOf() + differentServerDate), {days: 2}).valueOf()
                const eventTime = Date.parse( moment(repsonse.starts_at) )

                if(eventTime <= now){
                    // come add event to home
                    dispatch({
                        type: ADD_EVENT_TO_HOME_INFO,
                        payload: repsonse
                    })
                }
            }

            // // come add event to the cases - if selected a case :))
            if(data?.law_suit_id && singlesCase && singlesCase[data?.law_suit_id]){

                dispatch({
                    type: ADD_EVENT_TO_SINGLE_CASE,
                    payload: {
                        caseId: data?.law_suit_id,
                        data: repsonse
                    }
                })

            }     
            
            console.log( !location?.state?.forAddCase );
 
            // // push another page
            if(!location?.state?.forAddCase)
                navigate( location.key !== 'default' ? -1 : `/events/date/${data.event_date.slice(0, 10)}` , {replace: true})
            else    
                navigate( `/law-suits/${data?.law_suit_id}` , {replace: true})

        }
        
    }

    const getRefreshedAlarmDateHours = (date, hours, mins, label) => {
        
        let time
        let timeValue

        if( label.includes('یک') ){
            time = moment( date ).set({hours: (Number(hours) - 4), minutes: (Number(mins) - 31)}).format('YYYY:MM:DD HH:mm:ss')
            timeValue = moment( date ).set({hours: (Number(hours) - 4), minutes: (Number(mins) - 31)}).valueOf()
        }

        else if( label.includes('دو') ){
            time = moment( date ).set({hours: (Number(hours) - 5), minutes: (Number(mins) - 31)}).format('YYYY:MM:DD HH:mm:ss')
            timeValue = moment( date ).set({hours: (Number(hours) - 5), minutes: (Number(mins) - 31)}).valueOf()
        }

        else if( label.includes('سه') ){
            time = moment( date ).set({hours: (Number(hours) - 6), minutes: (Number(mins) - 31)}).format('YYYY:MM:DD HH:mm:ss')
            timeValue = moment( date ).set({hours: (Number(hours) - 6), minutes: (Number(mins) - 31)}).valueOf()
        }

        else if( label.includes('چهار') ){
            time = moment( date ).set({hours: (Number(hours) - 7), minutes: (Number(mins) - 31)}).format('YYYY:MM:DD HH:mm:ss')
            timeValue = moment( date ).set({hours: (Number(hours) - 7), minutes: (Number(mins) - 31)}).valueOf()
        }

        return { time, timeValue }
    }

    return (
        <>
            
            <Header 
                title={'تنظیم رویداد'}
                backBtn={true}
            >
                
                <Button 
                    className={'profile-btn bg-primary py-1 px-4'}
                    disabled={!canISendData && !loading}
                    onClick={addEvent}
                >
                    ایجاد     
                </Button>

            </Header>

            <main className="main-content">

                <AddEventForm 
                    persianDate={persianDate}
                    englishDate={englishDate}
                />

                { error && 
                    
                    <ToastCustom>
                    
                        <div className="flex items-center gap-2">
                            <ReactSVG
                                className="text-rose-500/90 4xm:w-8 w-6"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                            />

                            { error }

                        </div>

                    </ToastCustom>

                }

                { loading && <LoadingDot /> }

            </main>

        </>
    )

}

export default AddEvent