import { fromUnixTime, setDate } from "date-fns-jalali";
import moment from "jalali-moment";
import { ReactSVG } from "react-svg";
import CaseContentInfoDetailCategory from "./Category";
import CaseContentInfoDetailStep from "./Step";
import CaseContentInfoDetailLocation from "./Location";
import { useSelector } from "react-redux";

const CaseContentInfoDetail = (props) => {

    const location = useSelector(state => state.location.list)

    const updateCategoryField = (value) => {

        props.onSetData({
            ...props.data,
            category: value
        })

    }

    const updateStepField = ( value ) => {
        
        props.onSetData({
            ...props.data,
            step: value
        })

    }

    const updateBranchField = ( event ) => {

        if(event.target.value.length > 60) return

        props.onSetData({
            ...props.data,
            branch: event.target.value
        })
        
    }

    const updateLocationField = (data) => {
        
        const province = location.find(item => Number(item.id) === Number(data.province))

        props.onSetData({
            ...props.data,
            province: {
                id: data.province,
                name: province?.name ? province?.name : ''
            },
            city: data.city
        })
       
    }

    const updateAgreedFeeField = (event) => {
        
        const value = event.target.value.split(',').join('')
        const number =  Number(value)?.toLocaleString('en')

        if(number === 'NaN')
            return

        props.onSetData({
            ...props.data,
            agreed_fee: value
        })

    }

    // ----------------------------

    const getPersianDate = () => {
        if(!props.created_at) return '-'

        const jalaliDates = Date.parse(props.created_at) / 1000;
        const jalaliDate = fromUnixTime(jalaliDates);
        const date = moment(jalaliDate).utcOffset('-0330').format('jYYYY/jMM/jDD').split('/')

        const year = Number(date[0]).toLocaleString('fa').split('٬').join('')
        const month = Number(date[1])
        const day = Number(date[2])

        return `${year}/${month <= 9 ? '۰' + month.toLocaleString('fa') : month.toLocaleString('fa')}/${day <= 9 ? '۰' + day.toLocaleString('fa') : day.toLocaleString('fa')}`
    }

    return (
        <div className="md:grid md:grid-cols-2 flex flex-col justify-center gap-x-4 bg-white shadow-custom-md rounded-xl w-full relative">
    
            <div className="grid 5xm:grid-cols-2 gap-x-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="5xm:pt-0 pt-4 flex items-center gap-2 text-gray-600">
          
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/ticket.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className=""> شماره پرونده </h4>

                </div>

                <span className="text-primary font-medium py-4"> {props.code ? props.code : '-'} </span>

            </div>

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/archive_code.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />
  
                    <h4 className=""> شماره بایگانی </h4>

                </div>

                <span className="text-primary font-medium py-4"> {props.archive_code ? props.archive_code : '-'} </span>

            </div>

            {/* <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/judicial.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className=""> مرجع قضایی </h4>

                </div>

                <span className="text-primary font-medium">
                    {props.jurisdiction ? props.jurisdiction : '-'}
                </span>

            </div> */}

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/folder.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className=""> نوع دعوی </h4>

                </div>

                <CaseContentInfoDetailCategory 
                    value={ props.category }
                    firstData={ props.firstData?.category }
                    onUpdateCategoryField={ updateCategoryField }
                />

                { props.changes.includes('category') && 
                    <span className="bg-green-500 outline outline-emerald-200/70 w-2.5 h-2.5 absolute left-2 top-1/2 -translate-y-1/2 rounded-full"></span>
                }

            </div>

            {/* <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 first:pt-4 last:pb-4 last:border-b-0">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/bag.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className=""> وضعیت فعلی پرونده </h4>

                </div>

                <span className="text-primary font-medium">
                    {props.state ? props.state : '-'}
                </span>

            </div> */}

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <svg className="w-5 h-5 text-primary text-opacity-[0.85]" fill="currentColor" viewBox="0 0 20 20"><path d="M5.516 14.224c-2.262-2.432-2.222-6.244.128-8.611a6.074 6.074 0 0 1 3.414-1.736L8.989 1.8a8.112 8.112 0 0 0-4.797 2.351c-3.149 3.17-3.187 8.289-.123 11.531l-1.741 1.752 5.51.301-.015-5.834-2.307 2.323zm6.647-11.959l.015 5.834 2.307-2.322c2.262 2.434 2.222 6.246-.128 8.611a6.07 6.07 0 0 1-3.414 1.736l.069 2.076a8.122 8.122 0 0 0 4.798-2.35c3.148-3.172 3.186-8.291.122-11.531l1.741-1.754-5.51-.3z"></path></svg>

                    <h4 className=""> مرحله رسیدگی </h4>

                </div>

                <CaseContentInfoDetailStep 
                    value={ props?.step }
                    firstData={ props.firstData?.step }
                    onUpdateStepField={ updateStepField }
                />

                { props.changes.includes('step') && 
                    <span className="bg-green-500 outline outline-emerald-200/70 w-2.5 h-2.5 absolute left-2 top-1/2 -translate-y-1/2 rounded-full"></span>
                }

            </div>

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/court-2.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className=""> شعبه رسیدگی </h4>

                </div>

                <div className="flex items-center relative">
                    <input id="branch-case-info-input" onChange={ updateBranchField } type="text" spellCheck={false} className="case-input-field peer text-primary focus:outline-none font-medium bg-transparent w-full text-right py-4" value={ props.branch ? props.branch : '' } />
                    {!props.branch &&
                        <span className="peer-focus:hidden absolute right-0">-</span>
                    }
                </div>

                { props.changes.includes('branch') && 
                    <span className="bg-green-500 outline outline-emerald-200/70 w-2.5 h-2.5 absolute left-2 top-1/2 -translate-y-1/2 rounded-full"></span>
                }

            </div>

            <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                <div className="flex items-center gap-2 text-gray-600">

                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/location.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className=""> استان و شهر </h4>

                </div>

                <CaseContentInfoDetailLocation 
                    city={ props?.data?.city }
                    province={ props?.data?.province }

                    onUpdateLocation={ updateLocationField }
                    onCancleChoose={() => updateLocationField({province: '', city: {id: null, name: ''}})}
                />

                { props.changes.includes('city') && 
                    <span className="bg-green-500 outline outline-emerald-200/70 w-2.5 h-2.5 absolute left-2 top-1/2 -translate-y-1/2 rounded-full"></span>
                }

            </div>

            <div className={`grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 ${props?.agreed_fee && 'md:border-b-0 md:pb-0'}`}>

                <div className="flex items-center gap-2 text-gray-600">
                    
                    <ReactSVG 
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/calculate.svg`}
                        className="w-5 text-primary text-opacity-80"
                    />

                    <h4 className=""> تاریخ ایجاد </h4>

                </div>

                <span className="text-primary font-medium py-4"> { getPersianDate() } </span>

            </div>

            {props?.firstData?.agreed_fee && 
            
                <div className="grid grid-cols-2 gap-4 text-sm border-b border-b-gray-200 last:border-b-0 px-4 relative">

                    <div className="flex items-center gap-2 text-gray-600">
                        
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/money.svg`}
                            className="w-5 text-primary text-opacity-80"
                        />

                        <h4 className=""> حق الوکاله </h4>

                    </div>

                    <div className="flex items-center gap-1.5 text-primary font-medium">

                        <input id="agreed_fee-case-info-input" onChange={ updateAgreedFeeField } type="tel" spellCheck={false} className="case-input-field text-primary focus:outline-none font-medium bg-transparent text-right py-4 w-full peer" value={ props.agreed_fee ? Number(props.agreed_fee).toLocaleString('en') : '' } />
                        
                        <span className="peer-focus:opacity-0 duration-300"> تومان </span>

                        { props.changes.includes('agreed_fee') && 
                            <span className="bg-green-500 outline outline-emerald-200/70 w-2.5 h-2.5 absolute left-2 peer-focus:!top-1/2 duration-300 top-1/3 -translate-y-1/2 rounded-full"></span>
                        }
                        
                    </div>

                </div>

            }

        </div>
    )

}

export default CaseContentInfoDetail