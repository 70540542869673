import { ADD_CLIENT_IN_EDIT_FORM_CASE, ADD_CONTACTS_IN_EDIT_FORM_CASE, ADD_CONTACT_TO_UNIQ_EDIT_CASE, DELETE_CLIENT_FROM_EDIT_FORM_CASE, DELETE_CONTACTS_FROM_EDIT_FORM_CASE, DELETE_USER_FROM_UNIQ_CONTACTS_IN_EDIT, EDIT_ITEM_CLIENT_IN_EDIT_FORM_CASE, EDIT_ITEM_CONTACTS_IN_EDIT_FORM_CASE, RESET_DETAIL_OF_CASE_EDIT_CASE, RESET_FORMS_NOT_VALID_EDIT_CASE, RESTE_ALL_EDIT_CASES_DATA_APP, SET_ALL_CONTACTS_EDIT_CASE, SET_CHANGED_DATA_EDIT_CASE, SET_CLIENTS_EDIT_FORM, SET_CONTACTS_EDIT_FORM, SET_DELETED_INITIAL_CONTACTS_IN_EDIT_CASE, SET_DETAIL_OF_CASE_EDIT_CASE, SET_ERROR_EDIT_CASE, SET_FORMS_DATA_EDIT_CASE, SET_FORMS_NOT_VALID_EDIT_CASE, SET_LOADING_EDIT_CASE, SET_SHOW_CONTACTS_BOX_EDIT_CASE, SET_SUCCSESS_MESSAGE_EDIT_CASE, UPDATE_CONTACT_TO_UNIQ_EDIT_CASE } from "../../action/cases/edit/type"

const initalState = {
    
    showContactsBox: false,
    contactBoxOpened: false,

    detail: {
        id: null,
        position: '',
        notify: true,
    },

    forms: {
        title: {value: '', max: 60, min: 3, isValid: true, require: true},
        code: {value: '', isValid: true, min: 16, max: 18, require: true},
        archive_code: {value: '', isValid: true, min: 3, max: 7},
        step: '',
        category: {
            id: null,
            name: ''
        },
        state: '',
        location: {
            city: {id: null, name: ''},
            province: ''
        },
        branch: {value: '', min: 3, max: 60, isValid: true},
        description: {value: '', min: 3, isValid: true},
        agreed_fee: '',
        jurisdiction: {id: null, name: ''}
    },
    formsNotValid: true,
    
    clients: [],
    contacts: [],

    uniqContacts: [],
    deletedContacts: [],

    loading: false,
    error: null,
    successMessage: null,

    changedData: []
}

const editCaseReducer = (state = initalState, action) => {

    switch(action.type){
        
        case SET_SHOW_CONTACTS_BOX_EDIT_CASE :
            return {
                ...state,
                showContactsBox: action.payload.show,
                contactBoxOpened: action.payload.movakel
            }

        case SET_DETAIL_OF_CASE_EDIT_CASE:
            return {
                ...state,
                detail: action.payload
            }

        case RESET_DETAIL_OF_CASE_EDIT_CASE :
            return {
                ...state,
                detail: {
                    id: null,
                    position: '',
                    notify: true,
                }
            }

        case SET_CLIENTS_EDIT_FORM :
            return {
                ...state,
                clients: [...action.payload]
            }

        case ADD_CLIENT_IN_EDIT_FORM_CASE:
            return {
                ...state,
                clients: [
                    ...state.clients,
                    action.payload
                ],
                deletedContacts: [
                    ...state.deletedContacts,
                    state.uniqContacts.filter(item => item.id == action.payload.id)[0]
                ],
                uniqContacts: state.uniqContacts.filter(item => item.id != action.payload.id)
            }

        case DELETE_CLIENT_FROM_EDIT_FORM_CASE:
            return {
                ...state,
                clients: state.clients.filter(item => item.id != action.payload),
                uniqContacts: [
                    state.deletedContacts.filter(item => item.id == action.payload)[0],
                    ...state.uniqContacts
                ],
                deletedContacts: state.deletedContacts.filter(item => item.id != action.payload)
            }

        case EDIT_ITEM_CLIENT_IN_EDIT_FORM_CASE :
            return {
                ...state,
                clients: state.clients.map(item => {
                    
                    if(item.id === action.payload.id){
                        item = action.payload
                    }

                    return item
                })
            }

        case SET_CONTACTS_EDIT_FORM :
            return {
                ...state,
                contacts: [...action.payload]
            }

        case ADD_CONTACTS_IN_EDIT_FORM_CASE :
            return {
                ...state,
                contacts: [
                    ...state.contacts,
                    action.payload
                ],
                deletedContacts: [
                    ...state.deletedContacts,
                    state.uniqContacts.filter(item => item.id == action.payload.id)[0]
                ],
                uniqContacts: state.uniqContacts.filter(item => item.id != action.payload.id)
            }

        case DELETE_CONTACTS_FROM_EDIT_FORM_CASE :
            return {
                ...state,
                contacts: state.contacts.filter(item => item.id != action.payload),
                uniqContacts: [
                    state.deletedContacts.filter(item => item.id == action.payload)[0],
                    ...state.uniqContacts
                ],
                deletedContacts: state.deletedContacts.filter(item => item.id != action.payload)
            }

        case EDIT_ITEM_CONTACTS_IN_EDIT_FORM_CASE :
            return {
                ...state,
                contacts: state.contacts.map(item => {
                    
                    if(item.id === action.payload.id){
                        item = action.payload
                    }

                    return item
                })
            } 


        case SET_ALL_CONTACTS_EDIT_CASE :
            return {
                ...state,
                uniqContacts: action.payload
            }

        case ADD_CONTACT_TO_UNIQ_EDIT_CASE :
            return {
                ...state,
                uniqContacts: [
                    action.payload,
                    ...state.uniqContacts,
                ]
            }

        case UPDATE_CONTACT_TO_UNIQ_EDIT_CASE :
            return {
                ...state,
                uniqContacts: state.uniqContacts.map(item => {
                    if(item.id === Number(action.payload.id)){
                        return {
                            id: action.payload.id,
                            ...action.payload.data
                        }
                    }

                    return item 
                })
            }

        case DELETE_USER_FROM_UNIQ_CONTACTS_IN_EDIT :
            return {
                ...state,
                uniqContacts: state.uniqContacts.filter(item => Number(item.id) !== Number(action.payload)),
                deletedContacts: state.deletedContacts.filter(item => Number(item.id) !== Number(action.payload))
            }

        case SET_DELETED_INITIAL_CONTACTS_IN_EDIT_CASE :
            return {
                ...state,
                deletedContacts: action.payload
            } 



        case SET_FORMS_DATA_EDIT_CASE :
            return {
                ...state,
                forms: action.payload
            }
            
        case SET_FORMS_NOT_VALID_EDIT_CASE :
            return {
                ...state,
                formsNotValid: action.payload
            }

        case RESET_FORMS_NOT_VALID_EDIT_CASE :
            return {
                ...state,
                forms: {
                    title: {value: '', max: 60, min: 3, isValid: true, require: true},
                    code: {value: '', isValid: true, min: 16, max: 18, require: true},
                    archive_code: {value: '', isValid: true, min: 3, max: 7},
                    step: '',
                    category: {
                        id: null,
                        name: ''
                    },
                    state: '',
                    location: {
                        city: {id: null, name: ''},
                        province: ''
                    },
                    branch: {value: '', min: 3, max: 60, isValid: true},
                    description: {value: '', min: 3, isValid: true},
                    agreed_fee: '',
                    jurisdiction: {id: null, name: ''}
                },
                formsNotValid: true,
                clients: [],
                contacts: [],
                uniqContacts: [
                    ...state.deletedContacts,
                    ...state.uniqContacts,
                ],
                deletedContacts: [],
            }


        case SET_LOADING_EDIT_CASE :
            return {
                ...state,
                loading: action.payload
            }

        case SET_ERROR_EDIT_CASE :
            return {
                ...state,
                error: action.payload,
                loading: false,
                successMessage: null
            }

        case SET_SUCCSESS_MESSAGE_EDIT_CASE :
            return {
                ...state,
                successMessage: action.payload,
                loading: false,
                error: null
            }

        case SET_CHANGED_DATA_EDIT_CASE :
            return {
                ...state,
                changedData: action.payload
            }

        case RESTE_ALL_EDIT_CASES_DATA_APP :
            return {
                ...state,
                ...initalState
            }

        default :
            return state
    
    }

}

export default editCaseReducer