export const getProfileColor = (number) => {

    const colors = ['red', 'blue', 'purple', 'orange', 'green']

    number = Number(number)
    
    if(number < 2)
        return colors[0]

    if(number < 4)
        return colors[1]

    if(number < 6)
        return colors[2]

    if(number < 8)
        return colors[3]

    if(number <= 9)
        return colors[4]

}