import { useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { ReactSVG } from "react-svg"
import { SET_SHOW_CONTACTS_BOX } from "../../../../../redux/action/cases/add/type"
import { SET_SHOW_CONTACTS_BOX_EDIT_CASE } from "../../../../../redux/action/cases/edit/type"

const AddCaseContactsHeader = (props) => {

    const dispatch = useDispatch()

    const showContactsBox = () => { 
        
        if(!props.edit){

            dispatch({
                type: SET_SHOW_CONTACTS_BOX,
                payload: {
                    show: true,
                    movakel: props.location
                }
            })

        }
        else {

            dispatch({
                type: SET_SHOW_CONTACTS_BOX_EDIT_CASE,
                payload: {
                    show: true,
                    movakel: props.location
                }
            })

        }

        document.body.classList.add('modal-opened')

    }

    return (
        <div className="flex items-center flex-wrap gap-4 justify-between">
                
            <h2 className="font-medium"> {props.title} </h2>

            <button onClick={showContactsBox} type="button" className="w-8 h-8 shadow-custom-sm shadow-primary/10 rounded-lg text-white bg-primary flex items-center justify-center gap-2 profile-btn outline outline-2 outline-primary/10">

                <ReactSVG 
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`}
                    className="w-7"
                />

            </button>

        </div>
    )

}

export default AddCaseContactsHeader