import { useDispatch, useSelector } from "react-redux"
import { ReactSVG } from "react-svg"
import CaseAddFileContextItem from "./Item"

const CaseAddFileContext = ({context, onSetContext}) => {

    const dispatch = useDispatch()
    const baseDataContext = useSelector(state => state.baseData.info?.misc?.lawsuit_attach_context)
    const baseDataLoading = useSelector(state => state.baseData.loading)

    if(baseDataLoading || (baseDataContext && baseDataContext?.length >= 1) )
        return (
            <div className="flex gap-5 flex-col">

                <span className="w-full block"> نوع مدرک : </span>
                
                { !baseDataLoading ? 
                    
                    <div className="flex items-center flex-wrap gap-5 gap-y-7">

                        { baseDataContext.map((item, index) => 
                            
                            <CaseAddFileContextItem 
                                key={`context-add-file-${item}`}
                                title={item}
                                index={index}

                                context={context}
                                onSetContext={onSetContext}
                            />

                        )}

                    </div>
                    
                    :

                    <div className="h-20  flex items-center justify-center">
                        
                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                            className="w-10 text-primary"
                        />

                    </div>

                }

            </div>
        )

}

export default CaseAddFileContext