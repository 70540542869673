import CaseContentInfoContactsItem from "./Item"

const CaseContentInfoContacts = ({contacts, ...props}) => {

    return (
        <div className="flex flex-col gap-7">
            
            <h2 className="font-medium text-gray-700 text-lg"> {props.title} </h2>

            { contacts && contacts.length >= 1 ? 
            
                <ul className="grid sm:grid-cols-2 grid-cols-1 gap-4">

                    { contacts.map((item, index) => 
                        
                        <CaseContentInfoContactsItem 
                            key={'person-'+ item?.id}
                            name={item?.name}
                            image={item?.user?.image}
                            mobile={item?.mobile}
                            position={item?.position}
                            isUser={ (item?.user?.name && item?.last_login) ? true : false}
                            userId={item?.user?.id}
                        />

                    )}


                </ul>

                :
                <p className="text-gray-500"> هنوز {props.emptyLabel} برای پرونده ثبت نشده است! </p>
            }

        </div>
    )

}

export default CaseContentInfoContacts