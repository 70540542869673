import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { getProfileInfo } from "../../redux/action/profile/profileAction"

import MainMenu from "../Menu/MainMenu"

import { checkIsNewUser, setIsNewUser } from "../../utils/welcomeRegister"
import SideMenu from "../Menu/SideMenu"
import { SET_COMPUTER_DIFFERRNTTIME, SET_SERVER_DATE_OBJECT } from "../../redux/action/events/eventsType"
import { add } from "date-fns-jalali"
import { checkShouldUpdateBaseData, getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../utils/base-data"
import { SET_SUCCESS_BASEDATA } from "../../redux/action/baseData/baseType"
import { getBaseDataLawyer } from "../../redux/action/baseData/baseAction"
import { getUnSeenMessageCount } from "../../redux/action/messages/chat/chatAction"

const Layout = () => {

    const dispatch = useDispatch()
    const profile = useSelector(state => state.profile.profile)
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const homeInfo = useSelector((state) => state.home.info)

    const [cookies, setCookies] = useCookies(['authToken'])
    
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
            
        // check user is login or no
        if(!cookies.authToken && !location.state?.token){
            navigate('/auth/login', {replace: true})
        }
        else if(location.state && location.state?.token){
            
            const token = location.state?.token

            if(token && token !== ''){
                const expireDate = add( new Date(), {months: 3} )
                setCookies('authToken',  token, {path: '/',  expires: expireDate, sameSite: "lax"})
            }

        }

        // check user is new or no. when user is new should fill a few data
        const isNewUser = checkIsNewUser()
        
        if(isNewUser){
            navigate('/welcome', {replace: true, token: cookies?.authToken ? cookies?.authToken : location.state?.token })
        }

        // becuase profile checked in each opened. so if location is profile come not get profile, 
        else if(location.pathname !== '/profile') {
            // get profile info
            getProfile()
        }

        if(!navigator.onLine){
            navigate('/offline', {state: {url: location.pathname}})
        }

    }, [])

    useEffect(() => {
        
        // if user not new come check exist name or no
        if(!checkIsNewUser())
            checkUserIsNewOrNo()

        // check i should update base data or no
        if(profile?.update_base_data){
            
            const result = checkShouldUpdateBaseData(profile?.update_base_data)
            
            // if should update come do
            if(result){
                updateBaseData()
            }

            if(!homeInfo?.notes){
                // get unseen data of server
                getUnseenCountMessage()
            }

        }
        
    }, [profile])

    const getProfile = async () => {
        // get profile data
        const data = await dispatch( await getProfileInfo(cookies?.authToken ? cookies?.authToken : location.state?.token) )
        
        if(data?.status){
            
            // set server time
            const serverObj = {
                year: Number(data.time?.slice(0, 4)),
                month: Number(data.time?.slice(5, 7)) - 1,
                day: Number(data.time?.slice(8, 10)),
                hours: Number(data.time?.slice(11, 13)) + 3,
                mins: Number(data.time?.slice(14, 16)) + 30,
                seconds: Number(data.time?.slice(17, 19))
            }

            if( serverObj.month >= 12 ){
                serverObj.year = serverObj.year + 1
                serverObj.month = serverObj.month - 12
            }
            if( serverObj.hours >= 24 ){
                serverObj.month = serverObj.month + 1
                serverObj.hours = serverObj.hours - 24
            }
            if( serverObj.mins > 60 ){
                serverObj.hours = serverObj.hours + 1
                serverObj.mins = serverObj.mins - 60
            }
            if( serverObj.seconds > 60 ){
                serverObj.mins = serverObj.mins + 1
                serverObj.seconds = serverObj.seconds - 60 
            }

            dispatch({
                type: SET_SERVER_DATE_OBJECT,
                payload: serverObj
            })

            // server-time
            let serverTimeDate = new Date(serverObj.year, serverObj.month, serverObj.day , serverObj.hours, serverObj.mins, serverObj.seconds).valueOf()

            // get SYSTEM date
            const now = new Date().valueOf()

            // calcuate different   
            const diffTime = serverTimeDate - now

            dispatch({
                type: SET_COMPUTER_DIFFERRNTTIME,
                payload: diffTime
            })

            if(location.state && location.state?.token){
            
                const token = location.state?.token
    
                if(token && token !== ''){
                    const expireDate = add( new Date(), {months: 3} )
                    setCookies('authToken',  token, {path: '/',  expires: expireDate, sameSite: "lax"})
                }
    
            }

        }

    }

    const checkUserIsNewOrNo = () => {
        // if not exist name come go to welcome-register
        if(Object.keys(profile).length >= 1 && !profile.first_name){
            // user is new
            setIsNewUser(true)

            // go to welcome-register
            navigate('/welcome', {replace: true, token: cookies?.authToken ? cookies?.authToken : location.state?.token })
        }
    }

    const updateBaseData = async () => {

        if(baseDataLoading) return

        const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

        if(data){
            
            saveBaseDataInfoToLocaleStorage(data)

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: data
            })

        }

    }

    const getUnseenCountMessage = async () => {
        await dispatch( await getUnSeenMessageCount(cookies.authToken) )
    }

    return (
        <>

            <SideMenu />

            <div className="flex lg:flex-row flex-col">
            
                <MainMenu />

                <div className="lg:mt-4 mt-3 lg:w-3/4 lg:mr-auto lg:mb-0 mb-[92px] lg:px-4 px-3 lg:before:fixed lg:before:w-3/4 lg:before:h-4 lg:before:bg-custom-gray lg:before:bottom-0 lg:before:left-0 before:z-[1]">

                    <Outlet />

                </div>

            </div>
        </>
    )

}

export default Layout