import { ReactSVG } from "react-svg";
import ConfirmModal from "../../../../../UI/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { AddContact } from "../../../../../../redux/action/contacts/contactsAction";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";

const MessageProfileSaveContact = ({userData, onSetShowSaveContact, onSetAddUserToContactMessage, onSetContactId}) => {

    const dispatch = useDispatch()

    const loading = useSelector(state => state.contacts.updateLoading)

    const [cameResponse, setCameResponse] = useState(false)

    const [cookies] = useCookies(['authToken'])

    useEffect(() => {

        if(!loading && cameResponse){
            // hide modal
            onSetShowSaveContact(false)
            document.body.classList.remove('modal-opened')
        }

    }, [cameResponse])

    const addUserToContact = async() => {
        // hide modal
        onSetShowSaveContact(false)
        document.body.classList.remove('modal-opened')
        
        if( !userData?.name  || !userData?.mobile){
            // show message i cants to save this contatcs.
            return
        }

        // add user to contacts
        const info = {
            name: userData?.name,
            mobile: userData?.mobile
        }

        try{
            const data = await dispatch( await AddContact(info, cookies.authToken) )
            
            setCameResponse(true)

            if(data){
                
                // if be success
                if(data?.data?.success){
                    
                    // show success
                    onSetAddUserToContactMessage({
                        type: 'success',
                        message: 'با موفقیت اضافه شد'
                    })

                    onSetContactId(data?.data?.data?.id)

                    setTimeout(() => {

                        onSetAddUserToContactMessage({})
        
                    }, 1750)
                    
                }
    
                // if have error come show error
                else if(!data?.data?.success){
                    
                    onSetAddUserToContactMessage({
                        type: 'error',
                        message: (data?.data?.errors && data?.data?.errors[0]?.message) ? data?.data?.errors[0]?.message : 'نتونستم مخاطب را اضافه کنم'
                    })

                    setTimeout(() => {

                        onSetAddUserToContactMessage({})
        
                    }, 1750)

                }

            }
            else {
                
                // come show error
                onSetAddUserToContactMessage({
                    type: 'error',
                    message: 'عدم برقراری ارتباط با سرور'
                })
    
                setTimeout(() => {

                    onSetAddUserToContactMessage({})
    
                }, 1750)

            }
        }
        catch {

            setCameResponse(true)

            // come show error
            onSetAddUserToContactMessage({
                type: 'error',
                message: 'خطایی در سرور رخ داد'
            })

            setTimeout(() => {

                onSetAddUserToContactMessage({})

            }, 1750)

        }
        
        
        
    }

    return (
        <>
            <ConfirmModal 
                show={true}
                backdropClass={'w-screen h-screen !absoloute top-0 right-0 z-40'}  
                title="مخاطب ذخیره نشده !"
                titleClass="font-medium"
                confirmLoading={loading}
                buttonConfirmText={'افزودن مخاطب'}
                buttonCancelText="انصراف"
                buttonListClass={'4xm:flex-nowrap flex-wrap 4xm:flex-row flex-col'}
                description={'برای نمایش پروفایل، باید کاربر را به مخاطبین خود اضافه کنید.'}
                setShow={onSetShowSaveContact}
                onConfirm={addUserToContact}

            >
                
                <div className="w-14 h-14 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-rose-500">
                    <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/empty.svg`} className="w-10 h-10" />
                </div>

            </ConfirmModal>
        </>
    )

}

export default MessageProfileSaveContact
