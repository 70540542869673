import { useEffect, useState } from "react"

const SelectClientTypeTrustImages = () => {

    const [link, setLink] = useState('')
    const [image, setImage] = useState('')
    const [code, setCode] = useState('')
    
    useEffect(() => {

        if( window.location?.host ===  'vakilsun.com'){
            setLink('https://trustseal.enamad.ir/?id=534400&Code=TY5hE5aAJOpVsjXjTmfufdjIyiipuVm6')
            setImage('https://trustseal.enamad.ir/logo.aspx?id=534400&Code=TY5hE5aAJOpVsjXjTmfufdjIyiipuVm6')
            setCode('TY5hE5aAJOpVsjXjTmfufdjIyiipuVm6')
        }
        else {
            setLink('https://trustseal.enamad.ir/?id=534400&Code=TY5hE5aAJOpVsjXjTmfufdjIyiipuVm6')
            setImage('https://trustseal.enamad.ir/logo.aspx?id=534400&Code=TY5hE5aAJOpVsjXjTmfufdjIyiipuVm6')
            setCode('TY5hE5aAJOpVsjXjTmfufdjIyiipuVm6')
        }

    }, [])

    return (
        <a href={link} referrerPolicy='origin' target='_blank' className="w-24 h-24 mt-14 bg-white shadow-custom-md p-4 rounded-2xl flex items-center flex-wrap gap-4 justify-between border border-gray-200" >
            
            <img referrerPolicy='origin' src={image} alt='enamad image' code={code} />

        </a>
    )

}

export default SelectClientTypeTrustImages