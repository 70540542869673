import { useState } from "react"
import { ReactSVG } from "react-svg"

import Dropdown from "../../../../../../UI/Dropdown"
import { Link, useParams } from "react-router-dom"
import ConfirmModal from "../../../../../../UI/ConfirmModal"
import { DELETE_FINANCIAL_FROM_CASE } from "../../../../../../../redux/action/cases/casesType"
import { useDispatch } from "react-redux"
import { deleteFiancialFromCase } from "../../../../../../../redux/action/cases/casesAction"
import { useCookies } from "react-cookie"
import PublicStatusForClient from "../../../../../../Events/DateItems/Item/PublicStatus"

const CaseFinancialsItem = ({data, onSetSelectedItemDelete, selectedItemDelete}) => {

    const dispatch = useDispatch()

    const [dropdown, setDropdown] = useState(false)
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false)

    const [showTooltipClient, setShowTooltipClient] = useState(false)

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    const showBackDrop = () => {

        if(selectedItemDelete)
            return

        document.body.classList.add('modal-opened')

        setDropdown(true)

    }

    const hideBackDrop = () => {
        
        document.body.classList.remove('modal-opened')

    }

    const openTooltipClient = () => {
        
        if(!showTooltipClient){

            setShowTooltipClient(true)
    
            setTimeout(() => {
                
                setShowTooltipClient(false)
                
            }, 1500);
        
        }

    }

    const showDeleteItemAlert = () => {
        setShowDeleteItemModal(true)
        setDropdown(false)
    }

    const selectItemForDelete = () => {
        onSetSelectedItemDelete(data.id)
    }

    const handleSelectItem = () => {
        
        if(selectedItemDelete)
            onSetSelectedItemDelete(data.id)
        else
            return

    }

    const deleteItem = async () => {  

        document.body.classList.remove('modal-opened')
        setDropdown(false)
        setShowDeleteItemModal(false)


        // should work delete
        const response = await dispatch( await deleteFiancialFromCase(data.id, params.id, data.amount, data.type, cookies.authToken) )

        if(response){

            window.scrollTo({top: 0, behavior: 'smooth'})

        }
       
    }

    return (
        <li onContextMenu={selectItemForDelete} onClick={handleSelectItem} className={`bg-white select-none relative rounded-xl shadow-custom-md p-4 ${selectedItemDelete && 'cursor-pointer'}`}>
                            
            <div className="flex items-center relative justify-between flex-wrap mb-3.5">

                { data.type === 'income' ?

                    <div className="flex items-center gap-0">
                        
                        <ReactSVG
                            className="w-5 text-green-500"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/add.svg`}
                        />

                        <h3 className="text-gray-600 font-medium"> <span className="text-green-500"> دریافت </span> {data.category.includes('دریافت:') ? data.category.slice(7) : data.category} </h3>
                    
                    </div>

                    :

                    <div className="flex items-center gap-2.5">

                        <ReactSVG
                            className="w-2.5 -translate-x-1 text-rose-500"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/subtract.svg`}
                        />

                        <h3 className="text-gray-600 font-medium"> <span className="text-rose-500"> هزینه </span> {data.category && data.category.slice(0, 6).includes('هزینه') ? data.category.slice(5, data.category.length) : data.category} </h3>
                    
                    </div>

                }

                <div className="flex items-center gap-3 ml-6">

                    { data.type === 'income' ? 
                        
                        <div className="bg-green-50 border border-green-500 text-green-600 flex items-center justify-center w-6 h-6 rounded-full">
                            <ReactSVG
                                className="w-4 h-4 rotate-180"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`}
                            />
                        </div>
                        
                        :

                        <div className="bg-red-50 border border-red-500 text-red-600 flex items-center justify-center w-6 h-6 rounded-full">
                            <ReactSVG
                                className="w-4 h-4"
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/down.svg`}
                            />
                        </div>
                    
                    }

                    <button onClick={showBackDrop} className="absolute hover:text-primary duration-300 -translate-x-3 !outline-none flex items-center justify-center w-8 h-8 left-0"> 
                        <ReactSVG
                            className="w-4.5 text-gray-600"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/more.svg`}
                        />
                    </button>

                </div>

            </div>

            <div className="flex flex-wrap items-center justify-between gap-5">

                <div className="flex items-center gap-1">

                    <span className="text-sm text-gray-700"> مبلغ : </span>
                    <span className="font-medium"> {Number(data?.amount).toLocaleString('fa')} </span>
                    <span> تومان </span>

                </div>

                <div className="flex items-center gap-1 text-gray-700">

                    <svg className="w-3 h-3 mb-0.5" viewBox="0 0 24 24" fill="none">
                        <path d="M2.00162 10C2.00052 10.1027 2 10.2067 2 10.312V15.688C2 16.8044 2.05852 17.7698 2.23866 18.5804C2.42133 19.4024 2.74209 20.1251 3.30848 20.6915C3.87488 21.2579 4.59764 21.5787 5.41959 21.7613C6.23018 21.9415 7.19557 22 8.312 22H15.688C16.8044 22 17.7698 21.9415 18.5804 21.7613C19.4024 21.5787 20.1251 21.2579 20.6915 20.6915C21.2579 20.1251 21.5787 19.4024 21.7613 18.5804C21.9415 17.7698 22 16.8044 22 15.688V10.312C22 10.2067 21.9995 10.1027 21.9984 10H2.00162Z" fill="currentColor"></path>
                        <path d="M4.99991 4.34708V3C4.99991 2.44772 5.44762 2 5.99991 2C6.55219 2 6.99991 2.44772 6.99991 3V4.03477C7.41104 4.01008 7.84846 4 8.31191 4H15.6879C16.1514 4 16.5888 4.01008 16.9999 4.03477V3C16.9999 2.44772 17.4476 2 17.9999 2C18.5522 2 18.9999 2.44772 18.9999 3V4.34708C19.649 4.54034 20.2235 4.8406 20.6914 5.30848C21.2578 5.87488 21.5786 6.59764 21.7612 7.41959C21.8024 7.60498 21.8373 7.79846 21.8665 8H2.1333C2.16253 7.79846 2.19737 7.60498 2.23857 7.41959C2.42124 6.59764 2.742 5.87488 3.30839 5.30848C3.77627 4.8406 4.35086 4.54034 4.99991 4.34708Z" fill="currentColor"></path>
                    </svg>

                    <span className="text-xs"> {new Date(data.paid_at ? data.paid_at : data.created_at).toLocaleDateString('fa', {year: 'numeric', month: '2-digit', day: '2-digit'})} </span>

                </div>

            </div>
            
            { ( data.description || data.client) &&
                <div className="mt-auto">

                    { data.client && data.type !== 'expense' && 
                    
                        <hr className="my-4" />  
                    }

                    <div className="flex flex-wrap items-center gap-4 justify-between">

                        <div>
                            
                            { data.client && data.type !== 'expense' && 
                                <>
                                    <span className="2xm:text-sm text-xs"> پرداخت شده توسط </span>
                                    <span className="text-primary 5xm:text-base text-sm"> {data.client.name} </span>
                                </>
                            }

                        </div>
                        

                    </div>   

                    { data.description &&
                        
                        <>
                            <hr className="my-4" />

                            <p className="text-sm text-justify text-gray-700 leading-6"> {data.description} </p>

                        </>
                        
                    }

                    { data.is_public &&
                        
                        <>
                            <hr className="my-4" />

                            <div className="mr-auto w-full flex items-center justify-end">
                                <PublicStatusForClient 
                                    showTooltipClient={showTooltipClient}
                                    onClick={openTooltipClient}
                                    color="text-primary"
                                /> 
                            </div>

                        </>
                        
                    }

                </div>
            }

            { dropdown && 
                
                <Dropdown 
                    onSetShow={setDropdown}
                    title={'salm'}
                    classList="!top-0 !left-0 !right-auto !w-48"
                    backClass="!bg-black/20"
                >

                    <Link to={`/law-suits/${params.id}/edit-finanical/${data.id}`} state={{cases: true}} onClick={hideBackDrop} className="p-4 relative focus:outline-none outline-none flex text-sm items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300">
                            
                        ویرایش

                        <ReactSVG
                            className="w-6 absolute left-2.5"
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/edit-2.svg`}
                        />

                    </Link>

                    <button onClick={ showDeleteItemAlert } className="p-4 text-sm flex items-center justify-between gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-rose-600 duration-300">
                        حذف

                        <ReactSVG 
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-2.svg`}
                            className="w-5 xm:-translate-x-1 text-rose-600 -translate-x-1"
                        />

                    </button>
                    
                </Dropdown>

            }

            { showDeleteItemModal && 
                
                <ConfirmModal
                    title="حذف تراکنش"
                    description="آیا میخواهید این تراکنش را حذف کنید؟"
                    show={showDeleteItemAlert}
                    setShow={setShowDeleteItemModal}
                    onConfirm={deleteItem}
                    className={''}
                    backdropClass={''}
                >   
    
                    <div className="w-14 h-14 bg-white shadow-custom-lg flex items-center justify-center rounded-2xl text-rose-500">
                        <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/delete.svg`} className="w-10 h-10" />
                    </div>
    
                </ConfirmModal>

            }

            { selectedItemDelete === data.id && 

                <div className="absolute top-3.5 left-9 bg-white border border-green-200/80 rounded-full">

                    <ReactSVG   
                        className="w-7 h-7 text-green-600 rounded-full"
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/tick.svg`}
                    />

                </div>

            }

        </li>
    )

}

export default CaseFinancialsItem