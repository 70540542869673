import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import DropdownModal from "../../../../UI/Dropdown"
import CopyToClipboard from "react-copy-to-clipboard"
import ConfirmModalDelete from "../../../../UI/ConfirmModal/Delete"
import { useParams } from "react-router-dom"
import { deleteMessageFromChat } from "../../../../../redux/action/messages/chat/chatAction"
import { useCookies } from "react-cookie"
import { useDispatch } from "react-redux"
import ResendTextInChat from "./Resend"

const MessageContentText = (props) => {

    const dispatch = useDispatch()
    const [showMoreOption, setShowMoreOption] = useState(false)
    
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteBoth, setDeleteBoth] = useState(true)

    const params = useParams()

    const [cookies] = useCookies(['authToken'])

    const handleShowMoreOption = () => {
        setShowMoreOption(true)

        document.body.classList.add('modal-opened')
    }

    const closeShowMoreOption = (value) => {
        setShowMoreOption(value)

        document.body.classList.remove('modal-opened')
    }

    const copyText = () => {

        setTimeout(() => {
            setShowMoreOption(false)
            document.body.classList.remove('modal-opened')
        }, 50);

    }

    const handleShowMessage = (event) => {
        event.stopPropagation()

        setShowDeleteModal(true)
        setShowMoreOption(false)
    }

    const deleteMessage = async () => {
        setShowDeleteModal(false)

        document.body.classList.remove('modal-opened')
        
        const scope = deleteBoth ? 'both' : 'self'
        await dispatch( await deleteMessageFromChat(props.id, params.id, scope, cookies.authToken) )
    }

    if(props.self)
        return (    
            <li 
                className="flex flex-col gap-1.5 relative w-full select-none"
                onClick={handleShowMoreOption}
                onContextMenu={handleShowMoreOption}
            >

                <div className="flex flex-col gap-1.5 lg:max-w-md md:max-w-sm max-w-xs w-fit">
                    
                    <p 
                        className="bg-primary whitespace-pre-wrap py-2 px-4 text-right text-white w-fit shadow-custom-md rounded-2xl break-words rounded-tr-none lg:max-w-md md:max-w-sm max-w-xs"
                        dangerouslySetInnerHTML={{__html: props.body}}
                    >
                        
                    </p>

                    <div className="flex justify-between items-center">
                                        
                        <span className="text-sm-13px text-gray-500"> {new Date(props.time)?.toLocaleTimeString('fa', {hour: '2-digit', minute: '2-digit', timeZone: '+0330'})}  </span>

                        <div className="-translate-y-0.5 text-gray-500">
                            

                            { (props.status === 'loading') ?
                                
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-circle.svg`}
                                    className="w-4 h-4 animate-spin mr-1"
                                />
                                
                                :
                                
                                props?.status === 'error' ?
                                
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/info-box.svg`}
                                    className="w-4 h-4 text-rose-500 mr-1"
                                />
                                
                                :
                                (props.seen) ?
                                
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/seen.svg`}
                                    className="w-4 h-4"
                                />
                                :
                                <ReactSVG 
                                    src={`${process.env.PUBLIC_URL}/assets/images/icons/unseen.svg`}
                                    className="w-4 h-4 ml-1"
                                />

                            }


                        </div>

                    </div>

                </div>

                { showMoreOption && 
                    
                    <DropdownModal
                        onSetShow={closeShowMoreOption}
                        classList={'border-gray-300 xm:!w-48 !border-1 !left-0 !top-0'}
                    >
                        
                        { props?.status === 'error' && 
                            
                            <ResendTextInChat 
                                item={props}
                                onSetShowMoreOption={setShowMoreOption}
                            />

                        }

                        <CopyToClipboard text={props.body} onCopy={copyText}>

                            <button className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/copy-2.svg`} className="5xm:w-4 w-3.5 text-gray-500" />

                                کپی

                            </button>

                        </CopyToClipboard>

                        { props.status !== 'loading' && 
                            <button onClick={handleShowMessage} className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/delete-3.svg`} className="5xm:w-[18px] w-3.5 text-gray-500" />

                                حذف

                            </button>
                        }

                    </DropdownModal>

                }

                { showDeleteModal &&
                    
                    <ConfirmModalDelete
                        title="حذف پیام"
                        description="آیا از حذف این پیام اطمینان دارید؟"
                        show={showDeleteModal}
                        onSetShow={setShowDeleteModal}
                        onConfirm={deleteMessage}
                        selectCount={1}
                    >   

                        <div className="flex justify-center text-gray-600">
                        
                            <input type="checkbox" id="deleteBothInput" className="accent-primary rounded-lg w-5 cursor-pointer" onChange={() => setDeleteBoth(!deleteBoth) } checked={deleteBoth} />

                            <label htmlFor="deleteBothInput" className="select-none cursor-pointer pr-2.5">حذف همچنین برای {props.name}</label>

                        </div>

                    </ConfirmModalDelete>

                }

            </li>
        )
        
    return (    
        <li 
            className="flex flex-col gap-1.5 select-none"
            onClick={handleShowMoreOption}
            onContextMenu={handleShowMoreOption}
        >

            <div className="flex flex-col gap-1.5 mr-auto relative">

                <p 
                    className="bg-white py-2 px-4 text-gray-700 shadow-custom-lg rounded-2xl rounded-tl-none w-fit break-words lg:max-w-md md:max-w-sm max-w-xs"
                    dangerouslySetInnerHTML={{__html: props.body}}
                >
                    
                </p>

                <div className="flex justify-between items-center w-fit lg:max-w-md md:max-w-sm max-w-xs">
                                    
                    <span className="text-sm-13px text-gray-500"> {new Date(props.time)?.toLocaleTimeString('fa', {hour: '2-digit', minute: '2-digit', timeZone: '+0330'})}  </span>

                </div>

                { showMoreOption && 
                        
                    <DropdownModal
                        onSetShow={closeShowMoreOption}
                        classList={`border-gray-300 xm:!w-48 !border-1 ${props.body.length <= 10 ? '!-right-36' : props.body.length <= 20 ? '!-right-20' : '!right-0 !left-0 mx-auto'} !top-0`}
                    >

                        <CopyToClipboard text={props.body} onCopy={copyText}>

                            <button className="p-2.5 text-sm flex outline-none select-none items-center gap-4 flex-wrap border-b border-b-gray-200 last:border-b-0 w-full text-gray-700 duration-300 relative">
                                
                                <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/copy-2.svg`} className="5xm:w-4 w-3.5 text-gray-500" />

                                کپی

                            </button>

                        </CopyToClipboard>

                    </DropdownModal>

                }

            </div>


        </li>
    )

}

export default MessageContentText