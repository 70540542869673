import { ReactSVG } from "react-svg"

const SmsNotificationStatus = ({showTooltipSms, onClick = () => {} }) => {

    return (
        <div className="tooltip-for-sms z-[1] cursor-pointer flex items-center gap-1 relative" onClick={onClick}>
        
            <div className="absolute left-7 -translate-y-0.5 w-8 flex items-center justify-end">
                    
                <ReactSVG 
                    className="w-[18px] text-blue-500"
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/chat-message-notification.svg`}
                />

            </div>

            { showTooltipSms &&
                <span className="text-sm bg-white border z-10 border-gray-200 p-1 absolute block w-48 text-center rounded-md left-0 top-full translate-y-2 shadow-custom-lg mt-1.5 text-gray-800">یادآور پیامکی فعال شده است</span>
            }

        </div>
    )

}

export default SmsNotificationStatus