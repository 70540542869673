import { useCookies } from "react-cookie"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { applyInfoFirstRegister } from "../../redux/action/profile/profileAction"

import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.min.css";

import AuthLogo from "../../components/Auth/SubSection/Logo"
import WelcomeTitle from "../../components/WelcomeSection/Title"
import WelcomeForm from "../../components/WelcomeSection/Form"

import { checkIsNewUser, removeIsNewUser } from '../../utils/welcomeRegister'
import { showErrorMessage, showSuccessMessage } from '../../utils/toast'
import { add } from "date-fns-jalali"

// when user registered should get a few info of him. and this components is for same.
const WelcomeRegister = () => {

    const dispatch = useDispatch()
    const [cookies, setCookies] = useCookies(['authToken'])

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        if(!navigator.onLine){
            navigate('/offline', {state: {url: location.pathname}})
        }

        const isNew = checkIsNewUser()

        if( (location.state && location.state?.token) ){
            const token = location.state?.token

            if(token && token !== ''){
                const expireDate = add( new Date(), {months: 3} )
                setCookies('authToken',  token, {path: '/',  expires: expireDate, sameSite: "lax"})
            }
        }

        if( !isNew || (!location.state?.token && !cookies.authToken)){
            navigate('/auth/login', {replace: true})
        }

    }, [])

    const doApplyInfo = async (data) => {
        // send data to api
        const response = await dispatch( await applyInfoFirstRegister(data, cookies.authToken) )
        
        // if be success
        if(response && typeof response === 'boolean' ){
            // show success message
            showSuccessMessage('با موفقیت اطلاعات ذخیره شد')
            
            // remove isNew from localStorage
            removeIsNewUser()
            localStorage.removeItem('isNewUser')

            const token = cookies?.authToken ? cookies.authToken : location.state?.token ? location.state.token : ''

            if(token && token !== ''){
                const expireDate = add( new Date(), {months: 3} )
                setCookies('authToken',  token, {path: '/',  expires: expireDate, sameSite: "lax"})
            }

            setTimeout(() => {
                
                // go to the home page
                navigate('/', {replace: true, state: {token, newUser: true}})

            }, 300);
        }
        else {
            // show error message
            showErrorMessage(response)
        }

    }

    return (
        <main>
            
            <section className="container w-screen min-h-screen flex items-center justify-center flex-col pt-20 pb-7">

                <div className="bg-white relative p-4 pb-6 shadow-custom-md w-full max-w-lg rounded-3xl pt-20 mt-14">

                    <AuthLogo />

                    <WelcomeTitle />

                    <WelcomeForm 
                        onSubmit={ doApplyInfo }
                    />

                    <ToastContainer />

                </div>

            </section>
        
        </main>
    )

}

export default WelcomeRegister

// messages.