import { ReactSVG } from 'react-svg'
import Button from '../Button'

const ConfirmModal = (props) => {

    const hide = () => {
        props.setShow(false)

        document.body.classList.remove('modal-opened')
    }

    if(props.show)
        return (
            <>
                <div className={`modal px-3 max-w-md fixed top-0 left-0 bottom-0 right-0 m-auto h-fit z-50 ${props.className ? props.className : ''}`}>

                    <div className="w-full rounded-2xl bg-white p-5 flex flex-col gap-6">
                    
                        <div className="flex flex-col gap-4 items-center">

                            {props.children}

                            <span className={` ${props.titleClass ? props.titleClass : ''} text-lg`}> {props.title} </span>

                            { !props.isHtml ? 
                                <p className="text-center leading-7 text-sm"> {props?.description} </p>
                                :
                                <p className="text-center leading-7 text-sm" dangerouslySetInnerHTML={{__html: props?.description}}></p>
                            }

                        </div>

                        <div className={`flex items-center justify-between gap-4 ${props.buttonListClass ? props.buttonListClass : ''}`}>

                            <Button onClick={props.onConfirm ? props.onConfirm : hide} disabled={props.confirmLoading} className={`w-full h-12 disabled:!bg-opacity-80`}>

                                { props.confirmLoading ? 
                                    <ReactSVG 
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/loading-dot.svg`}
                                        className='w-8'
                                    />
                                    :
                                    props.buttonConfirmText ? props.buttonConfirmText 
                                    : 'تایید'
                                }

                            
                            </Button>
                                
                            { !props?.hideButtonCancel &&
                                <Button onClick={hide} disabled={props.confirmLoading} className="w-full bg-gray-100 text-black"> {props.buttonCancelText ? props.buttonCancelText : 'لغو'} </Button>
                            }

                        </div>

                    </div>

                </div>

                <div onClick={props.onHide ? props.onHide : hide} className={`backdrop ${props.backdropClass ? props.backdropClass : ''}`}></div>
            </>
        )

}

export default ConfirmModal