import { ADD_EVENT_TO_HOME_INFO, ADD_ITEM_TO_HOME_INFO_NOTE, ADD_ITEM_TO_LAW_SUITS_HOME, DELETE_CASE_FROM_HOME_INFO, DELETE_EVENT_FROM_HOME_INFO, DELETE_ITEMS_INFO_HOME_NOTES, DELETE_ITEM_INFO_HOME_NOTES, RESTE_ALL_HOME_DATA_APP, SET_COUNT_OF_MESSAGE_IN_HOME, SET_FAILURE_HOME, SET_INFO_HOME, SET_LOADING_HOME, SUBTRACT_OF_HOME_INFO_CONVERSATION_COUNT, UPDATE_EVENT_ITEM_IN_HOME_INFO, UPDATE_INFO_HOME_NOTES, UPDATE_ITEM_TO_LAW_SUITS_HOME } from "../../action/home/homeType"

const initalState = {
    info: {},
    
    loading: false,
    error: null,
    loaded: false,
}

const homeReducer = (state = initalState, action) => {

    switch(action.type){

        case SET_LOADING_HOME :
            return {
                ...state,
                loading: action.payload,
            }

        case SET_FAILURE_HOME :
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case SET_INFO_HOME :
            return {
                ...state,
                loading: false,
                error: null,
                info: action.payload,
                loaded: true
            }

        case UPDATE_INFO_HOME_NOTES :
            return {
                ...state,
                info: {
                    ...state.info,
                    notes: [
                        action.payload,
                        ...state.info.notes.filter(item => item.id != action.payload.id)
                    ]
                }
            }

        case DELETE_ITEM_INFO_HOME_NOTES :
            return {
                ...state,
                info: {
                    ...state.info,
                    notes: state.info.notes.filter(item => item.id != action.payload)
                }
            }

        case DELETE_ITEMS_INFO_HOME_NOTES :
            return {
                ...state,
                info: {
                    ...state.info,
                    notes: state.info.notes.filter(item => !action.payload.includes(item.id))
                }
            }

        case ADD_ITEM_TO_HOME_INFO_NOTE :
            return {
                ...state,
                info: {
                    ...state.info,
                    notes: [
                        action.payload,
                        ...state.info.notes
                    ]
                }
            }

        case DELETE_EVENT_FROM_HOME_INFO :
            return {
                ...state,
                info: {
                    ...state.info,
                    events: state.info.events.filter(item => item.id != action.payload)
                }
            }

        case UPDATE_EVENT_ITEM_IN_HOME_INFO :
            return {
                ...state,
                info: {
                    ...state.info,
                    events: state.info.events.map(item => {
                        if(item.id == action.payload.id)
                            return action.payload
                        else
                            return item
                    })
                }
            }

        case ADD_EVENT_TO_HOME_INFO :
            return {
                ...state,
                info: {
                    ...state.info,
                    events: [
                        action.payload,
                        ...state.info.events
                    ]
                }
            }


        case DELETE_CASE_FROM_HOME_INFO :
            return {
                ...state,
                info: {
                    ...state.info,
                    law_suits: state.info.law_suits.filter(item => item.id != action.payload)
                }
            }

        case ADD_ITEM_TO_LAW_SUITS_HOME :
            return {
                ...state,
                info: {
                    ...state.info,
                    law_suits: [
                        action.payload,
                        ...state.info.law_suits
                    ]
                }
            }

        case UPDATE_ITEM_TO_LAW_SUITS_HOME :
            return {
                ...state,
                info: {
                    ...state.info,
                    law_suits: state.info.law_suits.map(item => {
                        if( Number(item.id) === Number(action.payload.id) ){
                            return { ...action.payload }
                        }

                        return item
                    })
                }
            }

        case SET_COUNT_OF_MESSAGE_IN_HOME :
            return {
                ...state,
                info: {
                    ...state.info,
                    stats: {
                        ...state?.info?.stats,
                        conversations : action.payload
                    }
                }
            }

        case SUBTRACT_OF_HOME_INFO_CONVERSATION_COUNT :
            return {
                ...state,
                info: {
                    ...state.info,
                    stats: {
                        ...state.info.stats,
                        conversations: Number(state.info.stats.conversations) - action.payload
                    }
                }
            }

        case RESTE_ALL_HOME_DATA_APP :
            return {
                ...state,
                ...initalState
            }

        default : 
            return state

    }

}

export default homeReducer