import axios from 'axios'
import { apiKey } from '../../store'
import { SET_ERROR_PLANS, SET_FREE_PLAN_ERROR, SET_FREE_PLAN_LOADING, SET_LOADING_PLANS, SET_PLANS_DESCRIPTION, SET_PLANS_ITEMS } from './type'
import { SET_USED_GIFT_IN_PROFILE } from '../profile/profileType'

const apiKeyPlans = apiKey + 'plans'

export const getPlanItems = async (authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_LOADING_PLANS,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const data = await axios.get(`${apiKeyPlans}?user_type=lawyer`, config)
        .catch(error => {
            
            dispatch({
                type: SET_ERROR_PLANS,
                payload: 'عدم برقراری ارتباط با سرور'
            })

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_PLANS_ITEMS,
                    payload: data.data.data.plans
                })

                dispatch({
                    type: SET_PLANS_DESCRIPTION,
                    payload: data.data.data?.description
                })
 
                return data.data.data

            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_ERROR_PLANS,
                    payload: data.data.errors[0]?.message
                })

            }

        }

    }

}

export const getFreePlan = async (authToken) => {

    return async (dispatch) => {
        
        dispatch({
            type: SET_FREE_PLAN_LOADING,
            payload: true
        })
    
        const config = {
            headers: {
                'Auth-Token': authToken,
                'X-Auth-Token': authToken,
                'header_app_type': 'lawyer'
            },
            timeout: 15000
        }

        const info = {
            "plan_id": 1,
            "paid_money": 0,
            "discounted_price": 0,
        }

        const data = await axios.post(`${apiKey}plan`, info, config)
        .catch(error => {
            
            dispatch({
                type: SET_FREE_PLAN_ERROR,
                payload: 'عدم برقراری ارتباط با سرور'
            })

            setTimeout(() => {

                dispatch({
                    type: SET_FREE_PLAN_ERROR,
                    payload: null
                })

            }, 1800)

        })

        if(data){

            if(data.data.success){

                dispatch({
                    type: SET_FREE_PLAN_LOADING,
                    payload: false
                })

                dispatch({
                    type: SET_USED_GIFT_IN_PROFILE,
                    payload: 1
                })

                return true
            }

            else if(!data.data.success && data.data.errors){

                dispatch({
                    type: SET_FREE_PLAN_ERROR,
                    payload: typeof data.data.errors === 'string' ? data.data.errors : data.data.errors[0]?.message
                })

                setTimeout(() => {

                    dispatch({
                        type: SET_FREE_PLAN_ERROR,
                        payload: null
                    })

                }, 1800)

            }

        }

    }

}