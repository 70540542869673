import { useDispatch, useSelector } from "react-redux"
import Input from "../../../../../UI/Input"
import { useCookies } from "react-cookie"
import { useEffect } from "react"
import { getBaseDataLawyer } from "../../../../../../redux/action/baseData/baseAction"
import SelectContactCaseDetailPostion from "../Positions"
import Switch from "../../../../../UI/Switch"
import { SET_DETAIL_OF_CASE } from "../../../../../../redux/action/cases/add/type"
import { SET_DETAIL_OF_CASE_EDIT_CASE } from "../../../../../../redux/action/cases/edit/type"
import { useLocation } from "react-router-dom"
import { SET_SUCCESS_BASEDATA } from "../../../../../../redux/action/baseData/baseType"
import { getBaseDataInfoFromLocaleStorage, saveBaseDataInfoToLocaleStorage } from "../../../../../../utils/base-data"

const SelectContactCaseDetail = (props) => {

    const dispatch = useDispatch()
    const baseDataLoading = useSelector(state => state.baseData.loading)
    const baseDataError = useSelector(state => state.baseData.error)
    const baseDataLoaded = useSelector(state => state.baseData.loaded)
    const contactsLoading = useSelector(state => state.contacts.loading)
    const detailInfo = useSelector(state => state.addCase.detail)
    const detailInfoEdit = useSelector(state => state.editCase.detail)

    const [cookies] = useCookies(['authToken'])

    const location = useLocation()

    useEffect(() => {

        getPostionsFromBaseData()

    }, [])

    const getPostionsFromBaseData = async () => {

        if(baseDataLoaded) return

        if(baseDataLoading) return

        const baseData = getBaseDataInfoFromLocaleStorage()

        if(!baseData){
            const data = await dispatch( await getBaseDataLawyer(cookies.authToken) )

            if(data){
                saveBaseDataInfoToLocaleStorage(data)
            }

        }
        else {

            dispatch({
                type: SET_SUCCESS_BASEDATA,
                payload: baseData
            })

        }

    }

    const updateDetailNotify = (value) => {

        if(!props.editMode && !props.updatedPage){

            dispatch({
                type: SET_DETAIL_OF_CASE,
                payload: {
                    ...detailInfo,
                    notify: value
                }
            })

        }
        else if(props.editMode || props.updatedPage){

            dispatch({
                type: SET_DETAIL_OF_CASE_EDIT_CASE,
                payload: {
                    ...detailInfoEdit,
                    notify: value
                }
            })

        }

    }

    return (
        <section className="flex flex-col gap-8">
            
            <div className="flex flex-col gap-8 rounded-2xl shadow-custom-lg border-[3px] border-white p-4 relative">
                
                <div className="grid xm:grid-cols-2 grid-cols-1 gap-8 mb-2">
                
                    <Input 
                        label={'نام'}
                        value={props.user.name}
                        disabled={true}
                        className={'border border-gray-300/80'}
                        loading={contactsLoading}
                    />

                    <Input 
                        label={'موبایل'}
                        value={`${props?.user?.mobile?.slice(7)} ${props?.user?.mobile?.slice(4,7)} ${props?.user?.mobile?.slice(0,4)}`}
                        disabled={true}
                        className={'border border-gray-300/80'}
                        loading={contactsLoading}
                    />
                    
                </div>

            </div>

            <div className="flex flex-col gap-5 rounded-2xl shadow-custom-lg border-[3px] border-white p-4 relative">

                <h3 className="font-medium"> انتخاب نقش </h3>

                <SelectContactCaseDetailPostion 
                    editMode={props.editMode}
                    updatedPage={props.updatedPage}
                />

            </div>

            { location.state?.forMovakels &&
            
                <div className="flex flex-col gap-5 rounded-2xl shadow-custom-lg border-[3px] border-white p-4 relative">

                    <Switch 
                        name={'showEventToMovakel'}
                        title={'نمایش پرونده در اپلیکیشن موکل'}
                        description={'به موکل های این پرونده، اطلاعات پرونده نمایش داده میشود.'}
                        value={(!props.editMode && !props.updatedPage) ? detailInfo?.notify : detailInfoEdit?.notify}
                        onUpdateSwitchValue={updateDetailNotify}
                    />

                </div>

            }

        </section>
    )

}

export default SelectContactCaseDetail