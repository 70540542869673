import Input from "../../../../../../UI/Input"
import Textarea from "../../../../../../UI/Textarea"

const CaseAddFileDescription = ({description, onSetDescription}) => {

    const updateField = (event) => {

        if(event.target.value.length <= 300)
            onSetDescription(event.target.value)

    }

    return (
        <div className="flex gap-3 flex-col">

            <Textarea 
                id={'description'}
                rows={4}
                icon={'description'}
                label={'توضیحات'}
                max={300}
                value={ description }
                onChange={ updateField }
                className={'peer bg-white shadow-custom-lg rounded-xl p-3 px-4 w-full !outline-none border-gray-300/60 focus:!border-primary border transition-colors duration-300'}
                labelColor=""
                labelClass={'w-full block !text-base'}
            /> 

        </div>
    )

}

export default CaseAddFileDescription