import Input from "../../../../../../UI/Input"

const CaseAddFinanicalAmount = ({amount, onSetAmount, loading}) => {

    const updateField = (event) => {
        let value = event.target.value.split('٬').join('').split('')

        value = value.map(item => {
            item = item.replace('۰', '0')
            item = item.replace('۱', '1')
            item = item.replace('۲', '2')
            item = item.replace('۳', '3')
            item = item.replace('۴', '4')
            item = item.replace('۵', '5')
            item = item.replace('۶', '6')
            item = item.replace('۷', '7')
            item = item.replace('۸', '8')
            item = item.replace('۹', '9')
            return item
        }).join('')

        if( isNaN(value) || event.target.value === ' ' ) return

        value = Number(value)

        onSetAmount( value >= 1 ? `${value.toLocaleString('fa')}` : '' )
    }

    return (
        <div className="order-1">

            <div className="relative">

                <Input
                    id={'amount-finanical'}
                    icon={'money'}
                    type="tel"
                    value={ amount }
                    labelSize=""
                    label={'مبلغ'}
                    labelColor=""
                    labelClass={'w-full block'}
                    isValid={ true }
                    className="peer bg-white text-right shadow-custom-lg rounded-xl p-3 px-4 w-full !outline-none border-gray-300/60 focus:!border-primary border transition-colors duration-300" 
                    onChange={ updateField }
                    loading={loading}
                />

                <span className="text-primary absolute left-3 top-1/2 translate-y-1"> تومان </span>

            </div>
        </div>
    )

}

export default CaseAddFinanicalAmount