import { Link, useParams } from "react-router-dom"
import { getProfileColor } from "../../../../../../../utils/messages"
import { useDispatch } from "react-redux"
import { SET_CONTACTS_SEARCH } from "../../../../../../../redux/action/search/searchType"
import { SET_FILTER_CONTACTS } from "../../../../../../../redux/action/contacts/typeContacts"

const AddCaseContactsModalListItem = (props) => {
    
    const dispatch = useDispatch()

    const params = useParams()

    const handleNoImage = () => {

        const color = getProfileColor(props?.mobile ? props?.mobile[10] : 0)

        return (
            <div className={`flex-shrink-0 w-11 h-11 profile-${color} border-[2px] shadow-lg rounded-xl flex items-center justify-center`}>
                <span>{props?.name[0]}</span>
            </div> 
        )
    }

    const cleanSearchValue = () => {
        
        window.scrollTo({top: 0})

        dispatch({
            type: SET_CONTACTS_SEARCH,
            payload: ''
        })
        
        dispatch({
            type: SET_FILTER_CONTACTS,
            payload: {
                value: '',
                list: []
            }
        })

    }

    return (
        <li className="contact-item group flex w-full pl-2 5xm:items-center border border-gray-200 select-none duration-500 bg-white shadow-custom-md rounded-xl 4xm:flex-nowrap 5xm:flex-wrap 5xm:flex-row flex-col gap-4 relative p-0 cursor-pointer">
                
            <Link to={`/law-suits/add/contacts/${props.id}`} state={{forMovakels: props.location, data: props.item, editMode: props.edit, caseId: params?.id ? params?.id : null}} onClick={cleanSearchValue} className="flex items-center gap-4 p-3 w-full">
            
                { props.image ? 
                    <div className="flex-shrink-0 w-12 h-12 bg-slate-200 border-2 border-slate-200 shadow-lg rounded-xl flex items-center justify-center">
                        <img className="rounded-xl w-full h-full" src={props.image} alt={props.name} /> 
                    </div>
                    :
                    handleNoImage()               
                }
                
                <div className="flex flex-col gap-1">
                    <h3 className="text-gray-700"> {props.name} </h3>
                    { props?.mobile !== '' && props?.mobile?.length >= 1 ?
                        <span className="text-xs text-gray-500 flex gap-1.5 mr-auto" dir="ltr">
                            {props.mobile.slice(0,4)} {props.mobile.slice(4,7)} {props.mobile.slice(7)}  
                        </span>
                        :
                        <span className="text-xs text-gray-500 flex gap-1.5 mr-auto">شماره ندارد</span>
                    }
                </div>

            </Link>


        </li>
    )

}

export default AddCaseContactsModalListItem
